import React from 'react';

import BreadcrumbLinksDropdown from '~/components/breadcrumb-links-dropdown';
import BreadcrumbLinkItem from '~/components/breadcrumb-link-item';

import './styles.scss';

const linkListFactory = (links, activeLinkId, onClickCallback) => {
  return links.map((link, index) => ({
    key: link.id || 'root-id',
    Component: (
      <BreadcrumbLinkItem
        key={link.id}
        className={`link-item ${
          parseInt(link.id, 10) === parseInt(activeLinkId, 10) ? 'active' : ''
        }`}
        linkTitle={link.title}
        destination={link.route}
        showSeparator={index < links.length - 1}
        onClickCallback={onClickCallback}
      />
    )
  }));
};

const compressedLinkListFactory = (compressedLinks, onClickCallback) => {
  return {
    key: 'compressed-links-id',
    Component: (
      <BreadcrumbLinksDropdown
        links={compressedLinks}
        onClickCallback={onClickCallback}
      />
    )
  };
};

function Breadcrumb({ links, compressionStart = 4, onClickCallback }) {
  const renderList = [];
  const activeLinkId = links[links.length - 1]
    ? links[links.length - 1].id
    : null;

  if (compressionStart < 4) {
    compressionStart = 4;
  }

  if (links.length >= compressionStart) {
    const rootLink = links.shift();
    const compressedLinks = links.slice(0, links.length - 2);
    const visibleLinks = links.slice(links.length - 2);

    const [rootLinkComponent] = linkListFactory(
      [rootLink],
      activeLinkId,
      onClickCallback
    );
    const compressedLinkListComponent = compressedLinkListFactory(
      compressedLinks,
      onClickCallback
    );
    const visibleLinksComponents = linkListFactory(
      visibleLinks,
      activeLinkId,
      onClickCallback
    );

    renderList.push(
      rootLinkComponent,
      compressedLinkListComponent,
      ...visibleLinksComponents
    );
  } else {
    renderList.push(...linkListFactory(links, activeLinkId, onClickCallback));
  }

  return (
    <div className="breadcrumb">
      {renderList.map(item => (
        <span key={item.key}>{item.Component}</span>
      ))}
    </div>
  );
}

export default Breadcrumb;
