import styled from 'styled-components';

export const StyledTraining = styled.div.attrs(() => ({
  className: 'training-page'
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 32px;

  .page-header {
    padding-bottom: 0px;

    .text-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search-input {
        max-height: 42px;
        width: 40%;
        margin-left: 16px;
        background: var(--white-color);
      }
    }

    h1 {
      margin: 0px;
    }

    p {
      margin-top: 16px;
    }

    button {
      padding: 0px;
    }
  }

  .courses-card {
    width: 100%;
    padding: 32px;
    margin-top: 32px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      display: flex;
      align-items: center;

      .icon {
        font-size: 24px;
        margin-right: 32px;
        color: var(--primary-color);
      }

      h3 {
        font-size: 20px;
        line-height: 1;
      }
    }
  }

  .training-section-list {
    margin-top: 32px;

    .course-category-card {
      min-width: 187px;
      margin-right: 16px;
    }

    .course-category-card:last-child {
      margin-right: 0px;
    }

    .course-card {
      width: 262px;
      min-width: 262px;
      margin-right: 16px;
    }

    .course-card:last-child {
      margin-right: 0px;
    }
  }

  .screen-loading {
    margin-top: 64px;
  }

  @media (max-width: 720px) {
    .page-header {
      .text-info {
        flex-direction: column;
        align-items: flex-start;

        .search-input {
          margin-left: 0px;
          width: 100%;
        }
      }
    }

    .card {
      top: 0px;
    }
  }
`;
