import React from 'react';

import Card from '../card';
import { WololoRewardLine } from '../rewards';
import PostHeader from '~/components/post-header';
import DefaultTaskBody from '../feed/task/default-task-body';
import TaskDoableMobileFooter from '~/components/task-doable-mobile-footer';

function TaskDoableOnMobile({ types, channels, children, ...props }) {
  return (
    <Card>
      {!props.isSubtask && (
        <PostHeader
          info={types[props.type] || channels[props.channel]}
          points={props.wololo_points}
          organization={props.organization}
          expire={props.close_at}
          me_create_wololo={props.me_create_wololo}
          channel={props.channel}
          meTrail={props.me_trail}
        />
      )}
      <DefaultTaskBody {...props}>{children}</DefaultTaskBody>
      <TaskDoableMobileFooter />
      {props.reward_still_available && <WololoRewardLine {...props} />}
    </Card>
  );
}

export default TaskDoableOnMobile;
