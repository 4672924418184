import styled from 'styled-components';

export const StyledMetlife = styled.div.attrs(() => ({
  className: 'metlife-page'
}))`
  .page-header {
    padding-left: 8px;
  }

  @media (max-width: 720px) {
    .page-content {
      .metlife-card {
        width: 100%;

        .card {
          flex-direction: column;
          align-items: flex-start;

          p,
          h3 {
            max-width: 15ch;
          }
        }
      }
    }
  }
`;
