import styled from 'styled-components';

export const StyledRankingHeader = styled.div.attrs(() => ({
  className: 'ranking-list-header'
}))`
  .card-container {
    padding: 20px 32px;
  }

  .ranking-info {
    display: flex;
    justify-content: space-between;

    .title-image-ranking span,
    .info-account-block .label-info {
      color: var(--grey80);

      letter-spacing: 0.5px;

      font-size: 16px;
      font-weight: 450;
      line-height: 110%;
    }

    .title-image-ranking {
      gap: 16px;

      display: flex;

      & > div {
        gap: 4px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      img {
        width: 60px;
        max-width: 60px;
        height: 60px;
        max-height: 60px;

        border-radius: 100%;
      }

      .ranking-title,
      .sep {
        color: var(--grey100);
        font-size: 18px;
        font-weight: 900;

        line-height: 110%;
      }
    }

    .sep {
      margin: 0 5px;
    }

    .button {
      padding: 0;

      & i {
        font-weight: 100;
      }
    }

    .info-account-block {
      gap: 10px;

      display: flex;
      align-items: center;

      .current-position {
        color: var(--brand-primary-color);
        font-weight: 900;
        font-size: 22px;
        line-height: 110%;
        align-items: center;
        letter-spacing: 0.5px;
      }

      .rounded {
        border-radius: 50%;
      }
    }
  }

  @media (max-width: 720px) {
    .ranking-info {
      gap: 22px;
      flex-direction: column;

      .info-account-block {
        justify-content: space-between;
      }
    }
  }
`;
