import React from 'react';
import Bronze from '~/assets/values/bronze.png';
import Silver from '~/assets/values/silver.png';
import Gold from '~/assets/values/gold.png';
import Emerald from '~/assets/values/emerald.png';
import Diamond from '~/assets/values/diamond.png';
import './style.scss';

const Points = ({ value }) => {
  const pointsClassificationFactory = () => {
    const params = {
      name: '',
      image: null
    };

    if (0 <= value && value <= 399) {
      params.name = 'Bronze';
      params.image = Bronze;
    } else if (400 <= value && value <= 599) {
      params.name = 'Prata';
      params.image = Silver;
    } else if (600 <= value && value <= 799) {
      params.name = 'Ouro';
      params.image = Gold;
    } else if (800 <= value && value <= 999) {
      params.name = 'Esmeralda';
      params.image = Emerald;
    } else if (1000 <= value && value <= 10000) {
      params.name = 'Diamante';
      params.image = Diamond;
    } else {
      return null;
    }

    return <img src={params.image} alt={params.name} />;
  };

  return (
    <div className="points">
      <span>{value}</span>
      {pointsClassificationFactory()}
    </div>
  );
};

export default Points;
