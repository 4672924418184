import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'redux-react-hook';

import {
  getExamQuestion,
  saveExamQuestion,
  sendExamQuestion,
  resetExam
} from '~/api/training';

import downloadFile from '~/services/download';

import { showMessage } from '~/store/ducks/messageBar';
import {
  closeDefaultModal,
  openDefaultModal
} from '~/store/ducks/default-modal';

import { deepClone } from '~/utils';

import CustomHeader from '~/components/custom-header';
import Button from '~/components/button';
import Icon from '~/components/icon';
import SuspendedMenu from '~/components/suspended-menu';
import AlertBar from '~/components/alert-bar';
import PageControl from '~/components/page-control';
import CourseExamQuestion from '~/components/course-exam-question';
import CustomLoading from '~/components/custom-loading';
import PendingModificationModal from '~/components/pending-modification-modal';
import ConfirmModal from '~/components/confirm-modal';
import PageHeader from '~/components/page-header';

import StyledCourseExam from './styles';

let changeControl = {};

function CourseExam({
  organization,
  message: { showMessage },
  location,
  history,
  closeDefaultModal
}) {
  const { activeOrganizationId } = organization;
  const questionPage = location.pathname.split('/exam/')[1];
  const firstPage = questionPage ? parseInt(questionPage, 10) : 1;

  const courseId = location.pathname
    .replace('/training/course/', '')
    .split('/exam')[0];

  const dispatch = useDispatch();

  const [course, setCourse] = useState({});
  const [isApproved, setIsApproved] = useState(null);
  const [score, setScore] = useState(null);
  const [canStartAnswer, setCanStartAnswer] = useState(null);
  const [canAnswer, setCanAnswer] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(1);

  const [question, setQuestion] = useState({});

  const [loading, setLoading] = useState(true);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handlePendingChanges = () => {
    dispatch(
      openDefaultModal(
        <PendingModificationModal
          onAction={() => history.push(`/training/course/${courseId}`)}
        />
      )
    );
  };

  const handleBack = () => {
    const currentState = {
      question: { ...question }
    };

    if (JSON.stringify(currentState) !== JSON.stringify(changeControl)) {
      handlePendingChanges();
      return;
    }

    history.push(`/training/course/${courseId}`);
  };

  const handleDownload = () => {
    setDownloadLoading(true);

    downloadFile(course.certificate.url)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Certificado - ${course.title}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(e => {
        showMessage('Ocorreu um erro ao fazer o download', 'danger', 3000);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const handleResetExam = () => {
    setResetLoading(true);

    resetExam(activeOrganizationId, courseId, currentQuestion)
      .then(() => {
        setCurrentQuestion(1);
        window.history.pushState(
          'exam',
          'exam',
          `${location.pathname.split('/exam')[0]}/exam/1`
        );
        loadContent();
      })
      .catch(() => {
        showMessage(
          'Ocorreu um erro ao reiniciar a avaliação.',
          'danger',
          3000
        );
      })
      .finally(() => {
        setResetLoading(false);
      });
  };

  const getSaveData = () => {
    return {
      answer_options: question.answer_options
    };
  };

  const handleSave = () => {
    setSaveLoading(true);
    const saveData = getSaveData();

    saveExamQuestion(activeOrganizationId, courseId, currentQuestion, saveData)
      .then(() => {
        showMessage('Avaliação salva!', 'success', 1000);

        changeControl = deepClone({
          question: { ...question }
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao salvar a avaliação.', 'danger', 3000);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const confirmSend = () => {
    dispatch(
      openDefaultModal(
        <ConfirmModal
          title="Enviar avaliação?"
          description="Tem certeza que deseja enviar esta avaliação?"
          confirmButtonText="Enviar"
          onConfirm={handleSend}
        />
      )
    );
  };

  const handleSend = () => {
    setSendLoading(true);
    setLoading(true);
    const saveData = getSaveData();
    closeDefaultModal();

    saveExamQuestion(activeOrganizationId, courseId, currentQuestion, saveData)
      .then(() => {
        changeControl = deepClone({
          question: { ...question }
        });
        sendExamQuestion(activeOrganizationId, courseId, currentQuestion)
          .then(() => {
            showMessage(
              'Obrigado! A sua resposta à avaliação foi registrada.',
              'success'
            );
            setCurrentQuestion(1);
            window.history.pushState(
              'exam',
              'exam',
              `${location.pathname.split('/exam')[0]}/exam/1`
            );
            loadContent();
          })
          .catch(error => {
            if (error.response.status === 400) {
              showMessage(error.response.data.errors[0], 'danger');
              return;
            }

            history.push('/unexpected-error');
          })
          .finally(() => {
            setSendLoading(false);
            setLoading(false);
          });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao enviar a avaliação.', 'danger', 3000);
      })
      .finally(() => {
        setSendLoading(false);
      });
  };

  const loadQuestion = page => {
    getExamQuestion(activeOrganizationId, courseId, page)
      .then(response => {
        setCurrentQuestion(page);
        const {
          pk,
          title,
          options,
          is_single_choice,
          can_answer,
          can_start_answer,
          answer_options,
          is_correct
        } = response.data;

        const questionData = {
          pk,
          title,
          options,
          is_single_choice,
          can_answer,
          can_start_answer,
          answer_options: answer_options === null ? [] : answer_options,
          is_correct
        };

        setQuestion(questionData);

        changeControl = deepClone({
          question: { ...questionData }
        });

        window.history.pushState(
          'exam',
          'exam',
          `${location.pathname.split('/exam')[0]}/exam/${page}`
        );
      })
      .catch(error => {
        history.push('/unexpected-error');
      })
      .finally(() => {
        setQuestionLoading(false);
      });
  };

  const changePage = page => {
    setQuestionLoading(true);
    const saveData = getSaveData();

    if (isApproved !== null) {
      loadQuestion(page);
      return;
    }

    saveExamQuestion(activeOrganizationId, courseId, currentQuestion, saveData)
      .then(() => {
        loadQuestion(page);
      })
      .catch(() => {
        history.push('/unexpected-error');
      });
  };

  const loadContent = () => {
    setLoading(true);

    getExamQuestion(activeOrganizationId, courseId, firstPage)
      .then(response => {
        const {
          course,
          is_approved,
          score,
          idx,
          count,
          pk,
          title,
          options,
          is_single_choice,
          can_answer,
          can_start_answer,
          answer_options,
          is_correct
        } = response.data;

        const questionData = {
          pk,
          title,
          options,
          is_single_choice,
          can_answer,
          can_start_answer,
          answer_options: answer_options === null ? [] : answer_options,
          is_correct
        };

        setCourse(course);
        setCanStartAnswer(can_start_answer);
        setCanAnswer(can_answer);
        setIsApproved(is_approved);
        setScore(score);
        setCurrentQuestion(idx);
        setTotalQuestions(count);
        setQuestion(questionData);

        changeControl = deepClone({
          question: { ...questionData }
        });
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          showMessage('Esta avaliação não está disponível.', 'danger', 3000);
          history.push(`/training/course/${courseId}`);
          return;
        }

        history.push('/unexpected-error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <StyledCourseExam>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              Voltar
            </Button>
          </div>
          <div className="right-side">
            {!loading &&
              (isApproved === null || isApproved === false) &&
              canAnswer && (
                <>
                  <Button
                    color="primary"
                    className="outline"
                    disabled={
                      loading ||
                      saveLoading ||
                      sendLoading ||
                      isApproved === false
                    }
                    onClick={handleSave}
                  >
                    {!saveLoading && 'Salvar'}
                    {saveLoading && (
                      <CustomLoading type="spin" height={16} width={16} fluid />
                    )}
                  </Button>
                  <Button
                    color="primary"
                    disabled={
                      loading ||
                      saveLoading ||
                      sendLoading ||
                      isApproved === false
                    }
                    onClick={confirmSend}
                  >
                    {!sendLoading && 'Enviar'}
                    {sendLoading && (
                      <CustomLoading
                        type="spin"
                        height={16}
                        width={16}
                        fluid
                        color="#FFFFFF"
                      />
                    )}
                  </Button>
                </>
              )}

            {!loading && (isApproved === null || isApproved === false) && (
              <SuspendedMenu>
                <Button
                  color="primary"
                  className="outline"
                  disabled={
                    loading ||
                    saveLoading ||
                    sendLoading ||
                    isApproved === false
                  }
                  onClick={handleSave}
                >
                  {!saveLoading && 'Salvar'}
                  {saveLoading && (
                    <CustomLoading type="spin" height={16} width={16} fluid />
                  )}
                </Button>
                <Button
                  color="primary"
                  className="outline"
                  disabled={
                    loading ||
                    saveLoading ||
                    sendLoading ||
                    isApproved === false
                  }
                  onClick={confirmSend}
                >
                  {!sendLoading && 'Enviar'}
                  {sendLoading && (
                    <CustomLoading
                      type="spin"
                      height={16}
                      width={16}
                      fluid
                      color="#FFFFFF"
                    />
                  )}
                </Button>
              </SuspendedMenu>
            )}
          </div>
        </div>
      </CustomHeader>

      <div className="page-content">
        {!loading && (
          <>
            <div className="exam-header">
              <PageHeader>
                <div className="text-info">
                  <h1>Avaliação de aprendizagem</h1>
                  <p>{course.title}</p>
                </div>
              </PageHeader>

              {isApproved && (
                <AlertBar icon="check" title="Parabéns!" theme="success">
                  <p>Seu aproveitamento foi de {score}%.</p>
                  {course.certificate && course.certificate.status === 'done' && (
                    <Button
                      color="success"
                      className="success"
                      onClick={handleDownload}
                      disabled={downloadLoading}
                    >
                      {!downloadLoading ? 'Baixar certificado' : 'Baixando...'}
                    </Button>
                  )}
                </AlertBar>
              )}

              {isApproved === false && (
                <AlertBar icon="times" title="Desculpe!" theme="failure">
                  <p>
                    Seu aproveitamento de {score}% foi inferior ao mínimo
                    exigido.
                  </p>
                  {canStartAnswer && (
                    <Button
                      color="primary"
                      className="outline"
                      onClick={handleResetExam}
                      disabled={resetLoading}
                    >
                      {!resetLoading ? 'Tentar novamente' : 'Reiniciando...'}
                    </Button>
                  )}
                </AlertBar>
              )}
            </div>

            <PageControl
              pageTitle="Questão"
              counterLabel={`${currentQuestion}/${totalQuestions}`}
              previousDisabled={currentQuestion === 1}
              onPrevious={() => changePage(currentQuestion - 1)}
              nextDisabled={currentQuestion === totalQuestions}
              onNext={() => changePage(currentQuestion + 1)}
              disabled={questionLoading || saveLoading || sendLoading}
            />

            <div className="course-exam-question">
              {!questionLoading && question && (
                <CourseExamQuestion
                  statement={question.title}
                  options={question.options}
                  answers={question.answer_options}
                  type={question.is_single_choice ? 'single' : 'multi'}
                  isCorrect={question.is_correct}
                  question={question}
                  setQuestion={setQuestion}
                  disabled={saveLoading || sendLoading || !question.can_answer}
                />
              )}

              {questionLoading && (
                <CustomLoading type="spin" height={56} width={56} fluid />
              )}
            </div>
          </>
        )}

        {loading && <CustomLoading type="spin" height={56} width={56} fluid />}
      </div>
    </StyledCourseExam>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  },
  message: bindActionCreators(
    {
      showMessage
    },
    dispatch
  )
});

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseExam));
