import styled from 'styled-components';

export const StyledMarkAsDoneButton = styled.button.attrs(() => ({
  className: 'mark-as-done-button'
}))`
  border: 1px solid var(--border-color);
  background: var(--white-color);
  color: var(--primary-color);
  outline: none;
  border-radius: 4px;
  padding: 6px 18px;
  line-height: 1;
  min-height: 34px;
  cursor: pointer;

  display: flex;
  align-items: center;

  .open-check {
    width: 16px;
    height: 16px;
    border: 2px solid var(--secondary-text-color);
    border-radius: 50%;
    margin-right: 8px;
  }

  &.done {
    cursor: initial;
    background: var(--success-color);
    color: var(--white-color);
    border-color: var(--success-color);
  }

  &.disabled {
    cursor: initial;
    color: var(--secondary-text-color);
  }
`;
