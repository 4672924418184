import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { closeDefaultModal } from '~/store/ducks/default-modal';

import Close from '../close';

import './style.scss';

ReactModal.setAppElement('#root');

const DefaultModal = ({ isOpen, maxWidth, closeDefaultModal, children }) => (
  <ReactModal
    className="default-modal"
    overlayClassName="default-modal-overlay"
    isOpen={isOpen}
    parentSelector={() => document.getElementById('modal-root')}
    onRequestClose={closeDefaultModal}
    style={{
      content: {
        maxWidth: maxWidth
      }
    }}
  >
    <div className="modal-header">
      <Close onClick={closeDefaultModal} />
    </div>
    <div className="modal-content">{children}</div>
  </ReactModal>
);

DefaultModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  }
});

export default connect(null, mapDispatchToProps)(DefaultModal);
