import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { closeNewDefaultModal } from '~/store/ducks/new-default-modal';

import Close from '../close';

import './style.scss';

ReactModal.setAppElement('#root');

const NewDefaultModal = ({
  isOpen,
  maxWidth,
  closeNewDefaultModal,
  children
}) => (
  <ReactModal
    className="new-default-modal"
    overlayClassName="new-default-modal-overlay"
    isOpen={isOpen}
    parentSelector={() => document.getElementById('modal-root')}
    onRequestClose={closeNewDefaultModal}
    style={{
      content: {
        maxWidth: maxWidth
      }
    }}
  >
    <div className="modal-header">
      <Close onClick={closeNewDefaultModal} />
    </div>
    <div className="modal-content">{children}</div>
  </ReactModal>
);

NewDefaultModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

const mapDispatchToProps = dispatch => ({
  closeNewDefaultModal: () => {
    dispatch(closeNewDefaultModal());
  }
});

export default connect(null, mapDispatchToProps)(NewDefaultModal);
