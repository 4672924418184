import styled from 'styled-components';

export const StyledEditableField = styled.div.attrs(() => ({
  className: 'editable-field'
}))`
  width: 100%;

  .field-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      padding: 0px 4px;
      min-height: 16px;
      border: none;

      i {
        color: var(--secondary-text-color);
      }
    }
  }

  input {
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
`;
