import React from 'react';
import './style.scss';

const PostImage = props => (
  <div className="post-image">
    <img src={props.url} alt="Post" />
    {props.children}
  </div>
);

export default PostImage;
