import React from 'react';
import PostHeader from '~/components/post-header';

const channels = {
  profile: 'Perfil',
  facebook: 'Facebook',
  whatsapp: 'Whatsapp',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  youtube: 'Youtube',
  publishing: 'Publicação',
  questionnaire: 'Questionário'
};

const TaskHeader = ({
  channel,
  wololo_points,
  organization,
  close_at,
  me_create_wololo,
  icon_type,
  me_trail
}) => (
  <PostHeader
    info={channels[channel]}
    points={wololo_points}
    organization={organization}
    meTrail={me_trail}
    expire={close_at}
    me_create_wololo={me_create_wololo}
    icon_type={icon_type}
  />
);

export default TaskHeader;
