import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { closeDefaultModal } from '~/store/ducks/default-modal';

import Icon from '~/components/icon';

import StyledManaged from './styles';

function Managed({
  name,
  department,
  profile,
  performanceUrl,
  hasAnswer,
  disabled,
  history,
  closeDefaultModal
}) {
  const handleClick = () => {
    closeDefaultModal();
    history.push(performanceUrl);
  };

  return (
    <StyledManaged onClick={handleClick} disabled={disabled}>
      <div className="info">
        <h3>
          {name}
          {hasAnswer && <Icon name="check" />}
        </h3>
        <p className="description">
          {department} • {profile}
        </p>
      </div>
      <div className="actions">
        <Icon name="next" />
      </div>
    </StyledManaged>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  }
});

export default connect(null, mapDispatchToProps)(withRouter(Managed));
