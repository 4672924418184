import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { InputFinalForm } from '~/components/form';
import { maskValidator, composeValidators } from '~/components/form/validators';
import { getAddressByCEP } from '~/api/utils';
import PropTypes from 'prop-types';

export const CepField = ({
  name,
  value,
  onResolve,
  validate,
  placeholder = 'CEP'
}) => {
  const cepValidator = useMemo(
    () => composeValidators(...[validate, maskValidator].filter(v => !!v)),
    [validate]
  );

  return (
    <Field
      name={name}
      parse={null}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      component={InputFinalForm}
      validate={cepValidator}
      placeholder={placeholder}
      onBlur={() => {
        const ZIPCODE_REGEX = /(\d{5})-?(\d{3})/;
        const matches = value && value.match(ZIPCODE_REGEX);

        if (matches) {
          getAddressByCEP(matches[1] + matches[2])
            .then(({ data: { street, neighborhood, city, state } }) => {
              onResolve({
                street,
                neighborhood,
                city,
                state
              });
            })
            .catch(() => {
              onResolve({});
            });
        }
      }}
    />
  );
};

CepField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onResolve: PropTypes.func
};

CepField.defaultValues = {
  onResolve: () => {}
};
