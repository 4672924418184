import styled from 'styled-components';

export const StyledCourseStep = styled.div.attrs(() => ({
  className: 'course-step'
}))`
  width: 100%;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .info-side {
    position: relative;
    display: flex;
    align-items: center;

    .step-status {
      position: relative;
      width: 20px;
      height: 20px;
      margin-right: 32px;
      border: 1px solid #e5e5e5;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      .status-content {
        width: 12px;
        height: 12px;
        background: var(--secondary-text-color);
        border-radius: 50%;
      }

      &.done {
        border-color: var(--primary-color);
        background: var(--background-color);
        .status-content {
          background: var(--primary-color);
        }
      }

      &.available {
        background: var(--white-color);
        border-color: var(--primary-color);
        .status-content {
          background: var(--border-color);
        }
      }

      &::before {
        content: '';
        display: block;
        background-color: #e5e5e5;
        height: 79px;
        width: 1px;
        position: absolute;
        top: -79px;
        left: 50%;
        z-index: 1100;
      }

      &.done::before,
      &.available::before {
        background-color: var(--primary-color);
      }

      &.no-parent::before {
        display: none;
      }
    }

    .content-type {
      margin-right: 16px;
    }

    .step-title {
      color: var(--secondary-text-color);
      max-width: 50ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .actions-side {
    width: max-content;

    button {
      padding-right: 0px;
    }

    button:disabled {
      i {
        color: var(--secondary-text-color);
      }
    }
  }

  @media (max-width: 720px) {
    padding: 16px 16px;

    .info-side {
      .step-status {
        margin-right: 16px;

        &::before {
          height: 47px;
          top: -47px;
        }
      }

      .content-type {
        font-size: 12px;
      }

      .step-title {
        max-width: 8ch;
      }
    }

    .actions-side {
      button {
        width: max-content;
        padding: 4px 0px 4px 4px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        i {
          text-align: end;
        }
      }
    }
  }
`;
