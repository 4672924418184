import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showMessage } from '~/store/ducks/messageBar';
import { addWololo } from '~/services/tasks';
import { FileUpload } from '~/services/upload';

import { TaskHeader, TaskBody } from '~/components/task-helpers';
import { CommentBoxAndButtons, TaskCommentBox } from '~/components/comments';
import { WololoRewardLine } from '~/components/rewards';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Card from '~/components/card';
import Dropzone from '~/components/form/dropzone';

function UploadFileTask({ organization: { activeOrganizationId }, ...props }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [progress, setProgress] = useState({
    percentage: 0,
    completed: false
  });

  const answer = () => {
    const { id } = props;

    if (!file) {
      props.showMessage('Selecione um arquivo para enviar!', 'danger', 3000);
      return;
    }

    if (file.size > 100000000) {
      props.showMessage(
        'O tamanho do arquivo não pode ser superior à 100mb',
        'danger',
        3000
      );
      return;
    }

    setLoading(true);

    const newFileUpload = new FileUpload();
    setFileUpload(newFileUpload);

    newFileUpload
      .send(file, `core/task/${id}`, activeOrganizationId, progress => {
        setProgress(lastProgress => ({
          ...lastProgress,
          percentage: progress
        }));
      })
      .then(response => {
        const { moduleId } = response;

        setProgress(lastProgress => ({ ...lastProgress, completed: true }));

        const data = {
          share_id: moduleId
        };

        addWololo(id, data)
          .then(() => props.onFinish())
          .catch(() => {
            setLoading(false);
            props.showMessage(
              'Ocorreu um erro ao enviar a resposta',
              'danger',
              3000
            );
          });
      })
      .catch(error => {
        setProgress({
          percentage: 0,
          completed: false
        });
        setLoading(false);

        if (error.name !== 'upload-canceled') {
          props.showMessage(
            'Ocorreu um erro ao enviar o arquivo',
            'danger',
            3000
          );
        }
      });
  };

  const handleCancel = () => {
    fileUpload.cancel();
  };

  const handleDrop = files => {
    setFile(files[0]);
  };

  return (
    <Card>
      <TaskHeader {...props} icon_type="post" />
      <TaskBody {...props} />
      {!props.me_create_wololo && (
        <>
          <Dropzone
            placeholder="Arraste e solte seu arquivo aqui, ou clique para selecionar"
            sendedFileName={file ? file.name : null}
            onDrop={handleDrop}
            progress={{
              percentage: progress.percentage,
              completed: progress.completed,
              onCancel: handleCancel
            }}
            onRemove={() => setFile(null)}
            disabled={loading}
          />
          <div className="text-center">
            <Button
              onClick={answer}
              color={props.channel}
              title={!loading ? 'Enviar arquivo' : 'Enviando...'}
              disabled={loading}
            >
              <Icon name={props.channel} marginRight />
              <span>{!loading ? 'Enviar arquivo' : 'Enviando...'}</span>
            </Button>
          </div>
        </>
      )}

      {props.can_comment ? (
        <CommentBoxAndButtons
          {...props}
          childCommentsProps="taskcomment_set"
          CommentBoxComponent={TaskCommentBox}
        />
      ) : null}

      {props.reward_still_available && <WololoRewardLine {...props} />}
    </Card>
  );
}

const mapStateToProps = store => ({
  organization: store.organization
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileTask);
