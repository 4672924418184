import React from 'react';
import moment from 'moment';
import LinkPreviewMedia from '~/components/link-preview-media';

const DefaultTaskBody = ({ children, ...props }) => (
  <div
    className={`card-body default-task-body ${
      props.className ? props.className : ''
    }`}
  >
    <div className="content-card">
      {props.cover && (
        <LinkPreviewMedia
          channel={props.channel}
          type={props.type}
          id={props.id}
        >
          <img src={props.cover} alt="Cover" />
        </LinkPreviewMedia>
      )}
      <div className="title-date-task">
        {props.title && (
          <h3>
            <LinkPreviewMedia
              channel={props.channel}
              type={props.type}
              id={props.id}
            >
              {props.title}
            </LinkPreviewMedia>
          </h3>
        )}
        {props.open_at && (
          <p>
            <LinkPreviewMedia
              channel={props.channel}
              type={props.type}
              id={props.id}
            >
              Postado em {moment(props.open_at).format('DD/MM/YYYY')}
            </LinkPreviewMedia>
          </p>
        )}
      </div>

      {props.description && (
        <p className="text-center">
          <LinkPreviewMedia
            channel={props.channel}
            type={props.type}
            id={props.id}
          >
            {props.description}
          </LinkPreviewMedia>
        </p>
      )}
    </div>
    {children}
    {props.suggested_caption && (
      <p className="suggested-caption">
        {props.type === 'comment' ? (
          <strong>Comentário sugerido:</strong>
        ) : (
          <strong>Legenda sugerida:</strong>
        )}
        <br />
        {props.suggested_caption}
      </p>
    )}
  </div>
);

export default DefaultTaskBody;
