import React from 'react';
import store from '~/store';
import {
  openGenericModal,
  closeGenericModal
} from '~/store/ducks/generic-modal';
import { addWololo } from '~/services/tasks';
import {
  ExternalActionExplanation,
  ExternalActionConfirm
} from '~/components/external-action-explanation';

const BASE_URL = 'https://twitter.com/intent';

export const externalTwitter = ({
  id,
  externalUrl,
  isSubtask,
  onFinish,
  data
}) => {
  return new Promise((resolve, reject) => {
    const cancel = () => {
      reject();
      store.dispatch(closeGenericModal());
    };

    const confirm = () => {
      if (isSubtask) {
        onFinish(id);
        store.dispatch(closeGenericModal());
        resolve();
        return;
      }

      const answer = data ? data : {};

      addWololo(id, answer, true)
        .then(() => {
          onFinish(id);
          resolve();
        })
        .catch(reject);
      store.dispatch(closeGenericModal());
    };

    const next = ({ hide }) => {
      if (hide) {
        window.localStorage.hideExternalActionConfirm = true;
      }

      window.open(externalUrl);

      store.dispatch(
        openGenericModal(
          <ExternalActionConfirm onCancel={cancel} onConfirm={confirm} />
        )
      );
    };

    if (window.localStorage.hideExternalActionConfirm) {
      next({});
    } else {
      store.dispatch(
        openGenericModal(
          <ExternalActionExplanation onContinue={next} onCancel={cancel} />
        )
      );
    }
  });
};

const twitter = {
  tweet({ id, wololo_target_message, wololo_target_url, isSubtask, onFinish }) {
    wololo_target_message = wololo_target_message
      .replace(/\r?\n/g, '\n')
      .replace(/\n{2,}/g, '\n');

    const url = `tweet?text=${encodeURIComponent(wololo_target_message)}${
      wololo_target_url ? '&url=' + wololo_target_url : ''
    }`.replace(/[#]/g, '%23');

    return externalTwitter({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish
    });
  },

  image({
    id,
    wololo_target_message,
    wololo_target_image,
    isSubtask,
    onFinish
  }) {
    wololo_target_message = wololo_target_message
      .replace(/\r?\n/g, '\n')
      .replace(/\n{2,}/g, '\n');

    const url = `tweet?text=${encodeURIComponent(
      wololo_target_message
    )}&url=${wololo_target_image}`.replace(/[#]/g, '%23');

    return externalTwitter({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish
    });
  },

  movie({
    id,
    wololo_target_message,
    wololo_target_movie,
    isSubtask,
    onFinish
  }) {
    wololo_target_message = wololo_target_message
      .replace(/\r?\n/g, '\n')
      .replace(/\n{2,}/g, '\n');

    const url = `tweet?text=${encodeURIComponent(
      wololo_target_message
    )}&url=${wololo_target_movie}`.replace(/[#]/g, '%23');

    return externalTwitter({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish
    });
  },

  retweet({ id, object_id, isSubtask, onFinish }) {
    const url = `retweet?tweet_id=${object_id}`;

    return externalTwitter({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish
    });
  },

  like({ id, object_id, isSubtask, onFinish }) {
    const url = `like?tweet_id=${object_id}`;

    return externalTwitter({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish
    });
  },

  messageUgc({ id, wololo_target_url, isSubtask, onFinish }, data) {
    const url = `tweet?text=${data.message}${
      wololo_target_url ? '&url=' + wololo_target_url : ''
    }`.replace(/[#]/g, '%23');

    return externalTwitter({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish,
      data
    });
  },

  imageUgc({ id, isSubtask, onFinish }, fileUrl, data) {
    const url = `tweet?text=${data.message}&url=${fileUrl}`.replace(
      /[#]/g,
      '%23'
    );

    return externalTwitter({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish,
      data
    });
  },

  movieUgc({ id, isSubtask, onFinish }, fileUrl, data) {
    const url = `tweet?text=${data.message}&url=${fileUrl}`.replace(
      /[#]/g,
      '%23'
    );

    return externalTwitter({
      id,
      externalUrl: `${BASE_URL}/${url}`,
      isSubtask,
      onFinish,
      data
    });
  }
};

export default twitter;
