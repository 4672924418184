import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';

const StyledLink = ({ to, children }) => {
  return (
    <Link className="link" to={to}>
      {children}
    </Link>
  );
};

StyledLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default StyledLink;
