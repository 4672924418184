import React from 'react';
import PropTypes from 'prop-types';
import AppStore from '~/assets/images/app_store.svg';
import PlayStore from '~/assets/images/google_play.svg';
import './style.scss';

const stores = {
  app: {
    name: 'App Store',
    image: AppStore
  },
  play: {
    name: 'Play Store',
    image: PlayStore
  }
};

const Store = ({ name, url }) => {
  const storeInfo = stores[name];
  return (
    <a
      className="store"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={storeInfo.name}
    >
      <img src={storeInfo.image} alt={storeInfo.name} />
    </a>
  );
};

Store.propTypes = {
  name: PropTypes.oneOf(['app', 'play']).isRequired
};

export default Store;
