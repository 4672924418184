import React from 'react';

import Icon from '~/components/icon';

import StyledEmptyList from './styles';

function EmptyList({ message, icon, className, hideIcon }) {
  return (
    <StyledEmptyList className={className}>
      {!hideIcon && <Icon name={icon || 'triangle-alert'} />}

      <p>{message || 'Não há itens'}</p>
    </StyledEmptyList>
  );
}

export default EmptyList;
