import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { unregisterAccount } from '~/store/ducks/account';

import { PublicWrapper, PublicHeader, PublicFooter } from '~/components/public';
import Button from '~/components/button';

import StyledPageNotFound from './styles';

function PageNotFound({ history }) {
  return (
    <StyledPageNotFound>
      <PublicWrapper>
        <PublicHeader />
        <div className="main">
          <div className="content">
            <h3>Página não encontrada</h3>
            <p>
              Parece que a página que você está tentando acessar não está
              disponível. Verifique a URL ou volte para a página inicial.
            </p>

            <div className="actions">
              <Button color="primary" fill onClick={() => history.push('/')}>
                Voltar para a página inicial
              </Button>
            </div>
          </div>
        </div>
        <PublicFooter />
      </PublicWrapper>
    </StyledPageNotFound>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ unregisterAccount }, dispatch);

export default withRouter(connect(mapDispatchToProps)(PageNotFound));
