import React from 'react';
import PropTypes from 'prop-types';
import Icon from '~/components/icon';
import './style.scss';

const ProfileInfo = ({ image, right, name, info, rightImage, isTrailTask }) => {
  return (
    <div className="profile-info">
      {!isTrailTask ? (
        <img src={image} alt="Profile" className="profile-image" />
      ) : (
        <Icon name="trails" />
      )}
      {rightImage}
      <div className="profile-info-name">
        <h3>{name}</h3>
        {info && <small>{info}</small>}
      </div>

      {right ? right : null}
    </div>
  );
};

ProfileInfo.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  info: PropTypes.string,
  rightImage: PropTypes.node
};

export default ProfileInfo;
