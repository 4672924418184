import React from 'react';
import Card from '../card';
import PostHeader from '../post-header';
import PostImage from '../post-image';

import './style.scss';

function OrganizationAlert({ data }) {
  return (
    <div className="organization-alert">
      <div className="organization-alert-content">
        <Card>
          <PostHeader
            info="Alerta"
            icon_type="post"
            organization={data.organization}
          />
          <div className="card-body">
            <PostImage url={data.image} />
            <h3 className="text-center">{data.title}</h3>
            <p className="text-center">{data.description}</p>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default OrganizationAlert;
