import styled from 'styled-components';

export const StyledCellDetails = styled.div`
  margin-bottom: 32px;

  h3 {
    line-height: 1;
    font-size: 21px;
    margin-bottom: 10px;
  }

  p {
    color: var(--secondary-text-color);
  }
`;
