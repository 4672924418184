import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Separator = ({ children }) => (
  <div className="separator">{children && <span>{children}</span>}</div>
);

Separator.defaultProps = {
  children: ''
};

Separator.propTypes = {
  children: PropTypes.string
};

export default Separator;
