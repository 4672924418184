import { ORGANIZATION_ID } from '~/settings';

import { getOrganizationStoredID } from '~/utils';

export const Types = {
  SET_ORGANIZATION: 'organization/SET_ORGANIZATION'
};

const defaultState = {
  activeOrganizationId: getOrganizationStoredID() || ORGANIZATION_ID
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.SET_ORGANIZATION:
      return {
        activeOrganizationId: action.activeOrganizationId
      };
    default:
      return state;
  }
}

export const setOrganization = id => ({
  type: Types.SET_ORGANIZATION,
  activeOrganizationId: id
});
