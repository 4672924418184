import api from './api';

export const getFullLibrary = organizationId =>
  api.get('/library/', {
    params: {
      organization: organizationId
    }
  });

export const getFolder = (folderId, organizationId) =>
  api.get(`/library/${folderId}/`, {
    params: {
      organization: organizationId
    }
  });
