import { getInitialFeed, getNextItems } from '~/api/questionnaire';

export const Types = {
  RESET: 'questionnaire/RESET',
  LOAD: 'questionnaire/LOAD',
  LOAD_MORE: 'questionnaire/LOAD_MORE'
};

const defaultState = {
  loading: false,
  loadingMore: false,
  data: [],
  nextUrl: null,
  total: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.RESET:
      return defaultState;

    case `${Types.LOAD}_FULFILLED`: {
      const {
        payload: {
          data: { count, next, results }
        }
      } = action;

      return {
        ...state,
        data: results,
        nextUrl: next,
        total: count,
        loading: false
      };
    }

    case `${Types.LOAD}_PENDING`: {
      return {
        ...state,
        loading: true
      };
    }
    case `${Types.LOAD_MORE}_PENDING`: {
      return {
        ...state,
        loadingMore: true
      };
    }

    case `${Types.LOAD_MORE}_FULFILLED`: {
      const {
        payload: {
          data: { count, next, results }
        }
      } = action;

      return {
        ...state,
        data: [...state.data, ...results],
        nextUrl: next,
        total: count,
        loadingMore: false
      };
    }

    // case ActionsTypes.TASK_FINISHED: {
    //   const {
    //     payload: { type, id }
    //   } = action;

    //   const feedItem = state.data.find(
    //     d => d.type === type && d.object.id === id
    //   );
    //   const index = state.data.indexOf(feedItem);
    //   const newData = [...state.data];
    //   const newFeedItem = { ...feedItem };

    //   if (newFeedItem.object.can_comment) {
    //     newFeedItem.object.me_create_wololo = true;

    //     newData.splice(index, 1, newFeedItem);

    //     return {
    //       ...state,
    //       data: newData
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       data: state.data.filter(d => d !== feedItem)
    //     };
    //   }
    // }

    default:
      return state;
  }
}

export const reset = () => ({
  type: Types.RESET
});

export const load = search => ({
  type: Types.LOAD,
  payload: getInitialFeed({ search })
});

export const loadMore = nextUrl => ({
  type: Types.LOAD_MORE,
  payload: getNextItems(nextUrl)
});
