import React from 'react';
import './style.scss';
import Time from '~/assets/images/time/hdpi/Time Icon.png';
import TimeXhdpi from '~/assets/images/time/xhdpi/Time Icon.png';
import TimeXxhdpi from '~/assets/images/time/xxhdpi/Time Icon.png';

const TimeInfo = () => (
  <span>
    <img
      src={Time}
      style={{ marginRight: '5px' }}
      alt="time"
      srcSet={`${TimeXhdpi} 2x, ${TimeXxhdpi} 3x`}
    />{' '}
    <span className="remain">restando 2 dias</span>
  </span>
);

export default TimeInfo;
