import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, CheckList } from '../../form';
import Button from '../../button';
import Icon from '../../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
import { updateProfile } from '~/api/account';
import { showNewPoints } from '~/services/tasks';

const genders = [
  { name: 'Feminino', value: 'female' },
  { name: 'Masculino', value: 'male' },
  { name: 'Outro', value: 'other' }
];

class Gender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gender: '',
      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target }) {
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  handleSubmit() {
    const { gender, loading } = this.state;

    if (!loading && gender) {
      const { name, email } = this.props.account.data.profile;
      this.setState({ loading: true });

      updateProfile({ gender, name, email })
        .then(() => {
          showNewPoints(this.props.pointsToShow);
          this.props.onFinish();
        })
        .catch(() => {
          this.setState({ loading: false });
          alert('Erro');
        });
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <CheckList
          name="gender"
          type="radio"
          value={this.state.gender}
          onChange={this.handleChange}
          options={genders}
        />
        <div>
          <Button color="primary" disabled={this.state.loading}>
            <Icon name="arrow-right" marginRight />
            <span>Enviar</span>
          </Button>
        </div>
      </Form>
    );
  }
}

Gender.defaultProps = {
  onFinish: () => {}
};

Gender.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Gender);
