import React from 'react';
import { Login as FacebookLogin } from 'react-facebook-sdk';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../button';
import Icon from '../icon';
import { showLoading, closeLoading } from '~/store/ducks/loading';
import { showMessage } from '~/store/ducks/messageBar';
import { registerAccount } from '~/store/ducks/account';
import { loginFacebook } from '~/api/auth';
import { login } from '~/services/auth';

const FacebookAuth = props => {
  const callback = ({ tokenDetail: { accessToken } }) => {
    const { showLoading, closeLoading, showMessage } = props;

    showLoading();

    loginFacebook(accessToken)
      .then(({ data }) => {
        login(data.token);

        if (props.onSignIn) {
          props.onSignIn(data.token);
        }

        props.history.push('/');
      })
      .catch(e => {
        showMessage('Erro ao autenticar com Facebook', 'danger');
      })
      .finally(() => closeLoading());
  };

  return (
    <FacebookLogin
      onResponse={callback}
      onError={(...args) => {
        alert('Erro ao autenticar com o facebook!');
        console.error(...args);
      }}
      render={({ onClick, isWorking, isLoading }) => (
        <Button
          fill
          large
          color="facebook"
          onClick={onClick}
          disabled={isWorking || isLoading}
        >
          <Icon name="facebook" marginRight />
          <span>{props.children}</span>
        </Button>
      )}
    />
  );
};

FacebookAuth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onSignIn: PropTypes.func
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { showLoading, closeLoading, showMessage, registerAccount },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(withRouter(FacebookAuth));
