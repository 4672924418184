import React from 'react';

import Pagination from '~/components/pagination';
import Button from '~/components/button/button.js';
import Loading from '~/components/loading/loading.js';
import CardContainer from '~/components/card-container/card-container.js';

import { StyledSparkTable } from './styles.js';

const SparkTable = ({
  title,
  data,
  selectedLine,
  loading,
  //* Pagination
  showPaginationOnTop,
  pagination,
  //* Functions
  onNext,
  onPrevious,
  onPageClick,
  scrollToPosition
}) => {
  const { numPages, currentPage } = pagination;

  const buildBody = () => {
    return data.map((account, index) => {
      const className = account.position === selectedLine ? 'selected' : '';

      return (
        <tr
          key={`${index}-${account.position}`}
          id={`scroll-position-${account.position}`}
          className={className}
        >
          <td>
            <div>
              <div className="placing">
                <span>{account.position}º</span>
              </div>
              <img className="avatar" src={account.avatar_128x0} alt="avatar" />
              <span>{account.name}</span>
            </div>
          </td>
          <td className="total-score">{account.points} pontos</td>
        </tr>
      );
    });
  };

  const buildPagination = () => {
    return (
      <Pagination
        numPages={numPages}
        currentPage={currentPage}
        pageSize={pagination.pageSize}
        onPageNumberClick={page => onPageClick(page)}
        onPrevious={onPrevious}
        onNext={onNext}
        hasNext={pagination.next !== null}
        hasPrevious={pagination.previous !== null}
      />
    );
  };

  return (
    <StyledSparkTable>
      <h1 className="table-title">{title}</h1>

      <CardContainer>
        <div className="find-myself">
          <Button
            className="ranking-btn link"
            color="primary"
            onClick={() => scrollToPosition()}
          >
            Ir para minha posição
            <i className="icon achievements"></i>
          </Button>
        </div>

        {!loading && (
          <div className="user-list">
            {showPaginationOnTop ? buildPagination() : null}
            <table>
              <tbody>{buildBody()}</tbody>
            </table>
            {buildPagination()}
          </div>
        )}

        <Loading inline visible={loading} />
      </CardContainer>
    </StyledSparkTable>
  );
};

export default SparkTable;
