import React from 'react';

import StyledFab from './styles';

function Fab({ children }) {
  return (
    <StyledFab>
      <div className="view-content">{children}</div>
    </StyledFab>
  );
}

export default Fab;
