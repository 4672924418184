import styled from 'styled-components';

const StyledCourseReactionSurvey = styled.div.attrs(() => ({
  className: 'course-reaction-survey-page'
}))`
  width: 100%;
  display: flex;
  justify-content: center;

  .header-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side {
      .button {
        background: var(--white-color);
        padding: 0px 8px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: flex-end;

      .button {
        margin: 0px 4px;
        min-width: 76px;

        .custom-loading {
          margin: 0px;
        }
      }

      .button:not(.outline) {
        border: 1px solid var(--white-color);
      }

      .button.outline {
        background: var(--white-color);
      }

      .suspended-menu {
        display: none;

        & > button {
          background: var(--primary-color);
          color: var(--white-color);
          min-width: 42px;
        }
      }
    }
  }

  .page-content {
    width: 100%;
    max-width: 800px;
    margin-top: 76px;
    padding: 64px 0px;

    .reaction-survey-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .page-header {
        padding-top: 0px;
      }

      .alert {
        .alert-content {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .alert.failure {
      button {
        background: var(--white-color);
        color: var(--text-color);
      }
    }

    .alert.failure:hover {
      button {
        color: var(--text-color);
      }
    }

    .page-control {
      margin-top: 48px;
      margin-bottom: 32px;
    }
  }

  .custom-loading {
    margin: 32px 0px;
  }

  .go-to-top {
    font-size: 18px;
    background: var(--text-color);
    padding: 6px 18px;

    i {
      margin: 0px;
    }
  }

  @media (max-width: 1366px) {
    .go-to-top {
      position: absolute;
      bottom: 92px;
      right: 24px;
    }
  }

  @media (max-width: 720px) {
    .header-actions {
      .right-side {
        & > button {
          display: none;
        }

        .suspended-menu {
          display: block;
        }
      }
    }

    .page-content {
      padding: 16px;

      .reaction-survey-header {
        .alert {
          .alert-content {
            margin-bottom: 0px;
            flex-direction: column;
            align-items: flex-start;

            button {
              margin-top: 16px;
            }
          }
        }
      }

      .page-control {
        .control {
          p {
            display: block;
          }
        }
      }
    }
  }
`;

export default StyledCourseReactionSurvey;
