import React, { useState } from 'react';

import CustomSelect from '~/components/custom-select';
import Button from '~/components/button';
import Icon from '~/components/icon';
import CustomLoading from '~/components/custom-loading';

import { StyledStatusSelect } from './styles';

function StatusSelect({ statusList, currentStatus, onChangeStatus, loading }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = status => {
    onChangeStatus(status);
    setIsOpen(false);
  };

  return (
    <StyledStatusSelect>
      <CustomSelect
        openButton={
          <div
            className={`${currentStatus.status} ${loading ? 'in-load' : ''}`}
          >
            {!loading ? (
              <>
                {currentStatus.description}
                {statusList.length !== 0 && <Icon name="down-arrow" />}
              </>
            ) : (
              <CustomLoading type="spin" height={16} width={16} fluid />
            )}
          </div>
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disabled={loading || statusList.length === 0}
      >
        {statusList.map(status => (
          <Button
            key={status.status}
            color="primary"
            className={status.status}
            onClick={() => handleOptionClick(status)}
          >
            <span className={`status-color ${status.status}`}></span>
            {status.description}
          </Button>
        ))}
      </CustomSelect>
    </StyledStatusSelect>
  );
}

export default StatusSelect;
