import React, { useEffect, useState } from 'react';

import Icon from '~/components/icon/icon.js';
import Button from '~/components/button/button.js';
import CardContainer from '~/components/card-container/card-container.js';

import { StyledRankingReward } from './styles.js';

const RankingReward = ({ ranking, rewards, winnerRewards }) => {
  const [currentRewards, setCurrentRewards] = useState([]);
  const [rewardPagination, setRewardPagination] = useState({
    page: 1,
    previous: 'disabled',
    next: rewards.length >= 3 ? '' : 'disabled',
    totalPages: Math.ceil(rewards.length / 2),
    initialIndex: 0,
    lastIndex: 2
  });

  const getStatusRanking = () => {
    const status = ranking.status;
    switch (status) {
      case 'opened':
        return 'ativo';

      default:
        return 'encerrado';
    }
  };

  const getLabelTypeReward = (
    type,
    firstPosition,
    points,
    lastPosition,
    isTitle = false
  ) => {
    switch (type) {
      case 'multiple':
        return (
          <>
            <strong>
              {firstPosition}º ao {lastPosition}º
            </strong>
            <span>{isTitle ? ' colocado' : 'Lugar'}</span>
          </>
        );

      case 'points':
        return (
          <>
            <strong>{points}</strong>
            <span>{isTitle ? ' ponto(s)' : 'Ponto(s)'}</span>
          </>
        );

      default:
        return (
          <>
            <strong>{firstPosition}º</strong>
            <span>{isTitle ? ' colocado' : 'Lugar'}</span>
          </>
        );
    }
  };

  const getWinners = idReward => {
    let winners = [];

    if (winnerRewards) {
      winnerRewards.map(winner => {
        if (winner.reward.id === idReward) {
          return winners.push({
            avatar: winner.avatar_128x0,
            name: winner.name,
            points: winner.points
          });
        }

        return null;
      });
    }

    return {
      winners
    };
  };

  const makeWinnerInfo = winners => {
    return winners.map((winner, index) => (
      <React.Fragment key={`${index}-${winner.points}`}>
        <div>
          <img src={winner.avatar} alt="avatar" />
          <span>{winner.name}</span>
        </div>
        <span>{winner.points}</span>
      </React.Fragment>
    ));
  };

  const makeNoWinner = () => {
    return (
      <div className="no-winner">
        <span>Ganhador indefinido</span>
      </div>
    );
  };

  const getCurrentWinner = (type, id) => {
    const { winners } = getWinners(id);

    switch (type) {
      case 'multiple':
        if (winners.length > 0) {
          // return makeWinnerInfo(winners);
          return null;
        }

        return makeNoWinner();
      case 'points':
        if (winners.length > 0) {
          // return makeWinnerInfo(winners);
          return null;
        }

        return makeNoWinner();
      default:
        if (winners.length > 0) {
          return makeWinnerInfo(winners);
        }

        return makeNoWinner();
    }
  };

  const updatePageInfo = (pageIncrement, newInitialIndex, newLastIndex) => {
    const elements = rewards.slice(newInitialIndex, newLastIndex);

    const newInfoPage = {
      page: pageIncrement,
      previous: pageIncrement > 1 ? '' : 'disabled',
      next: pageIncrement === rewardPagination.totalPages ? 'disabled' : '',
      totalPages: Math.ceil(rewards.length / 2),
      initialIndex: newInitialIndex,
      lastIndex: newLastIndex
    };

    setCurrentRewards(elements);
    setRewardPagination(newInfoPage);
  };

  const handlePreviousPage = () => {
    const pageIncrement = rewardPagination.page - 1;
    const newInitialIndex = rewardPagination.initialIndex - 2;
    const newLastIndex = rewardPagination.lastIndex - 2;

    updatePageInfo(pageIncrement, newInitialIndex, newLastIndex);
  };

  const handleNextPage = () => {
    const pageIncrement = rewardPagination.page + 1;
    const newInitialIndex = rewardPagination.initialIndex + 2;
    const newLastIndex = rewardPagination.lastIndex + 2;

    updatePageInfo(pageIncrement, newInitialIndex, newLastIndex);
  };

  useEffect(() => {
    if (rewards) {
      const elements = rewards.slice(0, 2);

      setCurrentRewards(elements);
    }
  }, [rewards]);

  return (
    <StyledRankingReward>
      <h1 className="ranking-reward-title">Recompensas</h1>
      <div className="ranking-reward-list">
        <div className="previous-reward">
          <Button
            color="primary"
            className={`link ${rewardPagination.previous}`}
            onClick={() => handlePreviousPage()}
          >
            <Icon name="previous" />
          </Button>
        </div>
        {currentRewards
          ? currentRewards.map((reward, index) => (
              <CardContainer key={`${index}-${reward.id}`}>
                <span>Ranking {getStatusRanking()}</span>
                {reward.image ? (
                  <img src={reward.image} alt="Reward" />
                ) : (
                  <div className="reward-image" alt="Placeholder Reward">
                    <Icon name="rankings" />
                  </div>
                )}
                <div className="reward-info">
                  <div className="position">
                    {getLabelTypeReward(
                      reward.type,
                      reward.first_position,
                      reward.points,
                      reward.last_position
                    )}
                  </div>
                  <p>
                    {getLabelTypeReward(
                      reward.type,
                      reward.first_position,
                      reward.points,
                      reward.last_position,
                      true
                    )}{' '}
                    | {reward.title}
                  </p>
                </div>
                <div className="current-winner">
                  {ranking.status !== 'opened'
                    ? getCurrentWinner(reward.type, reward.id)
                    : makeNoWinner()}
                </div>
              </CardContainer>
            ))
          : null}
        <div className="previous-reward">
          <Button
            color="primary"
            className={`link ${rewardPagination.next}`}
            onClick={() => handleNextPage()}
          >
            <Icon name="next" />
          </Button>
        </div>
      </div>
    </StyledRankingReward>
  );
};

export default RankingReward;
