import styled from 'styled-components';

export const StyledPagination = styled.div.attrs(() => ({
  className: 'new-pagination'
}))`
  .pagination-container {
    margin: 0;
    padding: 10px 0;

    gap: 12px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .pagination-item {
      min-width: 34px;
      height: 34px;

      color: var(--grey100);

      font-size: 18px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      &.dots:hover {
        background: transparent;
        cursor: default;
      }

      &:hover {
        background: var(--grey20);
        cursor: pointer;
      }

      &.selected {
        color: var(--white-color);
        background: var(--grey100);
      }

      & .previous-btn,
      & .next-btn {
        color: var(--brand-primary-color);
      }

      &.disabled {
        pointer-events: none;

        & .previous-btn,
        & .next-btn {
          opacity: 0.3;
          color: var(--brand-primary-color);
        }

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
`;
