import React from 'react';

import { StyledPDIStatus } from './styles';

function PDIStatus({ status, description }) {
  return (
    <StyledPDIStatus className={status}>
      <p>{description}</p>
    </StyledPDIStatus>
  );
}

export default PDIStatus;
