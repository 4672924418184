import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'redux-react-hook';
import { connect } from 'react-redux';

import { getToken, upadateTokenKey } from '~/services/auth';
import { setUrlToRedirect } from '~/services/navigation';

import { tokenRefresh } from '~/api/auth';

import { setOrganization } from '~/store/ducks/organization';

import { storeOrganizationId } from '~/utils';

import CustomLoading from '~/components/custom-loading';
import CustomHeader from '~/components/custom-header';

import StyledDoCourse from './styles';

function DoCourse({ location, history, account }) {
  const dispatch = useDispatch();

  const { pathname } = location;

  const courseId = pathname.split('/do-course/')[1];
  const organizationId = pathname.split('/do-course/')[0].replace('/org/', '');

  const setOrganizationId = organizationId => {
    dispatch(setOrganization(organizationId));
    storeOrganizationId(organizationId);
  };

  useEffect(() => {
    if (account.loaded) {
      const token = getToken();
      tokenRefresh({
        organization: organizationId,
        token: token
      })
        .then(response => {
          upadateTokenKey(response.data.token);
          setOrganizationId(organizationId);
          setUrlToRedirect(`/training/course/${courseId}`);

          window.location.href = `/training/course/${courseId}`;
        })
        .catch(() => {
          history.push('/unexpected-error');
        });
    }
  }, []);

  return (
    <StyledDoCourse>
      <CustomHeader expanded />
      <CustomLoading type="spin" height={56} width={56} fluid />
      <p>Carregando...</p>
    </StyledDoCourse>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default withRouter(connect(mapStateToProps)(DoCourse));
