import _ from 'lodash';
import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Card from '../card';
import ProfileInfo from '../profile-info';
import Points from '../points';
import Button from '../button';
import TimeInfo from '../time-info';
import { CheckList } from '../form';
import Icon from '../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
// import { updateProfile } from '~/api/account';
import { welcome as WelcomeImage } from '~/components/svg-icons/svg-icons';
import PeopleWalking from '~/assets/images/people-walking.png';
import QuemPostou from '~/assets/images/tutorial/coachmark-quem-postou.png';
import QuemPostou2x from '~/assets/images/tutorial/coachmark-quem-postou@2x.png';
import Pontuacao from '~/assets/images/tutorial/coachmark-pontuacao.png';
import Pontuacao2x from '~/assets/images/tutorial/coachmark-pontuacao@2x.png';
import Tempo from '~/assets/images/tutorial/coachmark-tempo.png';
import Tempo2x from '~/assets/images/tutorial/coachmark-tempo@2x.png';
import Botao from '~/assets/images/tutorial/coachmark-botao.png';
import Botao2x from '~/assets/images/tutorial/coachmark-botao@2x.png';
import LinhaConteudo from '~/assets/images/tutorial/coachmark-linha-conteudo.png';
import LinhaConteudo2x from '~/assets/images/tutorial/coachmark-linha-conteudo@2x.png';
import TextoConteudo from '~/assets/images/tutorial/coachmark-texto-conteudo.png';
import TextoConteudo2x from '~/assets/images/tutorial/coachmark-texto-conteudo@2x.png';
import { Form, Field } from 'react-final-form';
import TaskCompletedModal from '~/components/task-completed-modal';
import './style.scss';

import { getIcon } from '~/components/post-header/post-header';
import useSite, { withSite } from '~/hooks/use-site';
import useAccount from '~/hooks/use-account';

const TutorialBegin = ({ onContinue }) => (
  <Card>
    <h2>Realize as atividades e ganhe pontos!</h2>
    <p>
      Cada atividade vai lhe dar uma quantidade de pontos que serão acumulados e
      depois passados para os professores.
    </p>
    <Button color="primary" onClick={onContinue}>
      Vamos começar?
    </Button>
  </Card>
);

const ActionExplanation = ({ onClose }) => {
  const { name, tutorialDescription } = useSite();
  const account = useAccount();

  const organizationAvatar = _.get(
    account,
    'data.organization_is_active_set[0].avatar_128x0'
  );

  return (
    <div className="action-explanation">
      <button className="close explanation-close" onClick={onClose}>
        <span>x</span>
      </button>
      <div className="media-modal" />
      <div className="relative">
        <div className="help-who-posted">
          <img
            src={QuemPostou}
            alt="Quem postou"
            srcSet={`${QuemPostou2x} 2x`}
          />
        </div>

        <div className="help-points">
          <img src={Pontuacao} alt="Pontuação" srcSet={`${Pontuacao2x} 2x`} />
        </div>

        <div className="help-time">
          <img src={Tempo} alt="Tempo" srcSet={`${Tempo2x} 2x`} />
        </div>

        <div className="help-task-button">
          <img src={Botao} alt="Botao" srcSet={`${Botao2x} 2x`} />
        </div>

        <Card className="text-left no-overflow">
          <header className="card-header" style={{ marginBottom: '10px' }}>
            <ProfileInfo
              name={name}
              image={organizationAvatar}
              info="2h atrás"
              right={
                <div>
                  <Points value={200} />
                </div>
              }
            />
          </header>

          <div className="relative">
            <img src={PeopleWalking} alt="task" />

            <h3 className="text-center">{tutorialDescription}</h3>

            <div className="help-task-content">
              <img
                src={LinhaConteudo}
                className="linha"
                alt="LinhaConteudo"
                srcSet={`${LinhaConteudo2x} 2x`}
              />
              <div>
                <img
                  src={TextoConteudo}
                  className="texto-conteudo"
                  alt="TextoConteudo"
                  srcSet={`${TextoConteudo2x} 2x`}
                />
              </div>
            </div>
          </div>

          <div className="task-footer">
            <TimeInfo />

            <div>
              <Button color="instagram" onClick={() => {}}>
                <Icon name="instagram" marginRight />
                Curtir
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

class TutorialGender extends PureComponent {
  state = {
    loading: false
  };

  informGender = gender => {
    const { loading } = this.state;

    const {
      onComplete
      // account: {
      //   data: {
      //     profile: { name, email }
      //   }
      // }
    } = this.props;

    if (!loading && gender) {
      this.setState({ loading: true });

      // updateProfile({ gender, name, email })
      //   .then(() => {
      //     onComplete();
      //   })
      //   .finally(() => {
      //     this.setState({ loading: false });
      //   });

      onComplete();
    }
  };

  render() {
    const {
      onHelp,
      date,
      company: { name, avatar_128x0 }
      // site: { actionGenderPoints }
    } = this.props;

    const { loading } = this.state;

    return (
      <Card>
        <header className="post-header task-channel-profile">
          <ProfileInfo
            name={name}
            image={avatar_128x0}
            info={`${moment(date).fromNow(true)} atrás`}
            rightImage={getIcon('profile')}
          />
          <div className="task-info">
            <Points value={10} />
          </div>
        </header>

        <h3>Como esta seu dia hoje?</h3>

        <Form
          onSubmit={({ gender }) => this.informGender(gender)}
          render={({ handleSubmit }) => (
            <>
              <Field
                name="gender"
                render={({ input }) => (
                  <CheckList
                    {...input}
                    options={[
                      { name: 'Excelente', value: 'female' },
                      { name: 'Ótimo', value: 'male' },
                      { name: 'Bom', value: 'other' }
                    ]}
                  />
                )}
              />

              <Button color="primary" onClick={handleSubmit} disabled={loading}>
                Enviar
              </Button>
            </>
          )}
        />

        <Button
          color="dark"
          circle
          outline
          small
          style={{
            position: 'absolute',
            right: 15,
            bottom: 5
          }}
          onClick={onHelp}
        >
          <Icon name="question" />
        </Button>
      </Card>
    );
  }
}

class Tutorial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 'start',
      gender: '',
      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  handleChange({ target }) {
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  handleNext() {
    this.setState(prev => ({
      step: prev.step + 1
    }));
  }

  onInformGender = () => {
    const {
      onFinish,
      openMessageModal,
      site: { actionGenderPoints }
    } = this.props;

    openMessageModal(<TaskCompletedModal actionPoints={actionGenderPoints} />);

    onFinish();
  };

  setStep = step => this.setState({ step });

  render() {
    const { name, date, account, site } = this.props;

    const { step } = this.state;

    const company = this.props.account.data.organization_is_active_set[0];

    return (
      <Card className="tutorial-card text-center no-overflow">
        <WelcomeImage className="welcome-image" />
        <div className="title-holder">
          <h3>Olá, {name}!</h3>
          <p>Bem-vindo(a) ao {site.name}</p>
        </div>
        <div className="tutorial-content">
          {step === 'start' ? (
            <TutorialBegin
              onContinue={() => this.setStep('actionExplanation')}
            />
          ) : null}

          {step === 'actionExplanation' ? (
            <ActionExplanation onClose={() => this.setStep('gender')} />
          ) : null}

          {step === 'gender' ? (
            <TutorialGender
              company={company}
              site={site}
              onComplete={this.onInformGender}
              account={account}
              date={date}
              onHelp={() => this.setStep('actionExplanation')}
            />
          ) : null}
        </div>

        <Button
          className="tutorial-skip"
          outline
          color="primary"
          onClick={this.props.onFinish}
        >
          Pular tutorial
        </Button>
      </Card>
    );
  }
}

const mapStateToProps = store => ({
  account: store.account
});

export default connect(mapStateToProps, { openMessageModal })(
  withSite(Tutorial)
);
