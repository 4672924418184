import React from 'react';
import { withRouter } from 'react-router-dom';

const BreadcrumbLinkItem = ({
  className,
  linkTitle,
  destination,
  showSeparator,
  onClickCallback,
  history
}) => {
  const goToDestination = destinationRoute => {
    if (onClickCallback) {
      onClickCallback(destinationRoute);
    }
    history.push(destinationRoute);
  };

  const disabled = showSeparator ? '' : 'disabled';

  return (
    <div className={className}>
      <button
        disabled={disabled}
        type="button"
        className="font-book no-button"
        onClick={() => goToDestination(destination)}
      >
        {linkTitle}
      </button>
      {showSeparator && <p className="font-book">/</p>}
    </div>
  );
};

export default withRouter(BreadcrumbLinkItem);
