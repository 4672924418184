import moment from 'moment';

const dateValidator = format => value => {
  if (!value) {
    return undefined;
  }
  return moment(value, format).isValid() ? undefined : 'Data inválida';
};

export default dateValidator;
