import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showMessage } from '~/store/ducks/messageBar';

// import { getMetlifeExtracts } from '~/api/organization';

import Button from '~/components/button';
import Icon from '~/components/icon';
import PageHeader from '~/components/page-header';
// import Breadcrumb from '~/components/breadcrumb';
// import SearchInput from '~/components/form/search-input';
import EmptyList from '~/components/empty-list';
// import DepartmentCard from '~/components/department-card';
// import CustomLoading from '~/components/custom-loading';
// import InfiniteScroll from '~/components/infinite-scroll';
// import TagFilter from '~/components/tag-filter';

import { StyledMetlifeExtracts } from './styles';

function MetlifeExtracts({ organization, showMessage, history }) {
  // const { pathname } = history.location;

  // const { activeOrganizationId } = organization;
  // const type = pathname.split('/metlife/')[1].split('/departments')[0];
  // const departmentVar = pathname.split('/departments')[1].replace('/', '');

  // const [pageTitle, setPageTitle] = useState('');
  // const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);
  // const [departments, setDepartments] = useState([]);
  // const [groupList, setGroupList] = useState(null);

  // const [loading, setLoading] = useState(true);
  // const [loadingMore, setLoadingMore] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [nextPage, setNextPage] = useState(null);

  // const [search, setSearch] = useState('');
  // const [currentTimeOut, setCurrentTimeOut] = useState(null);
  // const [selectedGroup, setSelectedGroup] = useState('');

  const handleBack = () => {
    history.goBack();
  };

  // const getDepartments = filters => {
  //   setLoading(true);

  //   getMetlifeExtracts(activeOrganizationId, type, departmentVar, 1, filters)
  //     .then(response => {
  //       const { title, breadcrumb, results, next, groups } = response.data;

  //       setPageTitle(title);
  //       setBreadcrumbLinks(
  //         breadcrumb.map(link => {
  //           link.route = `/metlife/${type}/departments/${link.id}`;
  //           return link;
  //         })
  //       );
  //       setDepartments(results);
  //       setNextPage(next);
  //       setCurrentPage(1);

  //       setGroupList(groups ? groups : null);
  //       setSelectedGroup(groups ? filters.group : '');
  //     })
  //     .catch(error => {
  //       showMessage(
  //         'Ocorreu um erro inesperado ao carregar os itens',
  //         'danger'
  //       );
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // const handleSearch = event => {
  //   if (currentTimeOut) {
  //     clearTimeout(currentTimeOut);
  //   }

  //   const { value } = event.target;

  //   const timeOut = setTimeout(() => {
  //     getDepartments({
  //       search: value,
  //       group: selectedGroup
  //     });
  //   }, 1000);

  //   setCurrentTimeOut(timeOut);
  //   setSearch(value);
  // };

  // const loadMoreDepartments = () => {
  //   setLoadingMore(true);

  //   const filters = {
  //     search: search,
  //     group: selectedGroup
  //   };

  //   getMetlifeExtracts(
  //     activeOrganizationId,
  //     type,
  //     departmentVar,
  //     currentPage + 1,
  //     filters
  //   )
  //     .then(response => {
  //       const { results, next } = response.data;

  //       setDepartments(lastDepartmentList => [
  //         ...lastDepartmentList,
  //         ...results
  //       ]);
  //       setNextPage(next);
  //       setCurrentPage(lastPage => {
  //         return lastPage + 1;
  //       });
  //     })
  //     .catch(() => {
  //       showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
  //     })
  //     .finally(() => {
  //       setLoadingMore(false);
  //     });
  // };

  // useEffect(() => {
  //   const filters = {
  //     search: search,
  //     group: selectedGroup
  //   };

  //   getDepartments(filters);
  // }, [departmentVar]);

  return (
    <StyledMetlifeExtracts>
      <div className="header-actions">
        <Button color="primary" className="link" onClick={handleBack}>
          <Icon name="back" />
          Voltar
        </Button>
      </div>

      <PageHeader>
        <div className="text-info">
          <h1>Extratos</h1>
        </div>
        {/* <SearchInput
          value={search}
          onChange={handleSearch}
          placeholder="Busque por nome"
        /> */}
      </PageHeader>

      {/* <div className="departments">
        {!loading &&
          departments.map(department => (
            <DepartmentCard
              key={`${
                department.department
                  ? `${department.pk}${department.department.pk}`
                  : department.pk
              }`}
              title={department.name}
              url={`/metlife/${type}/departments/${department.pk}`}
              description={department.group ? department.group.title : null}
              color={department.group ? department.group.color : null}
            />
          ))}
      </div> */}

      {/* <InfiniteScroll
        fetchMore={loadMoreDepartments}
        disabled={!nextPage || loading || loadingMore}
      /> */}

      {/* {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

      {loadingMore && (
        <CustomLoading type="spin" height={36} width={36} fluid />
      )} */}

      <EmptyList message="Não há extratos a serem exibidos" />
    </StyledMetlifeExtracts>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MetlifeExtracts)
);
