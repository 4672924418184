import React from 'react';

import './style.scss';

function ChoiceOption({ name, options, onChange, answers, type }) {
  return (
    <div className={'choice-list'}>
      {options.map(opt => (
        <label key={opt.pk} className="choice-item">
          <input
            name={name}
            type={type}
            value={opt.pk}
            onChange={() => onChange(opt.pk)}
            checked={answers.some(answer => answer === opt.pk)}
          />
          <span>{opt.text}</span>
        </label>
      ))}
    </div>
  );
}

export default ChoiceOption;
