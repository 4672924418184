import React from 'react';

import FilePreview from '~/components/file-preview';
import MarkAsDoneButton from '~/components/mark-as-done-button';

import { StyledDownloadFile } from './styles';

function DownloadFile({
  contentType,
  title,
  fileName,
  fileUrl,
  isDone,
  canManuallyAnswer,
  markAsDone
}) {
  return (
    <StyledDownloadFile>
      <div className="step-section">
        <div className="title">
          <h3>{title}</h3>
          <MarkAsDoneButton
            isDone={isDone}
            canManuallyAnswer={canManuallyAnswer}
            onClick={() =>
              markAsDone({
                ignoreAction: true
              })
            }
          />
        </div>
      </div>
      <div className="step-section">
        <FilePreview
          fileName={fileName}
          fileUrl={fileUrl}
          onClick={markAsDone}
        />
      </div>
    </StyledDownloadFile>
  );
}

export default DownloadFile;
