import styled from 'styled-components';

export const StyledMetlifeCard = styled.button.attrs(() => ({
  className: 'metlife-card no-button'
}))`
  width: ${({ fluid }) => (fluid ? '100%' : '50%')};
  padding: 8px;
  height: ${({ fluid }) => (fluid ? 'auto' : '280px')};
  max-height: ${({ fluid }) => (fluid ? 'auto' : '280px')};
  position: relative;

  .card {
    position: relative;
    padding: 42px 48px;
    height: 100%;

    display: flex;
    flex-direction: ${({ fluid }) => (fluid ? 'row' : 'column')};
    align-items: ${({ fluid }) => (fluid ? 'center' : 'flex-start')};
    justify-content: ${({ fluid }) => (fluid ? 'flex-start' : 'center')};

    img {
      /* border-radius: 50%; */
      max-width: 82px;
      margin-bottom: 16px;
      margin-right: 16px;
    }

    p,
    h3 {
      text-align: start;
      max-width: ${({ fluid }) => (fluid ? '42ch' : '24ch')};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      font-size: 16px;
      color: var(--secondary-text-color);
    }

    h3 {
      font-size: 24px;
    }
  }

  &:hover .card {
    box-shadow: 1px 1px 16px var(--background-placeholder-color);
  }
`;
