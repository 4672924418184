import React from 'react';
import './style.scss';

const SearchInput = ({ text, ...props }) => (
  <input
    className={`search-input`}
    type="search"
    placeholder={text}
    {...props}
  />
);

export default SearchInput;
