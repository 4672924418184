import { useEffect } from 'react';
import { connect } from 'react-redux';
import { enableNotification } from '~/api/account';
import useSite from '~/hooks/use-site';
import {
  askNotificationsPermission,
  urlBase64ToUint8Array,
  hasNotificationSupport
} from '~/utils';

const Notifications = ({ organization: { activeOrganizationId } }) => {
  const { pushApplicationServerKey } = useSite();

  useEffect(() => {
    if (hasNotificationSupport()) {
      navigator.serviceWorker
        .register('/js/push.js')
        .then(() => askNotificationsPermission())
        .then(result => {
          switch (result) {
            case 'granted':
              navigator.serviceWorker
                .register('/js/push.js')
                .then(registration => {
                  const subscribeOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(
                      pushApplicationServerKey
                    )
                  };

                  return registration.pushManager.subscribe(subscribeOptions);
                })
                .then(function(pushSubscription) {
                  enableNotification(
                    JSON.stringify(pushSubscription),
                    activeOrganizationId
                  );
                });

              break;

            default:
              break;
          }
        });
    }
  }, []);

  return null;
};

const mapStateToProps = ({ organization }) => ({
  organization
});

export default connect(mapStateToProps)(Notifications);
