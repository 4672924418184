import LogoImg from './comex-assets/images/logo-inverted.png';
import LogoImg2x from './comex-assets/images/logo-inverted.png';
import LogoReverseImg from './comex-assets/images/logo-color.png';
import LogoReverseImg2x from './comex-assets/images/logo-color.png';
import './main.scss';

const ElleSite = {
  name: 'Comex',
  googlePlayUrl: '',
  appStoreUrl: '',
  LogoImg,
  LogoImg2x,
  LogoReverseImg,
  LogoReverseImg2x,
  completeSignUp: false,
  tutorialDescription: 'Comex description...',
  actionGenderPoints: 150,
  pushApplicationServerKey:
    'BMplX3pQp1CyS43RjWTg3imHX2DWbgCTYwBF5jkcOOYA9hcwVGXp5rCk_EBRV1-ugvQuLKDibRlFoDArWx5O4C4',
  loginMinutes: null,
  primaryColor: '#0FDB40'
};

export default ElleSite;
