import api from './api';

export const getAccount = () => api.get('/accounts/me');

export const getAlerts = () => api.get('/accounts/me/alerts');

export const getInvitations = () => api.get('/accounts/me/invitations');

export const getCustomFields = () => api.get('/accounts/me/customfields');

export const saveCustomFields = data =>
  api.put('/accounts/me/customfields', data);

export const getRankings = () => api.get('/accounts/me/rankings');

export const getRanking = id => api.get(`/rankings/${id}`);

export const getRankingAccounts = id => api.get(`/rankings/${id}/accounts`);

export const getRankingAccountsRewards = id =>
  api.get(`/rankings/${id}/rewards/accounts/`);

export const updatePassword = (old_password, new_password) =>
  api.put('/accounts/me/password-update-request', {
    old_password,
    new_password
  });

export const postFacebook = authorization =>
  api.post('/accounts/me/facebook/', { authorization });

export const deleteFacebook = authorization =>
  api.delete('/accounts/me/facebook/disassociate-request/');

export const deleteTwitter = authorization =>
  api.delete('/accounts/me/twitter/disassociate-request/');

export const deleteInstagram = authorization =>
  api.delete('/accounts/me/instagram/disassociate-request/');

export const deleteLinkedin = authorization =>
  api.delete('/accounts/me/linkedin/disassociate-request/');

export const fetchHistory = () => api.get('/accounts/me/history/');

export const removeRequest = password =>
  api.post('/accounts/me/remove-request/', { password });

export const enableNotification = (token, organization) =>
  api.post('/accounts/me/notification-endpoint/', {
    type: 'web',
    token,
    organization
  });

export const updateProfile = (data, token = null) => {
  const formData = new FormData();

  Object.keys(data).forEach(key => formData.append(key, data[key]));

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  if (token) {
    config.headers['Authorization'] = token;
  }

  return api.put('/accounts/me/profile', formData, config);
};
