import React from 'react';

import { Input } from '~/components/form';

import { StyledCustomDecimal } from './styles';

function CustomDecimal({
  id,
  label,
  answer,
  data,
  setData,
  readOnly,
  maxLength,
  errorMessage,
  ...props
}) {
  const setNumber = value => {
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }

    setData(lastData => {
      const newData = [...lastData];

      const currentCustomFieldIndex = newData.findIndex(
        customField => customField.pk === id
      );

      newData[currentCustomFieldIndex].answer = value;
      return newData;
    });
  };

  const handlePaste = (event, value) => {
    value.slice(0, maxLength);
    event.persist();

    setTimeout(() => {
      setNumber(value);
    }, 1);
  };

  return (
    <StyledCustomDecimal className={errorMessage ? 'has-error' : ''}>
      <label>{label}</label>
      <Input
        type="number"
        value={answer >= 0 ? answer : ''}
        onChange={event => setNumber(event.target.value)}
        onPaste={event =>
          handlePaste(event, event.clipboardData.getData('text/plain'))
        }
        disabled={readOnly}
        {...props}
      />
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </StyledCustomDecimal>
  );
}

export default CustomDecimal;
