import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import PageHeader from '~/components/page-header';
import Card from '~/components/card';
import Button from '~/components/button';

import { forms_survey as SurveyIcon } from '~/components/svg-icons/svg-icons';
import { forms_performance as PerformanceIcon } from '~/components/svg-icons/svg-icons';

import { getConfig } from '~/utils';

import { StyledForms } from './styles';

function Forms({ account, organization, history }) {
  const { organization_is_active_set } = account.data;
  const { activeOrganizationId } = organization;

  const config = getConfig(organization_is_active_set, activeOrganizationId);

  return (
    <StyledForms>
      <PageHeader>
        <div className="text-info">
          <h1>Pesquisas &#38; Avaliações</h1>
          <p>
            Crie questionários para avaliar situações específicas dos
            participantes da sua empresa.
          </p>
        </div>
      </PageHeader>
      <div className="module-list">
        {config.modulePerformance && (
          <Button color="white" onClick={() => history.push('/performances')}>
            <Card className="module-item">
              <div className="beta-tag">Beta</div>
              <PerformanceIcon className="module-item-img" />
              <h3>Avaliações</h3>
              <p>
                Configuração, liberação e acompanhamento de formulários de
                avaliação de desempenho.
              </p>
            </Card>
          </Button>
        )}

        {config.moduleSurvey && (
          <Button color="white" onClick={() => history.push('/survey')}>
            <Card className="module-item">
              <SurveyIcon className="module-item-img" />
              <h3>Pesquisas</h3>
              <p>
                Configuração, liberação e acompanhamento de pesquisas genéricas
                junto aos colaboradores.
              </p>
            </Card>
          </Button>
        )}
      </div>
    </StyledForms>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default withRouter(connect(mapStateToProps)(Forms));
