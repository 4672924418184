import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { closeDefaultModal } from '~/store/ducks/default-modal';

import Icon from '~/components/icon';

import { StyledItem } from './styles';

function Item({
  title,
  description,
  image,
  startAt,
  finishAt,
  itemUrl,
  disabled,
  history,
  closeDefaultModal
}) {
  const handleClick = () => {
    closeDefaultModal();
    history.push(itemUrl);
  };

  return (
    <StyledItem onClick={handleClick} disabled={disabled}>
      <div className="info">
        {image && <img src={image} alt="" />}
        <div className="text-info">
          <h3>{title}</h3>
          <p className="description">{description}</p>

          {startAt && (
            <div className="period-card">
              <Icon name="calendar" />

              {finishAt ? (
                <p>
                  {moment(startAt).format('DD/MM/YYYY')} até{' '}
                  {moment(finishAt).format('DD/MM/YYYY')}
                </p>
              ) : (
                <p>Início em {moment(startAt).format('DD/MM/YYYY')}</p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="actions">
        <Icon name="next" />
      </div>
    </StyledItem>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  }
});

export default connect(null, mapDispatchToProps)(withRouter(Item));
