import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Icon = ({
  name,
  color,
  marginLeft,
  marginLeftSmall,
  marginRight,
  className
}) => {
  const classes = ['icon', name];
  if (color) classes.push(color);
  if (marginLeft) classes.push('margin-left');
  if (marginLeftSmall) classes.push('margin-left-small');
  if (marginRight) classes.push('margin-right');
  return <i className={`${classes.join(' ')} ${className}`} />;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool
};

export default Icon;
