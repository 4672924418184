import React from 'react';

import ReadVideo from '~/components/trail-contents/read-video';
import ReadLink from '~/components/trail-contents/read-link';
import DownloadFile from '~/components/trail-contents/download-file';
import ReadKaltura from '~/components/trail-contents/read-kaltura';
import SingleChoice from '~/components/trail-contents/single-choice';
import MultiChoice from '~/components/trail-contents/multi-choice';
import WriteText from '~/components/trail-contents/write-text';

const trailContentFactory = (
  stepTitle,
  contentType,
  contentTypeLabel,
  contentData,
  setContentData,
  isDone,
  canManuallyAnswer,
  markAsDone
) => {
  switch (contentType) {
    case 'download_file':
      return (
        <DownloadFile
          contentType={contentTypeLabel}
          title={stepTitle}
          fileName={contentData.name}
          fileUrl={contentData.url}
          isDone={isDone}
          canManuallyAnswer={canManuallyAnswer}
          markAsDone={markAsDone}
        />
      );
    case 'read_link':
      return (
        <ReadLink
          contentType={contentTypeLabel}
          title={stepTitle}
          url={contentData.url}
          isDone={isDone}
          canManuallyAnswer={canManuallyAnswer}
          markAsDone={markAsDone}
        />
      );
    case 'read_video':
      return (
        <ReadVideo
          contentType={contentTypeLabel}
          title={stepTitle}
          videoUrl={contentData.url}
          isDone={isDone}
          canManuallyAnswer={canManuallyAnswer}
          markAsDone={markAsDone}
        />
      );
    case 'kaltura_video':
      return (
        <ReadKaltura
          contentType={contentTypeLabel}
          title={stepTitle}
          videoUrl={contentData.embed_id}
          isDone={isDone}
          canManuallyAnswer={canManuallyAnswer}
          markAsDone={markAsDone}
        />
      );
    case 'single_choice':
      return (
        <SingleChoice
          title={stepTitle}
          markAsDone={markAsDone}
          contentData={contentData}
          setContentData={setContentData}
          isDone={isDone}
          isCorrect={contentData.answer ? contentData.answer.is_correct : null}
        />
      );
    case 'multi_choice':
      return (
        <MultiChoice
          title={stepTitle}
          markAsDone={markAsDone}
          contentData={contentData}
          setContentData={setContentData}
          isDone={isDone}
          isCorrect={contentData.answer ? contentData.answer.is_correct : null}
        />
      );
    case 'write_text':
      return (
        <WriteText
          title={stepTitle}
          markAsDone={markAsDone}
          contentData={contentData}
          setContentData={setContentData}
          isDone={isDone}
        />
      );
    default:
      return (
        <p style={{ margin: '24px 0px' }}>
          Ocorreu um erro ao exibir o conteúdo
        </p>
      );
  }
};

export default trailContentFactory;
