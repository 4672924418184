import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findActiveOrganization } from '~/utils';
import './style.scss';

const coins = [
  'bronze',
  'silver',
  'gold',
  'emerald',
  'diamond',
  'star',
  'reward',
  'prize-star-reward'
];

const TaskPrize = props => {
  let profileAvatar, companyAvatar;

  if (props.account.data) {
    const { profile, organization_is_active_set } = props.account.data;
    const activeOrganization = findActiveOrganization(
      organization_is_active_set,
      props.organization.activeOrganizationId
    );

    profileAvatar = profile.avatar_128x0;
    companyAvatar = activeOrganization.avatar_128x0;
  }

  return (
    <div className="task-prize">
      <img className="task-prize-image top-left" src={profileAvatar} alt="" />
      <img
        className="task-prize-image bottom-right"
        src={companyAvatar}
        alt=""
      />
      <div className="task-prize-coin">
        <div className={`coin-image ${props.coin}`} />
      </div>
    </div>
  );
};

TaskPrize.propTypes = {
  coin: PropTypes.oneOf(coins)
};

const mapStateToProps = store => ({
  account: store.account,
  organization: store.organization
});

export default connect(mapStateToProps)(TaskPrize);
