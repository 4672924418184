import GeracaoSSite from './geracao-s-site';
import Talkative from './talkative-site';
import Edukative from './edukative-site';
import Flow from './flow-site';
import Elle from './elle-site';
import Comex from './comex-site';
import Lhh from './lhh-site';
import GrupoFSB from './grupo-fsb-site';
import Cidadania from './cidadania-site';
import Bora from './bora-site';

class SiteFactory {
  static get(site) {
    switch (site) {
      case 'geracao_s': {
        return GeracaoSSite;
      }

      case 'talkative': {
        return Talkative;
      }

      case 'edukative': {
        return Edukative;
      }

      case 'flow': {
        return Flow;
      }

      case 'elle': {
        return Elle;
      }

      case 'comex': {
        return Comex;
      }

      case 'lhh': {
        return Lhh;
      }

      case 'grupo_fsb': {
        return GrupoFSB;
      }

      case 'cidadania': {
        return Cidadania;
      }

      case 'bora': {
        return Bora;
      }

      default: {
        throw new Error(`Unhandled site ${site}`);
      }
    }
  }
}

export default SiteFactory;
