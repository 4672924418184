import styled from 'styled-components';

export const StyledSingleChoiceQuestion = styled.div.attrs(() => ({
  className: 'single-choice-question'
}))`
  width: 100%;
  background: var(--white-color);

  .question-options {
    width: 100%;

    .button {
      width: 100%;
      border: 1px solid var(--border-color);
      color: var(--secondary-text-color);
      text-align: start;
      display: flex;
      align-items: center;
      padding: 8px 18px;
      margin-bottom: 24px;
      line-height: 18px;

      img {
        opacity: 0.5;
      }

      :hover {
        background: var(--background-color);
        color: var(--primary-color);
      }

      &.selected {
        border-color: var(--primary-color);
        color: var(--primary-color);

        img {
          opacity: 1;
        }
      }

      &:disabled {
        opacity: 1;
        cursor: not-allowed;
        pointer-events: none;
      }

      &.is-correct {
        background: var(--border-color);
      }

      img {
        margin-right: 12px;
      }
    }
  }
`;
