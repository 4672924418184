import React, { useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getToken, upadateTokenKey } from '~/services/auth';
import { acceptInvite } from '~/api/invitation';
import { tokenRefresh } from '~/api/auth';

import { setOrganization } from '~/store/ducks/organization';
import { closeNewDefaultModal } from '~/store/ducks/new-default-modal';
import { showMessage } from '~/store/ducks/messageBar';

import Button from '../button';

import { storeOrganizationId } from '~/utils';

import { StyledInvitationList } from './styles';

const InvitationList = ({
  invitations,
  closeNewDefaultModal,
  message: { showMessage }
}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const userToken = getToken();

  const setOrganizationId = organizationId => {
    dispatch(setOrganization(organizationId));
    storeOrganizationId(organizationId);
  };

  const selectOrganization = ({ id }) => {
    tokenRefresh({
      organization: id,
      token: userToken
    }).then(response => {
      upadateTokenKey(response.data.token);

      setOrganizationId(id);

      window.location.href = '/';
    });
  };

  const handleAcceptInvite = uuid => {
    setLoading(true);

    acceptInvite(uuid)
      .then(response => {
        closeNewDefaultModal();
        selectOrganization(response.data.organization);
      })
      .catch(error => {
        closeNewDefaultModal();
        showMessage(
          'Ocorreu um erro inesperado, por favor atualize a tela e tente novamente!',
          'danger',
          3000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <StyledInvitationList>
      <h1 className="title">Convites pendentes</h1>
      <p className="subtitle">
        Clique em aceitar para escolher a comunidade que deseja participar.
      </p>
      {invitations.map((invitation, index) => (
        <div key={index} className="invite-list-item">
          <Button
            className="link"
            color="primary"
            disabled={loading}
            onClick={() => handleAcceptInvite(invitation.uuid)}
          >
            Aceitar
          </Button>
          <div>
            <img src={invitation.avatar} alt="Community logo" />
          </div>
          <span>{invitation.name}</span>
        </div>
      ))}
      <div className="modal-footer">
        <Button
          color="primary"
          disabled={loading}
          onClick={() => handleAcceptInvite('all')}
        >
          Aceitar todos
        </Button>
      </div>
    </StyledInvitationList>
  );
};

const mapDispatchToProps = dispatch => ({
  closeNewDefaultModal: () => {
    dispatch(closeNewDefaultModal());
  },
  message: bindActionCreators(
    {
      showMessage
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(withRouter(InvitationList));
