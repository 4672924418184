import styled from 'styled-components';

export const StyledDeparmentCard = styled.button.attrs(() => ({
  className: 'department-card no-button'
}))`
  position: relative;
  width: 100%;
  padding: 42px 32px;
  color: #4a4a4a;
  background: var(--white-color);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  text-align: start;
  font-weight: normal;
  margin-bottom: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 1px 1px 16px var(--background-placeholder-color);
    cursor: ${({ readyOnly }) => (readyOnly ? 'initial' : 'pointer')};
  }

  .status {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    span {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-right: 6px;
    }

    p {
      color: var(--secondary-text-color);
      line-height: 1;
    }
  }

  .departments {
    color: var(--secondary-text-color);
  }

  .actions {
    margin-bottom: 0px !important;

    .icon {
      font-size: 20px;
      color: var(--primary-color);
    }
  }
`;
