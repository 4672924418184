import styled from 'styled-components';

export const StyledTagFilter = styled.div.attrs(() => ({
  className: 'tag-filter'
}))`
  display: flex;
  align-items: center;

  h3 {
    margin-right: 16px;
    font-size: 18px;
    color: var(--text-color);
    min-width: max-content;
  }

  .tag-buttons {
    button {
      border-radius: 100px;
      margin-right: 16px;
    }

    button:not(.active) {
      background-color: var(--border-color) !important;
      color: var(--secondary-text-color);
    }
  }

  @media (max-width: 720px) {
    .tag-buttons {
      button {
        margin-bottom: 8px;
      }
    }
  }
`;
