export const Types = {
  REGISTER: 'account/REGISTER',
  UPDATE_PROFILE: 'account/UPDATE_PROFILE',
  UNREGISTER: 'account/UNREGISTER',
  FACEBOOK_CONNECTED: 'account/FACEBOOK_CONNECTED',
  EXTERNAL_ACCOUNT_CONNECTED: 'account/EXTERNAL_ACCOUNT_CONNECTED',
  EXTERNAL_ACCOUNT_DISCONNECTED: 'account/EXTERNAL_ACCOUNT_DISCONNECTED'
};

const defaultState = { loaded: false, data: null };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.REGISTER:
      return {
        loaded: true,
        data: action.data
      };
    case Types.UPDATE_PROFILE: {
      return {
        ...state,
        data: {
          ...state.data,
          profile: action.data
        }
      };
    }
    case Types.FACEBOOK_CONNECTED:
    case Types.EXTERNAL_ACCOUNT_CONNECTED: {
      return {
        ...state,
        data: {
          ...state.data,
          externalaccount_set: [
            ...state.data.externalaccount_set,
            action.payload
          ]
        }
      };
    }
    case Types.EXTERNAL_ACCOUNT_DISCONNECTED: {
      return {
        ...state,
        data: {
          ...state.data,
          externalaccount_set: state.data.externalaccount_set.filter(
            act => act.type !== action.payload
          )
        }
      };
    }
    case Types.UNREGISTER: {
      return defaultState;
    }
    default:
      return state;
  }
}

export const registerAccount = data => ({
  type: Types.REGISTER,
  data
});

export const updateProfile = data => ({
  type: Types.UPDATE_PROFILE,
  data
});

export const unregisterAccount = () => ({
  type: Types.UNREGISTER
});

export const facebookConnected = data => ({
  type: Types.FACEBOOK_CONNECTED,
  payload: data
});

export const externalAccountConnected = data => ({
  type: Types.EXTERNAL_ACCOUNT_CONNECTED,
  payload: data
});

export const externalAccountDisconnected = socialNetwork => ({
  type: Types.EXTERNAL_ACCOUNT_DISCONNECTED,
  payload: socialNetwork
});
