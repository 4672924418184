import styled from 'styled-components';

export const StyledCardContainer = styled.div.attrs(() => ({
  className: 'card-container'
}))`
  & {
    background: var(--white-color);

    box-shadow: 0px 0px 10px rgba(74, 74, 74, 0.25);
    border-radius: 15px;
  }
`;
