import React from 'react';

import './style.scss';

function Alert({ color, icon, children }) {
  return (
    <div className={`alert-bar ${color}`}>
      <div className="alert-content">
        {icon}
        {children}
      </div>
    </div>
  );
}

export default Alert;
