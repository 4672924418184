import { useMemo } from 'react';

export const DOTS = '...';

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  numPages,
  pageSize,
  siblingCount = 1,
  currentPage
}) => {
  const paginationRange = useMemo(() => {
    // A contagem de páginas é determinada com siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
          Caso 1:
          Se o número de páginas for inferior aos números das páginas que queremos exibir em nosso paginationComponent, retornamos o intervalo [1..numPages]
        */
    if (totalPageNumbers >= numPages) {
      return range(1, numPages);
    }

    /*
            Calcular o índice irmão à esquerda e à direita e garantir que eles estão dentro do intervalo entre 1 e numPages
        */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, numPages);

    /*
          Não mostramos pontos apenas quando há apenas um número de página a ser inseridos entre os extremos dos irmãos e os limites das páginas, ou seja, 1 e numPages. Assim, estamos usando leftSiblingIndex > 2 e rightSiblingIndex < numPages - 2
        */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < numPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = numPages;

    /*
            Caso 2: Não há pontos à esquerda para serem exibidos, mas pontos à direita para exibir
        */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, numPages];
    }

    /*
            Caso 3: Não há pontos à direita para serem exibidos, mas pontos à esquerda para exibir
        */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(numPages - rightItemCount + 1, numPages);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
            Caso 4: Pontos à esquerda e à direita para exibir
        */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [numPages, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
