import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import Button from '../button';

import './style.scss';

const days = [1, 2, 3, 4, 5];

const BonusModal = ({ daysAccess, onClose, points }) => (
  <Modal onClose={onClose} hideClose className="bonus-modal">
    <div>
      <div className="header-modal-bonus">
        <span className="bonus-title">
          Bônus <span>do dia</span>
        </span>
        <div className="days">
          {days.map(day => {
            let dayCls, iconCls;

            if (day < daysAccess) {
              dayCls = 'day-before';
              iconCls = 'check-green';
            } else if (day === daysAccess) {
              dayCls = 'day-current';
              iconCls = 'check';
            } else {
              dayCls = 'day-after';
              iconCls = 'lock';
            }

            return (
              <div className={`day ${dayCls}`} key={day}>
                <span className="coin" />
                <span className="count">{points * day}</span> pontos
                <div>
                  <span className={iconCls} />
                </div>
                <div className="day-footer">Dia {day}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div id="modal-bonus-footer">
        <p className="content-paragraph-featured">
          <span>+{points * daysAccess} pontos</span>
        </p>

        <p className="normal-paragraph">
          Não se esqueça de voltar amanhã para não quebrar a sequência :)
        </p>

        <Button color="primary" onClick={onClose}>
          Fechar
        </Button>
      </div>
    </div>
  </Modal>
);

BonusModal.propTypes = {
  daysAccess: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  points: PropTypes.number.isRequired
};

export default BonusModal;
