import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './ducks';
import promiseMiddleware from 'redux-promise-middleware';

const composeEnhancers =
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const middlewares = [promiseMiddleware];

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
