import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'redux-react-hook';

import {
  getReactionSurveyQuestion,
  saveReactionSurveyQuestion,
  sendReactionSurveyQuestion
} from '~/api/training';

import { showMessage } from '~/store/ducks/messageBar';
import {
  closeDefaultModal,
  openDefaultModal
} from '~/store/ducks/default-modal';

import { deepClone } from '~/utils';

import CustomHeader from '~/components/custom-header';
import Button from '~/components/button';
import Icon from '~/components/icon';
import SuspendedMenu from '~/components/suspended-menu';
import PageControl from '~/components/page-control';
import CourseReactionSurveyQuestion from '~/components/course-reaction-survey-question';
import CustomLoading from '~/components/custom-loading';
import PendingModificationModal from '~/components/pending-modification-modal';
import ConfirmModal from '~/components/confirm-modal';
import PageHeader from '~/components/page-header';

import StyledCourseReactionSurvey from './styles';

let changeControl = {};

function CourseReactionSurvey({
  organization,
  message: { showMessage },
  location,
  history,
  closeDefaultModal
}) {
  const { activeOrganizationId } = organization;
  const questionPage = location.pathname.split('/reaction-survey/')[1];
  const firstPage = questionPage ? parseInt(questionPage, 10) : 1;

  const courseId = location.pathname
    .replace('/training/course/', '')
    .split('/reaction-survey')[0];

  const dispatch = useDispatch();

  const [course, setCourse] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(1);

  const [question, setQuestion] = useState({});

  const [loading, setLoading] = useState(true);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const handlePendingChanges = () => {
    dispatch(
      openDefaultModal(
        <PendingModificationModal
          onAction={() => history.push(`/training/course/${courseId}`)}
        />
      )
    );
  };

  const handleBack = () => {
    const currentState = {
      question: { ...question }
    };

    if (JSON.stringify(currentState) !== JSON.stringify(changeControl)) {
      handlePendingChanges();
      return;
    }

    history.push(`/training/course/${courseId}`);
  };

  const getSaveData = () => {
    return {
      value: question.answer.value,
      value_child: question.answer.value_child
    };
  };

  const handleSave = () => {
    setSaveLoading(true);
    const saveData = getSaveData();

    saveReactionSurveyQuestion(
      activeOrganizationId,
      courseId,
      currentQuestion,
      saveData
    )
      .then(() => {
        showMessage('Avaliação salva!', 'success', 1000);

        changeControl = deepClone({
          question: { ...question }
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao salvar a avaliação.', 'danger', 3000);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const confirmSend = () => {
    dispatch(
      openDefaultModal(
        <ConfirmModal
          title="Enviar avaliação?"
          description="Tem certeza que deseja enviar esta avaliação?"
          confirmButtonText="Enviar"
          onConfirm={handleSend}
        />
      )
    );
  };

  const handleSend = () => {
    setSendLoading(true);
    setLoading(true);
    const saveData = getSaveData();
    closeDefaultModal();

    saveReactionSurveyQuestion(
      activeOrganizationId,
      courseId,
      currentQuestion,
      saveData
    )
      .then(() => {
        changeControl = deepClone({
          question: { ...question }
        });
        sendReactionSurveyQuestion(
          activeOrganizationId,
          courseId,
          currentQuestion
        )
          .then(() => {
            showMessage(
              'Obrigado! A sua resposta à avaliação foi registrada.',
              'success'
            );
            history.push(`/training/course/${courseId}`);
          })
          .catch(error => {
            if (error.response.status === 400) {
              showMessage(error.response.data.errors[0], 'danger');
              return;
            }

            history.push('/unexpected-error');
          })
          .finally(() => {
            setSendLoading(false);
            setLoading(false);
          });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao enviar a avaliação.', 'danger', 3000);
        setLoading(false);
      })
      .finally(() => {
        setSendLoading(false);
      });
  };

  const loadQuestion = page => {
    getReactionSurveyQuestion(activeOrganizationId, courseId, page)
      .then(response => {
        setCurrentQuestion(page);
        const { pk, title, options, answer, type } = response.data;

        const questionData = {
          pk,
          title,
          options,
          answer,
          type
        };

        setQuestion(questionData);

        changeControl = deepClone({
          question: { ...questionData }
        });

        window.history.pushState(
          'reaction-survey',
          'reaction-survey',
          `${
            location.pathname.split('/reaction-survey')[0]
          }/reaction-survey/${page}`
        );
      })
      .catch(error => {
        history.push('/unexpected-error');
      })
      .finally(() => {
        setQuestionLoading(false);
      });
  };

  const changePage = page => {
    setQuestionLoading(true);
    const saveData = getSaveData();

    saveReactionSurveyQuestion(
      activeOrganizationId,
      courseId,
      currentQuestion,
      saveData
    )
      .then(() => {
        loadQuestion(page);
      })
      .catch(() => {
        history.push('/unexpected-error');
      });
  };

  const loadContent = () => {
    setLoading(true);

    getReactionSurveyQuestion(activeOrganizationId, courseId, firstPage)
      .then(response => {
        const {
          course,
          idx,
          count,
          pk,
          title,
          options,
          answer,
          type
        } = response.data;

        const questionData = {
          pk,
          title,
          options,
          answer,
          type
        };

        setCourse(course);
        setCurrentQuestion(idx);
        setTotalQuestions(count);
        setQuestion(questionData);

        changeControl = deepClone({
          question: { ...questionData }
        });
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          showMessage('Esta avaliação não está disponível.', 'danger', 3000);
          history.push(`/training/course/${courseId}`);
          return;
        }

        history.push('/unexpected-error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <StyledCourseReactionSurvey>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              Voltar
            </Button>
          </div>
          <div className="right-side">
            {!loading && (
              <>
                <Button
                  color="primary"
                  className="outline"
                  disabled={loading || saveLoading || sendLoading}
                  onClick={handleSave}
                >
                  {!saveLoading && 'Salvar'}
                  {saveLoading && (
                    <CustomLoading type="spin" height={16} width={16} fluid />
                  )}
                </Button>
                <Button
                  color="primary"
                  disabled={loading || saveLoading || sendLoading}
                  onClick={confirmSend}
                >
                  {!sendLoading && 'Enviar'}
                  {sendLoading && (
                    <CustomLoading
                      type="spin"
                      height={16}
                      width={16}
                      fluid
                      color="#FFFFFF"
                    />
                  )}
                </Button>
              </>
            )}

            {!loading && (
              <SuspendedMenu>
                <Button
                  color="primary"
                  className="outline"
                  disabled={loading || saveLoading || sendLoading}
                  onClick={handleSave}
                >
                  {!saveLoading && 'Salvar'}
                  {saveLoading && (
                    <CustomLoading type="spin" height={16} width={16} fluid />
                  )}
                </Button>
                <Button
                  color="primary"
                  className="outline"
                  disabled={loading || saveLoading || sendLoading}
                  onClick={confirmSend}
                >
                  {!sendLoading && 'Enviar'}
                  {sendLoading && (
                    <CustomLoading
                      type="spin"
                      height={16}
                      width={16}
                      fluid
                      color="#FFFFFF"
                    />
                  )}
                </Button>
              </SuspendedMenu>
            )}
          </div>
        </div>
      </CustomHeader>

      <div className="page-content">
        {!loading && (
          <>
            <div className="reaction-survey-header">
              <PageHeader>
                <div className="text-info">
                  <h1>Avaliação de reação</h1>
                  <p>{course.title}</p>
                </div>
              </PageHeader>
            </div>

            <PageControl
              pageTitle="Questão"
              counterLabel={`${currentQuestion}/${totalQuestions}`}
              previousDisabled={currentQuestion === 1}
              onPrevious={() => changePage(currentQuestion - 1)}
              nextDisabled={currentQuestion === totalQuestions}
              onNext={() => changePage(currentQuestion + 1)}
              disabled={questionLoading || saveLoading || sendLoading}
            />

            <div className="course-exam-question">
              {!questionLoading && question && (
                <CourseReactionSurveyQuestion
                  statement={question.title}
                  type={question.type}
                  questionData={question}
                  setQuestion={setQuestion}
                  disabled={saveLoading || sendLoading}
                />
              )}

              {questionLoading && (
                <CustomLoading type="spin" height={56} width={56} fluid />
              )}
            </div>
          </>
        )}

        {loading && <CustomLoading type="spin" height={56} width={56} fluid />}
      </div>
    </StyledCourseReactionSurvey>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  },
  message: bindActionCreators(
    {
      showMessage
    },
    dispatch
  )
});

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseReactionSurvey));
