import React, { PureComponent, useState } from 'react';
import Card from '~/components/card';
import { TaskHeader, TaskBody } from '~/components/task-helpers';
import { Form, Field } from 'react-final-form';
import { Check } from '~/components/form';
import { multiCheckRequired } from '~/components/form/validators';
import Button from '~/components/button';
import Icon from '~/components/icon';
import { CommentBoxAndButtons, TaskCommentBox } from '~/components/comments';
import { WololoRewardLine } from '~/components/rewards';
import { addWololo } from '~/services/tasks';

const MultiCheck = ({
  input: { onChange },
  meta: { touched, error },
  options,
  taskId,
  isSubtask,
  onFinish = () => {}
}) => {
  const [selectedList, setSelectedList] = useState([]);

  const updateSelectionAndChangeValue = newValue => {
    if (isSubtask) {
      let newMessage;

      if (newValue.length === 0) {
        newMessage = '';
      } else {
        // newMessage = JSON.stringify(newValue).replace(/["]+/g, "\\\"");
        newMessage = JSON.stringify(newValue);
      }

      onFinish(taskId, {
        message: newMessage
      });
    }

    setSelectedList(newValue);

    return newValue;
  };

  return (
    <>
      {options.map(opt => (
        <Check
          key={opt}
          type="checkbox"
          text={opt}
          checked={selectedList.includes(opt)}
          onChange={() => {
            if (selectedList.includes(opt)) {
              onChange(
                updateSelectionAndChangeValue(
                  selectedList.filter(o => o !== opt)
                )
              );
            } else {
              onChange(updateSelectionAndChangeValue([...selectedList, opt]));
            }
          }}
        />
      ))}

      {touched && error ? (
        <p className="text-center danger-message">{error}</p>
      ) : null}
    </>
  );
};

class MultipollTask extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      commentCounter: this.props.comment_counter
    };

    this.incrementCommentCounter = this.incrementCommentCounter.bind(this);
    this.select = this.select.bind(this);
  }

  incrementCommentCounter() {
    this.setState({ commentCounter: this.state.commentCounter + 1 });
  }

  setOptions = e => {
    console.log('Multipoll value = ', e);
  };

  select = selections => {
    const { id, me_create_wololo } = this.props;

    if (me_create_wololo) {
      return;
    }

    this.setState({ loading: true });

    addWololo(id, {
      // message: JSON.stringify(selections).replace(/["]+/g, "\\\"")
      message: JSON.stringify(selections)
    })
      .then(() => this.props.onFinish())
      .catch(() => this.setState({ loading: false }));
  };

  render() {
    const { id, isSubtask, onFinish } = this.props;

    return (
      <Card className={`${isSubtask ? 'subtask' : ''}`}>
        {!isSubtask && <TaskHeader {...this.props} icon_type="post" />}
        <TaskBody {...this.props} />

        {!this.props.me_create_wololo && (
          <Form
            onSubmit={({ selections }) => this.select(selections)}
            initialValues={{
              selections: []
            }}
            render={({ handleSubmit }) => (
              <div className="check-task-wrapper">
                {isSubtask ? (
                  <Field
                    name="selections"
                    options={this.props.wololo_target_options}
                    component={MultiCheck}
                    validate={multiCheckRequired}
                    taskId={id}
                    isSubtask={isSubtask}
                    onFinish={onFinish}
                  />
                ) : (
                  <Field
                    name="selections"
                    options={this.props.wololo_target_options}
                    component={MultiCheck}
                    validate={multiCheckRequired}
                  />
                )}

                {!isSubtask && (
                  <div className="text-center">
                    <Button
                      onClick={handleSubmit}
                      color={this.props.channel}
                      title="Enviar Resposta"
                      disabled={this.state.loading}
                    >
                      <Icon name={this.props.channel} marginRight />
                      <span>Enviar Resposta</span>
                    </Button>
                  </div>
                )}
              </div>
            )}
          />
        )}

        {this.props.can_comment ? (
          <CommentBoxAndButtons
            {...this.props}
            commentCounter={this.state.commentCounter}
            incrementCommentCounter={this.incrementCommentCounter}
            childCommentsProps="taskcomment_set"
            CommentBoxComponent={TaskCommentBox}
          />
        ) : null}

        {this.props.reward_still_available && (
          <WololoRewardLine {...this.props} />
        )}
      </Card>
    );
  }
}

export default MultipollTask;
