import styled from 'styled-components';

export const StyledCourseSubscribeModal = styled.div.attrs(() => ({
  className: 'course-subscribe-modal'
}))`
  width: 100%;

  h3 {
    font-size: 24px;
  }

  p {
    margin-bottom: 32px;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-left: 12px;
    }
  }
`;
