import React from 'react';
import renderHTML from 'react-render-html';

import PostHeader from '~/components/post-header/post-header';

import { StyledRankingRegulation } from './styles.js';

const RankingRegulation = ({ organization, title, period, regulation }) => {
  return (
    <StyledRankingRegulation>
      <PostHeader info="Ranking" organization={organization} />

      <div className="regulation-title">
        <h3>{title}</h3>
        <span>{period}</span>
      </div>

      <div className="regulation-section-title">
        <h3>Regulamento</h3>
      </div>

      <div className="regulation">{renderHTML(regulation)}</div>
    </StyledRankingRegulation>
  );
};

export default RankingRegulation;
