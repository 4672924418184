import styled from 'styled-components';

export const StyledPageHeader = styled.div.attrs(() => ({
  className: 'page-header'
}))`
  padding: 32px 0px;

  .text-info {
    h1 {
      margin: 0px;
      margin-bottom: 16px;
      font-size: 32px;
      font-weight: bold;
      color: var(--text-color);
      font-style: normal;
      line-height: normal;
    }

    p {
      margin: 0px;
      font-size: 14px;
      color: var(--secondary-text-color);
    }
  }
`;
