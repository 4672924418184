import styled from 'styled-components';

export const StyledSignUpCompleted = styled.div.attrs(() => ({
  className: 'sign-up-completed'
}))`
  .public-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .content-icon-success {
      width: 52px;
      height: 52px;
      color: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      border: solid 1px var(--primary-color);
    }

    .title-subtitle {
      margin: 24px 0 0;

      display: flex;
      flex-direction: column;
      align-items: center;

      strong + p {
        margin: 24px 0;
      }

      p {
        text-align: center;
      }
    }
  }

  .main .store-links-info {
    span {
      margin-left: 0 !important;
    }
  }
`;
