import styled from 'styled-components';

export const StyledRecoverySent = styled.div.attrs(() => ({
  className: 'recovery-sent'
}))`
  .button-confirm {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 12px;
  }
`;
