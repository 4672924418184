import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Card = props => (
  <div
    className={`card${props.className ? ' ' + props.className : ''}`}
    style={props.style}
  >
    {props.children}
  </div>
);

Card.propTypes = {
  className: PropTypes.string
};

export default Card;
