import React, { PureComponent } from 'react';
import Close from '../close';
import ReactModal from 'react-modal';

import './style.scss';

class Modal extends PureComponent {
  render() {
    const {
      onClose,
      className = 'message-modal',
      overlayClassName = 'message-modal-overlay',
      hideClose = false
    } = this.props;

    return (
      <ReactModal
        className={className}
        overlayClassName={overlayClassName}
        isOpen
        onRequestClose={onClose}
        parentSelector={() => document.getElementById('modal-root')}
      >
        {!hideClose && <Close onClick={onClose} />}
        {this.props.children}
      </ReactModal>
    );
  }
}
export default Modal;
