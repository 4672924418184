import styled from 'styled-components';

const StyledFooter = styled.header.attrs(() => ({
  className: 'footer'
}))`
  width: 100%;
  position: fixed;
  bottom: 0;
  background: var(--white-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 2000;
  padding-bottom: 0px;

  display: flex;
  justify-content: center;

  &.highlight {
    background: var(--failure-color);
    .main-content {
      p {
        color: var(--white-color);
      }
    }
  }

  .main-content {
    width: max-content;
    height: 40px;

    display: flex;
    align-items: center;

    img {
      width: 56px;
      margin: 0px 4px;
    }
  }

  @media (max-width: 988px) {
    display: none;
  }
`;

export default StyledFooter;
