import React from 'react';

import Icon from '~/components/icon';

import { StyledSearchInput } from './styles';

function SearchInput(props) {
  return (
    <StyledSearchInput>
      <Icon name="search" />
      <input {...props} />
    </StyledSearchInput>
  );
}

export default SearchInput;
