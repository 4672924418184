import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import continueTask from '~/components/feed/task/actions/continue-task';

class WololoContinueScreen extends PureComponent {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    if (window.localStorage.task && window.localStorage.task !== 'undefined') {
      const task = JSON.parse(window.localStorage.task);

      delete window.localStorage.task;

      continueTask(task).finally(() => {
        this.context.router.history.push('/');
      });
    } else {
      this.context.router.history.push('/');
    }
  }

  render() {
    return <div />;
  }
}

export default WololoContinueScreen;
