import api from './api';

export const getContacts = () => api.get(`/accounts/me/contacts/`);

export const createContact = (orgId, description, anonymous, type) =>
  api.post(`/organizations/${orgId}/contact/`, {
    description,
    anonymous,
    type
  });

export const getContactMessages = contactId =>
  api.get(`/contact/${contactId}/messages/`);

export const sendMessage = (contactId, description) =>
  api.post(`/contact/${contactId}/messages/`, {
    description
  });
