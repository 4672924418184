import React from 'react';

import { StyledSkeletonLoading } from './styles';

function SkeletonLoading({ type, customStyle }) {
  return (
    <StyledSkeletonLoading>
      {type === 'line' && (
        <div className="line">
          <span className="skeleton-box" style={{ width: '80%' }}></span>
        </div>
      )}

      {type === 'short-line' && (
        <div className="short-line">
          <span className="skeleton-box" style={{ width: '150px' }}></span>
        </div>
      )}

      {type === 'text' && (
        <div className="short-line">
          <span className="skeleton-box" style={{ width: '90%' }}></span>
          <span className="skeleton-box" style={{ width: '80%' }}></span>
        </div>
      )}

      {type === 'paragraph' && (
        <div className="paragraph">
          <span className="skeleton-box" style={{ width: '80%' }}></span>
          <span className="skeleton-box" style={{ width: '90%' }}></span>
          <span className="skeleton-box" style={{ width: '83%' }}></span>
          <span className="skeleton-box" style={{ width: '80%' }}></span>
        </div>
      )}

      {type === 'custom' && <div className="custom" style={customStyle}></div>}
    </StyledSkeletonLoading>
  );
}

export default SkeletonLoading;
