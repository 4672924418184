import React from 'react';

import Button from '~/components/button';

import { StyledTagFilter } from './styles';

function TagFilter({ title, items, selectedItem, onClick }) {
  return (
    <StyledTagFilter>
      {title && <h3>{title}</h3>}
      <div className="tag-buttons">
        {items.map(item => (
          <Button
            key={item.value}
            color="secondary"
            className={selectedItem === item.value ? 'active' : ''}
            onClick={() => onClick(item.value)}
            style={{ backgroundColor: item.color }}
          >
            {item.title}
          </Button>
        ))}
      </div>
    </StyledTagFilter>
  );
}

export default TagFilter;
