import styled from 'styled-components';

export const StyledPaginationControl = styled.div.attrs(() => ({
  className: 'pagination-control'
}))`
  display: flex;
  justify-content: center;
  align-items: center;

  .next,
  .previous {
    background: none;
    color: var(--primary-color);
    padding: 8px;
    margin: 0px 4px;

    i {
      padding: 0px;
      margin: 0px;
    }
  }

  .page-link {
    padding: 0px;
    width: 24px;
    min-height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    margin: 0px 4px;
    background: none;
    color: var(--secondary-text-color);

    &.active {
      background: var(--primary-color);
      color: var(--white-color);
    }

    &:hover {
      background: var(--primary-color);
      color: var(--white-color);
    }
  }
`;
