import queryString from 'query-string';
import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import EmptyScreen from '~/components/empty-screen';
import QuestionnaireFeed from '~/components/questionnaire-feed';
import LoadingMessage from '~/components/loading-message';
import useAccount from '~/hooks/use-account';
import { load, loadMore } from '~/store/ducks/questionnaire';
import { TaskHeader, TaskBody } from '~/components/task-helpers';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Card from '~/components/card';
import { addWololo } from '~/services/tasks';
import { Redirect } from 'react-router-dom';

import './styles.scss';

const EmptyQuestionnaire = () => (
  <EmptyScreen name="home">
    <span>As experiências acumuladas demonstram que a percepção </span>
    <span>das dificuldades prepara-nos para enfrentar situações </span>
    <span>atípicas decorrentes das diversas correntes de pensamento.</span>
  </EmptyScreen>
);

let FeedScreen = ({ location }) => {
  const dispatch = useDispatch();
  const search = queryString.parse(
    location.hash !== '' ? location.search + location.hash : location.search
  ).termo;

  const mapStateToProps = useCallback(
    ({ questionnaire: { data, loading, nextUrl, total, loadingMore } }) => ({
      questionnaire: data,
      loading,
      loadingMore,
      nextUrl,
      total
    }),
    []
  );

  // Obtem as informações do feed principal
  // const { feed, loading, loadingMore, nextUrl, total } = useMappedState(
  //   mapStateToProps
  // );

  // Obtem as informações do feed do questionário
  const {
    questionnaire,
    loading,
    loadingMore,
    nextUrl,
    total
  } = useMappedState(mapStateToProps);

  const [questionsData, setQuestionsData] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);

  useEffect(
    () => {
      dispatch(load(search));
    },
    [search, location.key]
  );

  const onLoadMore = useCallback(
    () => {
      if (loadingMore || !nextUrl) {
        return;
      }

      dispatch(loadMore(nextUrl));
    },
    [loadingMore, nextUrl]
  );

  const onFinishItem = useCallback((subtaskId, subtaskData = null) => {
    if (subtaskId) {
      setQuestionsData(lastQuestionsData => {
        const index = lastQuestionsData.findIndex(
          question => question.task === subtaskId
        );

        if (index === -1) {
          // Adicione o elemento
          if (subtaskData) {
            return lastQuestionsData.concat([
              { task: subtaskId, ...subtaskData }
            ]);
          } else {
            return lastQuestionsData.concat([{ task: subtaskId }]);
          }
        } else {
          // Sobreescrevo os dados da subtask, pois eles podem ter sido alterados.
          if (subtaskData) {
            // Verifica se a messagem é vazia e remove caso seja (Usado para remover respostas em branco das task multipoll)
            if (subtaskData.message === '') {
              return lastQuestionsData.filter((item, i) => i !== index);
            }

            lastQuestionsData[index] = { task: subtaskId, ...subtaskData };
          }

          return lastQuestionsData;
        }
      });
    }
  }, []);

  // Finaliza a ação questionário
  const onFinish = useCallback((type, id) => {
    // dispatch(taskFinished(type, id));
    setRedirectToHome(true);
  }, []);

  const answer = () => {
    const { id, type } = location.state;

    setSendLoading(true);

    addWololo(id, { questions: questionsData })
      .then(() => onFinish(type, id))
      .finally(() => {
        setSendLoading(false);
      });
  };

  if (loading && questionnaire.length === 0) {
    return <LoadingMessage />;
  }

  if (!loading && questionnaire.length === 0 && !search) {
    return <EmptyQuestionnaire />;
  }

  return (
    <>
      <Card>
        <Button
          to="/"
          style={{
            color: '#000',
            width: '100%',
            textAlign: 'center'
          }}
        >
          <span className="default-link">Exibir todos os itens</span>
        </Button>
      </Card>

      {loading && <LoadingMessage />}

      {!loading && (
        <div className="questionnaire card">
          <TaskHeader
            {...location.state}
            icon_type="post"
            channel={location.state.type}
          />
          <TaskBody {...location.state} />
          <QuestionnaireFeed
            total={total}
            search={search}
            results={questionnaire}
            onScrollFinish={onLoadMore}
            onFinishItem={onFinishItem}
          />
          <div className="action-area">
            <Button
              className="answer-questionnaire"
              onClick={() => answer()}
              color={location.state.channel}
              title="Enviar"
              disabled={loading || questionsData.length === 0 || sendLoading}
            >
              <Icon name={location.state.channel} marginRight />
              <span>Enviar</span>
            </Button>
          </div>
        </div>
      )}

      {loadingMore && <LoadingMessage />}
      {redirectToHome && (
        <Redirect
          to={{
            pathname: '/',
            state: {
              keepModalOpen: true
            }
          }}
        />
      )}
    </>
  );
};

FeedScreen = withRouter(FeedScreen);

let QuestionnaireScreen = ({ location }) => {
  const account = useAccount();

  return <FeedScreen key="feed" account={account.data} />;
};

QuestionnaireScreen = withRouter(QuestionnaireScreen);

const QuestionnaireScreenGuaranteeAccount = () => {
  const { loaded } = useAccount();

  if (!loaded) {
    return <LoadingMessage />;
  }

  return <QuestionnaireScreen />;
};

export default QuestionnaireScreenGuaranteeAccount;
