// como nem tudo esta no redux, algumas ações serão avulsas
//
// Esse arquivo serve para colocar esses tipos de ações

export const Types = {
  POST_LOADED: 'actions/POST_LOADED',
  TASK_FINISHED: 'actions/TASK_FINISHED',
  TASK_LOADED: 'actions/TASK_LOADED',
  FORUMPOST_LOADED: 'actions/FORUMPOST_LOADED',
  QUESTIONNAIRE_LOADED: 'actions/QUESTIONNAIRE_LOADED'
};

export const postLoaded = post => ({
  type: Types.POST_LOADED,
  payload: post
});

export const taskLoaded = task => ({
  type: Types.TASK_LOADED,
  payload: task
});

export const forumpostLoaded = forumpost => ({
  type: Types.FORUMPOST_LOADED,
  payload: forumpost
});

export const questionnaireLoaded = questionnaire => ({
  type: Types.QUESTIONNAIRE_LOADED,
  payload: questionnaire
});

export const taskFinished = (type, id) => ({
  type: Types.TASK_FINISHED,
  payload: {
    type,
    id
  }
});
