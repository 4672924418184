import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import store from '~/store';
import { openPostModal } from '~/store/ducks/post-modal';
import { showMessage } from '~/store/ducks/messageBar';

import _ from 'lodash';
import moment from 'moment';

import Button from '~/components/button/button.js';
import Loading from '~/components/loading/loading.js';
import CardContainer from '~/components/card-container/card-container.js';
import RankingRegulation from '../ranking-regulation/ranking-regulation.js';

import useAccount from '~/hooks/use-account';
import useOrganization from '~/hooks/use-organization.js';

import downloadFile from '~/services/download';

import { StyledRankingHeader } from './styles.js';

const RankingHeader = ({
  loading,
  accounts,
  ranking,
  rankingFromList,
  organization,
  showMessage
}) => {
  const account = useAccount();
  const { avatar_128x0 } = useOrganization(organization);

  const getPeriod = () => {
    const openAt = moment(ranking.open_at).format('DD/MM/YYYY');
    const closeAt = moment(ranking.close_at).format('DD/MM/YYYY');

    return `${openAt} à ${closeAt}`;
  };

  const handleOpenRegulation = () => {
    store.dispatch(
      openPostModal(
        <RankingRegulation
          organization={organization}
          title={ranking.title}
          period={getPeriod()}
          regulation={ranking.regulation}
        />
      )
    );
  };

  const handleDownload = () => {
    downloadFile(ranking.regulation_file)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${ranking.regulation_filername}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(() => {
        showMessage(
          'Ocorreu um erro ao realizar o download do regulamento!',
          'danger',
          3000
        );
      });
  };

  return (
    <StyledRankingHeader>
      <CardContainer>
        <div className="ranking-info">
          <div className="title-image-ranking">
            {ranking && (
              <>
                <img src={avatar_128x0} alt="Community icon" />
                <div>
                  <div className="ranking-title">
                    {ranking.title}
                    <span className="sep">|</span>
                    {moment(ranking.open_at)
                      .format('MMMM/YYYY')
                      .replace(/^\w/, str => str.toUpperCase())}
                  </div>
                  <span>{getPeriod()}</span>

                  {ranking.regulation || ranking.regulation_file ? (
                    ranking.regulation ? (
                      <Button
                        className="ranking-btn link"
                        color="primary"
                        onClick={() => handleOpenRegulation()}
                      >
                        <i className="icon documents"></i>
                        Regulamento
                      </Button>
                    ) : (
                      <Button
                        className="ranking-btn link"
                        color="primary"
                        onClick={() => handleDownload()}
                      >
                        <i className="icon documents"></i>
                        Regulamento
                      </Button>
                    )
                  ) : null}
                </div>
              </>
            )}
          </div>
          {rankingFromList && (
            <div className="info-account-block">
              <div>
                <span className="label-info">Você no ranking</span>
                <div className="current-position">
                  {rankingFromList.points.toLocaleString()} pts
                  <span className="sep">|</span>
                  {rankingFromList.position}º lugar
                </div>
              </div>

              <img
                src={_.get(account, 'data.profile.avatar_128x0')}
                className="rounded img48"
                alt={_.get(account, 'data.profile.name')}
                title={_.get(account, 'data.profile.name')}
              />
            </div>
          )}
        </div>

        <Loading inline visible={loading && accounts.length === 0} />
      </CardContainer>
    </StyledRankingHeader>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps, mapDispatchToProps)(RankingHeader);
