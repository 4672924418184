import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { REACT_APP_SITE } from '~/settings';

import Button from '~/components/button';
import Card from '~/components/card';
import Icon from '~/components/icon';

import { StyledCourseItem } from './styles';

function CourseItem({
  courseId,
  title,
  image,
  startAt,
  finishAt,
  stepCount,
  stepLabel,
  trailCount,
  trailLabel,
  history
}) {
  return (
    <StyledCourseItem>
      <Card className="course-item">
        <div className="info-side">
          {image && <img src={image} alt="" />}
          {!image && (
            <div className="default-image-placeholder">
              <Icon name="training" />
            </div>
          )}
          <div className="text-info">
            <h3 className="title">{title}</h3>
            <div className="tags">
              <div className="period-card">
                <Icon name="calendar" />
                {finishAt ? (
                  <p>
                    {moment(startAt).format('DD/MM/YYYY')} até{' '}
                    {moment(finishAt).format('DD/MM/YYYY')}
                  </p>
                ) : (
                  <p>Início em {moment(startAt).format('DD/MM/YYYY')}</p>
                )}
              </div>

              <div className="trails-count">
                <Icon name="trail" />
                <p>
                  {trailCount} {trailLabel}
                </p>
              </div>

              <div className="steps-count">
                <Icon name="step" />
                <p>
                  {stepCount} {stepLabel}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="action-side">
          <Button
            color="secondary"
            className="outline"
            onClick={() => {
              // Usado para verificar a url de origem ao clicar no botão voltar na página de detalhes do curso
              localStorage.setItem(
                `@${REACT_APP_SITE}/previousUrl`,
                history.location.pathname
              );
              history.push(`/training/course/${courseId}`);
            }}
          >
            Acessar
          </Button>
        </div>
      </Card>
    </StyledCourseItem>
  );
}

export default withRouter(CourseItem);
