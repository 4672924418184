import styled from 'styled-components';

export const StyledRankingRegulation = styled.div.attrs(() => ({
  className: 'ranking-regulation'
}))`
  .regulation-title {
    h3 {
      color: var(--grey100);

      margin-bottom: 6px;

      font-size: 18px;
      font-weight: 900;

      letter-spacing: 0.5px;
      line-height: 110%;
    }

    span {
      color: var(--grey80);

      font-size: 16px;
      font-weight: 450;

      letter-spacing: 0.5px;
      line-height: 110%;
    }
  }

  .regulation-section-title {
    text-align: center;
    padding: 28px 0 16px;

    border-bottom: 1px solid var(--grey20);

    h3 {
      color: var(--grey100);
      font-weight: 500;
    }
  }

  .regulation {
    margin-top: 28px;
  }

  .profile-info-name {
    color: var(--grey100);
  }
`;
