import React from 'react';
import PropTypes from 'prop-types';
import CollapsePost from '../collapse-post';
import AddrCep from './tasks/addr_cep';
import Avatar from './tasks/avatar';
import BornAt from './tasks/born_at';
import Cni from './tasks/cni';
import Cpf from './tasks/cpf';
import Facebook from './tasks/facebook';
import Gender from './tasks/gender';
import Instagram from './tasks/instagram';
import Linkedin from './tasks/linkedin';
import Phone from './tasks/phone';
import Email from './tasks/email';
import Review from './tasks/review';
import Twitter from './tasks/twitter';
import Notifications from './tasks/notifications';
import Creators from './tasks/creators';
import './profile-task.scss';

const tasks = {
  addr_cep: AddrCep,
  avatar: Avatar,
  born_at: BornAt,
  cni: Cni,
  cpf: Cpf,
  facebook: Facebook,
  gender: Gender,
  instagram: Instagram,
  linkedin: Linkedin,
  notifications: Notifications,
  phone: Phone,
  email: Email,
  review: Review,
  twitter: Twitter,
  creators: Creators
};

const ProfileTask = ({
  id,
  wololoTargetId,
  title,
  date,
  type,
  phone,
  onFinish,
  task,
  pointsToShow,
  isSubtask
}) => {
  const Task = tasks[type];

  let parsedTitle = title;

  if (type === 'phone') {
    parsedTitle = `Receba mensagens no WhatsApp para melhorar o seu desempenho e adicione o nosso número (${phone}) nos contatos`;
  } else if (type === 'email') {
    parsedTitle = `Informe o endereço de email`;
  } else if (type === 'review') {
    parsedTitle = null;
  }

  return (
    <CollapsePost name={parsedTitle} date={date} expanded={type === 'review'}>
      <Task
        id={id}
        wololoTargetId={wololoTargetId}
        onFinish={onFinish}
        task={task}
        pointsToShow={pointsToShow}
        isSubtask={isSubtask}
        date={date}
      />
    </CollapsePost>
  );
};

ProfileTask.defaultProps = {
  onFinish: () => {}
};

ProfileTask.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.oneOf([
    'addr_cep',
    'avatar',
    'born_at',
    'cni',
    'cpf',
    'facebook',
    'gender',
    'instagram',
    'linkedin',
    'phone',
    'review',
    'twitter',
    'email',
    'creators'
  ]),
  onFinish: PropTypes.func
};

export default ProfileTask;
