import React from 'react';
import PropTypes from 'prop-types';

const Form = props => (
  <form
    disabled={props.disabled}
    onSubmit={e => {
      e.preventDefault();

      if (!props.disabled) {
        props.onSubmit(e);
      }
    }}
  >
    {props.children}
  </form>
);

Form.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.node
};

export default Form;
