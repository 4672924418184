import React, { useEffect } from 'react';

import { kalturaPlayerConfig } from '~/settings';

import { StyledKaltura } from './styles';
import MarkAsDoneButton from '~/components/mark-as-done-button';

function ReadKaltura({
  contentType,
  title,
  videoUrl,
  isDone,
  canManuallyAnswer,
  markAsDone
}) {
  const removeScriptTags = () => {
    const scriptTagCDN = document.querySelector(
      'script#kaltura-player-script-cdn'
    );
    const scriptTagContent = document.querySelector(
      'script#kaltura-player-script'
    );

    if (scriptTagCDN) {
      scriptTagCDN.parentNode.removeChild(scriptTagCDN);
    }

    if (scriptTagContent) {
      scriptTagContent.parentNode.removeChild(scriptTagContent);
    }
  };

  const getScriptContent = () => {
    const videoUrlParts = videoUrl.split('/');
    const entryId = videoUrlParts[videoUrlParts.length - 1];

    return `
      try {
        const flixPlayer = FlixPlayer.setup({
          targetId: "kaltura-player",
          provider: {
            partnerId: ${kalturaPlayerConfig.partnerId},
            uiConfId: ${kalturaPlayerConfig.uiConfId}
          },
        });

        flixPlayer.addEventListener(flixPlayer.Event.Core.ENDED, ${() => {
          localStorage.setItem('@kaltura_player/video_ended', 1);
        }});

        flixPlayer.loadMedia({ entryId: '${entryId}' });
      } catch(error) {
        console.log(error);
      }
    `;
  };

  const appendScriptTagContent = () => {
    const scriptContent = getScriptContent();

    const script = document.createElement('script');
    script.id = 'kaltura-player-script';
    script.async = true;
    script.innerHTML = scriptContent;

    document.body.append(script);
  };

  const appendScriptTagCDN = () => {
    const script = document.createElement('script');
    script.id = 'kaltura-player-script-cdn';
    script.src = kalturaPlayerConfig.embedUrl;
    script.async = true;
    script.defer = true;
    script.onload = appendScriptTagContent;

    document.body.append(script);
  };

  const checkVideoEnded = () => {
    const checkInterval = setInterval(() => {
      const videoEnded = parseInt(
        localStorage.getItem('@kaltura_player/video_ended')
      );

      if (videoEnded) {
        markAsDone({});
        clearInterval(checkInterval);
      }
    }, 1000);

    return checkInterval;
  };

  useEffect(() => {
    removeScriptTags();
    appendScriptTagCDN();

    let checkInterval = null;

    if (!isDone) {
      localStorage.setItem('@kaltura_player/video_ended', 0);
      checkInterval = checkVideoEnded();
    }

    return () => {
      removeScriptTags();
      clearInterval(checkInterval);
    };
  }, [videoUrl]);

  return (
    <>
      <StyledKaltura>
        <div className="step-section">
          <div className="title">
            <h3>{title}</h3>
            <MarkAsDoneButton
              isDone={isDone}
              canManuallyAnswer={canManuallyAnswer}
              onClick={() =>
                markAsDone({
                  ignoreAction: true
                })
              }
            />
          </div>
        </div>
        <div className="step-section">
          <div id="kaltura-player"></div>
        </div>
      </StyledKaltura>
    </>
  );
}

export default ReadKaltura;
