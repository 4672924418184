import React, { PureComponent } from 'react';
import Card from '~/components/card';
import { TaskHeader, TaskBody } from '~/components/task-helpers';
import { Redirect } from 'react-router-dom';
import Button from '~/components/button';
import Icon from '~/components/icon';
import { CommentBoxAndButtons, TaskCommentBox } from '~/components/comments';
import { WololoRewardLine } from '~/components/rewards';

class QuestionnaireTask extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      commentCounter: this.props.comment_counter,
      showQuestionnaire: false
    };

    this.incrementCommentCounter = this.incrementCommentCounter.bind(this);
  }

  incrementCommentCounter() {
    this.setState({ commentCounter: this.state.commentCounter + 1 });
  }

  redirectToQuestionnaire = taskId => {
    const {
      channel,
      wololo_points,
      organization,
      close_at,
      me_create_wololo,
      icon_type,
      me_trail,
      cover,
      type,
      id,
      title,
      description
    } = this.props;

    return (
      <Redirect
        to={{
          pathname: '/questionnaire',
          search: `?termo=${taskId}`,
          state: {
            channel,
            wololo_points,
            organization,
            close_at,
            me_create_wololo,
            icon_type,
            me_trail,
            cover,
            type,
            id,
            title,
            description
          }
        }}
      />
    );
  };

  render() {
    return (
      <Card>
        <TaskHeader
          {...this.props}
          icon_type="post"
          channel={this.props.type}
        />
        <TaskBody {...this.props} />

        <div className="text-center">
          <Button
            onClick={() => this.setState({ showQuestionnaire: true })}
            color={this.props.channel}
            title="Abrir questionário"
            disabled={this.state.loading}
          >
            <Icon name={this.props.channel} marginRight />
            <span>Abrir questionário</span>
          </Button>
        </div>

        {this.state.showQuestionnaire &&
          this.redirectToQuestionnaire(this.props.id)}

        {this.props.can_comment ? (
          <CommentBoxAndButtons
            {...this.props}
            commentCounter={this.state.commentCounter}
            incrementCommentCounter={this.incrementCommentCounter}
            childCommentsProps="taskcomment_set"
            CommentBoxComponent={TaskCommentBox}
          />
        ) : null}

        {this.props.reward_still_available && (
          <WololoRewardLine {...this.props} />
        )}
      </Card>
    );
  }
}

export default QuestionnaireTask;
