import React from 'react';

import LinkPreview from '~/components/link-preview';
import MarkAsDoneButton from '~/components/mark-as-done-button';

import { StyledReadLink } from './styles';

function ReadLink({
  contentType,
  title,
  url,
  isDone,
  canManuallyAnswer,
  markAsDone
}) {
  return (
    <StyledReadLink>
      <div className="step-section">
        <div className="title">
          <h3>{title}</h3>
          <MarkAsDoneButton
            isDone={isDone}
            canManuallyAnswer={canManuallyAnswer}
            onClick={() =>
              markAsDone({
                ignoreAction: true
              })
            }
          />
        </div>
      </div>
      <div className="step-section">
        <LinkPreview url={url} onClick={markAsDone} />
      </div>
    </StyledReadLink>
  );
}

export default ReadLink;
