import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../icon';
import './style.scss';

class UploadImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: this.props.defaultImage
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ target }) {
    const reader = new FileReader();
    const file = target.files[0];

    reader.onloadend = () => {
      this.setState(
        {
          preview: reader.result
        },
        () => {
          this.props.onChange(file);
        }
      );
    };

    reader.readAsDataURL(file);
  }

  render() {
    return (
      <div className="upload-image">
        <div className="upload-image-preview">
          <img src={this.state.preview} alt="Preview" />
          <div className="upload-image-icon">
            <Icon name="camera" />
          </div>
        </div>
        <input type="file" onChange={this.handleChange} />
      </div>
    );
  }
}

UploadImage.propTypes = {
  defaultImage: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default UploadImage;
