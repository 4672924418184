import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import EmptyScreen from '~/components/empty-screen';
import TrailFeed from '~/components/trail-feed';
import LoadingMessage from '~/components/loading-message';
import useAccount from '~/hooks/use-account';
import { load, loadMore } from '~/store/ducks/trails';

const EmptyTrails = () => (
  <EmptyScreen name="trail">
    <span>As experiências acumuladas demonstram que a percepção </span>
    <span>das dificuldades prepara-nos para enfrentar situações </span>
    <span>atípicas decorrentes das diversas correntes de pensamento.</span>
  </EmptyScreen>
);

let FeedScreen = ({ location }) => {
  const dispatch = useDispatch();
  const search = queryString.parse(location.search).termo;

  const mapStateToProps = useCallback(
    ({ trails: { data, loading, nextUrl, total, loadingMore } }) => ({
      trails: data,
      loading,
      loadingMore,
      nextUrl,
      total
    }),
    []
  );

  const { trails, loading, loadingMore, nextUrl, total } = useMappedState(
    mapStateToProps
  );

  useEffect(
    () => {
      dispatch(load(search));
    },
    [search, location.key]
  );

  const onLoadMore = useCallback(
    () => {
      if (loadingMore || !nextUrl) {
        return;
      }

      dispatch(loadMore(nextUrl));
    },
    [loadingMore, nextUrl]
  );

  if (loading && trails.length === 0) {
    return <LoadingMessage />;
  }

  if (!loading && trails.length === 0 && !search) {
    return <EmptyTrails />;
  }

  return (
    <>
      {loading && <LoadingMessage />}

      {!loading && (
        <TrailFeed
          total={total}
          search={search}
          results={trails}
          onScrollFinish={onLoadMore}
          onFinishItem={() => {}}
        />
      )}

      {loadingMore && <LoadingMessage />}
    </>
  );
};

FeedScreen = withRouter(FeedScreen);

let TrailsScreen = ({ location }) => {
  const account = useAccount();

  return <FeedScreen key="feed" account={account.data} />;
};

TrailsScreen = withRouter(TrailsScreen);

const TrailsScreenGuaranteeAccount = () => {
  const { loaded } = useAccount();

  if (!loaded) {
    return <LoadingMessage />;
  }

  return <TrailsScreen />;
};

export default TrailsScreenGuaranteeAccount;
