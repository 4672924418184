import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';

import { getContactMessages, sendMessage } from '~/api/contact';

import useAccount from '~/hooks/use-account';

import Card from '~/components/card';
import ProfileInfo from '~/components/profile-info';
import { Input } from '~/components/form';
import Button from '~/components/button';
import Separator from '~/components/separator';

import './style.scss';

function ContactChat({ location }) {
  const { contactId, description, owner, date } = location.state;
  const {
    data: { profile }
  } = useAccount();
  const messageFeed = useRef(null);

  const [newMessage, setNewMessage] = useState('');
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [messages, setMessages] = useState([{ owner, description, date }]);

  const messageFactory = data => {
    return {
      owner: data.owner,
      description: data.description,
      date: data.created_at
    };
  };

  const getMessageList = useCallback(() => {
    setLoadingMessages(true);

    getContactMessages(contactId)
      .then(response => {
        setMessages(lastMessages => {
          const newData = response.data.map(message => messageFactory(message));

          return lastMessages.concat(newData);
        });
        messageFeed.current.scrollTop = messageFeed.current.scrollHeight;
      })
      .catch(e => {
        alert('Erro');
      })
      .finally(() => {
        setLoadingMessages(false);
      });
  }, []);

  const handleChange = ({ target }) => {
    const { value } = target;
    setNewMessage(value);
  };

  const send = () => {
    setLoadingSend(true);

    sendMessage(contactId, newMessage)
      .then(response => {
        const amountMessages = response.data.contactmessage_set.length;
        const owner =
          response.data.contactmessage_set[amountMessages - 1].owner;
        const date =
          response.data.contactmessage_set[amountMessages - 1].created_at;

        setMessages(lastMessages => {
          const newData = [
            {
              owner,
              description: newMessage,
              date
            }
          ];

          return lastMessages.concat(newData);
        });
        setNewMessage('');
        messageFeed.current.scrollTop = messageFeed.current.scrollHeight;
      })
      .catch(e => {
        alert('Erro');
      })
      .finally(() => {
        setLoadingSend(false);
      });
  };

  useEffect(() => {
    getMessageList();
  }, []);

  return (
    <div className="contact-chat">
      <Card>
        <div className="chat-header">
          <Button to="/contact-us">
            <span style={{ color: '#888' }}>&lt; Voltar</span>
          </Button>
          <ProfileInfo
            name={owner.name}
            image={owner.avatar_128x0}
            info=""
            rightImage={null}
          />
          <span />
        </div>
        <Separator />
        <div className="chat-messages" ref={messageFeed}>
          {loadingMessages && (
            <p className="loading">Carregando mensagens...</p>
          )}

          {!loadingMessages && messages.length === 0 && (
            <p>Ainda não há mensagens</p>
          )}

          {messages.map(msg => (
            <div
              className={
                profile.account === msg.owner.id
                  ? 'message-container me'
                  : 'message-container'
              }
              key={msg.id}
            >
              <div className="message">
                <ProfileInfo
                  name={msg.owner.name}
                  image={msg.owner.avatar_128x0}
                  info=""
                  rightImage={null}
                />
                <p className="message-content">{msg.description}</p>
                <div className="message-date">{moment(msg.date).fromNow()}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="message-field">
          <Input
            textarea
            rows={1}
            value={newMessage}
            onChange={handleChange}
            name="message"
          />
          <Button onClick={send} disabled={loadingSend} color="primary">
            {!loadingSend ? <span>Enviar</span> : <span>Enviando...</span>}
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default ContactChat;
