import styled from 'styled-components';

export const StyledCourseCategoryBanner = styled.div.attrs(() => ({
  className: 'course-category-banner'
}))`
  width: 100%;
  height: 450px;
  padding: 0px;
  background: var(--primary-color);
  background-image: url(${({ categoryImage }) => categoryImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: none;
  outline: none;
  border-radius: 4px;

  .category-card-content {
    width: 100%;
    height: 100%;
    padding: 32px 48px;
    // background: rgba(0,
    // 0,
    // 0,
    // ${({ showTitle, categoryImage }) =>
      categoryImage ? (showTitle ? '0.25' : '0.05') : '0.05'});
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    h3 {
      text-align: start;
      max-width: 600px;
      font-size: 32px;
      font-weight: normal;
      line-height: 1;
      color: var(--white-color);
      margin-bottom: 16px;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  @media (max-width: 720px) {
    height: 405px;

    .category-card-content {
      padding: 16px;
    }
  }

  @media (max-width: 414px) {
    height: 214px;
  }
`;
