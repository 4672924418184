import styled from 'styled-components';

export const StyledPDIConfirmRemoveModal = styled.div`
  width: 100%;

  h3 {
    font-size: 21px;
    line-height: 1;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 32px;
  }

  .actions {
    margin-bottom: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      min-width: 96px;
      margin: 0px 4px;
    }
  }
`;
