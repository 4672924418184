import React from 'react';
import { connect } from 'react-redux';
import Post from '../feed/post';
import Rumor from '../feed/rumor';
import Task from '../feed/task';

const types = {
  post: Post,
  rumor: Rumor,
  task: Task
};

const connectRequired = ['facebook', 'instagram', 'twitter', 'linkedin'];

const FeedItem = ({
  type,
  onChange,
  data,
  account,
  onFinishItem,
  isSubtask
}) => {
  const Item = types[type];
  let connected = true;
  let hasSubsequentActions = false;

  if (type === 'survey') {
    return null;
  }

  if (type === 'task') {
    const { channel, children_count } = data;

    if (!channel) return null;

    if (!!~connectRequired.indexOf(channel)) {
      connected =
        account.data.externalaccount_set.filter(s => s.type === channel)
          .length > 0;
    }

    if (children_count > 0) {
      hasSubsequentActions = true;
    }
  }

  return (
    <Item
      {...data}
      onChange={onChange}
      original_data={data}
      onFinish={onFinishItem}
      connected={connected}
      hasSubsequentActions={hasSubsequentActions}
      isSubtask={isSubtask}
    />
  );
};

const mapStateToProps = store => ({
  account: store.account
});

export default connect(mapStateToProps)(FeedItem);
