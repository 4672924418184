import styled from 'styled-components';

export const StyledDownloadInstructionsModal = styled.div.attrs(() => ({
  className: 'download-instructions-modal'
}))`
  text-align: center;

  .qr-code-container {
    padding: 32px 16px 16px;
  }

  .stores-links {
    margin-bottom: 16px;

    .store-links-info {
      margin: 0;
    }
  }

  @media (min-width: 900px) {
    .hide-on-desktop {
      display: none;
    }
  }
`;
