import React from 'react';

import Input from '~/components/form/input';

import StyledOpenAnswer from './styles';

function OpenAnswer({
  title,
  descriptiveAnswer,
  handleDescriptiveAnswer,
  disabled
}) {
  return (
    <StyledOpenAnswer>
      <div className="descriptive-answer">
        <h5>{title}</h5>
        <Input
          textarea
          rows={1}
          value={descriptiveAnswer || ''}
          onChange={handleDescriptiveAnswer}
          placeholder="Insira sua resposta"
          disabled={disabled}
        />
      </div>
    </StyledOpenAnswer>
  );
}

export default OpenAnswer;
