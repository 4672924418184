import styled from 'styled-components';

const StyledFab = styled.div.attrs(() => ({
  className: 'fab'
}))`
  width: 100%;
  position: fixed;
  bottom: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1900;
  pointer-events: none;

  .view-content {
    padding: 0 16px 16px;
    width: 100%;
    max-width: 1232px;

    display: flex;
    justify-content: flex-end;

    & > button {
      pointer-events: all;
    }
  }
`;

export default StyledFab;
