import styled from 'styled-components';

export const StyledPerformanceQuestionPreview = styled.div`
  width: 100%;
  background: var(--white-color);
  /* padding: 32px 42px; */
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 32px;

  .question-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;
    padding: 32px 42px 0px 42px;

    .info {
      width: 100%;
      display: flex;
      align-items: flex-start;

      .question-number {
        width: 28px;
        min-width: 28px;
        height: 28px;
        border: 1px solid var(--border-color);
        border-radius: 50%;
        text-align: center;
        line-height: 28px;
        color: var(--text-color);
        margin-right: 12px;
      }

      .statement {
        margin-top: 5px;
        color: var(--text-color);
      }
    }

    .actions {
      padding-left: 8px;
      display: flex;
      justify-content: center;

      .button {
        width: 168px;
      }

      .button.white {
        color: var(--secondary-text-color);
        background: var(--background-color);

        :hover {
          background: var(--border-color);
        }
      }

      .button:disabled:hover {
        background: var(--background-color);
      }
    }
  }

  .question-options {
    width: 100%;
    padding: 0px 42px 8px 42px;
    border-bottom: 1px solid var(--border-color);

    .button {
      width: 100%;
      border: 1px solid var(--border-color);
      color: var(--secondary-text-color);
      text-align: start;
      display: flex;
      align-items: center;
      padding: 8px 18px;
      margin-bottom: 24px;
      line-height: 18px;

      img {
        opacity: 0.5;
      }

      :hover {
        color: var(--secondary-text-color);
      }

      &.selected {
        border-color: var(--primary-color);
        color: var(--primary-color);

        img {
          opacity: 1;
        }
      }

      &:disabled {
        opacity: 1;
        cursor: not-allowed;
      }

      &.answer-by-managed {
        background: var(--border-color);
      }

      img {
        margin-right: 12px;
      }
    }
  }

  & > .descriptive-answer-by-managed {
    padding: 32px 42px;
    border-bottom: 1px solid var(--border-color);

    h5 {
      font-size: 16px;
      color: var(--text-color);
      margin-bottom: 4px;
      font-weight: normal;
    }

    p {
      color: var(--secondary-text-color);
      line-height: 18px;
    }
  }

  & > .descriptive-answer {
    padding: 32px 42px;
    border-bottom: 1px solid var(--border-color);

    h5 {
      font-size: 16px;
      color: var(--text-color);
      margin-bottom: 4px;
      font-weight: normal;
    }

    .input {
      padding: 12px 16px;
    }
  }

  @media (max-width: 720px) {
    .question-header {
      flex-direction: column-reverse;
      padding: 16px 0px 0px;

      & > div {
        width: 100%;
        padding: 16px;
      }

      .actions {
        justify-content: flex-end;
        padding: 0px 16px 16px 16px;
        border-bottom: 1px solid var(--border-color);
      }
    }

    .question-options {
      padding: 0px 16px 8px 16px;
    }

    & > .descriptive-answer {
      padding: 16px;
    }
  }
`;

export default StyledPerformanceQuestionPreview;
