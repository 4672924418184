import useAccount from './use-account';
import { getOrganizationStored } from '~/utils';

const useOrganization = organizationId => {
  const account = useAccount();

  let org = account.data.organization_is_active_set.find(
    o => o.id === organizationId
  );
  if (!org) {
    org = getOrganizationStored(account.data.organization_is_active_set);
  }

  return org;
};

export default useOrganization;
