export const APP_ID = '329798130132254';

let endpoint;

if (process.env.REACT_APP_API) {
  endpoint = process.env.REACT_APP_API;
} else {
  endpoint =
    process.env.NODE_ENV === 'development'
      ? 'https://api.dev.talkative.media/v1'
      : 'https://cni.talkative.media/v1';
}

export const REACT_APP_SITE = process.env.REACT_APP_SITE || 'edukative';

export const TOKEN_KEY = 'ktoken';
export const SKIP_TUTORIAL_KEY = 'skpt';

export const BASE_ENDPOINT = endpoint;

const orgIds = {
  talkative: {
    dev: 1,
    prod: 11
  }
};

export const ORGANIZATION_ID =
  process.env.NODE_ENV === 'development'
    ? orgIds[REACT_APP_SITE].dev
    : orgIds[REACT_APP_SITE].prod;

export const INTERCOM_APP_ID = 'koi2jxsb';

export const kalturaPlayerConfig = {
  embedUrl: process.env.REACT_APP_KPLAYER_EMBED_URL,
  partnerId: process.env.REACT_APP_KPLAYER_PARTNER_ID,
  uiConfId: process.env.REACT_APP_KPLAYER_UI_CONF_ID
};
