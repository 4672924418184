import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Video extends PureComponent {
  static propTypes = {
    onEnd: PropTypes.func
  };

  static defaultProps = {
    onEnd: () => {}
  };

  componentDidMount() {
    const { onEnd } = this.props;

    this.refs.video.addEventListener('ended', onEnd);
  }

  render() {
    const { onEnd, ...otherProps } = this.props;

    return <video {...otherProps} ref="video" />;
  }
}

export default Video;
