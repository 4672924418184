import styled from 'styled-components';

export const StyledCourseCategory = styled.div.attrs(() => ({
  className: 'course-category-page'
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 32px;

  .custom-header {
    position: relative;
  }

  .header-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side {
      .button {
        background: var(--white-color);
        padding: 0px 8px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: flex-end;
    }
  }

  .page-header {
    padding-bottom: 0px;
    padding-top: 0px;

    .text-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search-input {
        max-height: 42px;
        width: 40%;
        margin-left: 16px;
        background: var(--white-color);
      }
    }

    h1 {
      margin: 0px;
    }

    p {
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }

  .category-header {
    margin-top: 16px;

    h1 {
      font-weight: normal;
      line-height: 1;
    }

    p {
      margin-top: 8px;
      margin-bottom: 32px;
    }
  }

  .page-content {
    width: 100%;
    max-width: 800px;
    margin-top: 42px;

    .training-section-list {
      margin: 32px 0px;

      .course-category-card {
        min-width: 187px;
        margin-right: 16px;
      }

      .course-category-card:last-child {
        margin-right: 0px;
      }

      .course-card {
        width: 262px;
        min-width: 262px;
        margin-right: 16px;
      }

      .course-card:last-child {
        margin-right: 0px;
      }
    }

    .course-list-title {
      font-size: 24px;
      margin-right: 18px;
      font-weight: normal;
      color: var(--text-color);
      line-height: 1;
      margin-bottom: 16px;
    }

    .course-list {
      position: relative;
      width: 100%;
    }
  }

  .screen-loading {
    margin-top: 64px;
  }

  @media (max-width: 720px) {
    .page-header {
      .text-info {
        flex-direction: column;
        align-items: flex-start;

        .search-input {
          margin-left: 0px;
          width: 100%;
        }
      }
    }

    .page-content {
      padding: 16px;
    }
  }
`;
