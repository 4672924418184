import React from 'react';
import moment from 'moment';
import Card from '~/components/card';
import PostHeader from '~/components/post-header';
import Tags from '~/components/tags';
import PostImage from '~/components/post-image';
import RumorInfo from '~/components/rumor-info';

const Rumor = props => {
  return (
    <Card>
      <PostHeader
        info="Rumor"
        icon_type="rumor"
        organization={props.original_data.organization}
        meTrail={props.me_trail}
      />
      <div className="card-body">
        <div className="content-card">
          <PostImage url={props.cover}>
            <RumorInfo isTrue={props.is_true} />
          </PostImage>
          <div className="title-date-task">
            <h3>{props.title}</h3>
            <p>Postado em {moment(props.created_at).format('DD/MM/YYYY')}</p>
          </div>
          <p style={{ textAlign: 'center' }}>{props.description}</p>
          {props.tags && <Tags>{props.tags}</Tags>}
        </div>
      </div>
    </Card>
  );
};

export default Rumor;
