import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'redux-react-hook';

import { setSurvey, setCurrentQuestion } from '~/store/ducks/survey';

import { getStoragedSurvey } from '~/services/survey';

import Card from '~/components/card';
import Button from '~/components/button';
import Icon from '~/components/icon';

import './style.scss';

function SurveyCard({
  survey = {
    pk: null,
    title: null,
    questions: [],
    cycle: {
      start_at: null,
      finish_at: null
    }
  },
  history
}) {
  const timePerQuestion = 2;

  const dispatch = useDispatch();

  const getCurrentQuestion = questions =>
    questions.findIndex(
      question => !question.answer && !question.justification
    );

  const getStoredSurveyState = () => {
    const storedSurvey = getStoragedSurvey(`survey${survey.pk}`);

    if (
      storedSurvey &&
      storedSurvey.questions.length === survey.questions.length
    ) {
      return {
        data: storedSurvey,
        currentQuestion: getCurrentQuestion(storedSurvey.questions)
      };
    }

    return null;
  };

  const startSurvey = () => {
    const storedSurveyState = getStoredSurveyState();

    if (storedSurveyState) {
      dispatch(setSurvey(storedSurveyState.data));
      dispatch(setCurrentQuestion(storedSurveyState.currentQuestion));
    } else {
      dispatch(setSurvey(survey));
      dispatch(setCurrentQuestion(0));
    }

    history.push(`/survey/questions`);
  };

  return (
    <Card>
      <div className="survey-card-header">
        <h3 className="title">{survey.title}</h3>
        <p className="description">Lorem ipsum domain...</p>
      </div>
      <div className="survey-card-body">
        <div className="statistics">
          <div className="statistic-item">
            <h3 className="value">{survey.questions.length}</h3>
            <p className="label">Questões</p>
          </div>

          <div className="statistic-item">
            <h3 className="value">
              {survey.questions.length * timePerQuestion} <span>min</span>
            </h3>
            <p className="label">Tempo médio</p>
          </div>
        </div>

        <div className="action">
          <Button type="button" color="primary" onClick={startSurvey}>
            <Icon name="arrow-right" color="white" />
            Iniciar Avaliação
          </Button>
        </div>
      </div>
    </Card>
  );
}

export default withRouter(SurveyCard);
