import React, { Component } from 'react';
import FeedItem from '../feed-item';
import Card from '~/components/card';
import Button from '~/components/button';

class QuestionnaireFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  onChange = (currentResult, newObject) => {
    const newResults = [...this.props.results];

    const newResult = {
      ...currentResult,
      object: newObject
    };

    const index = this.props.results.indexOf(currentResult);

    newResults.splice(index, 1, newResult);

    this.props.onChange(newResults);
  };

  onScroll(e) {
    const { offsetHeight, scrollHeight } = document.body;
    const scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (offsetHeight + offsetHeight * 0.5 + scrollTop >= scrollHeight) {
      this.props.onScrollFinish();
    }
  }

  render() {
    return (
      <>
        {/* {this.props.search &&
          this.props.total !== null &&
          this.props.search.indexOf('#') === -1 && (
            <Card>
              <b>{this.props.total}</b>{' '}
              {this.props.total === 1 ? 'resultado' : 'resultados'} para a busca{' '}
              <b>{this.props.search}</b>
            </Card>
          )} */}

        {this.props.search &&
          this.props.total !== null &&
          this.props.search.indexOf('#') !== -1 && (
            <Card>
              <Button
                to="/"
                style={{
                  color: '#000',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                <span className="default-link">Exibir todos os itens</span>
              </Button>
            </Card>
          )}

        {this.props.search &&
          this.props.total === 0 &&
          this.props.search.indexOf('#') !== -1 && (
            <h3 className="text-center" style={{ marginTop: '32px' }}>
              Não há ações desbloqueadas
            </h3>
          )}

        {this.props.results.map((res, i) => (
          <FeedItem
            key={`${res.type}-${res.object.id}`}
            type={res.type}
            onChange={newObject => this.onChange(res, newObject)}
            data={res.object}
            onFinishItem={this.props.onFinishItem}
            isSubtask={true}
          />
        ))}
      </>
    );
  }
}

export default QuestionnaireFeed;
