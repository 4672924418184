import React from 'react';
import ReactLoading from 'react-loading';

import StyledCustomLoading from './styles';

export default function CustomLoading({
  type,
  color,
  height,
  width,
  styles,
  fluid,
  id,
  className
}) {
  return (
    <StyledCustomLoading
      className={`custom-loading ${className || ''}`}
      fluid={fluid}
      color={color}
      id={id}
      style={styles}
    >
      <ReactLoading type={type} color={color} height={height} width={width} />
    </StyledCustomLoading>
  );
}
