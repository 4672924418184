import styled from 'styled-components';

export const StyledPDIStatus = styled.div.attrs(() => ({
  className: 'pdi-status'
}))`
  width: max-content;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 8px;
  height: 25px;

  display: flex;
  align-items: center;

  i {
    margin-right: 8px;
    color: var(--secondary-text-color);
    font-size: 14px;
  }

  p {
    color: var(--white-color);
    font-size: 14px;
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.not_started {
    background: #4a4a4a;
  }

  &.in_progress {
    background: #f4b21b;
  }

  &.finished {
    background: #77a1ff;
  }

  &.canceled {
    background: #f6f6fb;

    p {
      color: var(--secondary-text-color);
    }
  }

  &.is_late {
    background: #f05050;
  }
`;
