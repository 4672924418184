import { PureComponent } from 'react';
import { addWololo, showNewPoints } from '~/services/tasks';
import { getParam } from '~/utils';
import PropTypes from 'prop-types';

class WololoSuccessScreen extends PureComponent {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    const { task } = this.props.match.params;
    const error = getParam('error');
    const points = getParam('points');

    if (!error) {
      if (points) {
        showNewPoints(parseInt(points), () => {
          this.context.router.history.push('/');
        });
      } else {
        addWololo(task, {}, true, () => {
          this.context.router.history.push('/');
        });
      }
    } else {
      alert('Erro!');
      this.context.router.history.push('/');
    }
  }

  render() {
    return null;
  }
}

export default WololoSuccessScreen;
