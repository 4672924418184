import LogoImg from './elle-assets/images/logo-color1.png';
import LogoImg2x from './elle-assets/images/logo-color1.png';
import LogoReverseImg from './elle-assets/images/logo-monochrome1.png';
import LogoReverseImg2x from './elle-assets/images/logo-monochrome1.png';
import './main.scss';

const ElleSite = {
  name: 'Elle',
  googlePlayUrl: '',
  appStoreUrl: '',
  showStoreLinks: false,
  LogoImg,
  LogoImg2x,
  LogoReverseImg,
  LogoReverseImg2x,
  completeSignUp: false,
  tutorialDescription: 'Elle description...',
  actionGenderPoints: 150,
  pushApplicationServerKey:
    'BMplX3pQp1CyS43RjWTg3imHX2DWbgCTYwBF5jkcOOYA9hcwVGXp5rCk_EBRV1-ugvQuLKDibRlFoDArWx5O4C4',
  loginMinutes: null,
  primaryColor: '#F60000'
};

export default ElleSite;
