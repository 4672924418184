import React from 'react';

import Card from '~/components/card';

import { StyledMetlifeCard } from './styles';

function MetlifeCard({ className, image, title, description, fluid, onClick }) {
  return (
    <StyledMetlifeCard fluid={fluid} onClick={onClick} className={className}>
      <Card>
        {image && <img src={image} alt="Metlife item" />}
        <div className="text-content">
          <p>{description}</p>
          <h3>{title}</h3>
        </div>
      </Card>
    </StyledMetlifeCard>
  );
}

export default MetlifeCard;
