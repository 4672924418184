import React from 'react';
import Card from '~/components/card';
import './style.scss';
import PropTypes from 'prop-types';

const AbstractTask = ({ Header, Body, Footer, channel, isSubtask }) => {
  return (
    <Card
      className={`task-${channel || 'default'} ${isSubtask ? 'subtask' : ''}`}
    >
      {Header}
      {Body}
      {Footer}
    </Card>
  );
};

AbstractTask.props = {
  Header: PropTypes.node,
  Body: PropTypes.node.isRequired,
  Footer: PropTypes.node,
  channel: PropTypes.string
};

export default AbstractTask;
