const minDigitValidator = length => value => {
  if (!value) {
    return undefined;
  }

  return value.length < length
    ? `Deve conter pelo menos ${length} caracteres`
    : undefined;
};

export default minDigitValidator;
