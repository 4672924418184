import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input } from '../../form';
import { Row, Col } from '../../grid';
import Button from '../../button';
import Icon from '../../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
import { updateProfile } from '~/api/account';
import { showNewPoints } from '~/services/tasks';

class BornAt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ddd: '',
      ddd_invalid: false,

      number: '',
      number_invalid: false,

      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [`${name}_invalid`]: false, [name]: value });
  }

  handleSubmit() {
    if (!this.state.loading) {
      const required = ['ddd', 'number'];

      const invalid = required.filter(name => !this.state[name]);

      if (!invalid.length) {
        this.setState({ loading: true });
        const { ddd, number } = this.state;
        const { name, email } = this.props.account.data.profile;

        updateProfile({
          phone: `${ddd} ${number}`,
          name,
          email
        })
          .then(() => {
            showNewPoints(this.props.pointsToShow);
            this.props.onFinish();
          })
          .catch(() => {
            this.setState({ loading: false });
            alert('Erro');
          });
      } else {
        invalid.map(name => this.setState({ [`${name}_invalid`]: true }));
      }
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col md={4}>
            <Input
              name="ddd"
              placeholder="DDD"
              mask={['(', /\d/, /\d/, ')']}
              guide={false}
              onChange={this.handleChange}
              value={this.state.ddd}
              invalid={this.state.ddd_invalid}
            />
          </Col>
          <Col md={8}>
            <Input
              name="number"
              placeholder="Número"
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              guide={false}
              onChange={this.handleChange}
              value={this.state.number}
              invalid={this.state.number_invalid}
            />
          </Col>
        </Row>
        <div>
          <Button color="primary" disabled={this.state.loading}>
            <Icon name="member-ok" marginRight />
            <span>Adicionar</span>
          </Button>
        </div>
      </Form>
    );
  }
}

BornAt.defaultProps = {
  onFinish: () => {}
};

BornAt.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BornAt);
