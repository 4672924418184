import React from 'react';

import Button from '~/components/button';

import { StyledMultiChoiceQuestion } from './styles';

function MultiChoiceQuestion({
  options,
  answers,
  setAnswers,
  correctAnswers,
  disabled,
  isDone
}) {
  const handleSelect = answer => {
    const currentAnswers = [...answers];

    const answerIndex = answers.indexOf(answer);
    if (answerIndex !== -1) {
      currentAnswers.splice(answerIndex, 1);
    } else {
      currentAnswers.push(answer);
    }

    setAnswers(currentAnswers);
  };

  return (
    <StyledMultiChoiceQuestion>
      <div className="question-options">
        {options.map(option => (
          <Button
            key={option.pk}
            color="primary"
            className={`
              outline
              ${answers.some(answer => answer === option.pk) ? ' selected' : ''}
              ${
                correctAnswers.some(answer => answer === option.pk) && isDone
                  ? ' is-correct'
                  : ''
              }
            `}
            onClick={() => handleSelect(option.pk)}
            disabled={disabled}
          >
            <p>{option.text}</p>
          </Button>
        ))}
      </div>
    </StyledMultiChoiceQuestion>
  );
}

export default MultiChoiceQuestion;
