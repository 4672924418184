import React from 'react';
import Form from './form';
import Input from './input';
import Select from './select';
import Check from './check';
import CheckList from './check-list';
import UploadImage from './upload-image';
import * as validators from './validators';

import './style.scss';

const InputFinalForm = ({
  input,
  meta: { submitFailed, error, submitError },
  ...props
}) => (
  <>
    <Input
      {...input}
      {...props}
      invalid={submitFailed && (error || submitError)}
    />
    {submitFailed && (error || submitError) ? (
      <p className="danger-message">{error || submitError}</p>
    ) : null}
  </>
);

const SelectInputFinalForm = ({
  input,
  options,
  placeholder,
  meta: { submitFailed, error, submitError },
  ...props
}) => (
  <>
    <Select
      {...input}
      {...props}
      invalid={submitFailed && (error || submitError)}
    >
      <>
        <option value="" disabled>
          {placeholder || '...'}
        </option>
        {options.map(([id, label]) => (
          <option key={id} value={id}>
            {label}
          </option>
        ))}
      </>
    </Select>
    {submitFailed && (error || submitError) ? (
      <p className="danger-message">{error || submitError}</p>
    ) : null}
  </>
);

export {
  Form,
  Input,
  Select,
  Check,
  CheckList,
  UploadImage,
  validators,
  InputFinalForm,
  SelectInputFinalForm
};
