import styled from 'styled-components';

const StyledDoPerformance = styled.div`
  width: 100%;
  min-height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .custom-loading {
    margin-top: 240px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    text-align: center;
    color: var(--text-color);
  }
`;

export default StyledDoPerformance;
