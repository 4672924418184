import styled from 'styled-components';

export const StyledCreatorsFormTask = styled.div.attrs(() => ({
  className: 'creators-form-task'
}))`
  .creators-form-task-container {
    width: 100%;

    .title-subtitle {
      margin: 30px 0 34px;

      .title {
        font-size: 24px;
        margin-bottom: 16px;
      }

      p {
        font-size: 16px;
      }
    }

    .information {
      margin-top: 28px;
    }

    .actions {
      margin: 28px 0 34px;

      display: flex;
      align-items: center;
      justify-content: center;

      .button {
        padding: 10px 12px;
        gap: 4px;
        width: 128px;
        height: 44px;
        background: var(--primary-color);
        border-radius: 100px;
      }
    }

    .disabled {
      .button {
        color: #999999;
        background: #dde0e6;

        pointer-events: none;
      }
    }
  }
`;
