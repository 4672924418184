import React from 'react';
import { connect } from 'react-redux';
import Close from '../close';
import { closeMediaModal } from '~/store/ducks/media-modal';
import './style.scss';

const MediaModal = ({ closeMediaModal, children }) => (
  <div className="media-modal">
    <div className="media-modal-content">
      <Close onClick={closeMediaModal} />
      <div>{children}</div>
    </div>
  </div>
);

const mapDispatchToProps = (dispatch, ownProps) => {
  const { onClose } = ownProps;

  return {
    closeMediaModal: () => {
      if (onClose) {
        onClose();
      }
      dispatch(closeMediaModal());
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(MediaModal);
