import React from 'react';

import { StyledCourseCategoryBanner } from './styles';

function CourseCategoryBanner({ title, image, showTitle }) {
  return (
    <StyledCourseCategoryBanner categoryImage={image} showTitle={showTitle}>
      {/*<div className="category-card-content">*/}
      {/*  {showTitle && <h3>{title}</h3>}*/}
      {/*</div>*/}
    </StyledCourseCategoryBanner>
  );
}

export default CourseCategoryBanner;
