import React from 'react';
import { useDispatch } from 'redux-react-hook';

import { closeDefaultModal } from '~/store/ducks/default-modal';

import checkboxImage from '~/assets/images/checkbox.svg';

import Button from '../button';

import { StyledCourseSubscribeSuccessModal } from './styles';

function CourseSubscribeSuccessModal() {
  const dispatch = useDispatch();

  return (
    <StyledCourseSubscribeSuccessModal>
      <div className="content-wrapper">
        <img src={checkboxImage} alt="" />
        <h3>Você está matriculado</h3>
        <p></p>
      </div>
      <div className="actions">
        <Button color="primary" onClick={() => dispatch(closeDefaultModal())}>
          Fechar
        </Button>
      </div>
    </StyledCourseSubscribeSuccessModal>
  );
}

export default CourseSubscribeSuccessModal;
