import React from 'react';
import { Link } from 'react-router-dom';
import useSite from '~/hooks/use-site';
import './style.scss';

const Logo = ({ vbWidth, vbHeight, small, reverse, customLogo }) => {
  const { LogoReverseImg, LogoImg, name } = useSite();

  const logo = customLogo || (reverse ? LogoReverseImg : LogoImg);

  return (
    <Link to="/" title={name}>
      <img
        className="logo"
        src={logo}
        alt="LogoImg"
        // srcSet={`${reverse ? LogoReverseImg2x : LogoImg2x} 2x`}
      />
    </Link>
  );
};

Logo.defaultProps = {
  vbWidth: 578.57098,
  vbHeight: 130.155,
  small: false,
  reverse: false
};

export default Logo;
