import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Select } from '../../form';
import Button from '../../button';
import Icon from '../../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
import { updateProfile } from '~/api/account';
import { showNewPoints } from '~/services/tasks';

class Cni extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sesi_senai: '',
      sesi_senai_invalid: false,

      office: '',
      office_invalid: false,

      area: '',
      area_invalid: false,

      erg: '',
      erg_invalid: false,

      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [`${name}_invalid`]: false, [name]: value });
  }

  handleSubmit() {
    if (!this.state.loading) {
      const required = ['sesi_senai', 'erg'];

      if (this.state.sesi_senai) {
        required.push('office');
      }

      if (this.state.office === 'Docente') {
        required.push('area');
      }

      const invalid = required.filter(name => !this.state[name]);

      if (!invalid.length) {
        this.setState({ loading: true });
        const { sesi_senai, office, area, erg } = this.state;
        const { name, email } = this.props.account.data.profile;

        updateProfile({
          sesi_senai,
          office,
          area,
          erg,
          name,
          email
        })
          .then(() => {
            showNewPoints(this.props.pointsToShow);
            this.props.onFinish();
          })
          .catch(() => {
            this.setState({ loading: false });
            alert('Erro');
          });
      } else {
        invalid.map(name => this.setState({ [`${name}_invalid`]: true }));
      }
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div>
          <div>
            <Select
              name="sesi_senai"
              onChange={this.handleChange}
              value={this.state.sesi_senai}
              invalid={this.state.sesi_senai_invalid}
            >
              <option>Selecione a instituição de trabalho</option>
              <option value="SESI">SESI</option>
              <option value="SENAI">SENAI</option>
            </Select>
          </div>

          {this.state.sesi_senai && (
            <div>
              <Select
                name="office"
                onChange={this.handleChange}
                value={this.state.office}
                invalid={this.state.office_invalid}
              >
                <option>Selecione a função ou cargo</option>
                {this.state.sesi_senai === 'SESI' ? (
                  <>
                    <option value="Aluno">Aluno</option>
                    <option value="Administrativo">Administrativo</option>
                    <option value="Coordenador Pedagógico">
                      Coordenador Pedagógico
                    </option>
                    <option value="Consultor técnico">Consultor técnico</option>
                    <option value="Docente">Docente</option>
                    <option value="Gestor">Gestor</option>
                    <option value="Pesquisador">Pesquisador</option>
                    <option value="Supervisor">Supervisor</option>
                    <option value="Técnico">Técnico</option>
                  </>
                ) : (
                  <>
                    <option value="Aluno">Aluno</option>
                    <option value="Administrativo">Administrativo</option>
                    <option value="Coordenador pedagógico">
                      Coordenador pedagógico
                    </option>
                    <option value="Consultor técnico">Consultor técnico</option>
                    <option value="Docente">Docente</option>
                    <option value="Gestor">Gestor</option>
                    <option value="Pesquisador">Pesquisador</option>
                    <option value="Supervisor técnico">
                      Supervisor técnico
                    </option>
                    <option value="Técnico">Técnico</option>
                  </>
                )}
              </Select>
            </div>
          )}

          {this.state.office === 'Docente' && (
            <div>
              <Select
                name="area"
                onChange={this.handleChange}
                value={this.state.area}
                invalid={this.state.area_invalid}
              >
                <option>Selecione a área</option>
                <option value="Rótulos de Linha">Rótulos de Linha</option>
                <option value="Alimentos e Bebidas">Alimentos e Bebidas</option>
                <option value="Automação">Automação</option>
                <option value="Automação e Mecatrônica">
                  Automação e Mecatrônica
                </option>
                <option value="Automotiva">Automotiva</option>
                <option value="Celulose e Papel">Celulose e Papel</option>
                <option value="Construção">Construção</option>
                <option value="Educação">Educação</option>
                <option value="Eletroeletrônica">Eletroeletrônica</option>
                <option value="Gemologia e Joalheria">
                  Gemologia e Joalheria
                </option>
                <option value="Gestão">Gestão</option>
                <option value="Gráfica e Editorial">Gráfica e Editorial</option>
                <option value="Logística">Logística</option>
                <option value="Madeira e Mobiliário">
                  Madeira e Mobiliário
                </option>
                <option value="Meio Ambiente">Meio Ambiente</option>
                <option value="Metalmecânica">Metalmecânica</option>
                <option value="Metalmecânica - Mecânica">
                  Metalmecânica - Mecânica
                </option>
                <option value="Metrologia">Metrologia</option>
                <option value="Mineração">Mineração</option>
                <option value="Minerais não Metálicos">
                  Minerais não Metálicos
                </option>
                <option value="Outras">Outras</option>
                <option value="Petróleo e Gás">Petróleo e Gás</option>
                <option value="Polímeros">Polímeros</option>
                <option value="Química">Química</option>
                <option value="Refrigeração e Climatização">
                  Refrigeração e Climatização
                </option>
                <option value="Segurança no Trabalho">
                  Segurança no Trabalho
                </option>
                <option value="Tecnologia da Informação">
                  Tecnologia da Informação
                </option>
                <option value="Telecomunicações">Telecomunicações</option>
                <option value="Têxtil e Vestuário">Têxtil e Vestuário</option>
                <option value="Transporte">Transporte</option>
              </Select>
            </div>
          )}

          <div>
            <Select
              name="erg"
              onChange={this.handleChange}
              value={this.state.erg}
              invalid={this.state.erg_invalid}
            >
              <option>Selecione a unidade de trabalho</option>
              <option value="DN">DN</option>
              <option value="CETIQT">CETIQT</option>
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </Select>
          </div>
        </div>

        <div>
          <Button color="primary" disabled={this.state.loading}>
            <Icon name="arrow-right" marginRight />
            <span>Enviar</span>
          </Button>
        </div>
      </Form>
    );
  }
}

Cni.defaultProps = {
  onFinish: () => {}
};

Cni.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cni);
