import LogoImg from './bora-assets/images/logo.png';
import LogoImg2x from './bora-assets/images/logo.png';
import LogoReverseImg from './bora-assets/images/logo-reverse.png';
import LogoReverseImg2x from './bora-assets/images/logo-reverse.png';
import './main.scss';

const BoraSite = {
  name: 'Bora!',
  googlePlayUrl: '',
  appStoreUrl: '',
  LogoImg,
  LogoImg2x,
  LogoReverseImg,
  LogoReverseImg2x,
  completeSignUp: false,
  tutorialDescription: 'Bora! description...',
  actionGenderPoints: 150,
  pushApplicationServerKey:
    'BMplX3pQp1CyS43RjWTg3imHX2DWbgCTYwBF5jkcOOYA9hcwVGXp5rCk_EBRV1-ugvQuLKDibRlFoDArWx5O4C4',
  loginMinutes: null,
  primaryColor: '#1a2e4c'
};

export default BoraSite;
