export const Types = {
  OPEN: 'generic-modal/OPEN',
  CLOSE: 'generic-modal/CLOSE'
};

const defaultState = {
  content: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.OPEN: {
      return {
        content: action.content
      };
    }
    case Types.CLOSE:
      return defaultState;
    default:
      return state;
  }
}

export const openGenericModal = content => ({
  type: Types.OPEN,
  content
});

export const closeGenericModal = () => ({
  type: Types.CLOSE
});
