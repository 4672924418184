import React, { useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { withRouter } from 'react-router-dom';

import { answerSurvey } from '~/api/survey';

import { openPostModal, closePostModal } from '~/store/ducks/post-modal';

import { removeSurvey } from '~/services/survey';

import ModalWrapper from '../modal-wrapper';
import Modal from '~/components/modal';
import Button from '~/components/button';
import CanceledSurveyModal from '~/components/survey/canceled-survey-modal';

import cancelImage from '~/assets/images/Illustration-cancel-evaluation.svg';

import './style.scss';

function CancelParticipationModal({ onClose, survey, history }) {
  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const sendAnswer = () => {
    const data = {
      answers: null,
      justification: reason
    };

    setLoading(true);

    answerSurvey(survey.pk, data)
      .then(() => {
        removeSurvey(`survey${survey.pk}`);

        dispatch(
          openPostModal(
            <CanceledSurveyModal onClose={() => dispatch(closePostModal())} />
          )
        );

        history.push('/survey');
      })
      .catch(() => {
        alert('Ocorreu um erro inesperado!');
        history.push('/survey');
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <ModalWrapper>
      <Modal className="message-modal cancel-participation" onClose={onClose}>
        <img src={cancelImage} alt="Cancel survey" />

        <h3 className="title">Cancelar a participação</h3>
        <p className="description">
          Que pena! Poderia nos informar o motivo do cancelamento?
        </p>

        <div className="reasons">
          <div className="reasons-form-group">
            <input
              type="radio"
              name="reason"
              id="reason-1"
              onChange={() => setReason('Motivo 1')}
            />
            <label htmlFor="reason-1">Motivo 1</label>
          </div>

          <div className="reasons-form-group">
            <input
              type="radio"
              name="reason"
              id="reason-2"
              onChange={() => setReason('Motivo 2')}
            />
            <label htmlFor="reason-2">Motivo 2</label>
          </div>

          <div className="reasons-form-group">
            <input
              type="radio"
              name="reason"
              id="reason-3"
              onChange={() => setReason('Motivo 3')}
            />
            <label htmlFor="reason-3">Motivo 3</label>
          </div>

          <div className="reasons-form-group">
            <input
              type="radio"
              name="reason"
              id="reason-4"
              onChange={() => setReason('Motivo 4')}
            />
            <label htmlFor="reason-4">Motivo 4</label>
          </div>
        </div>

        <div className="actions">
          <Button color="primary" onClick={sendAnswer}>
            {!loading ? 'Confirmar Cancelamento' : 'Cancelando...'}
          </Button>
          <Button color="link" onClick={onClose}>
            Continuar respondendo
          </Button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}

export default withRouter(CancelParticipationModal);
