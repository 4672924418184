import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openMediaModal } from '~/store/ducks/media-modal';
import { BASE_ENDPOINT } from '~/settings';
import './style.scss';

const available = {
  facebook: ['share', 'image', 'movie', 'watch'],
  linkedin: ['share', 'message', 'image', 'movie'],
  youtube: ['watch'],
  whatsapp: ['movie', 'image']
};

class LinkPreviewMedia extends Component {
  constructor(props) {
    super(props);
    this.previewUrl = `${BASE_ENDPOINT}/tasks/${props.id}/preview/`;
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({ open: true });

    this.props.openMediaModal(
      <iframe
        width={600}
        height={350}
        src={this.previewUrl}
        frameBorder="0"
        title="Preview"
      />
    );
  }

  render() {
    const { channel, type, children } = this.props;
    const channelTypes = available[channel];
    const hasMedia =
      channelTypes && channelTypes.filter(t => t === type).length;

    return hasMedia ? (
      <>
        <a
          href={this.previewUrl}
          onClick={this.handleClick}
          target="_blank"
          rel="noopener noreferrer"
          className="link-preview-media"
          title="Preview"
        >
          {children}
        </a>
      </>
    ) : (
      children
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMediaModal }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(LinkPreviewMedia);
