import styled from 'styled-components';

const SuspendedMenu = styled.div.attrs(() => ({
  className: 'suspended-menu'
}))`
  position: relative;
  width: max-content;

  & > button {
    padding: 0px !important;
    font-size: 16px;
    width: 32px;

    i {
      margin: 0px;
    }
  }

  & > a {
    padding: 0px;
    font-size: 16px;
    width: max-content;
    height: max-content;
    text-decoration: none;
  }

  .suspended-content {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    position: absolute;
    right: 0px;
    width: max-content;
    min-width: 200px;
    min-height: 50px;
    padding: 6px 0px;
    z-index: 1000;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    transition: all 0.1s;
    box-shadow: 0px 4px 20px 4px rgba(196, 196, 196, 0.15);

    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      width: 100%;
      border: none;
      border-radius: 0px;
      font-size: 14px !important;
      color: var(--secondary-text-color) !important;

      &:hover {
        background: var(--border-color);
      }
      .loading {
        left: calc(50% - 14px);
      }
    }
  }
`;

export default SuspendedMenu;
