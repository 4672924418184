import React, { useState } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import downloadFile from '~/services/download';

import useDownloads from '~/hooks/use-downloads';
import useOrganization from '~/hooks/use-organization';
import PageHeader from '~/components/page-header';

import './styles.scss';

export const DownloadIcon = props => (
  <svg width={32} height={32} {...props}>
    <path
      d="M6.999,0 L6.999,8 L3.48417969,4.50595703 L1.9875,6.02226563 L8.001,11.604 L13.9907715,6.02226563 L12.5170898,4.50595703 L9,8 L9,0 L6.999,0 Z M14.001,12 L14.001,14 L2,14 L2,12 L0,12 L0,16 L16,16 L16,12 L14.001,12 Z"
      id="path-1"
    />
  </svg>
);

function DownloadsLayout({ children }) {
  return (
    <>
      <PageHeader>
        <div className="text-info">
          <h1>Downloads</h1>
          <p>Para baixar, clique em download em um dos itens:</p>
        </div>
      </PageHeader>
      <div className="downloads card">{children}</div>
    </>
  );
}

function DownloadItem({ item }) {
  const { name: organizationName, avatar_128x0 } = useOrganization(
    item.organization
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const download = () => {
    setLoading(true);
    setError(false);

    downloadFile(item.file)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${item.title}${item.extension}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="download-item">
      <img
        src={item.cover_128x0 || avatar_128x0}
        alt={organizationName}
        className="org-image"
      />
      <div className="download-item-content">
        <div className="left-side">
          <h3 className="title">{item.title}</h3>
          <p className="description">{item.description}</p>
          {error && (
            <p className="error-message">
              Falha ao realizar o download. Tente novamente.
            </p>
          )}
          <button type="button" onClick={() => download()}>
            {!loading ? (
              <>
                <DownloadIcon />
                <p>Download</p>
              </>
            ) : (
              <p>Fazendo download...</p>
            )}
          </button>
        </div>
        <div className="right-side">
          <p className="date">
            {moment(item.updated_at).format('D [de] MMM [de] YYYY [às] H:mm')}
          </p>
        </div>
      </div>
    </div>
  );
}

function Downloads({ organization: { activeOrganizationId } }) {
  const downloads = useDownloads(activeOrganizationId);

  if (!downloads) {
    return (
      <DownloadsLayout>
        <p className="response-message">Carregando...</p>
      </DownloadsLayout>
    );
  }

  if (downloads.length === 0) {
    return (
      <DownloadsLayout>
        <p className="response-message">Não há itens</p>
      </DownloadsLayout>
    );
  }

  return (
    <DownloadsLayout>
      {downloads.map(download => (
        <DownloadItem key={download.id} item={download} />
      ))}
    </DownloadsLayout>
  );
}

const mapStateToProps = store => ({
  organization: store.organization
});

export default connect(mapStateToProps)(withRouter(Downloads));
