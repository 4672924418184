import React from 'react';

import { REACT_APP_SITE, BASE_ENDPOINT } from '~/settings';

import Card from '~/components/card';
import Icon from '~/components/icon';
import QRCode from '~/components/qr-code';
// import Link from '~/components/link';
import StoreLinks from '~/components/store-links';
import {
  PublicBanner,
  PublicFooter,
  PublicHeader,
  PublicWrapper
} from '~/components/public';

import { StyledSignUpCompleted } from './styles';

function SignUpCompleted() {
  const url = `${BASE_ENDPOINT}/app-qr-code-redirect/`;

  return (
    <StyledSignUpCompleted>
      <PublicWrapper>
        <PublicHeader>
          <Card className="public-card">
            <div className="content-icon-success">
              <Icon name="check" />
            </div>

            <div className="title-subtitle">
              <strong>Cadastro realizado com sucesso!</strong>
              <p>
                Use o QR code abaixo para acessar o aplicativo talkative no seu
                telefone ou continue para acessar o talkative no seu navegador
              </p>

              <QRCode title="Talkative by Spark" url={url} />
              {/* <Link to="/sign-in">
                <span>Continuar no navegador</span>
                <Icon name="arrow-right" marginLeftSmall />
              </Link> */}
            </div>
          </Card>
        </PublicHeader>
        <div className="main">
          <PublicBanner>
            {REACT_APP_SITE !== 'flow' && REACT_APP_SITE !== 'elle' ? (
              <StoreLinks>
                <span>Usuário, baixe nosso app para</span>
                <br />
                <span>interagir com suas comunidades.</span>
                <br />
                <span>Disponível para iOS e Android.</span>
              </StoreLinks>
            ) : (
              <span>Seja bem-vindo!</span>
            )}
          </PublicBanner>
          <div className="hide-on-mobile" style={{ marginBottom: 200 }} />
        </div>
        <PublicFooter />
      </PublicWrapper>
    </StyledSignUpCompleted>
  );
}

export default SignUpCompleted;
