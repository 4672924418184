import React from 'react';

import Icon from '~/components/icon';

import { StyledInputCustom } from './styles';

const InputCustom = ({ iconName, ...props }) => {
  return (
    <StyledInputCustom>
      <div className="input-custom-container">
        <div>
          <Icon name={iconName} marginRight color="gray" />
        </div>
        <input {...props} />
      </div>
    </StyledInputCustom>
  );
};

export default InputCustom;
