import { useState, useEffect } from 'react';

const isMobile = () => window.innerWidth < 988;

const getVersion = () => (isMobile() ? 'mobile' : 'desktop');

const useVersion = () => {
  const [version, setVersion] = useState(getVersion());

  useEffect(() => {
    const resize = () => {
      setVersion(getVersion());
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return version;
};

export default useVersion;
