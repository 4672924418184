import React from 'react';
import { usePagination, DOTS } from '../../hooks/use-pagination';

import Icon from '../icon';

import { StyledPagination } from './styles.js';

const Pagination = ({
  siblingCount = 2,
  currentPage,
  pageSize,
  numPages,
  onNext,
  onPrevious,
  onPageNumberClick,
  hasNext,
  hasPrevious
}) => {
  const paginationRange = usePagination({
    numPages,
    currentPage,
    siblingCount,
    pageSize
  });

  // Se houver menos que 2 vezes no intervalo de paginação, não renderizamos o componente
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <StyledPagination>
      <ul className="pagination-container">
        {/* Seta de navegação da esquerda */}
        <li
          className={`pagination-item ${!hasPrevious ? 'disabled' : ''}`}
          onClick={onPrevious}
        >
          <Icon name="previous" className="previous-btn" />
        </li>
        {paginationRange.map((pageNumber, index) => {
          // Se o pageItem for um PONTO (DOT), renderize o caractere unicode DOTS
          if (pageNumber === DOTS) {
            return (
              <li
                key={`${index}-${pageNumber}`}
                className="pagination-item dots"
              >
                &#8230;
              </li>
            );
          }

          // Renderize a amostra de página
          return (
            <li
              key={`${index}-${pageNumber}`}
              className={`pagination-item ${
                pageNumber === currentPage ? 'selected' : ''
              }`}
              onClick={() => onPageNumberClick(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        {/*  Seta de navegação da direita */}
        <li
          className={`pagination-item ${!hasNext ? 'disabled' : ''}`}
          onClick={onNext}
        >
          <Icon name="next" className="next-btn" />
        </li>
      </ul>
    </StyledPagination>
  );
};

export default Pagination;
