import React from 'react';

import { StyledCourseCategoryCard } from './styles';

function CourseCategoryCard({ title, showTitle, categoryImage, onClick }) {
  return (
    <StyledCourseCategoryCard
      onClick={onClick}
      categoryImage={categoryImage}
      showTitle={showTitle}
    >
      <div className="background"></div>
      <div className="card-content">
        {!categoryImage ? (
          <h3>{title}</h3>
        ) : (
          showTitle && showTitle && <h3>{title}</h3>
        )}
      </div>
    </StyledCourseCategoryCard>
  );
}

export default CourseCategoryCard;
