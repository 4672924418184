import _ from 'lodash';

import { Types as FeedTypes } from './feed';
import { Types as ActionsTypes } from './actions';

import {
  getComments as getTaskComments,
  postComment as postTaskComment,
  editComment as editTaskComment,
  destroyComment as destroyTaskComment
} from '~/api/tasks';

import {
  getComments as getPostComments,
  postComment as postPostComment,
  editComment as editPostComment,
  destroyComment as destroyPostComment
} from '~/api/post';

import {
  getComments as getForumPostComments,
  postComment as postForumPostComment,
  editComment as editForumPostComment,
  destroyComment as destroyForumPostComment
} from '~/api/forum';

import api from '~/api/api';

const config = {
  task: {
    get: getTaskComments,
    post: postTaskComment,
    edit: editTaskComment,
    destroy: destroyTaskComment,

    // usado para pegar os comentários filhos
    childKey: 'taskcomment_set'
  },
  post: {
    get: getPostComments,
    post: postPostComment,
    edit: editPostComment,
    destroy: destroyPostComment,

    // usado para pegar os comentários filhos
    childKey: 'accountcomment_set'
  },

  forumpost: {
    get: getForumPostComments,
    post: postForumPostComment,
    edit: editForumPostComment,
    destroy: destroyForumPostComment,

    // usado para pegar os comentários filhos
    childKey: 'accountcomment_set'
  }
};

export const Types = {
  COMMENT_CREATE: 'comments/COMMENT_CREATE',
  COMMENT_DESTROY: 'comments/COMMENT_DESTROY',
  COMMENT_EDIT: 'comments/COMMENT_EDIT',
  COMMENTS_LOAD: 'comments/COMMENTS_LOAD',
  COMMENTS_LOAD_NEXT: 'comments/COMMENTS_LOAD_NEXT'
};

const defaultState = {};
const defaultComment = {
  comments: [],
  loading: false,
  newCommentLoading: false,
  error: null,
  nextUrl: null
};

export const getKey = (type, id) => `${type}-${id}`;

export const getKeyFromMeta = ({ type, id }) => getKey(type, id);

export const getCommentFromMeta = (state, meta) => state[getKeyFromMeta(meta)];

export const getCommentAndKeyFromMeta = (state, meta) => ({
  comment: getCommentFromMeta(state, meta),
  key: getKeyFromMeta(meta)
});

// o backend manda os comentários filhos dentro do pai, aqui eu separo e coloco todos
// no mesmo nível para facilitar exclusão futura
//
// o component front que deve lidar com agrupamento
const extractComments = (comments, type) => {
  return _.flatMapDeep(comments, comment => [
    comment,
    comment[config[type].childKey] || []
  ]);
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case `${Types.COMMENTS_LOAD}_REJECTED`:
    case `${Types.COMMENTS_LOAD_NEXT}_REJECTED`: {
      const { comment, key } = getCommentAndKeyFromMeta(state, action.meta);

      return {
        ...state,
        [key]: {
          ...comment,
          error: true,
          loading: false
        }
      };
    }

    case `${Types.COMMENTS_LOAD}_PENDING`:
    case `${Types.COMMENTS_LOAD_NEXT}_PENDING`: {
      const { comment, key } = getCommentAndKeyFromMeta(state, action.meta);

      return {
        ...state,
        [key]: {
          ...comment,
          loading: true,
          error: null
        }
      };
    }

    case `${Types.COMMENTS_LOAD}_FULFILLED`: {
      const { comment, key } = getCommentAndKeyFromMeta(state, action.meta);
      const {
        payload: {
          data: { count, results, next }
        }
      } = action;

      return {
        ...state,
        [key]: {
          ...comment,
          count,
          loading: false,
          error: null,
          nextUrl: next,
          comments: extractComments(results, action.meta.type)
        }
      };
    }

    case `${Types.COMMENTS_LOAD_NEXT}_FULFILLED`: {
      const { comment, key } = getCommentAndKeyFromMeta(state, action.meta);
      const {
        payload: {
          data: { count, results, next }
        }
      } = action;

      return {
        ...state,
        [key]: {
          ...comment,
          count,
          loading: false,
          error: null,
          nextUrl: next,
          comments: [
            ...comment.comments,
            ...extractComments(results, action.meta.type)
          ]
        }
      };
    }

    case `${Types.COMMENT_CREATE}_FULFILLED`: {
      const { comment, key } = getCommentAndKeyFromMeta(state, action.meta);
      const {
        payload: { data },
        meta: { owner }
      } = action;

      return {
        ...state,
        [key]: {
          ...comment,
          comments: [
            {
              ...data,
              owner
            },
            ...comment.comments
          ],
          count: comment.count + 1,
          newCommentLoading: false,
          newCommentError: null
        }
      };
    }

    case `${Types.COMMENT_EDIT}_FULFILLED`: {
      const { comment, key } = getCommentAndKeyFromMeta(state, action.meta);

      const currentComment = comment.comments.find(
        c => c.id === action.meta.commentId
      );

      const newComment = {
        ...currentComment,
        comment: action.payload.data.comment
      };

      const newComments = [...comment.comments];

      newComments.splice(newComments.indexOf(currentComment), 1, newComment);

      return {
        ...state,
        [key]: {
          ...comment,
          comments: newComments
        }
      };
    }

    case `${Types.COMMENT_DESTROY}_FULFILLED`: {
      const { comment, key } = getCommentAndKeyFromMeta(state, action.meta);
      const {
        meta: { commentId }
      } = action;

      const newComments = comment.comments.filter(
        comment => comment.id !== commentId && comment.parent !== commentId
      );

      const commentsCountDiff = newComments.length - state[key].comments.length;

      return {
        ...state,
        [key]: {
          ...comment,
          comments: newComments,
          count: comment.count + commentsCountDiff
        }
      };
    }

    case `${ActionsTypes.POST_LOADED}`: {
      const {
        payload: { id, comment_counter }
      } = action;

      return {
        ...state,
        [getKey('post', id)]: {
          ...defaultComment,
          count: comment_counter
        }
      };
    }
    case `${ActionsTypes.TASK_LOADED}`: {
      const {
        payload: { id, comment_counter }
      } = action;

      return {
        ...state,
        [getKey('task', id)]: {
          ...defaultComment,
          count: comment_counter
        }
      };
    }
    case `${ActionsTypes.FORUMPOST_LOADED}`: {
      const {
        payload: { id, comment_counter }
      } = action;

      return {
        ...state,
        [getKey('forumpost', id)]: {
          ...defaultComment,
          count: comment_counter
        }
      };
    }

    case `${FeedTypes.LOAD}_FULFILLED`:
    case `${FeedTypes.LOAD_MORE}_FULFILLED`: {
      const {
        payload: {
          data: { results }
        }
      } = action;

      const comments = results.reduce(
        (memo, { type, object: { id, comment_counter } }) => {
          memo[getKey(type, id)] = {
            ...defaultComment,
            count: comment_counter
          };
          return memo;
        },
        {}
      );

      return {
        ...state,
        ...comments
      };
    }

    default:
      return state;
  }
}

export const loadComments = (type, id) => ({
  type: Types.COMMENTS_LOAD,
  payload: config[type].get(id),
  meta: {
    type,
    id
  }
});

export const loadNextComments = (type, id, url) => ({
  type: Types.COMMENTS_LOAD_NEXT,
  payload: api.get(url),
  meta: {
    type,
    id
  }
});

export const createComment = (type, id, { comment, parentId, owner }) => ({
  type: Types.COMMENT_CREATE,
  payload: config[type].post(id, comment, parentId),
  meta: {
    type,
    id,
    owner
  }
});

export const destroyComment = (type, id, commentId) => ({
  type: Types.COMMENT_DESTROY,
  payload: config[type].destroy(commentId),
  meta: {
    type,
    id,
    commentId
  }
});

export const editComment = (type, id, { comment, commentId, parentId }) => ({
  type: Types.COMMENT_EDIT,
  payload: config[type].edit(commentId, comment, parentId),
  meta: {
    type,
    id,
    commentId
  }
});
