import React, { useState, useRef } from 'react';

import useOutsideEvent from '~/hooks/use-outside-event';

import Button from '~/components/button';
import Card from '~/components/card';
import Icon from '~/components/icon';

import StyledSuspendedMenu from './styles';

function SuspendedMenu({ children, openButton: OpenButton }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const wrapperRef = useRef(null);
  useOutsideEvent(wrapperRef, () => {
    setIsOpen(false);
  });

  return (
    <StyledSuspendedMenu isOpen={isOpen} ref={wrapperRef}>
      {OpenButton && (
        <Button color="primary" className="outline" onClick={toggleMenu}>
          {OpenButton}
        </Button>
      )}

      {!OpenButton && (
        <Button
          color="primary"
          className="outline"
          fluid
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon name="ellipsis" />
        </Button>
      )}

      <Card className="suspended-content">{children}</Card>
    </StyledSuspendedMenu>
  );
}

export default SuspendedMenu;
