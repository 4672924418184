import React from 'react';

import LinkPreviewMedia from '~/components/link-preview-media';
import CreatorsFormQuestion from '../creators-form-question';
import CustomLoading from '~/components/custom-loading';
import Button from '~/components/button';

import { StyledCreatorsFormTask } from './styles';

function CreatorsFormTask({
  loading,
  questions,
  answers,
  setAnswers,
  handleSend,
  sendLoading,
  ...props
}) {
  const selectIconName = field => {
    switch (field) {
      case 'Amigos no Facebook':
        return 'facebook';

      case 'Contatos no Whatsapp':
        return 'whatsapp';

      case 'Seguidores no Instagram':
        return 'instagram';

      case 'Conexões no Linkedin':
        return 'linkedin';

      case 'Seguidores no Twitter':
        return 'twitter';

      default:
        return '';
    }
  };

  return (
    <StyledCreatorsFormTask>
      <div className="creators-form-task-container">
        {props.cover && (
          <LinkPreviewMedia
            channel={props.channel}
            type={props.type}
            id={props.id}
          >
            <img src={props.cover} alt="Cover" />
          </LinkPreviewMedia>
        )}

        <div className="title-subtitle">
          <h3 className="title">{props.title}</h3>
          <p>
            Responda às perguntas abaixo com relação ao número de contatos que
            você possui em suas redes sociais.
          </p>
        </div>

        <div className="question-list">
          {!loading &&
            questions.map(question => (
              <CreatorsFormQuestion
                key={question.pk}
                question={question}
                answers={answers}
                setAnswer={setAnswers}
                currentQuestionAnswer={
                  answers.filter(answer => answer.question === question.pk)[0]
                }
                isTask
                iconName={selectIconName(question.custom_field_key)}
              />
            ))}

          {loading && (
            <CustomLoading type="spin" height={36} width={36} fluid />
          )}
        </div>

        <div className="information">
          <p>
            Para os campos deixados em branco, serão mantidas as informações
            atuais do seu perfil.
          </p>
        </div>

        {!loading && questions.length > 0 && (
          <div className={`actions ${props.haveAnswer ? '' : 'disabled'}`}>
            <Button color="primary" onClick={handleSend} disabled={sendLoading}>
              {sendLoading ? 'Enviando...' : 'Enviar'}
            </Button>
          </div>
        )}
      </div>
    </StyledCreatorsFormTask>
  );
}

export default CreatorsFormTask;
