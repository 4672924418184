import styled from 'styled-components';

const StyledAlertBar = styled.div`
  width: 100%;
  padding: 16px 18px;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  border-left-width: 16px;

  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  i {
    font-size: 22px;
    margin-right: 16px;
    position: relative;
    top: -2px;
  }

  h3 {
    min-width: 100px;
    max-width: 100px;
    margin-right: 16px;
    color: var(--text-color);
  }

  div {
    color: var(--secondary-text-color);
  }

  &.primary {
    border-color: var(--primary-color);
    i {
      color: var(--primary-color);
    }
  }

  &.secondary {
    border-color: var(--secondary-color);
    i {
      color: var(--secondary-color);
    }
  }

  &.warning {
    border-color: var(--warning-color);
    i {
      color: var(--warning-color);
    }
  }

  &.failure {
    border-color: var(--failure-color);
    i {
      color: var(--failure-color);
    }
  }

  &.success {
    border-color: var(--success-color);
    i {
      color: var(--success-color);
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      margin-bottom: 16px;
    }
  }
`;

export default StyledAlertBar;
