import React, { useEffect, useState } from 'react';
import CoinSvg from '../../assets/images/prize-3.svg';
import api from '~/api/api';
import useAccount from '~/hooks/use-account';
import LoadingMessage from '~/components/loading-message';
import {
  getOrganizationStoredID,
  getRefreshOrganization,
  removeRefreshOrganization,
  storeOrganizationId
} from '~/utils';
import { tokenRefresh } from '~/api/auth';
import { getToken, upadateTokenKey } from '~/services/auth';
import { useDispatch } from 'redux-react-hook';
import { setOrganization } from '~/store/ducks/organization';

function WithdrawScreen(props) {
  const dispatch = useDispatch();
  const userToken = getToken();
  const [loading, setLoading] = useState(true);
  const [withdrawData, setWithdrawData] = useState({
    money: 0,
    withdraw_date: ''
  });

  const account = useAccount();
  const org = props.location.search.split('?org=').pop();

  const setOrganizationId = organizationId => {
    dispatch(setOrganization(organizationId));
    storeOrganizationId(organizationId);
  };

  useEffect(() => {
    if (!org) {
      api
        .get('/wallet/exchange_points_account/')
        .then(({ data }) => {
          setWithdrawData(data);
          setLoading(false);
        })
        .catch(response => {
          props.history.push('/unexpected-error');
        });
    } else {
      if (getRefreshOrganization()) {
        removeRefreshOrganization();
        tokenRefresh({
          organization: getOrganizationStoredID(),
          token: userToken
        })
          .then(response => {
            upadateTokenKey(response.data.token);
            setOrganizationId(org);

            props.history.replace({ search: '' });
          })
          .catch(response => {
            props.history.push('/unexpected-error');
          });
      }
    }
  }, [org]);
  return (
    <>
      {loading && <LoadingMessage />}

      {!loading && (
        <>
          {/*<div style={{background: '#fefa02', width: '100%', marginBottom: 16}}>*/}
          {/*  <img alt={'banner'}/>*/}
          {/*</div>*/}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              color: 'white'
            }}
          >
            <div
              style={{
                background: '#0055bc',
                width: '95%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 10,
                borderRadius: 8,
                padding: 16
              }}
            >
              <h1 style={{ color: '#e4b119' }}>{account.data.profile.name}</h1>
              <p>Veja aqui sua premiação do Programa de Engajamento Hapvida</p>
              <div
                style={{
                  display: 'flex',
                  marginTop: 25,
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <img src={CoinSvg} alt="" style={{ marginRight: 5 }} />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <p>Prêmio Conquistado</p>{' '}
                    <span
                      style={{
                        color: '#e4b119',
                        fontSize: 'large',
                        fontWeight: 'bolder'
                      }}
                    >
                      {withdrawData.money}
                    </span>
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <img src={CoinSvg} alt="" style={{ marginRight: 5 }} />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <p>Data limite do saque</p>{' '}
                    <span
                      style={{
                        color: '#e4b119',
                        fontSize: 'large',
                        fontWeight: 'bolder'
                      }}
                    >
                      Em breve {/*withdrawData.withdraw_date*/}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <a
              href={
                'https://s3.amazonaws.com/admin.useflow.tech/files/regulamento/Regulamento+e+regras+(5).pdf'
              }
              style={{
                borderRadius: '8px',
                border: '1px solid #0055bc',
                width: '95%',
                textDecoration: 'none',
                textAlign: 'center',
                padding: 8,
                marginTop: 16,
                color: 'black'
              }}
            >
              Regulamento e regras
            </a>
            {/* <a
              href={
                'https://api.whatsapp.com/send?phone=5585991243942&text=Ol%C3%A1!%20Quero%20solicitar%20meu%20resgate%20de%20pontos'
              }
              onclick={
                "window.open('https://api.whatsapp.com/send?phone=5585991243942&text=Ol%C3%A1!%20Quero%20solicitar%20meu%20resgate%20de%20pontos', '_system'); return false;"
              }
              style={{
                borderRadius: '8px',
                border: '1px solid #0055bc',
                width: '95%',
                textDecoration: 'none',
                textAlign: 'center',
                padding: 8,
                marginTop: 16,
                color: 'black'
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Solicite o resgate
            </a>*/}
          </div>
        </>
      )}
    </>
  );
}

export default WithdrawScreen;
