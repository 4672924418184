import React from 'react';
import './style.scss';

const Container = props => (
  <div className="container" style={props.style}>
    {props.children}
  </div>
);

export default Container;
