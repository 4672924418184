import actions from './index';

const typeTask = type_content => {
  switch (type_content) {
    case 'image':
      return 'imageUgc';

    case 'movie':
      return 'movieUgc';

    case 'text':
      return 'messageUgc';

    default:
      break;
  }
};

const continueTask = task => {
  let type = task.type;

  if (type === 'user_publishing') {
    type = typeTask(task.type_publishing_content);
  }

  return actions[task.channel][type](task);
};

export default continueTask;
