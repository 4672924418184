import styled from 'styled-components';

export const StyledCourseTrail = styled.div.attrs(() => ({
  className: 'course-trail'
}))`
  width: 100%;
  margin: auto;
  position: relative;
  /* overflow: hidden; */
  color: #4a4a4a;
  background: #fff;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  margin-bottom: 16px;

  &.has-parent {
    margin-top: 32px;
  }

  & > i {
    color: var(--primary-color);
    font-size: 20px;
    position: absolute;
    left: 50%;
    top: -32px;
    z-index: 1000;
    transform: rotate(180deg);
  }

  .link:hover {
    text-decoration: none;
  }

  .trail-header {
    width: 100%;
    padding: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px 4px 0px 0px;
    border-bottom: ${({ isOpened }) =>
      isOpened ? '1px solid var(--border-color)' : 'none'};

    h3 {
      padding-right: 16px;
      font-size: 20px;
      font-weight: normal;
      color: var(--text-color);
      text-align: start;
    }

    i {
      font-size: 16px;
    }
  }

  .ReactCollapse--collapse {
    transition: height 400ms;
  }

  .course-step:first-child .step-status::before {
    display: none;
  }

  @media (max-width: 720px) {
    .trail-header {
      padding: 16px;

      h3 {
        font-size: 20px;
      }
    }
  }
`;
