import styled from 'styled-components';

export const StyledCustomDecimal = styled.div.attrs(() => ({
  className: 'custom-decimal'
}))`
  width: 100%;

  &.has-error {
    input {
      border-color: var(--failure-color);
    }
  }

  .error-message {
    color: var(--failure-color);
  }
`;
