import styled from 'styled-components';

export const StyledReadLink = styled.div.attrs(() => ({
  className: 'read-link-step'
}))`
  width: 100%;
  position: relative;

  .step-section {
    padding: 32px 42px;
    border-bottom: 1px solid var(--border-color);

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        width: 100%;
        padding-right: 16px;
      }

      button {
        min-width: max-content;
      }
    }
  }

  .step-section:last-child {
    border: none;
  }

  .content-type {
    margin-bottom: 12px;
  }

  h3 {
    font-size: 18px;
    font-weight: normal;
  }

  @media (max-width: 720px) {
    .step-section {
      padding: 16px;
    }
  }
`;
