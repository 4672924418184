import styled from 'styled-components';

export const StyledSearchInput = styled.div.attrs(() => ({
  className: 'search-input'
}))`
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;

  i {
    color: var(--secondary-text-color);
    margin-right: 4px;
  }

  input {
    border: none;
    outline: none;
    width: 100%;
  }

  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--secondary-text-color);
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--secondary-text-color);
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--secondary-text-color);
  }
`;
