import React from 'react';
import { MultiSelect } from 'react-multi-select-component';

import { StyledSelectMultiple } from './styles';

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]

function SelectMultiple({ options, selected, setSelected, placeholder }) {
  return (
    <StyledSelectMultiple>
      <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy={placeholder || 'Selecione...'}
        overrideStrings={{
          allItemsAreSelected: 'Todos os itens selecionados',
          clearSearch: 'Limpar busca',
          clearSelected: 'Limpar selecionado',
          noOptions: 'Não encontrado',
          search: 'Buscar',
          selectAll: 'Selecionar tudo',
          selectAllFiltered: 'Selecionar tudo (filtrado)',
          selectSomeItems: placeholder || 'Selecione...',
          create: 'Criar'
        }}
      />
    </StyledSelectMultiple>
  );
}

export default SelectMultiple;
