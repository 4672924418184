import { useEffect, useState } from 'react';

export default (elem = 'div') => {
  const [ref, setRef] = useState(null);

  useEffect(() => {
    const root = document.getElementById('modal-root');
    const refAux = document.createElement('div');
    root.appendChild(refAux);
    setRef(refAux);

    return () => {
      root.removeChild(refAux);
    };
  }, []);

  return ref;
};
