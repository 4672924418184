import styled from 'styled-components';

export const StyledGenericTable = styled.div.attrs(() => ({
  className: 'generic-table'
}))`
  width: 100%;
  position: relative;

  .table-header {
    height: 66px;
    padding: 16px 20px;
    border-bottom: 1px solid var(--border-color);

    display: flex;
    justify-content: space-between;
    align-content: flex-end;
    align-items: center;

    .status {
      min-width: max-content;
      color: var(--secondary-text-color);
    }

    .table-actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .generic-table-wrapper {
    width: 100%;
    overflow-x: scroll;
  }

  table {
    width: 100%;

    th,
    td {
      padding: 16px 20px;
      border-left: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
    }

    thead {
      th {
        text-align: start;
        min-width: 100px;
      }
    }

    tr {
      th:first-child {
        border-left: none;
      }
    }

    tbody {
      td {
        position: relative;

        p {
          margin-right: 4px;
          max-width: ${({ cellsCharLimit }) =>
            cellsCharLimit ? `${cellsCharLimit}ch;` : '100%;'};
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .cell-details-button {
          position: absolute;
          right: 0px;
          top: calc(50% - 12px);
          opacity: 0;

          padding: 4px;
          min-height: auto;
          background: none;

          i {
            margin: 0;
            font-size: 14px;
            color: var(--secondary-text-color);
          }
        }

        &:hover {
          .cell-details-button {
            opacity: 1;
          }
        }
      }
    }
  }

  .empty-table-message {
    width: 100%;
    padding: 16px;
    p {
      width: 100%;
      text-align: center;
      color: var(--secondary-text-color);
    }
  }

  .pagination {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
  }

  .table-loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
