export const Types = {
  SET: 'links/SET'
};

const defaultState = {
  data: []
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.SET: {
      return {
        data: action.payload
      };
    }

    default:
      return state;
  }
}

export const setLinks = payload => ({
  type: Types.SET,
  payload
});
