import styled from 'styled-components';

export const StyledOpenAnswer = styled.div.attrs(() => ({
  className: 'open-answer'
}))`
  width: 100%;
  background: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 32px;

  & > .descriptive-answer {
    padding: 32px 42px;
    border-bottom: 1px solid var(--border-color);

    h5 {
      font-size: 16px;
      color: var(--text-color);
      margin-bottom: 4px;
      font-weight: normal;
    }

    .input {
      padding: 12px 16px;
    }
  }

  @media (max-width: 720px) {
    & > .descriptive-answer {
      padding: 16px;
    }
  }
`;

export default StyledOpenAnswer;
