import styled from 'styled-components';

const StyledEmptyList = styled.div.attrs({
  className: 'empty-list'
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--secondary-text-color);

  i {
    font-size: 20px;
  }

  p {
    font-size: 16px;
    margin: 4px;
    text-align: center;
    max-width: 300px;
    font-weight: normal;
  }
`;

export default StyledEmptyList;
