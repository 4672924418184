import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, UploadImage } from '../../form';
import Button from '../../button';
import Icon from '../../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
import { updateProfile } from '~/api/account';
import { showNewPoints } from '~/services/tasks';
import AvatarImage from '~/assets/images/avatar.png';

class Avatar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatar: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(avatar) {
    this.setState({ avatar });
  }

  handleSubmit() {
    if (!this.state.loading) {
      const { avatar } = this.state;
      const { name, email } = this.props.account.data.profile;

      if (avatar) {
        this.setState({ loading: true });

        updateProfile({
          avatar,
          name,
          email
        })
          .then(() => {
            showNewPoints(this.props.pointsToShow);
            this.props.onFinish();
          })
          .catch(e => {
            console.error(e);
            this.setState({ loading: false });
            alert('Erro');
          });
      }
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div>
          <UploadImage
            preview={this.state.preview}
            onChange={this.handleChange}
            defaultImage={AvatarImage}
          />
        </div>
        <div>
          <Button color="primary" disabled={this.state.loading}>
            <Icon name="arrow-right" marginRight />
            <span>Enviar</span>
          </Button>
        </div>
      </Form>
    );
  }
}

Avatar.defaultProps = {
  onFinish: () => {}
};

Avatar.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
