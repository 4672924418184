import styled from 'styled-components';

export const StyledCourseBanner = styled.div.attrs(() => ({
  className: 'course-banner'
}))`
  width: 100%;
  height: 450px;
  padding: 0px;
  background: var(--background-color);
  background-image: url(${({ courseImage }) => courseImage});
  background-repeat: no-repeat;
  background-size: ${({ hasImage }) => (hasImage ? 'cover' : '50px')};
  background-position: center center;
  border: none;
  outline: none;
  border-radius: 4px;

  .course-card-content {
    width: 100%;
    height: 100%;
    padding: 32px 48px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    h3 {
      text-align: start;
      max-width: 600px;
      font-size: 32px;
      font-weight: normal;
      line-height: 1;
      color: var(--white-color);
      margin-bottom: 16px;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .period-card {
      width: max-content;
      padding: 2px 8px;
      border-radius: 4px;
      background: var(--background-color);
      font-size: 16px;

      display: flex;
      align-items: center;

      i {
        margin-right: 8px;
        color: var(--secondary-text-color);
        font-size: 14px;
      }

      p {
        color: var(--secondary-text-color);
        font-size: 14px;
      }
    }
  }

  @media (max-width: 720px) {
    height: 405px;

    .course-card-content {
      padding: 16px;
    }
  }

  @media (max-width: 414px) {
    height: 214px;
  }
`;
