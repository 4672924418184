import styled from 'styled-components';

export const StyledSectionList = styled.div.attrs(() => ({
  className: 'section-list'
}))`
  width: 100%;

  .section-header {
    width: 100%;
    margin-bottom: 16px;

    & > div {
      width: 100%;
      display: flex;
      align-items: center;
    }

    h3 {
      font-size: 24px;
      margin-right: 18px;
      font-weight: normal;
      color: var(--text-color);
      line-height: 1;
    }

    .button {
      padding: 0px;
      font-size: 16px;
      color: var(--primary-color);
      min-width: max-content;
    }
  }

  .section-content {
    width: 100%;
    max-width: 850px;
    display: flex;
    align-items: center;

    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
