/**
 * urlBase64ToUint8Array
 *
 * @param {string} base64String a public vavid key
 */
export function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const hasNotificationSupport = () =>
  hasServiceWorkerSupport() && hasPushSupport();

const hasServiceWorkerSupport = () => 'serviceWorker' in navigator;

const hasPushSupport = () => 'PushManager' in window;

export const askNotificationsPermission = () =>
  new Promise(function(resolve, reject) {
    const permissionResult = Notification.requestPermission(function(result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });

export const enableCompleteSignUp = () =>
  (window.localStorage.enabledCompleteSignUp = '1');
export const isCompleteSignUpEnabled = () =>
  window.localStorage.enabledCompleteSignUp === '1';
export const disableCompleteSignUp = () =>
  delete window.localStorage.enabledCompleteSignUp;

export const getParam = (name, url) => {
  if (!url) {
    url = window.location.href;
  }

  name = name.replace(/[[\]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getError = ({ response }, fn) => {
  const result = {
    message: '',
    fields: []
  };

  const messages = [];

  if (response && response.data) {
    const { data } = response;
    let errorCode = null;

    if (data.code) {
      errorCode = data.code.toString() === 'field_error';
    }

    if (errorCode) {
      data.errors.forEach(error => {
        messages.push(error.error);
        result.fields.push(`${error.field}_invalid`);
      });
    } else {
      if (typeof data.errors !== 'string') {
        data.errors.forEach(error => {
          messages.push(error);
        });
      } else {
        messages.push(data.errors);
      }
    }
  } else {
    messages.push('Erro!');
  }

  result.message = messages.join(', ');
  fn(result);
};

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';

  document.body.appendChild(el);
  el.select();

  document.execCommand('copy');
  document.body.removeChild(el);
};

export const mergeArrayOfObjects = (arrayA, arrayB, prop) => {
  const reduced = arrayA.filter(
    aItem => !arrayB.find(bItem => aItem[prop] === bItem[prop])
  );
  return reduced.concat(arrayB);
};

export const capitalizeWord = word =>
  `${word[0].toUpperCase()}${word.slice(1)}`;

export const getOrganizationStoredID = () => {
  return localStorage.getItem('@flow/organizationId');
};
export const setRefreshOrganization = refresh => {
  return localStorage.setItem('@flow/refreshOrganization', refresh);
};
export const removeRefreshOrganization = () => {
  localStorage.removeItem('@flow/refreshOrganization');
};
export const getRefreshOrganization = () => {
  return !!localStorage.getItem('@flow/refreshOrganization');
};

export const getOrganizationStored = organizations => {
  const organizationIdStored = localStorage.getItem('@flow/organizationId');

  if (!organizationIdStored) {
    return null;
  }

  const [organizationStored] = organizations.filter(
    organization =>
      parseInt(organization.id, 10) === parseInt(organizationIdStored, 10)
  );

  return organizationStored || null;
};

export const storeOrganizationId = organizationId => {
  localStorage.setItem('@flow/organizationId', organizationId);
};

export const findActiveOrganization = (organizations, organizationId) => {
  const organizationStored = getOrganizationStored(organizations);

  let activeOrganization = organizationStored;

  if (!activeOrganization) {
    const [userOrganization] = organizations.filter(
      organization => organization.id === organizationId
    );

    activeOrganization = userOrganization || organizations[0];
  }

  return activeOrganization;
};

export const clearOrganizationName = organizationName => {
  const [cleanOrganizationName] = organizationName.split('(#');
  return cleanOrganizationName;
};

export const getConfig = (organizations, activeOrganizationId) => {
  const [activeOrganization] = organizations.filter(
    org => org.id === activeOrganizationId
  );

  if (!activeOrganization) {
    return false;
  }

  return {
    organizationName: activeOrganization.name,
    moduleDownloads: activeOrganization.organizationsettings.module_downloads,
    moduleSurvey: activeOrganization.organizationsettings.module_survey,
    modulePerformance:
      activeOrganization.organizationsettings.module_performance,
    modulePages: activeOrganization.organizationsettings.module_pages,
    moduleLibrary: activeOrganization.organizationsettings.module_library,
    moduleForum: activeOrganization.organizationsettings.module_forum,
    moduleTraining: activeOrganization.organizationsettings.module_training,
    modulePDI: activeOrganization.organizationsettings.module_pdi,
    moduleContact: activeOrganization.organizationsettings.module_contact,
    moduleMetlife: activeOrganization.organizationsettings.module_metlife,
    moduleRanking: activeOrganization.organizationsettings.module_ranking,
    primaryColor: activeOrganization.organizationsettings.color_bg,
    secondaryColor: activeOrganization.organizationsettings.color_button,
    moduleSettings: activeOrganization.organizationsettings.module_settings,
    appLogo: activeOrganization.organizationsettings.app_logo,
    hasCustom: activeOrganization.has_custom_fields
  };
};

export const deepClone = obj => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  let cloned, i;

  if (obj instanceof Date) {
    cloned = new Date(obj.getTime());
    return cloned;
  }

  if (obj instanceof Array) {
    let l;
    cloned = [];
    for (i = 0, l = obj.length; i < l; i++) {
      cloned[i] = deepClone(obj[i]);
    }

    return cloned;
  }

  cloned = {};
  for (i in obj)
    if (obj.hasOwnProperty(i)) {
      cloned[i] = deepClone(obj[i]);
    }

  return cloned;
};

// Colors
export const approximateColor1ToColor2ByPercent = (color1, color2, percent) => {
  var red1 = parseInt(color1[1] + color1[2], 16);
  var green1 = parseInt(color1[3] + color1[4], 16);
  var blue1 = parseInt(color1[5] + color1[6], 16);

  var red2 = parseInt(color2[1] + color2[2], 16);
  var green2 = parseInt(color2[3] + color2[4], 16);
  var blue2 = parseInt(color2[5] + color2[6], 16);

  var red = Math.round(mix(red1, red2, percent));
  var green = Math.round(mix(green1, green2, percent));
  var blue = Math.round(mix(blue1, blue2, percent));

  return generateHexFromRGB(red, green, blue);
};

export const generateHexFromRGB = (r, g, b) => {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  // to address problem mentioned by Alexis Wilke:
  while (r.length < 2) {
    r = '0' + r;
  }
  while (g.length < 2) {
    g = '0' + g;
  }
  while (b.length < 2) {
    b = '0' + b;
  }

  return '#' + r + g + b;
};

export const mix = (start, end, percent) => {
  return start + percent * (end - start);
};

export const limitFileName = fileName => {
  let limitedFileName = fileName;

  if (fileName.length > 32) {
    const startOfFileName = fileName.substring(0, 32);
    const endOfFileName = fileName.substring(
      fileName.length - 6,
      fileName.length
    );

    limitedFileName = `${startOfFileName}...${endOfFileName}`;
  }

  return limitedFileName;
};
