import React, { useState, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { getSurveys } from '~/api/survey';

import { setSurveys } from '~/store/ducks/survey';

import LoadingMessage from '~/components/loading-message';
import SurveyCard from '~/components/survey/survey-card';

import './style.scss';

function Surveys() {
  const dispatch = useDispatch();

  const [surveyList, setSurveyList] = useState(null);

  useEffect(() => {
    getSurveys()
      .then(({ data }) => {
        setSurveyList(data);
        dispatch(setSurveys(data));
      })
      .catch(() => {
        alert('Erro ao carregar pesquisas, tente novamente mais tarde.');
        setSurveyList([]);
        dispatch(setSurveys([]));
      });
  }, []);

  return (
    <div className="surveys-screen">
      {!surveyList && <LoadingMessage />}

      {surveyList && surveyList.length === 0 && (
        <h3 style={{ textAlign: 'center' }}>Não há pesquisas no momento</h3>
      )}

      {surveyList &&
        surveyList.length > 0 &&
        surveyList.map(survey => (
          <SurveyCard survey={survey} key={survey.pk} />
        ))}
    </div>
  );
}

export default Surveys;
