import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  PublicWrapper,
  PublicHeader,
  PublicBanner,
  PublicFooter
} from '~/components/public';
import Card from '~/components/card';
// import FacebookAuth from '~/components/facebook-auth';
import { Form, Input } from '~/components/form';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Link from '~/components/link';
import Separator from '~/components/separator';
import StoreLinks from '~/components/store-links';
import { showLoading, closeLoading } from '~/store/ducks/loading';
import { showMessage, closeMessage } from '~/store/ducks/messageBar';
import { registerAccount } from '~/store/ducks/account';
import { consumeInvitation, signIn, tokenRefresh } from '~/api/auth';
import { login, upadateTokenKey } from '~/services/auth';
// import { clearSkip } from '~/services/tutorial';
import { getError, getParam } from '~/utils';
import { REACT_APP_SITE } from '~/settings';
import { getOrganizationStored, storeOrganizationId } from '~/utils';
import { ORGANIZATION_ID } from '~/settings';
import { setOrganization } from '~/store/ducks/organization';

class SignInScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      formDisabled: false,
      siteName: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.completeLoginByInvitation = this.completeLoginByInvitation.bind(this);
  }

  successScreen() {
    window.location.reload();
  }

  completeLoginByInvitation(uuid, showMessage, closeLoading) {
    consumeInvitation(uuid)
      .then(() => {
        this.successScreen();
      })
      .catch(e => {
        getError(e, res => {
          showMessage(res.message, 'danger');
          this.setState({ formDisabled: false });
        });
      })
      .finally(() => {
        closeLoading();
      });
  }

  handleChange({ target }) {
    const { name, value } = target;

    this.setState({ [name]: value });
  }

  handleSubmit() {
    const { email, password } = this.state;
    const {
      showLoading,
      closeLoading,
      showMessage,
      closeMessage,
      setOrganization
    } = this.props;
    const uuid = getParam('uuid');

    showLoading();

    this.setState({ formDisabled: true });

    if (email && password) {
      signIn(email, password)
        .then(res => {
          const organizations = res.data.account.organization_is_active_set;
          const organizationStored = getOrganizationStored(organizations);

          let activeOrganization = organizationStored;

          if (!organizationStored) {
            const [userOrganization] = organizations.filter(
              organization => organization.id === ORGANIZATION_ID
            );
            activeOrganization = userOrganization || organizations[0];
          }

          tokenRefresh({
            organization: activeOrganization.id,
            token: res.data.token
          }).then(response => {
            closeMessage();
            // clearSkip(); // Temp
            login(response.data.token);
            upadateTokenKey(response.data.token);
            setOrganization(activeOrganization.id);
            storeOrganizationId(activeOrganization.id);

            if (uuid) {
              this.completeLoginByInvitation(uuid, showMessage, closeLoading);
            } else {
              this.props.history.push(
                _.get(this.props, 'location.state.from') || '/'
              );
            }
          });
        })
        .catch(e => {
          getError(e, res => {
            showMessage(res.message, 'danger');
            this.setState({ formDisabled: false });
          });
        })
        .finally(() => {
          closeLoading();
        });
    }
  }

  render() {
    return (
      <PublicWrapper>
        <PublicHeader>
          <Card className="public-card">
            {/* <FacebookAuth>Entrar com Facebook</FacebookAuth> */}
            <Separator>Acesse sua conta</Separator>
            <Form
              disabled={this.state.formDisabled}
              onSubmit={this.handleSubmit}
            >
              <Input
                name="email"
                type="text"
                placeholder="Digite seu login"
                autoCorrect="off"
                autoFocus
                required
                value={this.state.email}
                onChange={this.handleChange}
              />
              <Input
                name="password"
                type="password"
                placeholder="Digite sua senha"
                autoComplete="off"
                required
                value={this.state.password}
                onChange={this.handleChange}
              />
              <br />
              <Button
                fill
                large
                color="primary"
                style={{ marginBottom: '10px' }}
              >
                <Icon name="check" marginRight />
                <span>Entrar</span>
              </Button>
              <br />
              <Separator />
              <div className="text-center">
                <div className="text-center">
                  <Link to="/recovery">Esqueci a senha</Link>
                </div>
              </div>
              <Separator />
              <div className="text-center">
                <span>Não tem conta? </span>
                <Link to="/sign-up">
                  <span>Cadastre-se</span>
                  <Icon name="arrow-right" marginLeftSmall />
                </Link>
              </div>
            </Form>
          </Card>
        </PublicHeader>
        <div className="main">
          <PublicBanner>
            {REACT_APP_SITE !== 'flow' && REACT_APP_SITE !== 'elle' ? (
              <StoreLinks>
                <span>Através do nosso app</span>
                <br />
                <span>você também pode interagir</span>
                <br />
                <span>com nossa plataforma.</span>
              </StoreLinks>
            ) : (
              <span>Seja bem-vindo!</span>
            )}
          </PublicBanner>
          <div className="hide-on-mobile" style={{ marginBottom: 200 }} />
        </div>
        <PublicFooter />
      </PublicWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showLoading,
      closeLoading,
      showMessage,
      closeMessage,
      registerAccount,
      setOrganization
    },
    dispatch
  );

// export default connect(null, {
//   showLoading,
//   closeLoading,
//   showMessage,
//   closeMessage,
//   registerAccount
// })(withRouter(SignInScreen));

export default connect(null, mapDispatchToProps)(withRouter(SignInScreen));
