import api from './api';

export const getInitialFeed = ({ search }) =>
  api.get('/accounts/me/feed', {
    params: {
      questionnaire: search
    }
  });

export const getNextItems = url => api.get(url);
