import useSite from '~/hooks/use-site';
import React from 'react';

import { REACT_APP_SITE } from '~/settings';

import './style.scss';

const Copyright = () => {
  const { name } = useSite();

  return (
    <div className="copyright">
      <b>® {name} </b>
      {REACT_APP_SITE !== 'flow' && REACT_APP_SITE !== 'elle' ? (
        <>
          <span>usa a tecnologia Talkative</span>
          <br />
          <span>
            {process.env.REACT_APP_VERSION
              ? `by Wololo Tech - v${process.env.REACT_APP_VERSION}`
              : 'by Wololo Tech'}
          </span>
        </>
      ) : (
        <>
          <span>usa a tecnologia Flow.</span>
          <span>
            {process.env.REACT_APP_VERSION
              ? ` - v${process.env.REACT_APP_VERSION}`
              : ''}
          </span>
        </>
      )}
    </div>
  );
};

export default Copyright;
