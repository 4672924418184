export const Types = {
  SHOW: 'messageBar/SHOW',
  CLOSE: 'messageBar/CLOSE'
};

const SEVEN_SECONDS = 7000;

const defaultState = { message: null };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.SHOW:
      return {
        message: action.message,
        color: action.color,
        timeoutMilisseconds: action.timeoutMilisseconds
      };
    case Types.CLOSE:
      return defaultState;
    default:
      return state;
  }
}

export const showMessage = (
  message,
  color,
  timeoutMilisseconds = SEVEN_SECONDS
) => ({
  type: Types.SHOW,
  message,
  color,
  timeoutMilisseconds
});

export const closeMessage = () => ({
  type: Types.CLOSE
});
