import React from 'react';

import { Input } from '~/components/form';

import { StyledCustomLongString } from './styles';

function CustomLongString({
  id,
  label,
  answer,
  data,
  setData,
  readOnly,
  errorMessage,
  ...props
}) {
  const setText = value => {
    setData(lastData => {
      const newData = [...lastData];

      const currentCustomFieldIndex = newData.findIndex(
        customField => customField.pk === id
      );

      newData[currentCustomFieldIndex].answer = value;
      return newData;
    });
  };

  return (
    <StyledCustomLongString className={errorMessage ? 'has-error' : ''}>
      <label>{label}</label>
      <Input
        textarea
        value={answer || ''}
        onChange={event => setText(event.target.value)}
        disabled={readOnly}
        {...props}
      />
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </StyledCustomLongString>
  );
}

export default CustomLongString;
