import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Select } from '../../form';
import { Row, Col } from '../../grid';
import Button from '../../button';
import Icon from '../../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
import { updateProfile } from '~/api/account';
import { showNewPoints } from '~/services/tasks';

const months = [
  { num: 1, name: 'Janeiro' },
  { num: 2, name: 'Fevereiro' },
  { num: 3, name: 'Março' },
  { num: 4, name: 'Abril' },
  { num: 5, name: 'Maio' },
  { num: 6, name: 'Junho' },
  { num: 7, name: 'Julho' },
  { num: 8, name: 'Agosto' },
  { num: 9, name: 'Setembro' },
  { num: 10, name: 'Outubro' },
  { num: 11, name: 'Novembro' },
  { num: 12, name: 'Dezembro' }
];

const years = [];
const days = [];

for (let year = 2018; year >= 1900; year--) {
  years.push(year);
}

for (let day = 1; day < 31; day++) {
  days.push(day);
}

class BornAt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      day: '',
      day_invalid: false,

      month: '',
      month_invalid: false,

      year: '',
      year_invalid: false,

      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [`${name}_invalid`]: false, [name]: value });
  }

  handleSubmit() {
    if (!this.state.loading) {
      const required = ['day', 'month', 'year'];

      const invalid = required.filter(name => !this.state[name]);

      if (!invalid.length) {
        this.setState({ loading: true });
        const { day, month, year } = this.state;
        const { name, email } = this.props.account.data.profile;

        updateProfile({
          born_at: `${year}-${month}-${day}`,
          name,
          email
        })
          .then(() => {
            showNewPoints(this.props.pointsToShow);
            this.props.onFinish();
          })
          .catch(() => {
            this.setState({ loading: false });
            alert('Erro');
          });
      } else {
        invalid.map(name => this.setState({ [`${name}_invalid`]: true }));
      }
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col md={4}>
            <Select
              name="day"
              onChange={this.handleChange}
              value={this.state.day}
              invalid={this.state.day_invalid}
            >
              <option>Dia</option>
              {days.map(day => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </Select>
          </Col>
          <Col md={4}>
            <Select
              name="month"
              onChange={this.handleChange}
              value={this.state.month}
              invalid={this.state.month_invalid}
            >
              <option>Mês</option>
              {months.map(month => (
                <option key={month.num} value={month.num}>
                  {month.name}
                </option>
              ))}
            </Select>
          </Col>
          <Col md={4}>
            <Select
              name="year"
              onChange={this.handleChange}
              value={this.state.year}
              invalid={this.state.year_invalid}
            >
              <option>Ano</option>
              {years.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </Col>
        </Row>
        <div>
          <Button color="primary" disabled={this.state.loading}>
            <Icon name="arrow-right" marginRight />
            <span>Enviar</span>
          </Button>
        </div>
      </Form>
    );
  }
}

BornAt.defaultProps = {
  onFinish: () => {}
};

BornAt.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BornAt);
