import styled from 'styled-components';

const StyledPageControl = styled.div.attrs(() => ({
  className: 'page-control'
}))`
  width: 100%;

  .page-control-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    color: var(--text-color);

    h1 {
      font-size: 20px;
      font-weight: normal;
      max-width: 650px;
      line-height: 1;
    }
  }

  .control {
    display: flex;
    align-items: center;
    min-height: 48px;

    p {
      margin-right: 16px;
      color: var(--secondary-text-color);
    }

    .actions {
      .button {
        background: var(--white-color);
        padding: 8px;
        margin: 0px 4px;

        i {
          margin: 0px;
        }
      }
    }
  }

  .description {
    margin-top: 8px;
  }

  @media (max-width: 720px) {
    .title {
      h1 {
        font-size: 22px;
        max-width: 200px;
        padding-right: 4px;
        line-height: 1;
      }
    }

    .control {
      p {
        display: none;
      }
    }
  }
`;

export default StyledPageControl;
