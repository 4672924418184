import React, { useCallback, useState } from 'react';
import Icon from '~/components/icon';
import { like, unlike, removeLike, removeUnlike } from '~/store/ducks/likes';
import useLikes from '~/hooks/use-likes';
import { useDispatch } from 'redux-react-hook';

import './style.scss';

const LikeButtons = ({ type, id, allowLikeOnce, noActions, onClick }) => {
  const { currentUserValue, likeCounter, unlikeCounter } = useLikes(type, id);
  const dispatch = useDispatch();

  const [loadingLike, setLoadingLike] = useState(false);
  const [loadingUnlike, setLoadingUnlike] = useState(false);

  const innerOnLike = useCallback(
    async () => {
      if (onClick) {
        onClick();
        return;
      }

      if (noActions) {
        return;
      }

      // Se ja deu like ou dislike, não deixa
      // executar novamente caso passar a flag allowLikeOnce
      if (
        allowLikeOnce &&
        (currentUserValue === true || currentUserValue === false)
      ) {
        return;
      }

      setLoadingLike(true);

      if (currentUserValue !== true) {
        await dispatch(like(type, id, currentUserValue));
      } else {
        await dispatch(removeLike(type, id, currentUserValue));
      }

      setLoadingLike(false);
    },
    [currentUserValue, noActions, allowLikeOnce]
  );

  const innerOnUnlike = useCallback(
    async () => {
      if (onClick) {
        onClick();
        return;
      }

      if (noActions) {
        return;
      }

      // Se ja deu like ou dislike, não deixa
      // executar novamente caso passar a flag allowLikeOnce
      if (
        allowLikeOnce &&
        (currentUserValue === true || currentUserValue === false)
      ) {
        return;
      }

      setLoadingUnlike(true);

      if (currentUserValue !== false) {
        await dispatch(unlike(type, id, currentUserValue));
      } else {
        await dispatch(removeUnlike(type, id, currentUserValue));
      }

      setLoadingUnlike(false);
    },
    [currentUserValue, noActions, allowLikeOnce]
  );

  return (
    <>
      <button
        type="button"
        className={`like-button ${currentUserValue === true &&
          !noActions &&
          'executed'} ${noActions && 'no-actions'}`}
        onClick={innerOnLike}
        disabled={loadingLike}
      >
        <Icon name="positive" />
      </button>
      <span className="like-count">{likeCounter}</span>

      <button
        type="button"
        className={`unlike-button ${currentUserValue === false &&
          !noActions &&
          'executed'} ${noActions && 'no-actions'}`}
        onClick={innerOnUnlike}
        disabled={loadingUnlike}
      >
        <Icon name="negative" />
      </button>
      <span className="unlike-count">{unlikeCounter}</span>
    </>
  );
};

export default LikeButtons;
