import styled from 'styled-components';

export const StyledDropzone = styled.div.attrs(() => ({
  className: 'dropzone'
}))`
  .container {
    p {
      margin: 4px 0px;
      text-align: center;
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      & > div {
        background: var(--background-color) !important;
        opacity: 0.8;
        cursor: not-allowed;
      }
    }

    i {
      font-size: 18px;
    }

    .sended-file {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: var(--text-color);
        margin-right: 4px;
      }
    }
  }

  .footer {
    padding: 8px 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .progress-bar {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        width: 100%;
        margin-right: 8px;
      }
      p {
        min-width: max-content;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      button {
        margin: 0;
        margin-top: 2px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 14px;
          margin-right: 4px;
        }
      }

      button:hover {
        text-decoration: none;
      }
    }
  }
`;
