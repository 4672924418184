import styled from 'styled-components';

export const StyledQRCode = styled.div.attrs(() => ({
  className: 'qr-code'
}))`
  .qr-code-container {
    background: #fff;
    padding: 16px 16px 40px;
  }
`;
