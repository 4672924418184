import api from './api';

export const getInitialForumPosts = ({ search }) =>
  api.get('/accounts/me/forum/posts/', {
    params: {
      search
    }
  });

export const getNextItems = url => api.get(url);

export const getForumPost = id => api.get(`/forum/posts/${id}/`);

export const getComments = id => api.get(`/forum/posts/${id}/comment/`);

export const postComment = (id, comment, parent = null) =>
  api.post(`/forum/posts/${id}/comment/`, {
    post: id,
    comment,
    parent
  });

export const editComment = (id, comment, parent = null) =>
  api.put(`/forum/posts/${id}/comment/`, {
    comment,
    parent
  });

export const destroyComment = id => api.delete(`/forum/posts/${id}/comment/`);
