import React from 'react';

import useComments from '~/hooks/use-comments';
import PostComment from '~/components/post-comment';

import './style.scss';

const CommentButtons = ({ id, type, opened, onComment, onCloseComment }) => {
  const { count } = useComments('task', id);

  return (
    <div className="comment-buttons">
      <PostComment className="inline-block" onClick={onComment}>
        {count}
      </PostComment>

      <span className="comments-separator" />

      {!opened && (
        <button type="button" className="button link nmb" onClick={onComment}>
          Comentar
        </button>
      )}

      {opened && (
        <button
          type="button"
          className="button link nmb"
          onClick={onCloseComment}
        >
          Fechar Comentários
        </button>
      )}
    </div>
  );
};

export default CommentButtons;
