import { useEffect, useMemo } from 'react';
import './style.scss';

const BlockSiteScroll = () => {
  const body = useMemo(() => document.querySelector('body'), []);

  useEffect(() => {
    body.classList.add('scroll-blocked');

    return () => {
      body.classList.remove('scroll-blocked');
    };
  });

  return null;
};

export default BlockSiteScroll;
