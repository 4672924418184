import styled from 'styled-components';

export const StyledFeedbackQuestion = styled.div.attrs(() => ({
  className: 'feedback-question'
}))`
  width: 100%;
  padding: 16px 40px;

  .field-label {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .question-number {
      width: 30px;
      min-width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      background-color: var(--background-color);
      margin-right: 8px;
      font-size: 16px;
    }

    .question {
      font-size: 16px;
    }
  }
`;
