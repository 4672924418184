import React from 'react';
import useSite from '~/hooks/use-site';
import Button from '~/components/button';

import './style.scss';

function CookiesBanner({ onClose }) {
  const { name } = useSite();

  return (
    <div className="cookie-modal">
      <div className="cookies-banner">
        <p>
          Cookies em {window.location.hostname}. Utilizamos cookies para
          proporcionar uma melhor experiência ao usuário. Usando o {name} você
          aceita nossa{' '}
          <a href="https://d1asjutgsmztwh.cloudfront.net/talkative/cookie-policy">
            Política de cookies
          </a>
          .
        </p>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </div>
    </div>
  );
}

export default CookiesBanner;
