import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from '../../grid';
import './style.scss';

const PublicBanner = props => (
  <div className="public-banner">
    <Container>
      <Row>
        <Col md={5}>{props.children}</Col>
      </Row>
    </Container>
  </div>
);

PublicBanner.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default PublicBanner;
