import axios from 'axios';
import { TOKEN_KEY } from '../settings';

export const downloadFile = fileUrl =>
  axios({
    url: fileUrl,
    method: 'GET',
    responseType: 'blob'
  });

export const downloadWithToken = fileUrl => {
  const token = localStorage.getItem(TOKEN_KEY);

  return axios.get(fileUrl, {
    responseType: 'blob',
    headers: {
      Authorization: token
    }
  });
};

export default downloadFile;
