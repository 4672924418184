import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const ExternalLink = ({ to, children }) => {
  return (
    <a
      className="external-link"
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default ExternalLink;
