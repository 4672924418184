import React from 'react';
import moment from 'moment';

import Icon from '~/components/icon';

import { StyledCourseCard } from './styles';

function CourseCard({
  title,
  showCourseTitle,
  cover,
  startAt,
  finishAt,
  onClick
}) {
  return (
    <StyledCourseCard
      onClick={onClick}
      courseImage={cover}
      hasImage={cover}
      showCourseTitle={showCourseTitle}
    >
      <div className="course-card-content">
        {(!cover || showCourseTitle) && <h3>{title}</h3>}

        <div className="period-card">
          <Icon name="calendar" />
          {finishAt ? (
            <p>
              {moment(startAt).format('DD/MM/YYYY')} até{' '}
              {moment(finishAt).format('DD/MM/YYYY')}
            </p>
          ) : (
            <p>Início em {moment(startAt).format('DD/MM/YYYY')}</p>
          )}
        </div>
      </div>
    </StyledCourseCard>
  );
}

export default CourseCard;
