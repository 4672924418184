import React from 'react';
import ReactProgressBar from '@ramonak/react-progress-bar';

import { StyledProgressBar } from './styles';

function ProgressBar({ completed, isLabelVisible, bgColor, height }) {
  return (
    <StyledProgressBar>
      <ReactProgressBar
        completed={completed}
        isLabelVisible={isLabelVisible}
        bgColor={bgColor}
        height={height}
      />
    </StyledProgressBar>
  );
}

export default ProgressBar;
