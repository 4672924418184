import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const AlertModal = ({ title, content }) => (
  <div className="alert-modal">
    <h2 className="alert-title">{title}</h2>
    <p>{content}</p>
  </div>
);

AlertModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default AlertModal;
