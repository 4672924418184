import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  PublicWrapper,
  PublicHeader,
  PublicBanner,
  PublicFooter
} from '~/components/public';
import Card from '~/components/card';
import { Form, Input, Check } from '~/components/form';
// import FacebookAuth from '~/components/facebook-auth';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Link from '~/components/link';
import Separator from '~/components/separator';
import StoreLinks from '~/components/store-links';
import Badge from '~/components/badge';
import MessageBar from '~/components/message-bar';
import { Col, Row } from '~/components/grid';
import { showLoading, closeLoading } from '~/store/ducks/loading';
import { showMessage, closeMessage } from '~/store/ducks/messageBar';
import { registerAccount } from '~/store/ducks/account';
import { consumeInvitation, getInvitation, signUp } from '~/api/auth';
// import { updateProfile } from '~/api/account';
import { login } from '~/services/auth';
import { getError, enableCompleteSignUp, getParam } from '~/utils';
import { withSite } from '~/hooks/use-site';

class SignUpScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInvitation: false,
      invitationExpire: null,
      expiredInvitation: false,
      consumedInvitation: false,
      uuid: null,
      name: this.props.site.name !== 'USEFLOW' ? '' : 'Usuário do USEFLOW',
      email: '',
      email_invalid: false,
      password1: '',
      password1_invalid: false,
      password2: '',
      password2_invalid: false,
      useterms: false,
      cookiepolicy: false,
      formDisabled: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.completeRegistration = this.completeRegistration.bind(this);
    this.completeRegistrationByInvitation = this.completeRegistrationByInvitation.bind(
      this
    );
  }

  componentDidMount() {
    const uuid = getParam('uuid');
    const email = getParam('email');

    this.setState({
      email: email ? email : '',
      uuid: uuid ? uuid : null
    });

    const { showLoading, closeLoading, showMessage } = this.props;

    if (uuid) {
      showLoading();

      getInvitation(uuid)
        .then(({ data }) => {
          const currentDate = moment();
          const expireDate = moment(data.close_at);

          if (expireDate.isValid()) {
            const isExpired = currentDate.isAfter(expireDate);
            const wasConsumed = data.consumed_at ? true : false;
            const close = expireDate.fromNow(true);

            this.setState({
              showInvitation: isExpired || wasConsumed ? false : true,
              invitationExpire: close.match(/(.+)\s(.+)/),
              expiredInvitation: isExpired,
              consumedInvitation: wasConsumed
            });
          }
        })
        .catch(error => {
          getError(error, res => {
            showMessage(res.message, 'danger');
            res.fields.forEach(field => {
              this.setState({ [field]: true });
            });
          });

          if (error.response.status === 404) {
            const url = `/sign-up${email ? `?email=${email}` : ''}`;

            this.setState({
              uuid: null
            });

            window.history.replaceState(null, '', url);
          }
        })
        .finally(() => closeLoading());
    }
  }

  completeRegistration(data) {
    this.props.registerAccount(data.account);

    if (this.props.site.completeSignUp) {
      enableCompleteSignUp();
      this.props.history.push('/complete-sign-up');
    } else {
      this.props.history.push('/sign-up-completed');
    }
  }

  completeRegistrationByInvitation(data, uuid, showMessage, closeLoading) {
    consumeInvitation(uuid)
      .then(() => {
        this.completeRegistration(data);
      })
      .catch(e => {
        getError(e, res => {
          showMessage(res.message, 'danger');

          this.setState({ formDisabled: false });
        });

        setTimeout(() => {
          this.props.history.push('/');
        }, 2000);
      })
      .finally(() => {
        closeLoading();
      });
  }

  handleChange({ target }) {
    const { name, value } = target;

    if (name === 'useterms' || name === 'cookiepolicy') {
      const { checked } = target;

      this.setState({
        [name]: checked
      });

      return;
    }

    this.setState({
      [name]: value,
      [`${name}_invalid`]: false
    });
  }

  handleSubmit() {
    const {
      name,
      email,
      password1,
      password2,
      useterms,
      cookiepolicy
    } = this.state;
    const uuid = getParam('uuid');
    const { showLoading, closeLoading, showMessage, closeMessage } = this.props;

    if (!useterms || !cookiepolicy) {
      showMessage(
        'É necessário estar de acordo com as Políticas de Privacidade, Termos de Uso e Política de Cookies para continuar',
        'danger'
      );
      return;
    }

    if (password1 === password2) {
      showLoading();
      this.setState({ formDisabled: true });

      signUp(name, email, password1)
        .then(res => {
          closeMessage();
          login(res.data.token);

          if (uuid) {
            this.completeRegistrationByInvitation(
              res.data,
              uuid,
              showMessage,
              closeLoading
            );
          } else {
            this.completeRegistration(res.data);
          }
        })
        .catch(e => {
          getError(e, res => {
            showMessage(res.message, 'danger');
            const newState = { formDisabled: false };

            res.fields.forEach(field => {
              newState[field] = true;
            });

            this.setState(newState);
          });
        })
        .finally(() => {
          closeLoading();
        });
    } else {
      showMessage('As senhas precisam ser iguais!', 'danger');
      this.setState({
        password1_invalid: true,
        password2_invalid: true
      });
    }
  }

  render() {
    return (
      <PublicWrapper>
        <PublicHeader>
          <Card className="public-card">
            {/* <FacebookAuth>Cadastrar com Facebook</FacebookAuth> */}
            <Separator>Acesse sua conta</Separator>
            <Form
              disabled={this.state.formDisabled}
              onSubmit={this.handleSubmit}
            >
              {this.props.site.name !== 'USEFLOW' && (
                <Input
                  name="name"
                  type="text"
                  placeholder="Digite seu nome"
                  autoCorrect="off"
                  autoFocus
                  required
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              )}
              <Input
                name="email"
                type="text"
                placeholder="Digite seu e-mail"
                autoCorrect="off"
                required
                invalid={this.state.email_invalid}
                value={this.state.email}
                onChange={this.handleChange}
              />
              <Input
                name="password1"
                type="password"
                placeholder="Digite uma senha"
                autoComplete="off"
                required
                invalid={this.state.password1_invalid}
                value={this.state.password1}
                onChange={this.handleChange}
              />
              <Input
                name="password2"
                type="password"
                placeholder="Repita a senha"
                autoComplete="off"
                required
                invalid={this.state.password2_invalid}
                value={this.state.password2}
                onChange={this.handleChange}
              />
              <Check
                type="checkbox"
                name="useterms"
                checked={this.state.useterms}
                onChange={this.handleChange}
              >
                Li e concordo com a{' '}
                <a href="https://d1asjutgsmztwh.cloudfront.net/talkative/use-terms">
                  Política de Privacidade
                </a>{' '}
                e os{' '}
                <a href="https://d1asjutgsmztwh.cloudfront.net/talkative/use-terms">
                  Termos de Uso
                </a>
                .
              </Check>
              <Check
                type="checkbox"
                name="cookiepolicy"
                checked={this.state.cookiepolicy}
                onChange={this.handleChange}
              >
                Li e concordo com a{' '}
                <a href="https://d1asjutgsmztwh.cloudfront.net/talkative/cookie-policy">
                  Política de Cookies
                </a>
                .
              </Check>
              <Button fill large color="primary">
                <Icon name="check" marginRight />
                <span>Cadastrar</span>
              </Button>
              <Separator />
              <div className="text-center">
                <span>Já é cadastrado? </span>
                <Link
                  to={
                    this.state.uuid
                      ? `/sign-in?uuid=${this.state.uuid}`
                      : `/sign-in`
                  }
                >
                  <span>Faça login</span>
                  <Icon name="arrow-right" marginLeftSmall />
                </Link>
              </div>
            </Form>
          </Card>
        </PublicHeader>
        <div className="main">
          <PublicBanner>
            {this.props.site.name !== 'USEFLOW' ? (
              <StoreLinks>
                <span>Baixe nosso app para</span>
                <br />
                <span>interagir com suas comunidades.</span>
                <br />
                <span>Disponível para iOS e Android.</span>
              </StoreLinks>
            ) : (
              <span>Seja bem-vindo!</span>
            )}
          </PublicBanner>
          {this.state.showInvitation && (
            <div>
              <MessageBar color="secondary">
                <Row>
                  <Col md={5} style={{ textAlign: 'center' }}>
                    <span>Seu convite expira em </span>
                    <Badge>{this.state.invitationExpire[1]}</Badge>
                    <span> {this.state.invitationExpire[2]}.</span>
                  </Col>
                </Row>
              </MessageBar>
            </div>
          )}
          {this.state.expiredInvitation && (
            <div>
              <MessageBar color="secondary">
                <Row>
                  <Col md={5} style={{ textAlign: 'center' }}>
                    <span>Seu convite expirou.</span>
                  </Col>
                </Row>
              </MessageBar>
            </div>
          )}
          {this.state.consumedInvitation && (
            <div>
              <MessageBar color="secondary">
                <Row>
                  <Col md={5} style={{ textAlign: 'center' }}>
                    <span>Convite consumido.</span>
                  </Col>
                </Row>
              </MessageBar>
            </div>
          )}
          <div className="hide-on-mobile" style={{ marginBottom: 225 }} />
        </div>
        <PublicFooter />
      </PublicWrapper>
    );
  }
}

export default connect(null, {
  showLoading,
  closeLoading,
  showMessage,
  closeMessage,
  registerAccount
})(withRouter(withSite(SignUpScreen)));
