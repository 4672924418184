import React from 'react';

import Icon from '~/components/icon';

import StyledAlertBar from './styles';

function AlertBar({ theme, icon, title, children }) {
  return (
    <StyledAlertBar className={`alert ${theme}`}>
      <div>
        {icon && <Icon name={icon} />}
        {title && <h3>{title}</h3>}
      </div>
      <div className="alert-content">{children}</div>
    </StyledAlertBar>
  );
}

export default AlertBar;
