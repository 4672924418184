import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import useSite from '~/hooks/use-site';
import './style.scss';

const ConnectionsHeader = ({ avatar, first_count, second_count }) => {
  const { name } = useSite();

  return (
    <div className="account-screen connections-summary mb20 text-center pa20">
      <img src={avatar} alt="foo" className="rounded" />

      <h2>{name}</h2>

      <span className="small">
        <span className="bold">{(first_count || 0) + (second_count || 0)}</span>{' '}
        conexões
      </span>

      <div className="degree-connections">
        <div>
          <div className="title">conexões grau 1</div>
          <div className="count">{first_count}</div>
        </div>

        <div>
          <div className="title">conexões grau 2</div>
          <div className="count">{second_count}</div>
        </div>
      </div>
    </div>
  );
};

class ConnectionsHeaderEnhanced extends PureComponent {
  render() {
    const {
      account,
      organization: { activeOrganizationId }
    } = this.props;
    const activeOrganizationIndex = account.data.organization_is_active_set.findIndex(
      org => {
        return org.id === activeOrganizationId;
      }
    );

    return (
      <ConnectionsHeader
        avatar={_.get(
          account,
          `data.organization_is_active_set[${
            activeOrganizationIndex !== -1 ? activeOrganizationIndex : 0
          }].avatar_128x0`
        )}
        first_count={_.get(
          account,
          `data.organization_is_active_set[${
            activeOrganizationIndex !== -1 ? activeOrganizationIndex : 0
          }].invitation_count`
        )}
        second_count={_.get(
          account,
          `data.organization_is_active_set[${
            activeOrganizationIndex !== -1 ? activeOrganizationIndex : 0
          }].invitation_second_count`
        )}
      />
    );
  }
}

const mapStateToProps = store => ({
  account: store.account,
  organization: store.organization
});

export default connect(mapStateToProps)(ConnectionsHeaderEnhanced);
