import styled from 'styled-components';

const StyledUnexpectedError = styled.div.attrs(() => ({
  className: 'unexpected-error-page'
}))`
  .public-header {
    .col:first-child {
      flex: 1;
    }

    .col:last-child {
      display: none;
    }
  }

  .main {
    padding: 64px 0px;
    display: flex;
    justify-content: center;
    margin-bottom: 64px;

    .content {
      padding: 16px;
      min-height: max-content;
      width: 100%;
      max-width: 400px;
      text-align: center;

      h3 {
        font-size: 26px;
        margin-bottom: 16px;
      }

      p {
        margin-bottom: 32px;
      }
    }
  }
`;

export default StyledUnexpectedError;
