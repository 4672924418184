import React from 'react';
import './style.scss';

const Select = ({ invalid, small, children, ...props }) => {
  const classes = ['select'];
  if (invalid) classes.push('invalid');
  if (small) classes.push('small');

  const data = {
    className: classes.join(' '),
    type: 'text',
    ...props
  };

  return <select {...data}>{children}</select>;
};

export default Select;
