export const convertFileToBase64 = file => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onloadend = function() {
      resolve(reader.result);
    };

    reader.readAsDataURL(file);
  });
};
