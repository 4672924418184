import styled from 'styled-components';

export const StyledCourseContent = styled.div.attrs(() => ({
  className: 'course-content-page'
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 32px;

  .custom-header {
    position: relative;
  }

  .header-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side {
      .button {
        background: var(--white-color);
        padding: 0px 8px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: flex-end;
    }
  }

  .page-header {
    padding-bottom: 0px;
    padding-top: 0px;

    .text-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h1 {
      margin: 0px;
    }

    p {
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }

  .page-content {
    width: 100%;
    max-width: 800px;
    margin-top: 42px;

    .step-content-card {
      width: 100%;
      margin: auto;
      position: relative;
      overflow: hidden;
      color: #4a4a4a;
      background: #fff;
      border-radius: 4px;
      border: 1px solid var(--border-color);
      margin-bottom: 16px;

      .step-header {
        width: 100%;
        padding: 28px 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid var(--border-color);

        .pagination-control {
          display: flex;
          align-items: center;

          button {
            width: 32px;
            padding: 4px 8px;
            border: 1px solid var(--border-color);
            margin: 0px 16px;
            i {
              margin: 0px;
            }
          }
        }
      }

      .step-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .step-loading {
          margin: 32px 0px;
        }

        .empty-list {
          margin: 32px 0px;
        }

        .next-required-step {
          margin-bottom: 32px;
        }
      }

      .loading-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 4px;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .screen-loading {
    margin-top: 64px;
  }

  @media (max-width: 720px) {
    .page-header {
      .text-info {
        flex-direction: column;
        align-items: flex-start;

        .search-input {
          margin-left: 0px;
          width: 100%;
        }
      }
    }

    .page-content {
      padding: 16px;
    }
  }
`;
