import React from 'react';

import Button from '~/components/button';
import Icon from '~/components/icon';

import { StyledEditableField } from './styles';

function EditableField({
  value,
  inEdit,
  setInEdit,
  inputType,
  inputPlaceholder,
  step,
  min,
  onChange,
  onBlur,
  disabled
}) {
  const handleOnBlur = () => {
    setInEdit(false);
    if (onBlur) {
      onBlur();
    }
  };

  const handleOnChange = event => {
    onChange(event);
  };

  const handleEditClick = () => {
    setInEdit(true);
  };

  return (
    <StyledEditableField>
      {!inEdit && (
        <div className="field-label">
          <p>{value}</p>
          {!disabled && (
            <Button
              color="primary"
              className="outline"
              onClick={() => handleEditClick()}
            >
              <Icon name="edit" />
            </Button>
          )}
        </div>
      )}

      {inEdit && (
        <input
          type={inputType}
          value={value}
          placeholder={inputPlaceholder}
          step={step}
          min={min}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          autoFocus={true}
        />
      )}
    </StyledEditableField>
  );
}

export default EditableField;
