import styled from 'styled-components';

export const StyledTaskDoableMobileFooter = styled.div.attrs(() => ({
  className: 'task-doable-mobile-footer'
}))`
  .information-task {
    display: flex;
    justify-content: center;

    text-align: center;

    margin: 16px 0 16px;

    p {
      max-width: 406px;
      line-height: 18px;

      color: #5d59d2;
    }
  }
`;
