import React from 'react';

import { useDispatch } from 'redux-react-hook';

import { openDefaultModal } from '~/store/ducks/default-modal';

import Button from '~/components/button';
import Icon from '~/components/icon';
import PaginationControl from '~/components/pagination-control';
import CustomLoading from '~/components/custom-loading';
import CellDetails from './cell-details';

import { StyledGenericTable } from './styles';

function GenericTable({
  tableActions,
  status,
  columns,
  rows,
  cellsCharLimit,
  showPagination,
  currentPage,
  pages,
  onPageChange,
  loading
}) {
  const dispatch = useDispatch();

  const showCellDetails = (key, value) => {
    const [selectedColumn] = columns.filter(column => column.key === `${key}`);

    dispatch(
      openDefaultModal(
        <CellDetails title={selectedColumn.value} value={value} />
      )
    );
  };

  return (
    <StyledGenericTable cellsCharLimit={cellsCharLimit}>
      <div className="table-header">
        <p className="status">{status}</p>
        {tableActions && <div className="table-actions">{tableActions}</div>}
      </div>

      <div className="generic-table-wrapper default-scroll">
        <table>
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.key}>{column.value}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.length > 0 &&
              rows.map(row => (
                <tr key={row.key}>
                  {row.values.map(field => (
                    <td key={field.key}>
                      {field.isCustomField ? field.value : <p>{field.value}</p>}

                      {!field.isCustomField && (
                        <Button
                          color="primary"
                          className="cell-details-button"
                          onClick={() =>
                            showCellDetails(field.key, field.value)
                          }
                        >
                          <Icon name="eye" />
                        </Button>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {(!rows || rows.length === 0) && (
        <div className="empty-table-message">
          <p>Sem registros</p>
        </div>
      )}

      {showPagination && pages.length > 0 && (
        <div className="pagination">
          <PaginationControl
            currentPage={currentPage}
            pages={pages}
            onPageChange={onPageChange}
          />
        </div>
      )}

      {loading && (
        <div className="table-loading">
          <CustomLoading type="spin" height={56} width={56} fluid />
        </div>
      )}
    </StyledGenericTable>
  );
}

export default GenericTable;
