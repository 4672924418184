import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'redux-react-hook';
import axios from 'axios';

import { getCyclesTarget } from '~/api/performance';

import { showMessage } from '~/store/ducks/messageBar';
import { openDefaultModal } from '~/store/ducks/default-modal';

import PageHeader from '~/components/page-header';
import SearchInput from '~/components/form/search-input';
import Select from '~/components/form/select';
import PerformanceCard from '~/components/performance-card';
import EmptyList from '~/components/empty-list';
import InfiniteScroll from '~/components/infinite-scroll';
import CustomLoading from '~/components/custom-loading';
import ManagedModalContent from '~/components/managed-modal-content';

import { StyledPerformances } from './styles';

function Performances({ organization, showMessage }) {
  const { activeOrganizationId } = organization;
  const firstPage = 1;

  const url = new URL(window.location.href);
  const manager = url.searchParams.get('manager');
  const cycleId = url.searchParams.get('id');

  const dispatch = useDispatch();

  const [performances, setPerformances] = useState([]);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('all');
  const [order, setOrder] = useState('-start_at');
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const getPerformances = (filters, page) => {
    setLoading(true);
    setCurrentPage(firstPage);

    if (cancelTokenSource) {
      cancelTokenSource.cancel('canceled');
    }
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    getCyclesTarget(activeOrganizationId, filters, page, newCancelTokenSource)
      .then(response => {
        setPerformances(() => response.data.results);
        setNextPage(response.data.next);
        setLoading(false);
      })
      .catch(error => {
        if (error.message !== 'canceled') {
          showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
          setLoading(false);
        }
      });
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      getPerformances({
        name: value,
        status: status !== 'all' ? status : '',
        ordering: order
      });
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const handleStatus = event => {
    const { value } = event.target;

    setStatus(value);

    getPerformances({
      name: search,
      status: value !== 'all' ? value : '',
      ordering: order
    });
  };

  const handleOrder = event => {
    const { value } = event.target;

    getPerformances({
      name: search,
      status: status !== 'all' ? status : '',
      ordering: value
    });

    setOrder(value);
  };

  const loadMorePerformances = () => {
    setLoadingMore(true);

    const filters = {
      name: search,
      status: status !== 'all' ? status : '',
      ordering: order
    };

    getCyclesTarget(activeOrganizationId, filters, currentPage + 1)
      .then(response => {
        setPerformances(lastPerformanceList => [
          ...lastPerformanceList,
          ...response.data.results
        ]);
        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    if (!loading) {
      getPerformances({
        name: search,
        status: status !== 'all' ? status : '',
        ordering: order,
        id: cycleId
      });
    }

    if (manager) {
      dispatch(
        openDefaultModal(
          <ManagedModalContent
            orgId={activeOrganizationId}
            performanceId={manager}
          />
        )
      );
    }
  }, [activeOrganizationId]);

  return (
    <StyledPerformances>
      <PageHeader>
        <div className="text-info">
          <h1>Avaliações</h1>
        </div>
      </PageHeader>

      <div className="filters">
        <SearchInput
          value={search}
          onChange={handleSearch}
          placeholder="Busque por nome"
        />
        <Select value={status} onChange={handleStatus}>
          <option value="all">Todos</option>
          <option value="active">Ativos</option>
          <option value="finished">Finalizados</option>
        </Select>
        <Select value={order} onChange={handleOrder}>
          <option value="-start_at">Mais recente</option>
          <option value="start_at">Mais antigo</option>
        </Select>
      </div>

      {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

      <div className="performance-list">
        {!loading &&
          performances.map(performance => (
            <PerformanceCard
              key={performance.pk}
              performanceId={performance.pk}
              status={performance.status}
              startAt={performance.start_at}
              finishAt={performance.finish_at}
              isManager={performance.is_manager}
              title={performance.title}
              department={performance.department}
              profile={performance.profile}
              isAnswered={performance.is_answered}
              feedback={performance.feedback}
              orgId={activeOrganizationId}
            />
          ))}

        {!loading && performances.length === 0 && (
          <EmptyList message="Nenhuma avaliação encontrada." />
        )}
      </div>

      {loadingMore && (
        <CustomLoading type="spin" height={36} width={36} fluid />
      )}

      <InfiniteScroll
        fetchMore={loadMorePerformances}
        disabled={!nextPage || loading || loadingMore}
      />
    </StyledPerformances>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Performances));
