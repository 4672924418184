import api from './api';

export const getTrainingProfile = (orgId, cancelTokenSource) =>
  api.get(`/organization/${orgId}/training/me/profile/`, {
    cancelToken: cancelTokenSource.token
  });

export const getCategories = (orgId, filters = {}, page) =>
  api.get(`/organization/${orgId}/training/me/category/`, {
    params: {
      parent: filters.parent,
      search: filters.name,
      page
    }
  });

export const getCourses = (orgId, filters = {}, page, cancelTokenSource) =>
  api.get(`/organization/${orgId}/training/me/course/`, {
    params: {
      category: filters.category,
      search: filters.name,
      include_children: filters.include_children,
      only_can_subscribe: filters.only_can_subscribe,
      only_subscribed: filters.only_subscribed,
      page
    },
    cancelToken: cancelTokenSource ? cancelTokenSource.token : null
  });

export const getCourse = (orgId, courseId) =>
  api.get(`/organization/${orgId}/training/me/course/${courseId}/`);

export const getCategoryDetail = (orgId, catId) =>
  api.get(`/organization/${orgId}/training/me/category/${catId}/`);

export const subscribe = (orgId, courseId) =>
  api.post(`/organization/${orgId}/training/me/course/${courseId}/subscribe/`);

export const getStep = (orgId, courseId, stepId) =>
  api.get(
    `/organization/${orgId}/training/me/course/${courseId}/step/${stepId}`
  );

export const registerReply = (orgId, courseId, stepId, data) =>
  api.put(
    `/organization/${orgId}/training/me/course/${courseId}/step/${stepId}`,
    data
  );

export const registerReplyDone = (orgId, courseId, stepId, data) =>
  api.post(
    `/organization/${orgId}/training/me/course/${courseId}/step/${stepId}`,
    data
  );

export const getExamQuestion = (orgId, courseId, question) =>
  api.get(
    `/organization/${orgId}/training/me/course/${courseId}/exam/question/${question}`
  );

export const saveExamQuestion = (orgId, courseId, question, data) =>
  api.put(
    `/organization/${orgId}/training/me/course/${courseId}/exam/question/${question}`,
    data
  );

export const sendExamQuestion = (orgId, courseId, question) =>
  api.post(
    `/organization/${orgId}/training/me/course/${courseId}/exam/question/${question}`
  );

export const resetExam = (orgId, courseId, question) =>
  api.delete(
    `/organization/${orgId}/training/me/course/${courseId}/exam/question/${question}`
  );

export const getCertificate = (orgId, courseId) =>
  api.get(`/organization/${orgId}/training/me/course/${courseId}/certificate/`);

export const getReactionSurveyQuestion = (orgId, courseId, question) =>
  api.get(
    `/organization/${orgId}/training/me/course/${courseId}/survey/question/${question}/`
  );

export const saveReactionSurveyQuestion = (orgId, courseId, question, data) =>
  api.put(
    `/organization/${orgId}/training/me/course/${courseId}/survey/question/${question}/`,
    data
  );

export const sendReactionSurveyQuestion = (orgId, courseId, question) =>
  api.post(
    `/organization/${orgId}/training/me/course/${courseId}/survey/question/${question}/`
  );
