import React, { useState, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ProgressBar from '@ramonak/react-progress-bar';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { REACT_APP_SITE } from '~/settings';

import { getCourse, getCertificate } from '~/api/training';

import useSite from '~/hooks/use-site';

import { openDefaultModal } from '~/store/ducks/default-modal';
import { showMessage } from '~/store/ducks/messageBar';

import CustomLoading from '~/components/custom-loading';
import PageHeader from '~/components/page-header';
import CustomHeader from '~/components/custom-header';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Breadcrumb from '~/components/breadcrumb';
import CourseBanner from '~/components/course-banner';
import CourseSubscribeModal from '~/components/course-subscribe-modal';
import CourseSubscribeSuccessModal from '~/components/course-subscribe-success-modal';
import Card from '~/components/card';
import CourseTrail from '~/components/course-trail';

import { StyledCourseDetails } from './styles';
import downloadFile from '~/services/download';
import renderHTML from 'react-render-html';

function CourseDetails({
  account,
  organization,
  history,
  location,
  showMessage
}) {
  const { activeOrganizationId } = organization;
  const courseId = location.pathname.replace('/training/course/', '');

  const dispatch = useDispatch();
  const { primaryColor } = useSite();

  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState('Curso');

  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);

  const [course, setCourse] = useState({
    pk: null,
    title: '',
    description: '',
    cover: '',
    start_at: '',
    finish_at: '',
    credit_hour: '',
    credit_minute: '',
    credit: '',
    show_course_title: true
  });

  const [showCourseTitle, setShowCourseTitle] = useState(false);
  const [canSubscribe, setCanSubscribe] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const [certificate, setCertificate] = useState(false);
  const [hasExam, setHasExam] = useState(false);
  const [hasReactionSurvey, setHasReactionSurvey] = useState(false);

  const [stepLabel, setStepLabel] = useState('');
  const [stepCount, setStepCount] = useState(0);
  const [stepAnswersCount, setStepAnswersCount] = useState(0);
  const [nextStep, setNextStep] = useState(null);

  const [trailLabel, setTrailLabel] = useState('');
  const [trails, setTrails] = useState([]);

  const [loadingDownload, setLoadingDownload] = useState(false);

  const showCourseDescription = () => {
    dispatch(openDefaultModal(renderHTML(course.description)));
  };

  const handleBack = () => {
    const previousUrl = localStorage.getItem(`@${REACT_APP_SITE}/previousUrl`);
    if (previousUrl) {
      history.push(previousUrl);
    } else {
      history.push('/training/my-courses');
    }
  };

  const showSuccessSubscribe = () => {
    loadContent();
    dispatch(openDefaultModal(<CourseSubscribeSuccessModal />));
  };

  const showDescription = () => {
    dispatch(
      openDefaultModal(
        <CourseSubscribeModal
          orgId={activeOrganizationId}
          courseId={courseId}
          subscribeCallback={showSuccessSubscribe}
        />
      )
    );
  };

  const convertToTrailList = trailTree => {
    const trailList = [];

    trailTree.forEach(trail => {
      let currentTrail = { ...trail };
      let currentChild = trail.child ? { ...trail.child } : null;
      delete currentTrail.child;
      trailList.push(currentTrail);

      while (currentChild) {
        currentTrail = { ...currentChild };
        currentChild = currentTrail.child ? { ...currentTrail.child } : null;
        delete currentTrail.child;

        trailList.push(currentTrail);
      }
    });

    return trailList;
  };

  const handleDownload = () => {
    setLoadingDownload(true);

    downloadFile(certificate.url)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Certificado - ${course.title}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(e => {
        showMessage('Ocorreu um erro ao fazer o download', 'danger', 3000);
      })
      .finally(() => {
        setLoadingDownload(false);
      });
  };

  const checkCertificateIsDone = () => {
    let handle = setInterval(() => {
      getCertificate(activeOrganizationId, courseId)
        .then(response => {
          const { name, url, status } = response.data;

          if (status === 'done') {
            clearInterval(handle);
            setCertificate({
              name,
              url,
              status
            });
          }
        })
        .catch(() => {
          clearInterval(handle);
        });
    }, 5000);
  };

  const loadContent = () => {
    setLoading(true);

    getCourse(activeOrganizationId, courseId)
      .then(response => {
        const {
          title,
          breadcrumb,
          can_subscribe,
          subscribed,
          course,
          steps_count,
          steps_answers_count,
          step_name,
          step_name_plural,
          next_step,
          trail_name_plural,
          has_reaction_survey,
          trails: trailTree
        } = response.data;

        const bread = breadcrumb.map(link => {
          link.id = link.pk;
          link.route = `/training/category/${link.pk}`;
          delete link.pk;
          return link;
        });

        bread[0].id = `${Date.now()}1`;
        bread[0].route = '/training';

        if (subscribed) {
          bread[1].id = `${Date.now()}2`;
          bread[1].route = '/training/my-courses';
        }

        bread[bread.length - 1].route = `/training/course/${course.pk}`;
        bread[bread.length - 1].id = Date.now();

        const trailList = convertToTrailList(trailTree);

        course.credit = null;
        if (course.credit_hour > 0) {
          course.credit = `${course.credit_hour} hora`;
          if (course.credit_hour > 1) {
            course.credit += 's';
          }
        }

        if (course.credit_minute > 0) {
          if (course.credit == null) {
            course.credit = '';
          } else {
            course.credit = course.credit + ' e ';
          }
          course.credit += `${course.credit_minute} minuto`;
          if (course.credit_minute > 1) {
            course.credit += 's';
          }
        }

        setPageTitle(title);
        setBreadcrumbLinks(bread);
        setCanSubscribe(can_subscribe);
        setSubscribed(subscribed);
        setCourse(course);
        setShowCourseTitle(course.show_course_title);
        setStepLabel(steps_count === 1 ? step_name : step_name_plural);
        setStepCount(steps_count);
        setStepAnswersCount(steps_answers_count);
        setNextStep(next_step);
        setTrailLabel(trail_name_plural);
        setTrails(trailList);
        setCertificate(course.certificate);
        setHasExam(course.has_exam);
        setHasReactionSurvey(has_reaction_survey);

        if (course.certificate && course.certificate.status === 'doing') {
          checkCertificateIsDone();
        }
      })
      .catch(error => {
        if (error.response.status === 403 || error.response.status === 404) {
          history.push('/page-not-found');
          return;
        }

        showMessage('Ocorreu um erro ao carregar o conteúdo', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (account.loaded && account.data && !account.data.is_reseted_password) {
      loadContent();
    }
  }, []);

  return (
    <StyledCourseDetails showCourseTitle={showCourseTitle}>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              Voltar
            </Button>
          </div>
          <div className="right-side"></div>
        </div>
      </CustomHeader>

      <div className="page-content">
        {!loading && (
          <PageHeader>
            <div className="text-info">
              <h1>{pageTitle}</h1>
            </div>
          </PageHeader>
        )}

        {!loading && (
          <Breadcrumb
            links={breadcrumbLinks}
            compressionStart={10}
            onClickCallback={() => {}}
          />
        )}

        {!loading && (
          <CourseBanner
            title={course.title}
            image={course.cover}
            description={course.description}
          />
        )}

        <div className="course-info-content">
          <div className="course-header-content">
            {course.show_course_title && <h3>{course.title}</h3>}
            <div className="course-credit-content">
              {course.credit && <h2>Carga horária: {course.credit}</h2>}
            </div>
          </div>
          {course.description && (
            <Button
              color="primary"
              className="small"
              onClick={showCourseDescription}
            >
              Ver descrição
            </Button>
          )}
        </div>

        {!loading && subscribed && (
          <div className="progress-side">
            {nextStep ? (
              <p className="course-progress-label">
                Continue de onde você parou
              </p>
            ) : (
              course.status !== 'finished' && <p></p>
            )}

            {course.status === 'finished' && (
              <p className="course-progress-label">
                Finalizado em {moment(course.finish_at).format('DD/MM/YYYY')}
              </p>
            )}

            <div className="progress-bar-wrapper">
              <ProgressBar
                completed={(stepAnswersCount * 100) / stepCount}
                isLabelVisible={false}
                bgColor={primaryColor}
                height="12px"
              />
              <p>{parseInt((stepAnswersCount * 100) / stepCount, 10)}%</p>
            </div>
          </div>
        )}

        {!loading && subscribed && nextStep && (
          <Card className="course-progress-card">
            <div className="info">
              <Icon name="study" />
              <div className="text-info">
                <h3>Próxima atividade</h3>
                <p>
                  {stepAnswersCount}/{stepCount} {stepLabel}
                </p>
              </div>
            </div>
            <div className="action">
              <Button
                color="white"
                className="primary"
                onClick={() =>
                  history.push(`/training/course/${courseId}/step/${nextStep}`)
                }
              >
                <Icon name="next" />
              </Button>
            </div>
          </Card>
        )}

        {!loading && subscribed && hasExam && (
          <Card className="course-exam-card">
            <div className="info">
              <div className="text-info">
                <h3>Avaliação de aprendizagem</h3>
              </div>
            </div>
            <div className="action">
              <Button
                color="primary"
                className="outline"
                onClick={() =>
                  history.push(`/training/course/${courseId}/exam/1`)
                }
              >
                Acessar
              </Button>
            </div>
          </Card>
        )}

        {!loading && subscribed && hasReactionSurvey && (
          <Card className="course-reaction-survey-card">
            <div className="info">
              <div className="text-info">
                <h3>Avaliação de reação</h3>
              </div>
            </div>
            <div className="action">
              <Button
                color="primary"
                className="outline"
                onClick={() =>
                  history.push(`/training/course/${courseId}/reaction-survey/1`)
                }
              >
                Acessar
              </Button>
            </div>
          </Card>
        )}

        {!loading && subscribed && certificate && (
          <Card className="course-certificate-card">
            <div className="info">
              <div className="text-info">
                <h3>Certificado de conclusão</h3>
              </div>
            </div>
            <div className="action">
              {certificate.status === 'doing' && (
                <div className="doing-certificate">
                  <CustomLoading type="spin" height={24} width={24} fluid />
                  <p>Gerando certificado...</p>
                </div>
              )}

              {certificate.status === 'done' && (
                <Button
                  color="outline"
                  className="primary"
                  disabled={loadingDownload}
                  onClick={handleDownload}
                >
                  {loadingDownload ? 'Baixando...' : 'Baixar'}
                </Button>
              )}
            </div>
          </Card>
        )}

        {!loading && <h3 className="content-title">{trailLabel}</h3>}

        {!loading && !subscribed && canSubscribe && (
          <div className="subscribe-area">
            <div className="text-info">
              <p>
                Veja em detalhes todos os conteúdos que você irá aprender.
                Inscreva-se para acessar os conteúdos.
              </p>
            </div>
            <div className="actions">
              <Button color="primary" onClick={showDescription}>
                Inscrever-se
              </Button>
            </div>
          </div>
        )}

        {!loading && (
          <div className="trail-list">
            {trails.map(trail => (
              <CourseTrail
                key={trail.pk}
                title={trail.title}
                steps={trail.steps}
                parent={trail.parent}
                courseId={courseId}
              />
            ))}
          </div>
        )}
      </div>

      {loading && (
        <CustomLoading
          type="spin"
          height={56}
          width={56}
          fluid
          className="screen-loading"
        />
      )}
    </StyledCourseDetails>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseDetails));
