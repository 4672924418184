import { getInitialForumPosts, getNextItems } from '~/api/forum';

export const Types = {
  RESET: 'forum/RESET',
  LOAD: 'forum/LOAD',
  LOAD_MORE: 'forum/LOAD_MORE'
};

const defaultState = {
  loading: false,
  loadingMore: false,
  data: [],
  nextUrl: null,
  total: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.RESET:
      return defaultState;

    case `${Types.LOAD}_FULFILLED`: {
      const {
        payload: {
          data: { count, next, results }
        }
      } = action;

      return {
        ...state,
        data: results,
        nextUrl: next,
        total: count,
        loading: false
      };
    }

    case `${Types.LOAD}_PENDING`: {
      return {
        ...state,
        loading: true
      };
    }
    case `${Types.LOAD_MORE}_PENDING`: {
      return {
        ...state,
        loadingMore: true
      };
    }

    case `${Types.LOAD_MORE}_FULFILLED`: {
      const {
        payload: {
          data: { count, next, results }
        }
      } = action;

      return {
        ...state,
        data: [...state.data, ...results],
        nextUrl: next,
        total: count,
        loadingMore: false
      };
    }

    default:
      return state;
  }
}

export const reset = () => ({
  type: Types.RESET
});

export const load = search => ({
  type: Types.LOAD,
  payload: getInitialForumPosts({ search })
});

export const loadMore = nextUrl => ({
  type: Types.LOAD_MORE,
  payload: getNextItems(nextUrl)
});
