import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  PublicWrapper,
  PublicHeader,
  PublicBanner,
  PublicFooter
} from '~/components/public';
import Card from '~/components/card';
import { Form, Input } from '~/components/form';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Link from '~/components/link';
import Separator from '~/components/separator';
// import StoreLinks from '~/components/store-links';
import { showLoading, closeLoading } from '~/store/ducks/loading';
import { showMessage } from '~/store/ducks/messageBar';
import { passwordReset } from '~/api/auth';
import { getParam, getError } from '~/utils';

class PasswordResetScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: getParam('id'),
      key: getParam('key'),
      password1: '',
      password1_invalid: false,
      password2: '',
      password2_invalid: false,
      formDisabled: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target }) {
    const { name, value } = target;
    this.setState({
      [name]: value,
      password1_invalid: false,
      password2_invalid: false
    });
  }

  handleSubmit() {
    const { id, key, password1, password2 } = this.state;
    const { showLoading, closeLoading, showMessage } = this.props;

    if (password1 === password2) {
      showLoading();
      this.setState({ formDisabled: true });

      passwordReset(id, key, password1)
        .then(({ data }) => {
          this.props.history.push('/password-reset-success');
        })
        .catch(e => {
          getError(e, res => {
            showMessage(res.message, 'danger');
            const newState = { formDisabled: false };

            res.fields.forEach(field => {
              newState[field] = true;
            });

            this.setState(newState);
          });
        })
        .finally(() => {
          this.setState({ formDisabled: false });
          closeLoading();
        });
    } else {
      showMessage('As senhas precisam ser iguais!', 'danger');
      this.setState({
        password1_invalid: true,
        password2_invalid: true
      });
    }
  }

  render() {
    return (
      <PublicWrapper>
        <PublicHeader>
          <Card className="public-card">
            <Form
              disabled={this.state.formDisabled}
              onSubmit={this.handleSubmit}
            >
              <Input
                name="password1"
                type="password"
                placeholder="Digite uma senha"
                autoComplete="off"
                required
                invalid={this.state.password1_invalid}
                value={this.state.password1}
                onChange={this.handleChange}
              />
              <Input
                name="password2"
                type="password"
                placeholder="Repita a senha"
                autoComplete="off"
                required
                invalid={this.state.password2_invalid}
                value={this.state.password2}
                onChange={this.handleChange}
              />
              <br />
              <Button fill large color="primary">
                <Icon name="check" marginRight />
                <span>Salvar senha</span>
              </Button>
              <br />
              <div className="text-center">
                <Link to="/sign-in">Cancelar</Link>
              </div>
              <Separator />
              <div className="text-center">
                <span>Está com problemas? </span>
                <Link to="/help">Obtenha ajuda</Link>
              </div>
            </Form>
          </Card>
        </PublicHeader>
        <div className="main">
          <PublicBanner>
            <span>Seja bem-vindo!</span>
          </PublicBanner>
          <div className="hide-on-mobile" style={{ marginBottom: 175 }} />
        </div>
        <PublicFooter />
      </PublicWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showLoading, closeLoading, showMessage }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(withRouter(PasswordResetScreen));
