import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { login } from '~/services/auth';

const TokenLoginScreen = ({ history, location: { search } }) => {
  useEffect(() => {
    if (!search.includes('?token=')) {
      history.push('/');
      return;
    }

    const token = search.split('?token=').pop();
    login(decodeURIComponent(token));
    history.push('/');
  }, []);

  return null;
};

export default withRouter(TokenLoginScreen);
