import React from 'react';
// import { useDispatch } from 'redux-react-hook';
// import renderHTML from 'react-render-html';

// import { openDefaultModal } from '~/store/ducks/default-modal';

import courseImage from '~/assets/images/course.svg';

import { StyledCourseBanner } from './styles';

function CourseBanner({ title, description, image }) {
  // const dispatch = useDispatch();

  // const showDescription = () => {
  //   dispatch(openDefaultModal(renderHTML(description)));
  // };

  return (
    <StyledCourseBanner courseImage={image || courseImage} hasImage={image}>
      {/*<div className="course-card-content">*/}
      {/*  <h3>{title}</h3>*/}
      {/*  {description && (*/}
      {/*    <Button color="white" className="outline" onClick={showDescription}>*/}
      {/*      Ver descrição*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*</div>*/}
    </StyledCourseBanner>
  );
}

export default CourseBanner;
