import React, { useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { subscribe } from '~/api/training';

import { closeDefaultModal } from '~/store/ducks/default-modal';
import { showMessage } from '~/store/ducks/messageBar';

import Button from '../button';

import { StyledCourseSubscribeModal } from './styles';

function CourseSubscribeModal({
  orgId,
  courseId,
  subscribeCallback,
  showMessage
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleSubscribe = () => {
    setLoading(true);

    subscribe(orgId, courseId)
      .then(() => {
        dispatch(closeDefaultModal());
        subscribeCallback();
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a inscrição', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <StyledCourseSubscribeModal>
      <div className="content-wrapper">
        <h3>Deseja se inscrever?</h3>
        <p>
          Ao confirmar você irá ter acesso aos conteúdos e deverá concluí-lo até
          a data de finalização.
        </p>
      </div>
      <div className="actions">
        <Button
          color="outline"
          className="primary"
          onClick={() => dispatch(closeDefaultModal())}
        >
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSubscribe} disabled={loading}>
          {!loading ? 'Confirmar' : 'Inscrevendo-se...'}
        </Button>
      </div>
    </StyledCourseSubscribeModal>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CourseSubscribeModal);
