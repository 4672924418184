import styled from 'styled-components';

export const StyledTimelineStatus = styled.div.attrs(() => ({
  className: 'timeline-status'
}))`
  width: 100%;
  padding: 8px 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .status-message {
    font-size: 14px;
    padding-right: 8px;
  }

  .status-date {
    min-width: max-content;
    font-size: 12px;
    color: var(--secondary-text-color);
    font-style: italic;
  }

  .comment {
    width: 100%;
    background: var(--white-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;

    .comment-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 8px;
      border-bottom: 1px solid var(--border-color);

      .status-message {
        color: var(--secondary-text-color);
        span {
          color: var(--text-color);
        }
      }
    }

    .comment-content {
      padding: 8px;
      color: var(--secondary-text-color);
    }
  }
`;
