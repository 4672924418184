import styled from 'styled-components';

export const StyledRankingReward = styled.div.attrs(() => ({
  className: 'ranking-reward'
}))`
  .ranking-reward-title {
    color: var(--grey100);

    font-weight: 900;
    font-size: 24px;

    margin-bottom: 22px;

    line-height: 30px;
  }

  .ranking-reward-list {
    gap: 12px;
    display: flex;

    .card-container {
      width: 100%;
      max-width: 334px;

      padding: 16px;

      gap: 12px;
      display: flex;
      flex-direction: column;

      & span {
        color: var(--grey100);

        font-weight: 900;
        font-size: 16px;

        line-height: 110%;
        letter-spacing: 0.5px;
      }

      & .reward-image,
      & img {
        width: 100%;
        height: 121px;

        border-radius: 8px;
      }

      & .reward-image {
        color: var(--white-color);
        background: radial-gradient(
          81.25% 81.25% at 87.5% 9.38%,
          #00bc9b 0%,
          #1696e1 100%
        );

        font-size: 54px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      & .reward-info {
        gap: 15px;

        display: flex;
        align-items: center;

        & > div {
          min-width: 58px;
          height: 53px;

          padding: 4px;

          font-size: 12px;
          text-align: center;

          color: var(--white-color);
          background: var(--alert-secondary-color);
          border-radius: 8px;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        p span,
        & p {
          color: var(--grey100);

          font-weight: 700;
          font-size: 14px;

          line-height: 110%;
          letter-spacing: 0.5px;
        }

        .position span {
          font-weight: 450;
          font-size: 12px;

          color: var(--white-color);
        }
      }

      .current-winner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
          font-weight: 700;
          font-size: 16px;
          line-height: 110%;

          letter-spacing: 0.5px;

          color: var(--grey100);

          gap: 12px;
          display: flex;
          align-items: center;

          & img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
        }

        & > span {
          color: var(--brand-primary-color);
        }

        & .no-winner {
          width: 164px;
          height: 27px;

          padding: 6px 10px;

          border-radius: 6px;
          border: 1px solid var(--alert-secondary-color);

          & span {
            color: var(--alert-secondary-color);

            font-weight: 700;
            font-size: 14px;
            line-height: 32px;

            letter-spacing: 0.5px;
          }
        }
      }
    }

    .previous-reward,
    .next-reward {
      display: flex;
      align-items: center;
    }

    .previous-reward .button,
    .next-reward .button {
      width: 40px;
      height: 40px;

      color: var(--brand-primary-color);

      font-size: 18px;

      border: 2px solid var(--brand-primary-color);
      border-radius: 50%;

      & i {
        margin: 0px;
      }
    }

    .previous-reward .button:hover,
    .next-reward .button:hover {
      background: var(--brand-primary-color-hover);
    }

    .previous-reward .button.disabled,
    .next-reward .button.disabled {
      color: var(--grey60);

      pointer-events: none;
      border: 2px solid var(--grey60);

      cursor: pointer;
    }
  }

  @media (max-width: 987px) {
    .ranking-reward-list {
      justify-content: space-between;

      .previous-reward .button,
      .next-reward .button {
        & i {
          top: 0;
          left: -6px;
        }
      }
    }
  }

  @media (max-width: 580px) {
    .ranking-reward-list {
      flex-direction: column;
      align-items: center;
    }
  }
`;
