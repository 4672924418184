import styled from 'styled-components';

const StyledMyProfile = styled.div.attrs(() => ({
  className: 'my-profile-page'
}))`
  .input-group {
    margin-bottom: 16px;
  }

  @media (max-width: 987px) {
    .actions {
      button + button {
        margin-top: 12px;
      }
    }
  }
`;

export default StyledMyProfile;
