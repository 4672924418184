import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from '~/components/button';

import folderIcon from '~/assets/images/folder-icon.png';

import './styles.scss';

function LibraryFolder({ title, folderId, history }) {
  const openFolder = () => {
    history.push(`/library/${folderId}`);
  };

  return (
    <Button onClick={openFolder} color="white" className="library-folder">
      <img src={folderIcon} alt="Folder" />
      <p>{title}</p>
    </Button>
  );
}

export default withRouter(LibraryFolder);
