import { useEffect, useState, useRef } from 'react';

const useDebounce = (value, ms) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timeout = useRef();

  useEffect(
    () => {
      timeout.current = setTimeout(() => {
        setDebouncedValue(value);
      }, ms);

      return () => {
        clearTimeout(timeout.current);
      };
    },
    [value]
  );

  return debouncedValue;
};

export default useDebounce;
