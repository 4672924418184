import styled from 'styled-components';

export const StyledProgressBar = styled.div.attrs(() => ({
  className: 'progress-bar'
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    width: 100%;
    margin-right: 8px;
  }
  p {
    min-width: max-content;
  }
`;
