export const Types = {
  SHOW: 'invitation/SHOW',
  HIDE: 'invitation/HIDE'
};

const defaultState = { visible: false, organization: null, expires: null };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.SHOW:
      return {
        visible: true,
        organization: action.organization,
        expires: action.expires
      };
    case Types.HIDE:
      return defaultState;
    default:
      return state;
  }
}

export const invitationExpires = (organization, expires) => ({
  type: Types.SHOW,
  organization,
  expires
});

export const hideInvitationExpires = () => ({
  type: Types.HIDE
});
