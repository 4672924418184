import styled from 'styled-components';

export const StyledSkeletonLoading = styled.div.attrs(() => ({
  className: 'skeleton-loading'
}))`
  & > div {
    height: max-content;
  }

  .skeleton-box {
    display: inline-block;
    height: 16px;
    position: relative;
    overflow: hidden;
    background-color: var(--background-color);
    border-radius: 4px;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        var(--background-color),
        #fcfcfc,
        var(--background-color)
      );
      animation: shimmer 2s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
