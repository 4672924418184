import styled from 'styled-components';

export const StyledPDIList = styled.div.attrs(() => ({
  className: 'pdi-list-page'
}))`
  width: 100%;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;

    h1 {
      margin: 0px;
    }

    p {
      font-size: 14px;
    }
  }

  .filters {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;

    select {
      width: max-content;
      color: var(--secondary-text-color);
      -webkit-appearance: listbox;
      min-height: 41px;
      margin-left: 8px;

      &:focus {
        outline: none;
        border-color: var(--border-color);
        color: var(--secondary-text-color);
      }
    }

    .check-wrapper {
      display: flex;
      align-items: center;
      margin-left: 8px;

      input {
        margin-right: 8px;
      }

      span {
        line-height: 1;
        color: var(--secondary-text-color);
      }
    }

    .order-control {
      display: flex;
      align-items: center;
      margin-left: 16px;

      p {
        color: var(--secondary-text-color);
      }

      select {
        width: max-content;
      }
    }
  }

  .pdi-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .custom-loading {
    margin: 32px 0px;
  }

  .add-pdi-button {
    i {
      margin: 0px;
    }
  }

  .fab {
    bottom: 80px;
  }

  @media (max-width: 720px) {
    .pdi-list {
      margin-bottom: 56px;
    }

    .filters {
      flex-wrap: wrap;

      .search-input {
        flex: initial;
        margin-right: 0px;
        width: 100%;
      }

      .select {
        width: 50%;
      }
    }
  }
`;
