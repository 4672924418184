import React, { Component, PureComponent } from 'react';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Share } from 'react-facebook-sdk';
import { connect } from 'react-redux';
import Button from '~/components/button';
import Card from '~/components/card';
import { CommentBoxAndButtons, TaskCommentBox } from '~/components/comments';
import { Check, Form } from '~/components/form';
import Icon from '~/components/icon';
import LinkPreviewMedia from '~/components/link-preview-media';
import PostHeader from '~/components/post-header';
import ProfileTask from '~/components/profile-task';
import Facebook from '~/components/profile-task/tasks/facebook';
import Instagram from '~/components/profile-task/tasks/instagram';
import Linkedin from '~/components/profile-task/tasks/linkedin';
import Twitter from '~/components/profile-task/tasks/twitter';
import ImageTask from '~/components/publishing-tasks/image-task';
import UploadFileTask from '~/components/publishing-tasks/upload-file-task';
import CreatorsTask from '~/components/publishing-tasks/creators-task';
import TaskDoableOnMobile from '~/components/task-doable-on-mobile';
import MessageTask from '~/components/publishing-tasks/message-task';
import MultipollTask from '~/components/publishing-tasks/multipoll-task';
import QuestionnaireTask from '~/components/publishing-tasks/questionnaire-task';
import InvitationTask from '~/components/publishing-tasks/invitation-task';
import { WololoRewardLine } from '~/components/rewards';
import { addWololo } from '~/services/tasks';
import { openMessageModal } from '~/store/ducks/message-modal';
import actions from './actions';
import './style.scss';
import TaskComponentFactory from './task-component-factory';

const noop = () => {};

const types = {
  share: 'Compartilhar',
  comment: 'Comentar',
  like: 'Curtir',
  repost: 'Repostar',
  tweet: 'Post',
  retweet: 'Repost',
  watch: 'Assistir',
  message: 'Mensagem',
  image: 'Imagem',
  movie: 'Vídeo',
  read: 'Ler',
  open_url: 'Abrir url',
  poll: 'Enviar resposta',
  checkin: 'Checkin',
  questionnaire: 'Questionário',
  invitation: 'Obter convite',
  user_publishing: 'Publicação do usuário'
};

const channels = {
  profile: 'Perfil',
  facebook: 'Facebook',
  whatsapp: 'Whatsapp',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  twitter: 'X (antigo Twitter)',
  youtube: 'Youtube',
  publishing: 'Publicação'
};

const connectTask = {
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  twitter: Twitter
};

const ConnectSocial = ({ id, name, task, onFinish, isSubtask }) => {
  const Social = connectTask[name];
  return (
    <Social id={id} task={task} onFinish={onFinish} isSubtask={isSubtask} />
  );
};

class ActionCallback extends PureComponent {
  state = {
    loading: false
  };

  render() {
    const {
      channel,
      type,
      wololo_target_image,
      wololo_target_url
    } = this.props;

    const { loading } = this.state;

    // Temp
    let actionTask = '';
    if (this.props.channel !== 'challenge') {
      actionTask = actions[this.props.channel][this.props.type];
    }

    if (channel === 'facebook') {
      if (type === 'image' || type === 'share') {
        let finalUrl;

        if (type === 'image') {
          finalUrl = wololo_target_image;
        }

        if (type === 'share') {
          finalUrl = wololo_target_url;
        }

        return (
          <Share
            display="popup"
            href={finalUrl}
            onResponse={() => {
              addWololo(this.props.id)
                .then(() => {
                  this.props.onFinish();
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            }}
            onError={() => {
              this.setState({ loading: false });
            }}
            render={({ onClick }) =>
              this.props.children({
                onClick: (...params) => {
                  this.setState({ loading: true });
                  if (params.length === 0) {
                    onClick({
                      preventDefault: noop,
                      stopPropagation: noop
                    });
                  } else {
                    onClick(...params);
                  }
                },
                loading
              })
            }
          />
        );
      }
    }

    return this.props.children({
      loading,
      onClick: () => {
        this.setState({ loading: true });

        actionTask(this.props)
          .then(() => this.props.onFinish())
          .catch(() => this.setState({ loading: false }));
      }
    });
  }
}

class Task extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      poll: null,
      commentCounter: this.props.comment_counter,
      copied: false
    };

    this.handleConnect = this.handleConnect.bind(this);
    this.handleChangePoll = this.handleChangePoll.bind(this);
    this.handleSubmitPoll = this.handleSubmitPoll.bind(this);
    this.handleSuccessMessage = this.handleSuccessMessage.bind(this);
  }

  handleSuccessMessage = () => {
    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);
  };

  handleConnect() {
    alert('Conectar ' + this.props.channel);
  }

  handleChangePoll({ target }) {
    const poll = target.value;

    this.setState({ poll });
    if (this.props.isSubtask) {
      this.props.onFinish(this.props.id, { message: poll });
    }
  }

  incrementCommentCounter() {
    this.setState({ commentCounter: this.state.commentCounter + 1 });
  }

  handleSubmitPoll() {
    if (!this.state.loading) {
      this.setState({ loading: true });
      addWololo(this.props.id, { message: this.state.poll })
        .then(() => this.props.onFinish())
        .catch(() => this.setState({ loading: false }));
    }
  }

  renderActionButton = (callback, loading) => {
    const colorButton = this.props.channel;

    return (
      <Button
        color={colorButton}
        title={types[this.props.type]}
        onClick={callback}
        disabled={loading}
      >
        <Icon name={this.props.channel} marginRight />
        <span>{types[this.props.type]}</span>
      </Button>
    );
  };

  getTask() {
    const { channel, type, type_publishing_content } = this.props;

    let Task;

    if (channel === 'publishing') {
      if (type === 'multipoll') {
        Task = MultipollTask;
      }

      if (type === 'invitation') {
        Task = InvitationTask;
      }

      if (type === 'message') {
        Task = MessageTask;
      }

      if (type === 'image') {
        Task = ImageTask;
      }

      if (type === 'questionnaire') {
        Task = QuestionnaireTask;
      }

      if (type === 'file') {
        Task = UploadFileTask;
      }

      if (type === 'form') {
        Task = CreatorsTask;
      }
    }

    if (
      channel === 'whatsapp' &&
      type === 'user_publishing' &&
      ['image', 'movie'].some(item => item === type_publishing_content)
    ) {
      Task = TaskDoableOnMobile;
    }

    if (!Task) {
      Task = TaskComponentFactory.createTask(this.props, types, channels);

      return Task;
    }

    if (Task) {
      return <Task {...this.props} types={types} channels={channels} />;
    }
  }

  render() {
    let result;
    const { channel, isSubtask } = this.props;

    // no web ficou ruim, escondendo
    if (channel === 'zoom') {
      return null;
    }

    const colorButton = this.props.channel;

    result = this.getTask();

    if (!result) {
      result = (
        <Card
          className={`task-${this.props.channel || 'default'} ${
            this.props.isSubtask ? 'subtask' : ''
          }`}
        >
          {!this.props.isSubtask && (
            <PostHeader
              info={types[this.props.type] || channels[this.props.channel]}
              points={this.props.wololo_points}
              organization={this.props.organization}
              expire={this.props.close_at}
              me_create_wololo={this.props.me_create_wololo}
              channel={this.props.channel}
              meTrail={this.props.me_trail}
            />
          )}
          <div className="card-body">
            {this.props.channel !== 'profile' ? (
              <div className="content-card">
                {this.props.cover && (
                  <LinkPreviewMedia
                    channel={this.props.channel}
                    type={this.props.type}
                    id={this.props.id}
                  >
                    <img src={this.props.cover} alt="Cover" />
                  </LinkPreviewMedia>
                )}
                <div className="title-date-task">
                  {this.props.title && (
                    <h3>
                      <LinkPreviewMedia
                        channel={this.props.channel}
                        type={this.props.type}
                        id={this.props.id}
                      >
                        {this.props.title}
                      </LinkPreviewMedia>
                    </h3>
                  )}
                  {this.props.open_at && (
                    <p>
                      <LinkPreviewMedia
                        channel={this.props.channel}
                        type={this.props.type}
                        id={this.props.id}
                      >
                        Postado em{' '}
                        {moment(this.props.open_at).format('DD/MM/YYYY')}
                      </LinkPreviewMedia>
                    </p>
                  )}
                </div>
                {this.props.description && (
                  <p className="text-center">
                    <LinkPreviewMedia
                      channel={this.props.channel}
                      type={this.props.type}
                      id={this.props.id}
                    >
                      {this.props.description}
                    </LinkPreviewMedia>
                  </p>
                )}
                {this.props.channel === 'twitter' && this.props.type === 'image'
                  ? this.props.suggested_caption && (
                      <p className="suggested-caption">
                        <strong>Legenda sugerida:</strong>
                        <br />
                        {this.props.suggested_caption}
                      </p>
                    )
                  : null}
              </div>
            ) : (
              <ProfileTask
                id={this.props.id}
                wololoTargetId={this.props.wololo_target_id}
                title={this.props.title}
                date={this.props.open_at}
                type={this.props.type}
                phone={this.props.wololo_target_location}
                onFinish={this.props.onFinish}
                pointsToShow={this.props.wololo_points}
                isSubtask={this.props.isSubtask}
              />
            )}
          </div>

          {!this.props.me_create_wololo &&
            this.props.channel !== 'profile' &&
            (this.props.type !== 'poll' ? (
              <footer className="card-footer">
                <div className="text-center">
                  {this.props.channel === 'twitter' &&
                  this.props.type === 'image'
                    ? this.props.suggested_caption && (
                        <CopyToClipboard text={this.props.suggested_caption}>
                          <Button
                            onClick={() => this.handleSuccessMessage()}
                            color="primary"
                            title={
                              this.state.copied
                                ? 'Copiado com sucesso!'
                                : 'Copiar legenda'
                            }
                            className="btn-copy"
                            disabled={this.state.copied}
                          >
                            <Icon name="copy" marginRight />
                            <span>
                              {this.state.copied
                                ? 'Copiado com sucesso!'
                                : 'Copiar legenda'}
                            </span>
                          </Button>
                        </CopyToClipboard>
                      )
                    : null}
                  <ActionCallback {...this.props}>
                    {({ onClick, loading }) =>
                      this.props.connected ? (
                        this.renderActionButton(onClick, loading)
                      ) : (
                        <ConnectSocial
                          task={this.props}
                          id={this.props.id}
                          name={this.props.channel}
                          onFinish={onClick}
                          isSubtask={this.props.isSubtask}
                        />
                      )
                    }
                  </ActionCallback>
                </div>
              </footer>
            ) : (
              <Form onSubmit={this.handleSubmitPoll}>
                <div className="check-task-wrapper">
                  {this.props.wololo_target_options.map((opt, i) => (
                    <Check
                      key={i}
                      name="answer"
                      type="radio"
                      text={opt}
                      value={opt}
                      onChange={this.handleChangePoll}
                    />
                  ))}
                </div>
                {!this.props.me_create_wololo && !isSubtask && (
                  <div className="text-center">
                    <Button
                      color={colorButton}
                      title={types[this.props.type]}
                      disabled={this.state.loading}
                    >
                      <Icon name={this.props.channel} marginRight />
                      <span>{types[this.props.type]}</span>
                    </Button>
                  </div>
                )}
              </Form>
            ))}

          {this.props.can_comment && this.props.me_create_wololo ? (
            <CommentBoxAndButtons
              id={this.props.id}
              type="task"
              commentCounter={this.state.commentCounter}
              incrementCommentCounter={this.incrementCommentCounter}
              childCommentsProps="taskcomment_set"
              CommentBoxComponent={TaskCommentBox}
            />
          ) : null}

          {this.props.reward_still_available && (
            <WololoRewardLine {...this.props} />
          )}
        </Card>
      );
    }

    return result;
  }
}

export default connect(null, { openMessageModal })(Task);
