import LogoImg from './grupo-fsb-assets/images/logo-color.png';
import LogoImg2x from './grupo-fsb-assets/images/logo-color.png';
import LogoReverseImg from './grupo-fsb-assets/images/logo-inverted.png';
import LogoReverseImg2x from './grupo-fsb-assets/images/logo-inverted.png';
import './main.scss';

const GrupoFSBSite = {
  name: 'Grupo FSB',
  googlePlayUrl: '',
  appStoreUrl: '',
  LogoImg,
  LogoImg2x,
  LogoReverseImg,
  LogoReverseImg2x,
  completeSignUp: false,
  tutorialDescription: 'Grupo FSB',
  actionGenderPoints: 150,
  pushApplicationServerKey:
    'BMplX3pQp1CyS43RjWTg3imHX2DWbgCTYwBF5jkcOOYA9hcwVGXp5rCk_EBRV1-ugvQuLKDibRlFoDArWx5O4C4',
  loginMinutes: null,
  primaryColor: '#161a28'
};

export default GrupoFSBSite;
