import { useEffect } from 'react';

const InfiniteScroll = ({ fetchMore, disabled, scrollElement }) => {
  const elementScroll = ({ target }) => {
    if (!disabled) {
      const max = target.scrollHeight - target.clientHeight;

      if (target.scrollTop >= max) {
        fetchMore();
      }
    }
  };

  const pageScroll = () => {
    if (!disabled) {
      const { offsetHeight, scrollHeight } = document.body;
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (offsetHeight + scrollTop >= scrollHeight) {
        fetchMore();
      }
    }
  };

  useEffect(() => {
    const currentScrollElement = scrollElement || document;

    currentScrollElement.addEventListener(
      'scroll',
      scrollElement ? elementScroll : pageScroll
    );
    return () => {
      currentScrollElement.removeEventListener(
        'scroll',
        scrollElement ? elementScroll : pageScroll
      );
    };
  }, [disabled, scrollElement]);

  return null;
};

export default InfiniteScroll;
