import React from 'react';

import { StyledCellDetails } from './styles';

function CellDetails({ title, value }) {
  return (
    <StyledCellDetails>
      <h3>{title}</h3>
      <p>{value}</p>
    </StyledCellDetails>
  );
}

export default CellDetails;
