import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FileUpload from '~/services/upload';
import { showMessage } from '~/store/ducks/messageBar';

import { useConnect } from '../feed/task/task-component-factory';
import Dropzone from '~/components/form/dropzone';
import Button from '~/components/button';
import Icon from '~/components/icon';
import { Input } from '../form';

function UserPublishingBody({
  organization: { activeOrganizationId },
  expanded,
  account,
  organization,
  actions,
  contentType,
  auth,
  buttonText,
  children,
  ...props
}) {
  const [connect, connectLoading] = useConnect(auth, props, true);
  const [text, setText] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [progress, setProgress] = useState({
    percentage: 0,
    completed: false
  });
  const [preview, setPreview] = useState(null);
  const [hasPreview, setHasPreview] = useState(false);

  const actionFunction = () => {
    switch (contentType) {
      case 'image':
        return actions.imageUgc;

      case 'movie':
        return actions.movieUgc;

      case 'text':
        return actions.messageUgc;

      default:
        break;
    }
  };

  const acceptType = () => {
    switch (contentType) {
      case 'image':
        return { image: ['.jpeg', '.png', '.jpg'] };

      case 'movie':
        return { video: ['.mp4', '.MP4'] };

      default:
        break;
    }
  };

  const allowedCharacterLimits = text => {
    let max;
    if (props.channel === 'linkedin' && text.length > 3000) {
      max = 3000;
    } else if (props.channel === 'instagram' && text.length > 2200) {
      max = 2200;
    } else if (props.channel === 'twitter' && text.length > 280) {
      max = 280;
    } else if (props.channel === 'facebook' && text.length > 63200) {
      max = 63200;
    } else if (props.channel === 'whatsapp' && text.length > 4096) {
      max = 4096;
    }

    return max;
  };

  const answerWithText = () => {
    if (text.trim() === '') {
      props.showMessage('Preencha o texto para enviar!', 'danger', 3000);
      return;
    }

    const isValidLimit = allowedCharacterLimits(text);
    if (isValidLimit) {
      props.showMessage(
        `Quantidade de caracteres não pode ser maior que ${isValidLimit}`,
        'danger',
        3000
      );
      return;
    }

    setLoading(true);

    const actionTask = actionFunction();

    if (actionTask !== undefined) {
      const data = {
        message: text
      };

      actionTask(props, data)
        .then(() => props.onFinish())
        .catch(error => {
          if (error && error.data.errors && error.data.code[0] === 'invalid') {
            props.showMessage(error.data.errors[0], 'danger', 3000);
          } else if (error) {
            props.showMessage(
              'Ocorreu um erro ao enviar a resposta',
              'danger',
              3000
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const answerWithFile = () => {
    const { id } = props;

    if (!file) {
      props.showMessage('Selecione um arquivo para enviar!', 'danger', 3000);
      return;
    }

    if (file.size > 100000000) {
      props.showMessage(
        'O tamanho do arquivo não pode ser superior à 100mb',
        'danger',
        3000
      );
      return;
    }

    if (text.trim() === '') {
      props.showMessage('Preencha o texto para enviar!', 'danger', 3000);
      return;
    }

    const isValidLimit = allowedCharacterLimits(text);
    if (isValidLimit) {
      props.showMessage(
        `Quantidade de caracteres não pode ser maior que ${isValidLimit}`,
        'danger',
        3000
      );
      return;
    }

    setLoading(true);

    const newFileUpload = new FileUpload();
    setFileUpload(newFileUpload);

    newFileUpload
      .send(file, `core/task/${id}`, activeOrganizationId, progress => {
        setProgress(lastProgress => ({
          ...lastProgress,
          percentage: progress
        }));
      })
      .then(response => {
        const { moduleId, fileUrl } = response;

        setProgress(lastProgress => ({ ...lastProgress, completed: true }));

        const data = {
          share_id: moduleId,
          message: text
        };

        const actionTask = actionFunction();

        if (actionTask !== undefined) {
          actionTask(props, fileUrl, data)
            .then(() => props.onFinish())
            .catch(error => {
              setLoading(false);

              if (error.data.errors && error.data.code[0] === 'invalid') {
                props.showMessage(error.data.errors[0], 'danger', 3000);
                return;
              }

              props.showMessage(
                'Ocorreu um erro ao enviar a resposta',
                'danger',
                3000
              );
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch(error => {
        setProgress({
          percentage: 0,
          completed: false
        });
        setLoading(false);

        if (error.name !== 'upload-canceled') {
          props.showMessage(
            'Ocorreu um erro ao enviar o arquivo',
            'danger',
            3000
          );
        }
      });
  };

  const handleDrop = files => {
    if (!files.length) {
      props.showMessage('Selecione um arquivo válido!', 'danger', 3000);
      return;
    }

    setFile(files[0]);
  };

  const handleCancel = () => {
    fileUpload.cancel();
  };

  const handleChange = ({ target }) => {
    const { value } = target;

    setText(value);
  };

  const factoryPreview = () => {
    switch (contentType) {
      case 'image':
        return <img src={preview} style={{ width: '100%' }} alt="" />;

      case 'movie':
        return (
          <video
            style={{ width: '100%' }}
            controls
            preload="none"
            src={preview}
          />
        );

      default:
        return null;
    }
  };

  const buttonLabel = () => {
    const label = props.me_create_wololo ? 'Enviado' : 'Enviar';

    return label;
  };

  useEffect(() => {
    if (!file) {
      setPreview(null);
      setHasPreview(false);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
    setHasPreview(true);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  return (
    <>
      {contentType !== 'text' && !props.me_create_wololo ? (
        <Dropzone
          placeholder={`
            Arraste e solte ${
              contentType === 'image'
                ? 'sua imagem (.jpeg, .jpg ou .png)'
                : 'seu vídeo (.mp4)'
            }
            aqui, ou clique para selecionar
          `}
          accept={acceptType()}
          sendedFileName={file ? file.name : null}
          onDrop={handleDrop}
          progress={{
            percentage: progress.percentage,
            completed: progress.completed,
            onCancel: handleCancel
          }}
          onRemove={() => setFile(null)}
          disabled={loading}
          hasPreview={hasPreview}
          factoryPreview={factoryPreview}
        />
      ) : null}

      {!props.me_create_wololo ? (
        <Input
          textarea
          value={text}
          onChange={handleChange}
          name="text"
          placeholder="Escreva o texto"
        />
      ) : null}

      <div className="text-center" style={{ paddingTop: '15px' }}>
        {props.connected ? (
          <Button
            onClick={contentType === 'text' ? answerWithText : answerWithFile}
            color={props.channel}
            title={!loading ? buttonLabel() : 'Enviando...'}
            disabled={props.me_create_wololo || loading}
          >
            <Icon name={props.channel} marginRight />
            <span>{!loading ? buttonLabel() : 'Enviando...'}</span>
          </Button>
        ) : (
          <Button
            disabled={props.me_create_wololo || connectLoading}
            color={props.channel}
            title={`Conectar com o ${buttonText}`}
            onClick={connect}
          >
            <Icon name={props.channel} marginRight />
            <span>Conectar com o {buttonText}</span>
          </Button>
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserPublishingBody);
