import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Badge = props => <span className="badge">{props.children}</span>;

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Badge;
