import styled from 'styled-components';

const StyledPerformanceAnswers = styled.div.attrs(() => ({
  className: 'performance-answers-page'
}))`
  width: 100%;
  display: flex;
  justify-content: center;

  .header-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side {
      .button {
        background: var(--white-color);
        padding: 0px 8px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: flex-end;

      .button {
        margin: 0px 4px;
        min-width: 76px;

        .custom-loading {
          margin: 0px;
        }
      }

      .suspended-menu {
        display: none;

        & > button {
          background: var(--primary-color);
          color: var(--white-color);
          min-width: 42px;
        }
      }
    }
  }

  .box-card {
    display: flex;
    padding: 16px 32px;
    box-shadow: none;
    border: none;

    h3 {
      min-width: max-content;
      margin-right: 16px;
    }

    p {
      margin-top: 3px;
    }
  }

  .alert {
    margin-top: 16px;
  }

  .page-content {
    width: 100%;
    max-width: 800px;
    margin-top: 76px;
    padding: 64px 0px;

    .performance-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .finish-date {
        margin-bottom: 12px;
        color: var(--secondary-text-color);
      }

      .collaborator-card {
        margin-bottom: 16px;
      }
    }

    .page-control {
      margin-top: 48px;
      margin-bottom: 32px;
    }
  }

  .custom-loading {
    margin: 32px 0px;
  }

  .go-to-top {
    font-size: 18px;
    background: var(--text-color);
    padding: 6px 18px;

    i {
      margin: 0px;
    }
  }

  @media (max-width: 1366px) {
    .go-to-top {
      position: absolute;
      bottom: 54px;
      right: 24px;
    }
  }

  @media (max-width: 720px) {
    .header-actions {
      .right-side {
        & > button {
          display: none;
        }

        .suspended-menu {
          display: block;
        }
      }
    }

    .page-content {
      padding: 16px;
    }
  }
`;

export default StyledPerformanceAnswers;
