import api from './api';
import { mergeArrayOfObjects } from '~/utils';

export const getPages = id => api.get(`/organizations/${id}/pages/`);

export const getAllPages = (organizations, index, allData) => {
  const amountOrgs = organizations.length;
  const lastOrgIndex = amountOrgs - 1;

  return new Promise((resolve, reject) => {
    if (index > lastOrgIndex) {
      resolve(allData);
    }

    getPages(organizations[index].id).then(({ data }) => {
      const newAllData = mergeArrayOfObjects(allData, data, 'id');

      resolve(getAllPages(organizations, index + 1, newAllData));
    });
  });
};
