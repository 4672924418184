import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const FakeLink = ({ to, onClick, children, data }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick(data);
  };

  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className="fake-link"
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

FakeLink.defaultProps = {
  data: {}
};

FakeLink.propTypes = {
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default FakeLink;
