import styled from 'styled-components';

export const StyledPDIParticipantItem = styled.button.attrs(() => ({
  className: 'pdi-participant-item',
  type: 'button'
}))`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  margin-bottom: 16px;
  padding: 0px;

  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 16px var(--background-placeholder-color);
  }

  .card {
    width: 100%;
    padding: 16px 32px;
    margin: 0px;

    display: flex;
    align-items: flex-start;
    flex-direction: row;

    .avatar {
      margin-right: 16px;
      min-width: max-content;

      img {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }

    .participant-info {
      h3 {
        text-align: start;
      }

      p {
        color: var(--secondary-text-color);
        text-align: start;
      }

      .email {
        color: var(--primary-color);
      }
    }
  }
`;
