import React from 'react';
import moment from 'moment';

import { StyledTimelineStatus } from './styles';

function TimelineStatus({ owner, type, description, status, createdAt }) {
  const getFieldLabel = field => {
    if (field === 'deadline') {
      return 'o prazo';
    }
  };

  let message = '';

  if (type === 'add') {
    message = 'registrou esse PDI';
  }

  if (type === 'update') {
    message = `editou ${getFieldLabel(description.field)} para ${
      description.new_value
    } ${description.new_value === 1 ? 'semana' : 'semanas'}`;
  }

  if (type === 'status') {
    message = `mudou a situação para ${status}`;
  }

  return (
    <StyledTimelineStatus>
      {type !== 'comment' && (
        <>
          <p className="status-message">
            {owner} {message}
          </p>
          <p className="status-date">
            {moment(createdAt).format('D [de] MMM [de] YYYY [às] h:mm')}
          </p>
        </>
      )}

      {type === 'comment' && (
        <div className="comment">
          <div className="comment-header">
            <p className="status-message">
              <span>{owner}</span> comentou
            </p>
            <p className="status-date">
              {moment(createdAt).format('D [de] MMM [de] YYYY [às] h:mm')}
            </p>
          </div>
          <div className="comment-content">{description}</div>
        </div>
      )}
    </StyledTimelineStatus>
  );
}

export default TimelineStatus;
