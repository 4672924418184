import React from 'react';
import moment from 'moment';

import Card from '~/components/card';
import Icon from '~/components/icon';
import PDIStatus from '~/components/pdi-status';

import { StyledPDIItem } from './styles';

function PDIItem({ title, status, isLate, startAt, finishAt, onClick }) {
  return (
    <StyledPDIItem onClick={onClick}>
      <Card className="pdi-item">
        <div className="info-side">
          <div className="text-info">
            <h3 className="title">{title || 'Não informado'}</h3>
            <div className="tags">
              <div className="period-card">
                <Icon name="calendar" />
                <p>
                  {moment(startAt).format('DD/MM/YYYY')} até{' '}
                  {moment(finishAt).format('DD/MM/YYYY')}
                </p>
              </div>

              <PDIStatus status={status.value} description={status.text} />

              {isLate && status !== 'canceled' && (
                <PDIStatus status="is_late" description="Atrasado" />
              )}
            </div>
          </div>
        </div>
        <div className="action-side"></div>
      </Card>
    </StyledPDIItem>
  );
}

export default PDIItem;
