import React from 'react';

import ModalWrapper from '../modal-wrapper';
import Button from '~/components/button';

import cancelImage from '~/assets/images/Illustration-cancel-evaluation.svg';

import './style.scss';

function CanceledSurveyModal({ onClose }) {
  return (
    <ModalWrapper>
      <div className="message-modal canceled-survey">
        <img src={cancelImage} alt="Cancel survey" />

        <h3 className="title">Avaliação cancelada</h3>
        <p className="description">
          Sua participação será desconsiderada e nenhum relatório será gerado
          para esta avaliação em seus registros.
        </p>

        <div className="actions">
          <Button color="primary" onClick={onClose}>
            Fechar
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default CanceledSurveyModal;
