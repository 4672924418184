import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMyTeam } from '~/api/pdi';

import { showMessage } from '~/store/ducks/messageBar';

import PageHeader from '~/components/page-header';
import EmptyList from '~/components/empty-list';
import InfiniteScroll from '~/components/infinite-scroll';
import CustomLoading from '~/components/custom-loading';
import PDIParticipantItem from '~/components/pdi-participant-item';
import Button from '~/components/button';
import Icon from '~/components/icon';
import SearchInput from '~/components/search-input';
import SelectMultiple from '~/components/select-multiple';
import Breadcrumb from '~/components/breadcrumb';

import { StyledPDIMyTeam } from './styles';

function PDIMyTeam({ organization, history, showMessage }) {
  const { activeOrganizationId } = organization;
  const firstPage = 1;

  const [participants, setParticipants] = useState([]);

  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [search, setSearch] = useState('');
  const [currentTimeOut, setCurrentTimeOut] = useState(null);

  const [breadcrumbLinks] = useState([
    { id: '1', title: 'PDIs', route: '/pdi' },
    { id: '2', title: 'Minha equipe', route: '/pdi/my-team' }
  ]);

  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [profileList, setProfileList] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const getPDIMyTeam = (filters, page) => {
    setLoading(true);
    setCurrentPage(firstPage);

    getMyTeam(activeOrganizationId, filters, page)
      .then(response => {
        const { departments, profiles } = response.data;

        setDepartmentList(
          departments.map(department => ({
            value: department.id,
            label: department.name
          }))
        );
        setProfileList(
          profiles.map(profile => ({
            value: profile.id,
            label: profile.name
          }))
        );

        setParticipants(() => response.data.results);
        setNextPage(response.data.next);
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          history.push('/page-not-found');
          return;
        }

        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadMoreParticipants = () => {
    setLoadingMore(true);

    const filters = {
      search: '',
      departments: selectedDepartments
        .map(department => department.value)
        .toString(),
      profiles: selectedProfiles.map(profile => profile.value).toString()
    };

    getMyTeam(activeOrganizationId, filters, currentPage + 1)
      .then(response => {
        setParticipants(lastParticipantList => [
          ...lastParticipantList,
          ...response.data.results
        ]);

        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      getPDIMyTeam({
        search: value,
        departments: selectedDepartments
          .map(department => department.value)
          .toString(),
        profiles: selectedProfiles.map(profile => profile.value).toString()
      });
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const handleDepartments = selectedDepartmentList => {
    getPDIMyTeam({
      search: search,
      departments: selectedDepartmentList
        .map(department => department.value)
        .toString(),
      profiles: selectedProfiles.map(profile => profile.value).toString()
    });
    setSelectedDepartments(selectedDepartmentList);
  };

  const handleProfiles = selectedProfileList => {
    getPDIMyTeam({
      search: search,
      departments: selectedDepartments
        .map(department => department.value)
        .toString(),
      profiles: selectedProfileList.map(profile => profile.value).toString()
    });
    setSelectedProfiles(selectedProfileList);
  };

  useEffect(() => {
    getPDIMyTeam({
      search: '',
      departments: selectedDepartments
        .map(department => department.value)
        .toString(),
      profiles: selectedProfiles.map(profile => profile.value).toString()
    });
  }, [activeOrganizationId]);

  return (
    <StyledPDIMyTeam>
      <Button
        color="primary"
        className="link"
        onClick={() => history.push('/pdi')}
      >
        <Icon name="back" />
        Voltar
      </Button>

      <PageHeader>
        <div className="text-info">
          <h1>Minha equipe</h1>
          <Breadcrumb links={breadcrumbLinks} compressionStart={10} />
        </div>
        <Button color="primary" onClick={() => history.push('/pdi')}>
          Meu PDI
        </Button>
      </PageHeader>

      <div className="filters">
        <SearchInput
          value={search}
          onChange={handleSearch}
          placeholder="Busque por nome"
        />

        <SelectMultiple
          options={departmentList}
          selected={selectedDepartments}
          setSelected={handleDepartments}
          placeholder="Selecione os departamentos..."
        />

        <SelectMultiple
          options={profileList}
          selected={selectedProfiles}
          setSelected={handleProfiles}
          placeholder="Selecione os cargos..."
        />
      </div>

      {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

      <div className="pdi-participant-list">
        {!loading &&
          participants.map(participant => (
            <PDIParticipantItem
              key={participant.pk}
              avatar={participant.avatar}
              name={participant.name}
              email={participant.email}
              department={participant.department}
              profile={participant.profile}
              onClick={() => history.push(`/pdi/${participant.pk}`)}
            />
          ))}

        {!loading && participants.length === 0 && (
          <EmptyList message="Nenhum participante encontrado." />
        )}
      </div>

      {loadingMore && (
        <CustomLoading type="spin" height={36} width={36} fluid />
      )}

      <InfiniteScroll
        fetchMore={loadMoreParticipants}
        disabled={!nextPage || loading || loadingMore}
      />
    </StyledPDIMyTeam>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PDIMyTeam));
