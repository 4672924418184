import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container } from '../grid';
import { closeMessage } from '~/store/ducks/messageBar';
import { useDispatch } from 'redux-react-hook';
import Timeout from '~/components/timeout';
import './style.scss';

const MessageBar = props => {
  const classes = ['message-bar', props.color];

  if (props.fixed) {
    classes.push('fixed');

    const positions = ['top', 'left', 'right', 'bottom'];

    positions.forEach(pos => {
      if (props[pos]) {
        classes.push(pos);
      }
    });
  }

  const dispatch = useDispatch();

  const onTimeout = useCallback(() => {
    dispatch(closeMessage());
  }, []);

  return (
    <div className={classes.join(' ')} style={props.style}>
      {props.timeoutMilisseconds && (
        <Timeout duration={props.timeoutMilisseconds} onTimeout={onTimeout} />
      )}
      <Container>{props.children}</Container>
    </div>
  );
};

const colors = ['primary', 'secondary', 'danger', 'success', 'light', 'dark'];

MessageBar.defaultProps = {
  style: {},
  fixed: 0
};

MessageBar.propTypes = {
  color: PropTypes.oneOf(colors).isRequired,
  top: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  bottom: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

export default MessageBar;
