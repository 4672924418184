import React from 'react';
import { Collapse } from 'react-collapse';

import Button from '~/components/button';
import LimitedTextarea from '~/components/limited-textarea';

import StyledCourseReactionSurveyQuestion from './styles';

function CourseReactionSurveyQuestion({
  statement,
  type,
  questionData,
  setQuestion,
  disabled
}) {
  const handleChange = (value, isAChild) => {
    const currentQuestion = { ...questionData };

    if (isAChild) {
      setQuestion(() => {
        currentQuestion.answer.value_child = value;
        return currentQuestion;
      });
      return;
    }

    setQuestion(() => {
      currentQuestion.answer.value = value;
      currentQuestion.answer.value_child = '';
      return currentQuestion;
    });
  };

  const getNPSOptions = scoreData => {
    const NPSOptions = [];

    for (let i = scoreData.min_score; i <= scoreData.max_score; i += 1) {
      let description = '';

      if (i === scoreData.min_score) {
        description = scoreData.min_description;
      }

      if (i === scoreData.max_score) {
        description = scoreData.max_description;
      }

      NPSOptions.push({
        pk: i,
        text: i,
        description
      });
    }

    return NPSOptions;
  };

  const questionContentFactory = (question, isAChild) => {
    const currentValue = isAChild
      ? questionData.answer.value_child
      : questionData.answer.value;

    switch (question.type) {
      case 'single_choice':
        return (
          <div className="single-choice-options">
            {question.options.map(option => (
              <Button
                key={option.pk}
                color="primary"
                className={`
                  outline
                  ${currentValue === option.pk ? ' selected' : ''}
                `}
                onClick={() => handleChange(option.pk, isAChild)}
                disabled={disabled}
              >
                <p>{option.text}</p>
              </Button>
            ))}
          </div>
        );
      case 'text':
        return (
          <div className="text-field">
            <LimitedTextarea
              limit={2000}
              value={currentValue || ''}
              onChange={event => handleChange(event.target.value, isAChild)}
              placeholder=""
              disabled={disabled}
            />
          </div>
        );
      case 'NPS':
        const NPSOptions = getNPSOptions(question.options);

        return (
          <div className="nps-options">
            {NPSOptions.map(option => (
              <div className="option-wrapper" key={option.pk}>
                <Button
                  color="primary"
                  className={`
                    outline
                    ${currentValue === option.pk ? ' selected' : ''}
                  `}
                  onClick={() => handleChange(option.pk, isAChild)}
                  disabled={disabled}
                >
                  <p>{option.text}</p>
                </Button>
                <p className="description">{option.description}</p>
              </div>
            ))}
          </div>
        );
      default:
        return <p>Ocorreu um erro ao carregar o conteúdo</p>;
    }
  };

  const getQuestionData = data => {
    const { pk, title, options, answer, type } = data;

    return {
      pk,
      title,
      options,
      answer,
      type
    };
  };

  return (
    <StyledCourseReactionSurveyQuestion>
      <div className="question-header">
        <div className="info">
          <p className="statement">{statement}</p>
        </div>
        <div className="actions"></div>
      </div>
      <div className="question-options">
        {questionContentFactory(questionData)}
      </div>
      {type === 'single_choice' &&
        questionData.options.map(
          option =>
            option.child && (
              <Collapse
                key={option.pk}
                isOpened={questionData.answer.value === option.pk}
                initialStyle={{ height: 0, overflow: 'hidden' }}
              >
                <div className="question-header">
                  <div className="info">
                    <p className="statement">{option.child.title}</p>
                  </div>
                </div>
                <div className="question-options">
                  {questionContentFactory(getQuestionData(option.child), true)}
                </div>
              </Collapse>
            )
        )}
    </StyledCourseReactionSurveyQuestion>
  );
}

export default CourseReactionSurveyQuestion;
