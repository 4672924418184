import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logoff } from '~/services/auth';

import { unregisterAccount } from '~/store/ducks/account';

import { PublicWrapper, PublicHeader, PublicFooter } from '~/components/public';
import Button from '~/components/button';

import StyledUnexpectedError from './styles';

function UnexpectedError({ history }) {
  const logout = () => {
    unregisterAccount();
    logoff();
    history.push('/sign-in');
  };

  return (
    <StyledUnexpectedError>
      <PublicWrapper>
        <PublicHeader />
        <div className="main">
          <div className="content">
            <h3>Algo está errado</h3>
            <p>
              Não se preocupe, já estamos tentando consertar isso. Por favor
              volte em alguns instantes.
            </p>

            <div className="actions">
              <Button color="primary" fill onClick={() => history.push('/')}>
                Voltar para a página inicial
              </Button>
              <Button
                color="outline"
                className="secondary"
                fill
                onClick={logout}
              >
                Sair
              </Button>
            </div>
          </div>
        </div>
        <PublicFooter />
      </PublicWrapper>
    </StyledUnexpectedError>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ unregisterAccount }, dispatch);

export default withRouter(connect(mapDispatchToProps)(UnexpectedError));
