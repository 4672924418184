import React from 'react';

import Button from '~/components/button';

import { StyledSectionList } from './styles';

function SectionList({
  title,
  description,
  viewAllLabel,
  viewAllAction,
  className,
  children
}) {
  return (
    <StyledSectionList className={className}>
      <div className="section-header">
        <div>
          <h3>{title}</h3>
          <Button color="white" className="link" onClick={viewAllAction}>
            {viewAllLabel || 'Ver todos'}
          </Button>
        </div>
        <p>{description}</p>
      </div>
      <div className="section-content">{children}</div>
    </StyledSectionList>
  );
}

export default SectionList;
