import React from 'react';
import { connect } from 'react-redux';

import { closeDefaultModal } from '~/store/ducks/default-modal';

import Button from '~/components/button';

import { StyledConfirmModal } from './styles';

function ConfirmModal({
  title,
  description,
  onConfirm,
  confirmButtonText,
  closeDefaultModal
}) {
  return (
    <StyledConfirmModal>
      <h3 className="title">{title}</h3>
      <p className="subtitle">{description}</p>

      <div className="content-wrapper">
        <Button
          color="primary"
          className="outline"
          onClick={() => closeDefaultModal()}
        >
          Cancelar
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {confirmButtonText || 'Confirmar'}
        </Button>
      </div>
    </StyledConfirmModal>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  }
});

export default connect(null, mapDispatchToProps)(ConfirmModal);
