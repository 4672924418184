import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Row = props => {
  const classes = ['row'];
  const options = ['reverse', 'center'];

  options.forEach(opt => {
    if (props[opt]) classes.push(opt);
  });

  return <div className={classes.join(' ')}>{props.children}</div>;
};

Row.defaultProps = {
  reverse: false,
  center: false
};

Row.propTypes = {
  reverse: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node
};

export default Row;
