import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import './style.scss';

const PostComment = ({ children, className, onClick }) => (
  <div
    className={`post-comment ${className} ${onClick && 'cursor-pointer'}`}
    onClick={onClick}
  >
    <Icon name="comment" color="primary" />
    <span>{children}</span>
  </div>
);

PostComment.propTypes = {
  children: PropTypes.node.isRequired
};

export default PostComment;
