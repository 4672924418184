import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import SparkTable from '~/components/tables/spark-table';
import RankingHeader from './ranking-header';
import RankingReward from './ranking-reward';
import Button from '~/components/button';
import ContentCardError from '~/components/content-card-error';

import {
  getRankings,
  getRanking,
  getRankingAccounts,
  getRankingAccountsRewards
} from '~/api/account';
import api from '~/api/api';

import './style.scss';
import './ranking.scss';

const RankingScreen = ({ match, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [rankingReward, setRankingReward] = useState(null);
  const [ranking, setRanking] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [rankingFromList, setRankingFromList] = useState(null);
  const [hasError, setHasError] = useState(false);

  const [pagination, setPagination] = useState({
    numPages: 0,
    next: null,
    previous: null,
    currentPage: 1,
    pageSize: 10
  });

  const handleExit = () => {
    props.history.push('/rankings');
  };

  const loadMore = useCallback(
    async nextAccountUrl => {
      setLoading(true);
      try {
        const {
          data: { results, next, previous, num_pages, current_page, page_size }
        } = await api.get(nextAccountUrl);

        setAccounts(results);

        setPagination({
          numPages: num_pages,
          next: next,
          previous: previous,
          currentPage: current_page,
          pageSize: page_size
        });
      } catch (e) {
        console.error(e);
      }

      setLoading(false);
    },
    [accounts]
  );

  useEffect(() => {
    // não podemos retornar uma função async/promise para o use effect
    (async function() {
      setLoading(true);
      try {
        const [
          rankingsResponse,
          rankingResponse,
          accountsResponse,
          rewardsResponse
        ] = await Promise.all([
          getRankings(),
          getRanking(match.params.id),
          getRankingAccounts(match.params.id),
          getRankingAccountsRewards(match.params.id)
        ]);

        const rankingFromList = _.get(rankingsResponse, 'data', []).find(
          r => r.ranking.pk.toString() === match.params.id
        );

        setRanking(rankingResponse.data);
        setAccounts(accountsResponse.data.results);
        setRankingFromList(rankingFromList);
        setRankingReward(rewardsResponse.data);

        setPagination({
          numPages: accountsResponse.data.num_pages,
          next: accountsResponse.data.next,
          previous: accountsResponse.data.previous,
          currentPage: accountsResponse.data.current_page,
          pageSize: accountsResponse.data.page_size
        });
      } catch (e) {
        setHasError(true);
      }

      setLoading(false);
    })();
  }, []);

  const buildUrlTopage = page => {
    return `/rankings/${ranking.pk}/accounts?page=${page}`;
  };

  const scrollTo = position => {
    const element = document.getElementById(`scroll-position-${position}`);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  const handleScrollToPosition = () => {
    try {
      const { pageSize } = pagination;
      const { position } = rankingFromList;
      const page = Math.ceil(position / pageSize);

      if (page === pagination.currentPage) {
        scrollTo(position);
      } else {
        loadMore(buildUrlTopage(page));

        setTimeout(() => {
          scrollTo(position);
        }, 1000);
      }
    } catch {
      console.log('Implementar erro!!');
    }
  };

  const renderRanking = () => {
    return (
      <>
        <Button
          className="back-to-list ranking-btn link"
          color="primary"
          onClick={() => handleExit()}
        >
          <i className="icon back"></i>
          Voltar
        </Button>

        <RankingHeader
          loading={loading}
          accounts={accounts}
          ranking={ranking}
          rankingFromList={rankingFromList}
        />

        {ranking && ranking.rankingreward_set.length > 0 && (
          <RankingReward
            ranking={ranking}
            rewards={ranking.rankingreward_set}
            winnerRewards={rankingReward}
          />
        )}

        <SparkTable
          title="Placar"
          data={accounts}
          selectedLine={rankingFromList ? rankingFromList.position : null}
          loading={loading}
          //* Pagination
          showPaginationOnTop={true}
          pagination={pagination}
          //* Functions
          onNext={() => loadMore(pagination.next)}
          onPrevious={() => loadMore(pagination.previous)}
          onPageClick={page => {
            loadMore(buildUrlTopage(page));
          }}
          scrollToPosition={() => handleScrollToPosition()}
        />
      </>
    );
  };

  return <>{hasError ? <ContentCardError /> : renderRanking()}</>;
};

export default withRouter(RankingScreen);
