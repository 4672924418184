import axios from 'axios';

import { createS3Url } from '~/api/upload';

export class FileUpload {
  constructor() {
    this.cancelTokenSource = null;
    this.progressSum = 0;
    this.totalFiles = 1;
    this.progress = 0;
    this.uploaded = false;
    this.canceled = false;
  }

  customError(name, message, error) {
    const customError = new Error();
    customError.name = name;
    customError.message = message;
    customError.error = error;
    return customError;
  }

  isADir(fileList) {
    const files = [...fileList];
    return files.some(file => file.webkitRelativePath !== '');
  }

  handleProgress(event, callback) {
    this.progress = Math.floor((event.loaded / event.total) * 100);

    if (callback) {
      callback((this.progressSum + this.progress) / this.totalFiles);
    }

    if (event.loaded === event.total) {
      this.progressSum += this.progress;
    }
  }

  cancel() {
    if (this.cancelTokenSource) {
      this.canceled = true;
      this.progress = 0;
      this.progressSum = 0;
      this.cancelTokenSource.cancel();
    }
  }

  uploadFile(url, fields, progressCallback, isDir) {
    const data = new FormData();
    data.append('key', fields.key);
    data.append('AWSAccessKeyId', fields.AWSAccessKeyId);
    data.append('policy', fields.policy);
    data.append('signature', fields.signature);
    data.append('file', fields.file);

    if (!isDir) {
      this.progress = 0;
      this.progressSum = 0;
      this.uploaded = false;
      this.canceled = false;
    }

    return new Promise((resolve, reject) => {
      this.cancelTokenSource = axios.CancelToken.source();

      axios
        .post(url, data, {
          onUploadProgress: event => {
            this.handleProgress(event, progressCallback);
          },
          cancelToken: this.cancelTokenSource.token
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          if (this.canceled) {
            reject(
              this.customError('upload-canceled', 'O upload foi cancelado', {
                status: 400,
                error
              })
            );
            return;
          }

          reject(
            this.customError(
              'upload-failed',
              `Ocorreu um erro ao enviar o arquivo ${fields.file.name}`,
              {
                status: error.response.status,
                error
              }
            )
          );
        });
    });
  }

  handleCreateS3Url(data, isDir) {
    const { organization, module, file } = data;

    return new Promise((resolve, reject) => {
      createS3Url(organization, {
        module,
        name: isDir ? '' : file.name,
        is_dir: isDir
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(
            this.customError(
              'create-s3-url-failed',
              'Ocorreu um erro ao criar o caminho do arquivo no s3',
              {
                status: error.response.status,
                error
              }
            )
          );
        });
    });
  }

  sendFile(data, progressCallback, resolve, reject) {
    this.handleCreateS3Url(data, false)
      .then(response => {
        const { url, fields, pk } = response;

        fields.file = data.file;

        this.uploadFile(url, fields, progressCallback)
          .then(() => {
            this.uploaded = true;
            this.cancelTokenSource = null;

            resolve({
              totalFiles: this.totalFiles,
              progress: this.progress,
              uploaded: this.uploaded,
              canceled: this.canceled,
              moduleId: pk,
              fileUrl: `${url}/${fields.key}`
            });
          })
          .catch(error => {
            reject(error);
          });
      })
      .catch(error => {
        reject(error);
      });
  }

  uploadAllFiles(fileList, currentFileIndex, url, fields, progressCallback) {
    return new Promise((resolve, reject) => {
      const currentFile = fileList[currentFileIndex];
      const filePath = currentFile.webkitRelativePath.substring(
        currentFile.webkitRelativePath.indexOf('/') + 1
      );
      const currentFields = { ...fields };

      currentFields.file = currentFile;
      currentFields.key = currentFields.key.replace(
        '#{filename}'.replace('#', '$'),
        filePath
      );

      this.uploadFile(url, currentFields, progressCallback, true)
        .then(() => {
          if (currentFileIndex + 1 < fileList.length) {
            resolve(
              this.uploadAllFiles(
                fileList,
                currentFileIndex + 1,
                url,
                fields,
                progressCallback
              )
            );
          } else {
            resolve();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  sendDir(data, progressCallback, resolve, reject) {
    this.handleCreateS3Url(data, true)
      .then(response => {
        const files = [...data.fileList];
        const { url, fields, pk, path } = response;

        this.totalFiles = files.length;
        this.progress = 0;
        this.progressSum = 0;
        this.uploaded = false;
        this.canceled = false;

        this.uploadAllFiles(files, 0, url, fields, progressCallback)
          .then(() => {
            this.uploaded = true;
            this.cancelTokenSource = null;

            resolve({
              totalFiles: this.totalFiles,
              progress: this.progress,
              uploaded: this.uploaded,
              canceled: this.canceled,
              moduleId: pk,
              fileUrl: `${url}/${path}`
            });
          })
          .catch(error => {
            reject(error);
          });
      })
      .catch(error => {
        reject(error);
      });
  }

  send(uploadData, module, organization, progressCallback) {
    const data = {
      module,
      organization
    };

    return new Promise((resolve, reject) => {
      if (!uploadData.length) {
        data.file = uploadData;
        this.sendFile(data, progressCallback, resolve, reject);
        return;
      }

      if (this.isADir(uploadData)) {
        data.fileList = uploadData;
        this.sendDir(data, progressCallback, resolve, reject);
      } else {
        reject(
          this.customError(
            'no-common-directory',
            'Os arquivos carregados devem pertencer a um diretório raiz comum'
          )
        );
      }
    });
  }
}

export default FileUpload;
