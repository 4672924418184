import _ from 'lodash';

import { Types as FeedTypes } from './feed';
import { Types as ActionsTypes } from './actions';

// por enquanto estamos lidando com post
// vou chamar os métodos direto
//
// caso apareça likes em actions por ex, dai podemos utilizar um factory da vida
import {
  like as apiLike,
  unlike as apiUnlike,
  removeLike as apiRemoveLike,
  removeUnlike as apiRemoveUnlike
} from '~/api/post';

const Types = {
  LIKE: 'likes/LIKE',
  UNLIKE: 'likes/UNLIKE',
  REMOVE_LIKE: 'likes/REMOVE_LIKE',
  REMOVE_UNLIKE: 'likes/REMOVE_UNLIKE'
};

const defaultState = {};
const defaultLike = {
  currentUserValue: null,
  likeCounter: null,
  unlikeCounter: null
};

export const getKey = (type, id) => `${type}-${id}`;

const getKeyFromMeta = ({ type, id }) => getKey(type, id);

const getLikeFromMeta = (state, meta) => state[getKeyFromMeta(meta)];

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case `${Types.LIKE}_FULFILLED`: {
      const like = {
        ...getLikeFromMeta(state, action.meta)
      };

      like.likeCounter += 1;

      // se antes o usuário deu unlike
      // e agora ele deu like
      if (like.currentUserValue === false) {
        like.unlikeCounter -= 1;
      }

      like.currentUserValue = true;

      return {
        ...state,
        [getKeyFromMeta(action.meta)]: like
      };
    }

    case `${Types.UNLIKE}_FULFILLED`: {
      const like = {
        ...getLikeFromMeta(state, action.meta)
      };

      like.unlikeCounter += 1;

      // se antes o usuário deu like
      // e agora ele deu unlike
      if (like.currentUserValue === true) {
        like.likeCounter -= 1;
      }

      like.currentUserValue = false;

      return {
        ...state,
        [getKeyFromMeta(action.meta)]: like
      };
    }

    case `${Types.REMOVE_LIKE}_FULFILLED`: {
      const like = {
        ...getLikeFromMeta(state, action.meta)
      };

      like.likeCounter -= 1;
      like.currentUserValue = null;

      return {
        ...state,
        [getKeyFromMeta(action.meta)]: like
      };
    }

    case `${Types.REMOVE_UNLIKE}_FULFILLED`: {
      const like = {
        ...getLikeFromMeta(state, action.meta)
      };

      like.unlikeCounter -= 1;
      like.currentUserValue = null;

      return {
        ...state,
        [getKeyFromMeta(action.meta)]: like
      };
    }

    case `${ActionsTypes.POST_LOADED}`: {
      const {
        payload: { id, like_counter, unlike_counter, me_like }
      } = action;

      return {
        ...state,
        [getKey('post', id)]: {
          ...defaultLike,
          likeCounter: like_counter,
          unlikeCounter: unlike_counter,
          currentUserValue: me_like
        }
      };
    }

    case `${FeedTypes.LOAD}_FULFILLED`:
    case `${FeedTypes.LOAD_MORE}_FULFILLED`: {
      const {
        payload: {
          data: { results }
        }
      } = action;

      const likes = results
        .filter(({ object: { can_like } }) => can_like)
        .map(
          ({ type, object: { id, like_counter, unlike_counter, me_like } }) => [
            getKey(type, id),
            {
              ...defaultLike,
              likeCounter: like_counter,
              unlikeCounter: unlike_counter,
              currentUserValue: me_like
            }
          ]
        );

      return {
        ...state,
        ..._.fromPairs(likes)
      };
    }

    default:
      return state;
  }
}

export const like = (type, id) => ({
  type: Types.LIKE,
  payload: apiLike(id),
  meta: {
    type,
    id
  }
});

export const unlike = (type, id) => ({
  type: Types.UNLIKE,
  payload: apiUnlike(id),
  meta: {
    type,
    id
  }
});

export const removeLike = (type, id) => ({
  type: Types.REMOVE_LIKE,
  payload: apiRemoveLike(id),
  meta: {
    type,
    id
  }
});

export const removeUnlike = (type, id) => ({
  type: Types.REMOVE_UNLIKE,
  payload: apiRemoveUnlike(id),
  meta: {
    type,
    id
  }
});
