import React from 'react';

import CustomString from './custom-string';
import CustomChoices from './custom-choices';
import CustomBoolean from './custom-boolean';
import CustomDecimal from './custom-decimal';
import CustomLongString from './custom-long-string';

const customFieldsFactory = (
  id,
  label,
  options,
  answer,
  type,
  readOnly,
  data,
  setData,
  error
) => {
  switch (type) {
    case 'short_text':
      return (
        <CustomString
          id={id}
          label={label}
          answer={answer}
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorMessage={error}
          placeholder="Digite sua resposta"
        />
      );
    case 'text':
      return (
        <CustomLongString
          id={id}
          label={label}
          answer={answer}
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorMessage={error}
          placeholder="Digite sua resposta"
          rows={4}
        />
      );
    case 'choice':
      return (
        <CustomChoices
          id={id}
          label={label}
          answer={answer}
          options={options}
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorMessage={error}
        />
      );
    case 'boolean':
      return (
        <CustomBoolean
          id={id}
          label={label}
          answer={answer}
          options={options}
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorMessage={error}
        />
      );
    case 'decimal':
      return (
        <CustomDecimal
          id={id}
          label={label}
          answer={answer}
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorMessage={error}
          placeholder="Digite o número"
          maxLength={15}
        />
      );
    default:
      return (
        <p style={{ margin: '24px 0px' }}>Ocorreu um erro ao exibir o campo</p>
      );
  }
};

export default customFieldsFactory;
