import styled from 'styled-components';

export const StyledCommentField = styled.div.attrs(() => ({
  className: 'comment-field'
}))`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  textarea {
    width: 100%;
    padding: 8px;
    border: none;
    outline: none;
    resize: none;

    &::placeholder {
      color: var(--secondary-text-color);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: var(--secondary-text-color);
    }

    &::-ms-input-placeholder {
      color: var(--secondary-text-color);
    }
  }

  button {
    min-width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 18px;
      margin: 0px;
    }
  }

  button:hover {
    text-decoration: none;
  }
`;
