import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCourses } from '~/api/training';

import { showMessage } from '~/store/ducks/messageBar';

import SearchInput from '~/components/form/search-input';
import CustomLoading from '~/components/custom-loading';
import EmptyList from '~/components/empty-list';
import InfiniteScroll from '~/components/infinite-scroll';
import Item from './item';

import { StyledViewAllCoursesModal } from './styles';

function ViewAllCoursesModal({
  title,
  orgId,
  categoryId,
  onlyCanSubscribe,
  showMessage
}) {
  const firstPage = 1;

  const listWrapperRef = useRef(null);
  const listRef = useRef(null);

  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState('');
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [count, setCount] = useState(0);
  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const getCourseList = (filters, page) => {
    setLoading(true);
    setCurrentPage(firstPage);

    getCourses(orgId, filters, page)
      .then(response => {
        setCount(response.data.count);
        setCourses(() => response.data.results);
        setNextPage(response.data.next);
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      getCourseList({
        name: value,
        only_can_subscribe: onlyCanSubscribe || 0,
        category: categoryId
      });
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const loadMoreCourses = () => {
    setLoadingMore(true);

    const filters = {
      name: search,
      only_can_subscribe: onlyCanSubscribe || 0,
      category: categoryId
    };

    getCourses(orgId, filters, currentPage + 1)
      .then(response => {
        setCourses(lastCourseList => [
          ...lastCourseList,
          ...response.data.results
        ]);
        setNextPage(response.data.next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    getCourseList({
      name: search,
      only_can_subscribe: onlyCanSubscribe || 0,
      category: categoryId
    });
  }, []);

  return (
    <StyledViewAllCoursesModal>
      <h3 className="title">{title}</h3>
      {count !== undefined && (
        <p className="counter">
          <span>{count}</span> ite{count !== 1 ? 'ns' : 'm'}
        </p>
      )}

      <div className="content-wrapper">
        <SearchInput
          placeholder="Buscar por nome"
          value={search}
          onChange={handleSearch}
        />

        {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

        <div className="item-list-wrapper default-scroll" ref={listWrapperRef}>
          <div className="item-list" ref={listRef}>
            {!loading &&
              courses.map(item => (
                <Item
                  key={item.pk}
                  title={item.title}
                  image={item.cover}
                  startAt={item.start_at}
                  finishAt={item.finish_at}
                  itemUrl={`/training/course/${item.pk}`}
                />
              ))}

            {!loading && courses.length === 0 && (
              <EmptyList message="Nenhum item encontrado." />
            )}
          </div>
        </div>

        {loadingMore && (
          <CustomLoading type="spin" height={36} width={36} fluid />
        )}

        <InfiniteScroll
          fetchMore={loadMoreCourses}
          disabled={!nextPage || loading || loadingMore}
          scrollElement={listWrapperRef.current}
        />
      </div>
    </StyledViewAllCoursesModal>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(withRouter(ViewAllCoursesModal));
