import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Login as FacebookLogin } from 'react-facebook-sdk';
import PropTypes from 'prop-types';
import Button from '../../button';
import Icon from '../../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
import { postFacebook } from '~/api/account';
import { showNewPoints } from '~/services/tasks';

class Facebook extends PureComponent {
  static defaultProps = {
    onFinish: () => {}
  };

  static propTypes = {
    onFinish: PropTypes.func,
    pointsToShow: PropTypes.number
  };

  state = {
    loading: false
  };

  render() {
    const { onFinish } = this.props;
    const { loading } = this.state;

    return (
      <FacebookLogin
        onResponse={({ tokenDetail: { accessToken } }) => {
          postFacebook(accessToken)
            .then(() => {
              if (this.props.pointsToShow) {
                showNewPoints(this.props.pointsToShow);
              }
            })
            .then(() => onFinish())
            .catch(err => {
              console.warn(err);
              this.setState({ loading: false });
              alert('Erro!');
            });
        }}
        onError={(...args) => {
          this.setState({ loading: false });
          alert('Erro ao autenticar com Facebook');
        }}
        render={({ onClick }) => (
          <Button
            color="facebook"
            onClick={(...args) => {
              this.setState({ loading: true });
              onClick(...args);
            }}
            disabled={loading}
          >
            <Icon name="facebook" marginRight />
            <span>Conectar</span>
          </Button>
        )}
      />
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(Facebook);
