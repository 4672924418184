import React, { useEffect, useState } from 'react';
import SearchArea from './search-area';
// import ExternalLink from '~/components/external-link';
import useSite from '~/hooks/use-site';
import StoreLinks from '~/components/store-links';

export const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const scroll = () => {
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;

      setScrollTop(scrollTop);
    };

    document.addEventListener('scroll', scroll);
    scroll();

    return () => {
      document.removeEventListener('scroll', scroll);
    };
  }, []);

  return scrollTop;
};

const SearchAside = () => {
  // const scrollTop = useScrollTop();
  const { name } = useSite();

  return (
    <aside className="aside search-aside">
      <SearchArea />
      {name !== 'USEFLOW' && name !== 'Elle' && (
        <StoreLinks>
          <p>
            Através do nosso app você também pode interagir com nossa
            plataforma.
          </p>
        </StoreLinks>
      )}
    </aside>
  );
};

export default SearchAside;
