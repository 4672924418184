import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { showMessage } from '~/store/ducks/messageBar';

import downloadFile from '~/services/download';

import Button from '../button';
import Icon from '../icon';

import { StyledFilePreview } from './styles';

function FilePreview({ fileName, fileUrl, onClick, showMessage }) {
  const [loading, setLoading] = useState(false);

  let buttonName = 'Baixar';
  const _validFileExtensionsOpen = ['.pdf'];
  const re = /(?:\.([^.]+))?$/;
  const isFileOpen = _validFileExtensionsOpen.indexOf(re.exec(fileUrl)[0]) > -1;

  if (isFileOpen) {
    buttonName = 'Abrir';
  }

  const showFileStepCourse = () => {
    switch (re.exec(fileUrl)[0]) {
      case '.pdf':
        setLoading(true);
        return downloadFile(fileUrl)
          .then(response => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' })
            );
            const win = window.open(url, '_blank');
            win.focus();
          })
          .catch(e => {
            showMessage('Ocorreu um erro ao abrir', 'danger', 3000);
          })
          .finally(() => {
            setLoading(false);
            if (onClick) {
              onClick({
                name: fileName,
                url: fileUrl,
                ignoreDownload: true
              });
            }
          });
      default:
    }
  };

  const handleDownload = () => {
    if (onClick) {
      onClick({
        name: fileName,
        url: fileUrl
      });
      return;
    }

    setLoading(true);
    downloadFile(fileUrl)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(e => {
        showMessage('Ocorreu um erro ao fazer o download', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <StyledFilePreview>
      <div className="info-side">
        <div className="preview">
          <Icon name="file" />
        </div>
        <p>{fileName}</p>
      </div>
      <div className="actions-side">
        <Button
          color="primary"
          className="link"
          disabled={loading}
          onClick={isFileOpen ? showFileStepCourse : handleDownload}
        >
          {loading
            ? isFileOpen
              ? 'Abrindo...'
              : 'Fazendo download...'
            : buttonName}
        </Button>
      </div>
    </StyledFilePreview>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(FilePreview);
