import React from 'react';

import QRCodeContainer from 'react-qr-code';

import { StyledQRCode } from './styles';

function QRCode({ title, url }) {
  return (
    <StyledQRCode>
      <div className="hide-on-mobile qr-code-container">
        <QRCodeContainer title={title} value={url} />
      </div>
    </StyledQRCode>
  );
}

export default QRCode;
