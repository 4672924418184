export const Types = {
  OPEN: 'new-default-modal/OPEN',
  CLOSE: 'new-default-modal/CLOSE'
};

const defaultState = {
  open: false,
  content: '',
  maxWidth: '598px',
  onClose: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.OPEN: {
      return {
        open: true,
        content: action.content,
        maxWidth: action.maxWidth,
        onClose: action.onClose
      };
    }
    case Types.CLOSE:
      return defaultState;
    default:
      return state;
  }
}

export const openNewDefaultModal = (content, maxWidth = '598px', onClose) => ({
  type: Types.OPEN,
  content,
  maxWidth,
  onClose
});

export const closeNewDefaultModal = () => ({
  type: Types.CLOSE
});
