import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import Button from '../button';
import { closeMessageModal } from '~/store/ducks/message-modal';
import './style.scss';

ReactModal.setAppElement('#root');

const MessageModal = ({ isOpen, closeMessageModal, children }) => (
  <ReactModal
    className="message-modal"
    overlayClassName="message-modal-overlay"
    isOpen={isOpen}
    parentSelector={() => document.getElementById('modal-root')}
    onRequestClose={closeMessageModal}
  >
    <div>{children}</div>
    <div className="text-center pv20">
      <Button
        className="message-modal-ok"
        onClick={closeMessageModal}
        color="primary"
        fill
      >
        Ok
      </Button>
    </div>
  </ReactModal>
);

MessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeMessageModal: (...args) => {
    ownProps.onClose && ownProps.onClose(...args);
    dispatch(closeMessageModal(...args));
  }
});

export default connect(null, mapDispatchToProps)(MessageModal);
