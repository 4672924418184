import React, { useCallback, useState } from 'react';
import Icon from '~/components/icon';
import Button from '~/components/button';
import AccountMenu from './account-menu';
import { Login as FacebookLogin } from 'react-facebook-sdk';
import Loading from '~/components/loading';
import {
  postFacebook,
  deleteFacebook,
  deleteTwitter,
  deleteInstagram,
  deleteLinkedin
} from '~/api/account';
import { useDispatch } from 'redux-react-hook';
import useExternalAccounts from '~/hooks/use-external-accounts';
import useSite from '~/hooks/use-site';
import { BASE_ENDPOINT } from '~/settings';
import { showMessage } from '~/store/ducks/messageBar';
import {
  externalAccountConnected,
  externalAccountDisconnected
} from '~/store/ducks/account';
import './social-networks.scss';

const useConnectedSocial = socialNetwork => {
  const externalAccounts = useExternalAccounts(socialNetwork.toLowerCase());
  return externalAccounts.length > 0;
};

const RedirectSocialConnectOrDisconnect = ({
  socialNetwork,
  unAuthFunc,
  children
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const connected = useConnectedSocial(socialNetwork);
  const dispatch = useDispatch();

  const onClickAuth = useCallback(() => {
    if (loading) {
      return;
    }

    setLoading(true);
    const callbackUrl = window.location.href;
    const url = `${BASE_ENDPOINT}/accounts/me/oauth${socialNetwork}/?callback_url=${callbackUrl}`;
    window.location.href = url;
  }, [loading]);

  const onClickUnauth = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await unAuthFunc();
      dispatch(externalAccountDisconnected(socialNetwork));
      dispatch(showMessage('Removido com sucesso', 'secondary'));
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  }, [loading]);

  const onClick = connected ? onClickUnauth : onClickAuth;

  return children({
    onClick,
    connected,
    loading,
    error
  });
};

const TwitterRedirectSocialConnectOrDisconnect = props => {
  return (
    <RedirectSocialConnectOrDisconnect {...props} unAuthFunc={deleteTwitter} />
  );
};

const InstagramRedirectSocialConnectOrDisconnect = props => {
  return (
    <RedirectSocialConnectOrDisconnect
      {...props}
      unAuthFunc={deleteInstagram}
    />
  );
};

const LinkedinRedirectSocialConnectOrDisconnect = props => {
  return (
    <RedirectSocialConnectOrDisconnect {...props} unAuthFunc={deleteLinkedin} />
  );
};

const SocialConnectOrDisconnectFacebook = ({ socialNetwork, children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const connected = useConnectedSocial(socialNetwork);
  const dispatch = useDispatch();

  const onClickUnauth = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await deleteFacebook();
      dispatch(externalAccountDisconnected(socialNetwork));
      dispatch(showMessage('Removido com sucesso', 'secondary'));
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  }, [loading]);

  if (connected) {
    return children({
      onClick: onClickUnauth,
      connected,
      loading,
      error
    });
  } else {
    return (
      <FacebookLogin
        onResponse={async ({ tokenDetail: { accessToken } }) => {
          try {
            const { data } = await postFacebook(accessToken);
            dispatch(externalAccountConnected(data));
            dispatch(
              showMessage('Facebook conectado com sucesso', 'secondary')
            );
          } catch (e) {
            setError(e);
          }

          setLoading(false);
        }}
        onError={(...args) => {
          setError(args[0]);
        }}
        render={({ onClick }) => {
          return children({
            onClick: (...args) => {
              if (loading) {
                return;
              }

              setLoading(true);
              onClick(...args);
            },
            connected,
            loading,
            error
          });
        }}
      />
    );
  }
};

class SocialConnectOrDisconnectFactory {
  static get(socialNetwork) {
    switch (socialNetwork) {
      case 'facebook': {
        return SocialConnectOrDisconnectFacebook;
      }

      case 'twitter': {
        return TwitterRedirectSocialConnectOrDisconnect;
      }

      case 'instagram': {
        return InstagramRedirectSocialConnectOrDisconnect;
      }

      case 'linkedin': {
        return LinkedinRedirectSocialConnectOrDisconnect;
      }

      default: {
        throw new Error(`Dont know what to do with ${socialNetwork}`);
      }
    }
  }
}

const socialNetworks = ['Facebook', 'Instagram', 'Twitter', 'Linkedin'];

const SocialNewtorkRow = ({ socialNetwork }) => {
  const SocialConnectOrDisconnect = SocialConnectOrDisconnectFactory.get(
    socialNetwork.toLowerCase()
  );

  return (
    <div className="network-row">
      <div>
        <span className={`rounded-icon ${socialNetwork.toLowerCase()}`}>
          <Icon name={socialNetwork.toLowerCase()} />
        </span>

        {socialNetwork}
      </div>

      <div>
        <SocialConnectOrDisconnect socialNetwork={socialNetwork.toLowerCase()}>
          {({ onClick, connected, loading, error }) => (
            <>
              <Button
                color="primary"
                outline={connected}
                onClick={onClick}
                disabled={loading}
              >
                {connected ? 'Desconectar' : 'Conectar'}
              </Button>
              {error && (
                <span className="error">Houve um erro ao tentar conectar.</span>
              )}
            </>
          )}
        </SocialConnectOrDisconnect>
      </div>
    </div>
  );
};

const SocialNetworksScreen = () => {
  const { name } = useSite();

  return (
    <>
      <Loading visible={false} />
      <div className="account-screen">
        <AccountMenu />
        <div className="section-wrapper">
          <h2>Redes Conectadas</h2>
          <p>
            Gerencie quais redes sociais você deseja vincular à sua conta {name}{' '}
            para realizar ações e pontuar.
          </p>
        </div>

        <div className="section-wrapper">
          {socialNetworks.map(socialNetwork => (
            <SocialNewtorkRow
              key={socialNetwork}
              socialNetwork={socialNetwork}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SocialNetworksScreen;
