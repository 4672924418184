import styled from 'styled-components';

export const StyledCourseCategoryCard = styled.button.attrs(() => ({
  className: 'course-category-card'
}))`
  position: relative;
  padding: 32px 16px;
  width: 187px;
  height: 105px;
  border: none;
  outline: none;
  border-radius: 4px;
  background: var(--primary-color);

  background-image: url(${({ categoryImage }) => categoryImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: rgba(
      0,
      0,
      0,
      ${({ showTitle, categoryImage }) =>
        categoryImage ? (showTitle ? '0.25' : '0.05') : '0.05'}
    );
  }

  .card-content {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3 {
    text-align: center;
    max-width: 200px;
    font-size: 19px;
    font-weight: normal;
    line-height: 1;
    color: var(--white-color);
    padding: 0px 8px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:hover {
    cursor: pointer;
  }
`;
