import React from 'react';

import ModalWrapper from '../modal-wrapper';
import Button from '~/components/button';

import starIcon from '~/assets/images/star-small.svg';
import completedImage from '~/assets/images/Illustration-evaluation-completed.svg';

import './style.scss';

function CompletedSurveyModal({ onClose, points }) {
  return (
    <ModalWrapper>
      <div className="message-modal completed-survey">
        <img src={completedImage} alt="Evaluation completed" />

        <h3 className="title">Obrigado por participar!</h3>
        <p className="description">
          Sua opinião é essencial para construirmos um ambiente de trabalho cada
          vez melhor.
          <span> Lembrando que todas as informações são anônimas.</span>
        </p>

        {points && (
          <div className="pontuation">
            <h3 className="points-label">Você ganhou</h3>
            <div className="points">
              <img src={starIcon} alt="Star" />
              <p className="value">{points} Pontos</p>
            </div>
          </div>
        )}

        <div className="actions">
          <Button color="primary" onClick={onClose}>
            Fechar
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default CompletedSurveyModal;
