import moment from 'moment';

export const setStoreUserAccess = data => {
  localStorage.setItem(`@${data.id}UserAccessLog`, JSON.stringify(data));
};

export const removeUserAccess = id => {
  localStorage.removeItem(`@${id}UserAccessLog`);
};

export const getStoragedUserAccess = id => {
  const userAccess = localStorage.getItem(`@${id}UserAccessLog`);

  return JSON.parse(userAccess);
};

const checkLastAccess = date => {
  const lastDate = moment(date);
  const currentDate = moment().format('YYYY-MM-DD');

  return lastDate.isBefore(currentDate);
};

export const checkUserAccess = data => {
  const lastAccess = getStoragedUserAccess(data.id);
  const date = lastAccess ? lastAccess.date : null;
  const isValid = checkLastAccess(date);

  return !lastAccess || isValid;
};

export const storeUserAccess = data => {
  const info = {
    id: data.id,
    email: data.email,
    date: moment().format('YYYY-MM-DD')
  };

  setStoreUserAccess(info);
};
