import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Col = props => {
  const classes = ['col'];

  if (props.md) {
    classes.push(`col-${props.md}`);
  }

  if (props.className) {
    classes.push(props.className);
  }

  return (
    <div className={classes.join(' ')} style={props.style}>
      {props.children}
    </div>
  );
};

const sizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

Col.defaultProps = {
  style: {}
};

Col.propTypes = {
  md: PropTypes.oneOf(sizes),
  style: PropTypes.object,
  children: PropTypes.node
};

export default Col;
