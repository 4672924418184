import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import _ from 'lodash';
import Card from '~/components/card';
import Icon from '~/components/icon';
import Button from '~/components/button';
import { Input } from '~/components/form';
import { WololoRewardLine } from '~/components/rewards';
import { TaskHeader, TaskBody } from '~/components/task-helpers';
import { CommentBoxAndButtons, TaskCommentBox } from '~/components/comments';

import { addWololo } from '~/services/tasks';

class InvitationTask extends PureComponent {
  state = {
    copied: false
  };

  handleSuccessMessage = () => {
    this.setState({ copied: true });
  };

  answer = message => {
    const { id } = this.props;

    this.setState({ loading: true });

    addWololo(id, {
      message
    })
      .then(() => this.props.onFinish())
      .catch(() => this.setState({ loading: false }));
  };

  render() {
    const activeOrganizationIndex = this.props.account.data.organization_is_active_set.findIndex(
      org => {
        return org.id === this.props.organization.activeOrganizationId;
      }
    );

    const url = _.get(
      this.props,
      `account.data.organization_is_active_set[${
        activeOrganizationIndex !== -1 ? activeOrganizationIndex : 0
      }].invitation_url`
    );

    return (
      <Card>
        <TaskHeader {...this.props} wololo_points="0" icon_type="post" />
        <TaskBody {...this.props} />
        {!this.props.me_create_wololo && (
          <Form
            initialValues={{
              message: ''
            }}
            render={({ handleSubmit }) => (
              <div className="check-task-wrapper">
                {/* <Field
                  name="message"
                  textarea
                  validate={messageRequired}
                  render={({ meta: { touched, error }, input, ...props }) => (
                    <>
                      <Input {...props} {...input} invalid={touched && error} />
                      {touched && error ? (
                        <p className="text-center danger-message">{error}</p>
                      ) : null}
                    </>
                  )}
                /> */}
                <Input disabled value={url} />

                <div className="text-center">
                  {this.state.copied && <p>Convite copiado com sucesso!</p>}
                  <CopyToClipboard text={url}>
                    <Button
                      onClick={() => this.handleSuccessMessage()}
                      color={this.props.channel}
                      title="Obter convite"
                      disabled={this.state.loading}
                    >
                      <Icon name={this.props.channel} marginRight />
                      <span>Copiar convite</span>
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            )}
          />
        )}

        {this.props.can_comment ? (
          <CommentBoxAndButtons
            {...this.props}
            childCommentsProps="taskcomment_set"
            CommentBoxComponent={TaskCommentBox}
          />
        ) : null}

        {this.props.reward_still_available && (
          <WololoRewardLine {...this.props} />
        )}
      </Card>
    );
  }
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps)(InvitationTask);
