import React from 'react';
import store from '~/store';
import { openMediaModal } from '~/store/ducks/media-modal';
import { getInfo } from '~/api/tasks';
// import { APP_ID } from '~/settings';
import Video from '~/components/video';
import { addWololo } from '~/services/tasks';
import { openMessageModal } from '~/store/ducks/message-modal';
import AlertModal from '~/components/alert-modal';

// const fbShare = (id, href) => {
//   const baseUrl = window.location.origin;
//   const callback = `${baseUrl}/wololo-success/${id}`;
//   const url = [
//     `https://www.facebook.com/dialog/share?`,
//     `app_id=${APP_ID}`,
//     `&display=popup`,
//     `&href=${href}`,
//     `&redirect_uri=${callback}`
//   ].join('');
//
//   window.location.href = url;
// };

const facebook = {
  // ficou ruim compartilhar o movie
  // no facebook, então vou comentar aqui
  // caso quiser voltar com isso
  //
  // movie({ id, wololo_target_movie }) {
  //   return new Promise(resolve => {
  //     fbShare(id, wololo_target_movie);
  //   });
  // },

  watch({ id, isSubtask, onFinish: onFinishItem }) {
    return new Promise((resolve, reject) => {
      getInfo(id).then(resp => {
        const {
          data: { wololo_target_url_embedded }
        } = resp;

        let finished = false;

        const onFinish = () => {
          finished = true;
        };

        const onClose = () => {
          if (finished) {
            if (isSubtask) {
              onFinishItem(id);
              resolve();
              return;
            }

            addWololo(id)
              .then(resolve)
              .catch(reject);
          } else {
            store.dispatch(
              openMessageModal(
                <AlertModal
                  title="Você precisa assistir ao vídeo até o final."
                  content="Você não assistiu ao vídeo até o final, por isso a tarefa não pôde ser realizada."
                />
              )
            );
            reject();
          }
        };

        store.dispatch(
          openMediaModal(
            <Video
              src={wololo_target_url_embedded}
              onEnd={onFinish}
              controls
              autoPlay
            />,

            onClose
          )
        );
      });
    });
  }
};

export default facebook;
