import React, { useState } from 'react';

import moment from 'moment';

import Card from '../card';
import { WololoRewardLine } from '../rewards';
import { CommentBoxAndButtons, TaskCommentBox } from '../comments';
import Creators from '../profile-task/tasks/creators';

import PostHeader from '~/components/post-header';
import LinkPreviewMedia from '~/components/link-preview-media';

const CreatorsTask = props => {
  const [commentCounter, setCommentCounter] = useState(props.comment_counter);

  const incrementCommentCounter = () => {
    const newValue = commentCounter + 1;
    setCommentCounter(newValue);
  };

  return (
    <Card>
      {!props.isSubtask && (
        <PostHeader
          info="Formulário"
          points={props.wololo_points}
          organization={props.organization}
          expire={props.close_at}
          me_create_wololo={props.me_create_wololo}
          channel={props.channel}
          meTrail={props.me_trail}
        />
      )}
      <div className="card-body">
        <div className="content-card">
          {props.cover && (
            <LinkPreviewMedia
              channel={props.channel}
              type={props.type}
              id={props.id}
            >
              <img src={props.cover} alt="Cover" />
            </LinkPreviewMedia>
          )}
          <div className="title-date-task">
            {props.title && (
              <h3>
                <LinkPreviewMedia
                  channel={props.channel}
                  type={props.type}
                  id={props.id}
                >
                  {props.title}
                </LinkPreviewMedia>
              </h3>
            )}
            {props.open_at && (
              <p>
                <LinkPreviewMedia
                  channel={props.channel}
                  type={props.type}
                  id={props.id}
                >
                  Postado em {moment(props.open_at).format('DD/MM/YYYY')}
                </LinkPreviewMedia>
              </p>
            )}
          </div>
          {props.description && (
            <p className="text-center">
              <LinkPreviewMedia
                channel={props.channel}
                type={props.type}
                id={props.id}
              >
                {props.description}
              </LinkPreviewMedia>
            </p>
          )}
        </div>
        {!props.me_create_wololo && (
          <footer className="card-footer">
            <div className="text-center">
              <Creators
                wololoTargetId={props.wololo_target_id}
                onFinish={props.onFinish}
                isTask
                {...props}
              />
            </div>
          </footer>
        )}
      </div>
      {props.can_comment && props.me_create_wololo ? (
        <CommentBoxAndButtons
          id={props.id}
          type="task"
          commentCounter={commentCounter}
          incrementCommentCounter={incrementCommentCounter}
          childCommentsProps="taskcomment_set"
          CommentBoxComponent={TaskCommentBox}
        />
      ) : null}

      {props.reward_still_available && <WololoRewardLine {...props} />}
    </Card>
  );
};

export default CreatorsTask;
