import styled from 'styled-components';

export const StyledPDIItem = styled.button.attrs(() => ({
  className: 'pdi-item',
  type: 'button'
}))`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  margin-bottom: 16px;
  padding: 0px;

  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 16px var(--background-placeholder-color);
  }

  .card {
    width: 100%;
    align-items: flex-start;
    padding: 16px 32px;
    margin: 0px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .info-side {
      display: flex;
      align-items: center;

      .text-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
          font-size: 18px;
          color: var(--text-color);
          font-weight: normal;
          width: 100%;
          max-width: 64ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: start;
        }

        .description {
          font-size: 16px;
          margin-top: 8px;
          font-weight: 400;
          color: var(--secondary-text-color);
          margin: 0;
          font-size: 14px;
        }

        .tags {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 4px;

          .period-card {
            margin-top: 8px;
            width: max-content;
            padding: 2px 8px;
            border-radius: 4px;
            background: var(--background-color);
            font-size: 16px;
            margin-right: 8px;

            display: flex;
            align-items: center;

            i {
              margin-right: 8px;
              color: var(--secondary-text-color);
              font-size: 14px;
            }

            p {
              color: var(--secondary-text-color);
              font-size: 14px;
              max-width: 40ch;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .pdi-status {
            margin-top: 8px;
            margin-right: 8px;
          }
        }
      }
    }
  }

  .action-side {
    width: 100px;
    display: flex;
    justify-content: center;

    .button {
      min-width: max-content;
    }
  }

  @media (max-width: 720px) {
    .card {
      flex-direction: column;

      .info-side {
        flex-direction: column;
        align-items: center;

        img {
          margin: 0px;
          margin-bottom: 16px;
        }

        .default-image-placeholder {
          margin: 0px;
          margin-bottom: 16px;
        }

        .text-info {
          h3 {
            max-width: 32ch;
          }
        }

        .tags {
          margin-bottom: 32px;
          flex-wrap: wrap;
        }
      }
    }
  }
`;
