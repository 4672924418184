import React from 'react';

import StyledCollaboratorCard from './styles';

function CollaboratorCard({
  name,
  avatar,
  department,
  profile,
  manager,
  children
}) {
  return (
    <StyledCollaboratorCard>
      <div className="segmentation">
        {department} {!manager ? ` • ${profile}` : ''}
      </div>
      <div className="profile-info">
        <div className="profiles">
          <div className="managed">
            {avatar && (
              <div className="avatar">
                <img src={avatar} alt="Avatar" />
              </div>
            )}
            <div className="text">
              <p>Avaliação de</p>
              <h3>{name}</h3>
              {manager && <p>{profile}</p>}
            </div>
          </div>

          {manager && (
            <div className="manager">
              {manager.avatar_128x0 && (
                <div className="avatar">
                  <img src={manager.avatar_128x0} alt="Avatar" />
                </div>
              )}
              <div className="text">
                <p>Avaliado por</p>
                <h3>{manager.name}</h3>
                {manager && <p>{manager.profile}</p>}
              </div>
            </div>
          )}
        </div>

        <div className="custom-content">{children}</div>
      </div>
    </StyledCollaboratorCard>
  );
}

export default CollaboratorCard;
