export const Types = {
  SHOW: 'loading/SHOW',
  HIDE: 'loading/HIDE'
};

const defaultState = { loading: false };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.SHOW:
      return {
        loading: true
      };
    case Types.HIDE:
      return {
        loading: false
      };
    default:
      return state;
  }
}

export const showLoading = () => ({
  type: Types.SHOW
});

export const closeLoading = () => ({
  type: Types.HIDE
});
