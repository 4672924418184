import React, { useRef } from 'react';

import useOutsideEvent from '~/hooks/use-outside-event';

import Button from '~/components/button';
import Card from '~/components/card';
import Icon from '~/components/icon';

import StyledCustomSelect from './styles';

function CustomSelect({
  children,
  openButton: OpenButton,
  isOpen,
  setIsOpen,
  disabled
}) {
  const toggleMenu = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const wrapperRef = useRef(null);
  useOutsideEvent(wrapperRef, () => {
    setIsOpen(false);
  });

  return (
    <StyledCustomSelect isOpen={isOpen} ref={wrapperRef}>
      {OpenButton && (
        <Button
          color="primary"
          className="outline"
          onClick={toggleMenu}
          disabled={disabled}
        >
          {OpenButton}
        </Button>
      )}

      {!OpenButton && (
        <Button
          color="primary"
          className="outline"
          fluid
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon name="ellipsis" />
        </Button>
      )}

      <Card className="suspended-content">{children}</Card>
    </StyledCustomSelect>
  );
}

export default CustomSelect;
