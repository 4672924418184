import React from 'react';
import { NavLink } from 'react-router-dom';

const itens = [
  {
    label: 'Conexões',
    url: '/connections'
  },
  {
    label: 'Convidar',
    url: '/connections/invite'
  }
];

const AccountMenu = () => (
  <div className="account-menu">
    {itens.map(item => (
      <NavLink key={item.url} to={item.url} exact activeClassName="active">
        {item.label}
      </NavLink>
    ))}
  </div>
);

export default AccountMenu;
