import React from 'react';
import Modal from '~/components/modal';
import Button from '../button';
import Action from '~/assets/images/instagram-external/external-action.png';
import Action2x from '~/assets/images/instagram-external/external-action@2x.png';
import './styles.scss';
import { Form, Field } from 'react-final-form';

const ExternalActionExplanation = ({
  onContinue,
  onCancel,
  message = 'Para este tipo de ação, abriremos uma janela do app na rede social para você realizar'
}) => (
  <Modal onClose={onCancel}>
    <Form
      onSubmit={({ hide }) => onContinue({ hide })}
      render={({ handleSubmit }) => (
        <div className="external-action-explanation text-center">
          <img src={Action} alt="Action" srcSet={`${Action2x} 2x`} />

          <div className="title-holder">
            <h3>Realização externa</h3>

            <p>{message}</p>
          </div>

          <div>
            <Button
              color="primary"
              className="continue-button"
              fill
              onClick={handleSubmit}
            >
              Ok, prosseguir
            </Button>
          </div>
          <Field
            name="hide"
            type="checkbox"
            render={({ input }) => (
              <div className="checkbox-announce">
                <label>
                  <input {...input} type="checkbox" /> Não exibir este anuncio
                  novamente
                </label>
              </div>
            )}
          />
        </div>
      )}
    />
  </Modal>
);

export default ExternalActionExplanation;
