import React from 'react';
import YouTube from 'react-youtube';
import store from '~/store';
import { addWololo } from '~/services/tasks';
import { openMediaModal } from '~/store/ducks/media-modal';
import { openMessageModal } from '~/store/ducks/message-modal';
import AlertModal from '~/components/alert-modal';

const youtube = {
  watch({ id, wololo_target_url, isSubtask, onFinish: onFinishItem }) {
    return new Promise((resolve, reject) => {
      let finished = false;

      const onFinish = () => {
        finished = true;
      };

      const onClose = () => {
        if (finished) {
          if (isSubtask) {
            onFinishItem(id);
            resolve();
            return;
          }

          addWololo(id)
            .then(resolve)
            .catch(reject);
        } else {
          store.dispatch(
            openMessageModal(
              <AlertModal
                title="Você precisa assistir ao vídeo até o final."
                content="Você não assistiu ao vídeo até o final, por isso a tarefa não pôde ser realizada."
              />
            )
          );
          reject();
        }
      };

      const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1
        }
      };

      const youtubeUrlRegex = /(https?:\/\/www\.youtube\.com\/watch\?v=)/i;
      const videoUrl = wololo_target_url.replace(youtubeUrlRegex, '');

      store.dispatch(
        openMediaModal(
          <YouTube
            videoId={videoUrl}
            opts={opts}
            onEnd={onFinish}
            onError={reject}
          />,
          onClose
        )
      );
    });
  }
};

export default youtube;
