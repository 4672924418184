import React, { useContext } from 'react';
import { SiteContext } from '~/contexts/site-provider';

const useSite = () => {
  const site = useContext(SiteContext);

  return site;
};

export const withSite = WrappedComponent => {
  return props => {
    const site = useSite();

    return <WrappedComponent {...props} site={site} />;
  };
};

export default useSite;
