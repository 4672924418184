import React, { useState, useEffect } from 'react';
import Card from '~/components/card';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import './style.scss';

export const trailsIcon = props => (
  <svg width={32} height={32} {...props}>
    <path
      d="M10,3 C11.1045695,3 12,3.8954305 12,5 L12,7 L15,7 C16.1045695,7 17,7.8954305 17,9 L17,9 L17,23 L20,23 L20,21 C20,19.8954305 20.8954305,19 22,19 L28,19 C29.1045695,19 30,19.8954305 30,21 L30,27 C30,28.1045695 29.1045695,29 28,29 L22,29 C20.8954305,29 20,28.1045695 20,27 L20,25 L17,25 C15.8954305,25 15,24.1045695 15,23 L15,23 L15,9 L12,9 L12,11 C12,12.1045695 11.1045695,13 10,13 L4,13 C2.8954305,13 2,12.1045695 2,11 L2,5 C2,3.8954305 2.8954305,3 4,3 L10,3 Z M28,21 L22,21 L22,27 L28,27 L28,21 Z M10,5 L4,5 L4,11 L10,11 L10,5 Z"
      id="path-1"
    />
  </svg>
);

const TrailFeedItem = ({ trail, tasks, account }) => {
  const [amountTasksDone, setAmountTasksDone] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalPointsEarned, setTotalPointsEarned] = useState(0);
  const [percentDone, setPercentDone] = useState(0);
  const [barColor, setBarColor] = useState('');

  const amountTasks = tasks.length;
  const NavIcon = trailsIcon;
  const barColors = {
    lightgreen: '#9eec2b',
    yellow: '#f1e019',
    red: '#fe6a3b',
    green: '#4cb392'
  };

  useEffect(() => {
    let currentAmountTasksDone = 0;

    tasks.forEach(task => {
      setTotalPoints(lastPoints => lastPoints + task.wololo_points);
      if (task.me_create_wololo) {
        setTotalPointsEarned(lastPoints => lastPoints + task.wololo_points);
        setAmountTasksDone(lastQuantity => {
          currentAmountTasksDone = lastQuantity + 1;
          return currentAmountTasksDone;
        });
      }
    });

    setPercentDone(() => {
      const percent = (currentAmountTasksDone * 100) / amountTasks;

      if (percent < 50) {
        setBarColor('red');
      } else if (percent >= 50 && percent < 75) {
        setBarColor('yellow');
      } else if (percent >= 75 && percent < 100) {
        setBarColor('lightgreen');
      } else {
        setBarColor('green');
      }

      return percent;
    });
  }, []);

  return (
    <Card>
      <header>
        <NavIcon className="trail-icon" />
        <span className="trail-title">{trail.title}</span>
      </header>
      <main>
        <div className="description">
          <p className="trail-description">{trail.description}</p>
        </div>
        <div className="trail-values">
          <div className="percent">
            <CircularProgressbarWithChildren
              value={percentDone}
              strokeWidth={6}
              styles={{
                path: {
                  stroke: `${barColors[barColor]}`,
                  strokeLinecap: 'round',
                  transition: 'stroke-dashoffset 0.5s ease 0s'
                },
                trail: {
                  stroke: '#EEEFF5'
                }
              }}
            >
              <div className="percent-container">
                <h1>
                  {percentDone.toFixed(0)}
                  <span>%</span>
                </h1>
                <p>concluída</p>
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div className="statistics">
            <div className="trail-tasks">
              <h3>
                <span>{amountTasksDone}</span>/{amountTasks}
              </h3>
              <p>atividades</p>
            </div>
            <div className="trail-points">
              <h3>
                <span>{totalPointsEarned}</span>/{totalPoints}
              </h3>
              <p>pontuação</p>
            </div>
          </div>
        </div>
      </main>
    </Card>
  );
};

export default TrailFeedItem;
