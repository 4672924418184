import React from 'react';
import PropTypes from 'prop-types';
import Store from '../store';
import './style.scss';
import useSite from '~/hooks/use-site';

const StoreLinks = props => {
  const { appStoreUrl, googlePlayUrl } = useSite();

  return (
    <div className="stores-links">
      <div className="store-links-info">{props.children}</div>
      <Store name="app" url={appStoreUrl} />

      <Store name="play" url={googlePlayUrl} />
    </div>
  );
};

StoreLinks.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default StoreLinks;
