import LogoImg from './lhh-assets/images/logo-color.png';
import LogoImg2x from './lhh-assets/images/logo-color.png';
import LogoReverseImg from './lhh-assets/images/logo-color.png';
import LogoReverseImg2x from './lhh-assets/images/logo-color.png';
import './main.scss';

const LhhSite = {
  name: 'LHH',
  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=br.com.edukative.talkative&hl=en',
  appStoreUrl: 'https://apps.apple.com/br/app/edukative/id1509604808',
  showStoreLinks: false,
  LogoImg,
  LogoImg2x,
  LogoReverseImg,
  LogoReverseImg2x,
  completeSignUp: false,
  tutorialDescription: 'LHH description...',
  actionGenderPoints: 150,
  pushApplicationServerKey:
    'BMplX3pQp1CyS43RjWTg3imHX2DWbgCTYwBF5jkcOOYA9hcwVGXp5rCk_EBRV1-ugvQuLKDibRlFoDArWx5O4C4',
  loginMinutes: null,
  primaryColor: '#832796'
};

export default LhhSite;
