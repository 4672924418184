import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Card from '~/components/card';
import SurveyQuestion from '~/components/survey/survey-question';
import ProfileInfo from '~/components/profile-info';
import SurveyQuestionStatus from '~/components/survey/survey-question-status';
import Alert from '~/components/alert';
import Icon from '~/components/icon';
import CancelParticipationModal from '~/components/survey/cancel-participation-modal';

import questionIcon from '~/assets/images/task-icons/question-icon.svg';

import './style.scss';

function SurveyQuestions({ survey, history }) {
  const { selectedSurvey, currentQuestion, questionsStatus } = survey;

  if (!selectedSurvey || !currentQuestion) {
    history.push('/survey');
  }

  const [showCancelModal, setShowCancelModal] = useState(false);

  return (
    <>
      <Alert color="warning" icon={<Icon name="lock" color="warning" />}>
        Para garantir a sua total privacidade, todas as respostas são enviadas
        anonimamente.
      </Alert>

      <Card className="survey-question-card">
        <div className="survey-question-header">
          <ProfileInfo
            name={currentQuestion && currentQuestion.title}
            image={currentQuestion && currentQuestion.category.icon}
            info={currentQuestion && currentQuestion.category.title}
            rightImage={
              <img className="right-icon" src={questionIcon} alt="icon" />
            }
          />
        </div>
        <div className="helper-text">
          <p>
            Responda as questões abaixo escolhendo um valor de 0 a 5, onde 0 é
            discordo completamente e 5 é concordo completamente.
          </p>
        </div>
        <div className="question-status-container">
          <SurveyQuestionStatus
            current={questionsStatus}
            total={selectedSurvey && selectedSurvey.questions.length}
          />
        </div>
        <div className="question">
          <SurveyQuestion
            selectedSurvey={selectedSurvey}
            currentQuestionIndex={questionsStatus - 1}
          />
        </div>
        <div className="footer">
          <button type="button" onClick={() => setShowCancelModal(true)}>
            Não quero responder esta Pesquisa
          </button>
        </div>
      </Card>

      {showCancelModal && (
        <CancelParticipationModal
          onClose={() => setShowCancelModal(false)}
          survey={selectedSurvey}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ survey }) => ({
  survey
});

export default connect(mapStateToProps)(withRouter(SurveyQuestions));
