import styled from 'styled-components';

export const StyledSparkTable = styled.div.attrs(() => ({
  className: 'spark-table'
}))`
  .card-container {
    padding: 22px 32px;
  }

  .table-title {
    color: var(--grey100);

    font-weight: 900;
    font-size: 24px;

    margin-bottom: 22px;

    line-height: 30px;
  }

  .placing {
    min-width: 60px;
    width: 60px;
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .total-score {
    min-width: fit-content;
  }

  .find-myself {
    display: flex;
    justify-content: flex-end;
  }

  .find-myself .button {
    display: flex;
    align-items: center;
  }

  .find-myself .button i {
    margin: -5px 0px 0px 7px;
    font-size: 22px;
  }

  .user-list {
    margin: 12px 0px 10px;

    & table {
      width: 100%;
      margin: 10px 0px;

      & tbody {
        gap: 10px;

        display: flex;
        flex-direction: column;
      }

      & tr {
        color: var(--grey100);

        font-size: 16px;
        font-weight: 700;

        letter-spacing: 0.5px;

        &.selected {
          color: var(--brand-primary-color);
        }
      }

      & tr,
      & td > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & td > div {
        gap: 12px;
      }
    }
  }
`;
