import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '../button';
import Icon from '../icon';
import './style.scss';

const Poll = props => (
  <div className="poll text-center">
    <div style={{ textAlign: 'left' }}>
      <h3>{props.children}</h3>
      {props.date && (
        <p className="open-date">
          Postado em {moment(props.date).format('DD/MM/YYYY')}
        </p>
      )}
    </div>
    <div>
      <Button
        color="success"
        disabled={props.pollState.poll_yes_percent !== null}
        circle
        onClick={() => props.onChange(true)}
      >
        {props.pollState.poll_yes_percent === null && <Icon name="positive" />}

        {props.pollState.poll_yes_percent !== null && (
          <p>{parseInt(props.pollState.poll_yes_percent, 10)}%</p>
        )}
      </Button>
      <Button
        color="danger"
        disabled={props.pollState.poll_no_percent !== null}
        circle
        onClick={() => props.onChange(false)}
      >
        {props.pollState.poll_no_percent === null && <Icon name="negative" />}

        {props.pollState.poll_no_percent !== null && (
          <p>{parseInt(props.pollState.poll_no_percent, 10)}%</p>
        )}
      </Button>
    </div>
  </div>
);

Poll.propTypes = {
  children: PropTypes.string,
  onChange: PropTypes.func
};

export default Poll;
