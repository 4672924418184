import facebook from './facebook';
import linkedin from './linkedin';
import publishing from './publishing';
import twitter from './twitter';
import youtube from './youtube';
import whatsapp from './whatsapp';
import instagram from './instagram';
import kaltura from './kaltura';

const actions = {
  facebook,
  linkedin,
  publishing,
  twitter,
  youtube,
  whatsapp,
  instagram,
  zoom: () => {},
  kaltura
};

export default actions;
