import React from 'react';

import Button from '~/components/button';
import Icon from '~/components/icon';

import StyledCourseExamQuestion from './styles';

function CourseExamQuestion({
  statement,
  options,
  answers,
  type,
  isCorrect,
  question,
  setQuestion,
  disabled
}) {
  const handleSelect = answer => {
    const currentQuestion = { ...question };

    if (type === 'multi') {
      setQuestion(() => {
        const answerIndex = currentQuestion.answer_options.indexOf(answer);

        if (answerIndex !== -1) {
          currentQuestion.answer_options.splice(answerIndex, 1);
        } else {
          currentQuestion.answer_options.push(answer);
        }

        return currentQuestion;
      });
      return;
    }

    setQuestion(() => {
      currentQuestion.answer_options[0] = answer;
      return currentQuestion;
    });
  };

  return (
    <StyledCourseExamQuestion>
      <div className="question-header">
        <div className="info">
          <p className="statement">{statement}</p>
        </div>
        <div className="actions">
          {isCorrect && <Icon name="check" className="correct" />}

          {isCorrect === false && <Icon name="times" className="incorrect" />}
        </div>
      </div>
      <div className="question-options">
        {options.map(option => (
          <Button
            key={option.pk}
            color="primary"
            className={`
              outline
              ${answers.some(answer => answer === option.pk) ? ' selected' : ''}
            `}
            onClick={() => handleSelect(option.pk)}
            disabled={disabled}
          >
            <p>{option.text}</p>
          </Button>
        ))}
      </div>
    </StyledCourseExamQuestion>
  );
}

export default CourseExamQuestion;
