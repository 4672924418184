import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  PublicWrapper,
  PublicHeader,
  PublicBanner,
  PublicFooter
} from '~/components/public';
import Card from '~/components/card';
import { Form, Input } from '~/components/form';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Link from '~/components/link';
import Separator from '~/components/separator';
// import StoreLinks from '~/components/store-links';
import RecoveryIcon from '~/assets/images/recovery.svg';
import { showLoading, closeLoading } from '~/store/ducks/loading';
import { showMessage } from '~/store/ducks/messageBar';
import { recovery } from '~/api/auth';
import { getError } from '~/utils';

class RecoveryScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      email_invalid: false,
      formDisabled: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target }) {
    const { name, value } = target;
    this.setState({
      [name]: value,
      [`${name}_invalid`]: false
    });
  }

  handleSubmit() {
    const { email } = this.state;
    const { showLoading, closeLoading, showMessage } = this.props;

    showLoading();
    this.setState({ formDisabled: true });

    if (email) {
      recovery(email)
        .then(({ data }) => {
          if (data.status) {
            this.props.history.push('/recovery-sent');
          }
        })
        .catch(e => {
          getError(e, res => {
            showMessage(res.message, 'danger');
            const newState = { formDisabled: false };

            res.fields.forEach(field => {
              newState[field] = true;
            });

            this.setState(newState);
          });
        })
        .finally(() => {
          this.setState({ formDisabled: false });
          closeLoading();
        });
    }
  }

  render() {
    return (
      <PublicWrapper>
        <PublicHeader>
          <Card className="public-card text-center">
            <div>
              <img src={RecoveryIcon} alt="Enviado" />
            </div>
            <h3>Recuperar Senha</h3>
            <br />
            <p>
              Para recuperar sua senha, digite abaixo o endereço de email
              associado ao seu cadastro e em seguida clique em “Enviar
              solicitação". Caso não possua um email cadastrado na sua conta,
              entrar em contato com o Suporte.
            </p>
            <br />
            <Form
              disabled={this.state.formDisabled}
              onSubmit={this.handleSubmit}
            >
              <Input
                name="email"
                type="email"
                placeholder="Digite seu e-mail"
                autoCorrect="off"
                autoFocus
                required
                invalid={this.state.email_invalid}
                value={this.state.email}
                onChange={this.handleChange}
              />
              <br />
              <Button fill large color="primary">
                Enviar solicitação
              </Button>
              <Separator />
              <div className="text-center">
                <Link to="/sign-in">
                  <span>Voltar para o login</span>
                  <Icon name="arrow-right" marginLeftSmall />
                </Link>
              </div>
            </Form>
          </Card>
        </PublicHeader>
        <div className="main">
          <PublicBanner>
            <span>Seja bem-vindo!</span>
          </PublicBanner>
          <div className="hide-on-mobile" style={{ marginBottom: 200 }} />
        </div>
        <PublicFooter />
      </PublicWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showLoading, closeLoading, showMessage }, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(RecoveryScreen));
