import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';

import useAccount from '~/hooks/use-account';
import useOrganization from '~/hooks/use-organization';

import Card from '~/components/card';
import ChatList from '~/components/chat-list';
import ChatForm from '~/components/chat-form';
import PageHeader from '~/components/page-header';

import { findActiveOrganization } from '~/utils';

import './style.scss';

function ContactUs({ organization: { activeOrganizationId } }) {
  const listSectionButton = useRef(null);
  const formSectionButton = useRef(null);

  const {
    data: { organization_is_active_set }
  } = useAccount();
  // const teste = useAccount();
  const {
    organizationsettings: { organization }
  } = useOrganization(activeOrganizationId);

  const activeOrganization = findActiveOrganization(
    organization_is_active_set,
    activeOrganizationId
  );

  const [activeSection, setActiveSection] = useState('list'); // list and form

  const toggleSection = value => {
    if (value === 'list') {
      formSectionButton.current.classList.remove('active');
      listSectionButton.current.classList.add('active');
    } else {
      listSectionButton.current.classList.remove('active');
      formSectionButton.current.classList.add('active');
    }

    setActiveSection(value);
  };

  return (
    <div className="contact-us-screen">
      <PageHeader>
        <div className="text-info">
          <h1>COMS</h1>
          <p>
            Seção dedicada à comunicação direta entre a organização e seus
            colaboradores.
          </p>
        </div>
      </PageHeader>

      <Card>
        <div className="contact-us-content">
          <div className="toggle-buttons">
            <button
              type="button"
              className="toggle-section active"
              onClick={e => toggleSection('list')}
              ref={listSectionButton}
            >
              Ativos
            </button>
            <button
              type="button"
              className="toggle-section"
              onClick={e => toggleSection('form')}
              ref={formSectionButton}
            >
              Criar novo
            </button>
          </div>
          <div className="sections">
            {activeSection === 'list' ? (
              <ChatList
                subjectMatters={activeOrganization.contacttype_set || []}
              />
            ) : (
              <ChatForm
                toggleSection={toggleSection}
                organization={organization}
                subjectMatters={activeOrganization.contacttype_set || []}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps)(ContactUs);
