import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showMessage } from '~/store/ducks/messageBar';

import { getMetlifeDepartments } from '~/api/organization';

import Button from '~/components/button';
import Icon from '~/components/icon';
import PageHeader from '~/components/page-header';
import Breadcrumb from '~/components/breadcrumb';
import SearchInput from '~/components/form/search-input';
import EmptyList from '~/components/empty-list';
import DepartmentCard from '~/components/department-card';
import CustomLoading from '~/components/custom-loading';
import InfiniteScroll from '~/components/infinite-scroll';
import TagFilter from '~/components/tag-filter';

import { StyledMetlifeDepartments } from './styles';

function MetlifeDepartments({ organization, showMessage, history }) {
  const { pathname } = history.location;

  const { activeOrganizationId } = organization;
  const type = pathname.split('/metlife/')[1].split('/departments')[0];
  const departmentVar = pathname.split('/departments')[1].replace('/', '');

  const [pageTitle, setPageTitle] = useState('');
  const [pageSubtitle, setPageSubtitle] = useState('');
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [groupList, setGroupList] = useState(null);

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(null);

  const [search, setSearch] = useState('');
  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState('');

  const handleBack = () => {
    setSearch('');
    setSelectedGroup('');

    if (breadcrumbLinks.length === 1) {
      history.push(`/metlife`);
    } else {
      history.push(breadcrumbLinks[breadcrumbLinks.length - 2].route);
    }
  };

  const getTypeName = () => {
    return type === 'eb' ? 'corretores' : 'consultores';
  };

  const getDepartmentsList = account => {
    let departments = [];
    let department = account.department;
    while (department) {
      departments.push(department.name);
      department = department.parent;
    }
    return departments.reverse().join(' / ');
  };

  const getDepartments = filters => {
    setLoading(true);

    getMetlifeDepartments(activeOrganizationId, type, departmentVar, 1, filters)
      .then(response => {
        const {
          title,
          breadcrumb,
          results,
          count,
          next,
          groups
        } = response.data;
        setPageTitle(title);
        setPageSubtitle(
          count === 1 ? `${count} registro` : `${count} registros`
        );
        let bread = breadcrumb.map(link => {
          link.route = `/metlife/${type}/departments/${link.id}`;
          return link;
        });

        bread.unshift({
          id: Date.now(),
          title: type === 'eb' ? 'Diretorias' : 'Agências',
          route: `/metlife/${type}/departments/`
        });

        setBreadcrumbLinks(bread);
        setDepartments(results);
        setNextPage(next);
        setCurrentPage(1);

        setGroupList(groups ? groups : null);
        setSelectedGroup(groups ? filters.group : '');
      })
      .catch(error => {
        showMessage(
          'Ocorreu um erro inesperado ao carregar os itens',
          'danger'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBreadcrumbLinkClick = route => {
    if (route !== history.location.pathname) {
      setSearch('');
      setSelectedGroup('');
    }
  };

  const handleSearch = event => {
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }

    const { value } = event.target;

    const timeOut = setTimeout(() => {
      getDepartments({
        search: value,
        group: selectedGroup
      });
    }, 1000);

    setCurrentTimeOut(timeOut);
    setSearch(value);
  };

  const handleChangeGroup = value => {
    const filters = {
      search: search,
      group: value === selectedGroup ? '' : value
    };

    setSelectedGroup(value === selectedGroup ? '' : value);
    getDepartments(filters);
  };

  const loadMoreDepartments = () => {
    setLoadingMore(true);

    const filters = {
      search: search,
      group: selectedGroup
    };

    getMetlifeDepartments(
      activeOrganizationId,
      type,
      departmentVar,
      currentPage + 1,
      filters
    )
      .then(response => {
        const { results, next } = response.data;

        setDepartments(lastDepartmentList => [
          ...lastDepartmentList,
          ...results
        ]);
        setNextPage(next);
        setCurrentPage(lastPage => {
          return lastPage + 1;
        });
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao fazer a busca', 'danger', 3000);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    const filters = {
      search: search,
      group: selectedGroup
    };

    getDepartments(filters);
  }, [departmentVar]);

  return (
    <StyledMetlifeDepartments>
      <div className="header-actions">
        <Button color="primary" className="link" onClick={handleBack}>
          <Icon name="back" />
          Voltar
        </Button>
      </div>

      {!loading && (
        <PageHeader>
          <div className="text-info">
            <h1>{pageTitle}</h1>
            <p>{pageSubtitle}</p>
          </div>
          <SearchInput
            value={search}
            onChange={handleSearch}
            placeholder="Busque por nome"
          />
        </PageHeader>
      )}

      <div className="actions">
        {!loading && breadcrumbLinks && breadcrumbLinks.length > 1 && (
          <Breadcrumb
            links={breadcrumbLinks}
            compressionStart={10}
            onClickCallback={handleBreadcrumbLinkClick}
          />
        )}
      </div>

      {!loading && groupList && groupList.length > 0 && (
        <TagFilter
          title="Segmentos"
          items={groupList}
          selectedItem={selectedGroup}
          onClick={value => handleChangeGroup(value)}
        />
      )}

      <div className="departments">
        {!loading &&
          departments.map(department => (
            <DepartmentCard
              key={`${
                department.department
                  ? `${department.pk}${department.department.pk}`
                  : department.pk
              }`}
              title={department.name}
              subtitle={
                department.group ? getDepartmentsList(department) : null
              }
              url={`/metlife/${type}/departments/${department.pk}${
                department.group ? '/extracts' : ''
              }`}
              description={department.group ? department.group.title : null}
              color={department.group ? department.group.color : null}
              readyOnly={type === 'agent' && department.group ? true : false}
            />
          ))}
      </div>

      <InfiniteScroll
        fetchMore={loadMoreDepartments}
        disabled={!nextPage || loading || loadingMore}
      />

      {loading && <CustomLoading type="spin" height={36} width={36} fluid />}

      {loadingMore && (
        <CustomLoading type="spin" height={36} width={36} fluid />
      )}

      {!loading && departments.length === 0 && (
        <EmptyList message={`Não há ${getTypeName()} a serem exibidos`} />
      )}
    </StyledMetlifeDepartments>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MetlifeDepartments)
);
