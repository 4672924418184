import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Loader from '~/assets/images/loader.svg';

const Loading = ({ visible, inline }) => (
  <>
    {visible && inline && (
      <div className="text-center loader-inline-wrapper">
        <img src={Loader} alt="carregando" />
      </div>
    )}

    {!inline && (
      <div className={`loading${visible ? ' visible' : ''}`}>
        <svg
          width="64px"
          height="64px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="50" cy="24.5461" r="13">
            <animate
              attributeName="cy"
              calcMode="spline"
              values="23;77;23"
              keyTimes="0;0.5;1"
              dur="1"
              keySplines="0.45 0 0.9 0.55;0 0.45 0.55 0.9"
              begin="0s"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      </div>
    )}
  </>
);

Loading.defaultProps = {
  inline: false
};

Loading.propTypes = {
  visible: PropTypes.bool.isRequired,
  inline: PropTypes.bool
};

export default Loading;
