import React from 'react';

import { Select } from '~/components/form';

import { StyledCustomBoolean } from './styles';

function CustomBoolean({
  id,
  label,
  options,
  answer,
  data,
  setData,
  readOnly,
  errorMessage
}) {
  const setOption = value => {
    setData(lastData => {
      const newData = [...lastData];

      const currentCustomFieldIndex = newData.findIndex(
        customField => customField.pk === id
      );

      newData[currentCustomFieldIndex].answer = value;
      return newData;
    });
  };

  return (
    <StyledCustomBoolean className={errorMessage ? 'has-error' : ''}>
      <label>{label}</label>
      <Select
        value={answer}
        onChange={event => setOption(event.target.value)}
        disabled={readOnly}
      >
        {options.map(option => (
          <option
            key={option.key}
            value={option.key === null ? '' : option.key}
          >
            {option.label}
          </option>
        ))}
      </Select>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </StyledCustomBoolean>
  );
}

export default CustomBoolean;
