import styled from 'styled-components';

const StyledManaged = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: 100%;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
  padding: 8px;

  &:hover {
    background: var(--background-placeholder-color);
  }

  &:last-child {
    border: none;
  }

  h3 {
    font-size: 16px;
    color: var(--text-color);
    font-weight: normal;

    i {
      position: relative;
      top: -2px;
      color: var(--white-color);
      background-color: var(--success-color);
      padding: 4px;
      font-size: 12px;
      border-radius: 50%;
      margin-left: 8px;
    }
  }

  .description {
    font-size: 16px;
    margin-top: 8px;
    font-weight: 400;
    color: var(--secondary-text-color);
    margin: 0;
    font-size: 14px;
  }

  .actions {
    width: 50px;
    display: flex;
    justify-content: center;

    i {
      color: var(--primary-color);
    }

    .button {
      border: none;
      width: 100%;
      padding: 0px;
    }

    .button:hover {
      color: var(--primary-color);
      background: none;
    }

    .button:disabled {
      color: var(--secondary-text-color);
    }
  }

  &:disabled {
    cursor: initial;

    h3 {
      color: var(--secondary-text-color);
    }

    i {
      color: var(--secondary-text-color);
    }
  }

  @media (max-width: 720px) {
    &:hover {
      background: none;
    }
  }
`;

export default StyledManaged;
