import React, { Component } from 'react';
import moment from 'moment';
import Icon from '../icon';
import './style.scss';

class CollapsePost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }

  render() {
    const classes = ['collapse-post'];
    const { visible } = this.state;

    if (visible) {
      classes.push('visible');
    }

    return !this.props.expanded ? (
      <div className={classes.join(' ')}>
        <div className="collapse-header" onClick={this.toggle}>
          <div className="collapse-info">
            {this.props.name && <h3>{this.props.name}</h3>}
            <p>Postado em {moment(this.props.date).format('DD/MM/YYYY')}</p>
          </div>
          <div>
            <div className="collapse-icon">
              <Icon name={visible ? 'up-arrow' : 'down-arrow'} />
            </div>
          </div>
        </div>
        <div className="collapse-content">
          <div className="collapse-content-wrapper">{this.props.children}</div>
        </div>
      </div>
    ) : (
      <div className="collapse-post expanded">
        {this.props.name && (
          <div className="collapse-header">
            <div className="collapse-info">
              <h3>{this.props.name}</h3>
              <p>Postado em {moment(this.props.date).format('DD/MM/YYYY')}</p>
            </div>
          </div>
        )}
        <div className="collapse-content">
          <div>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default CollapsePost;
