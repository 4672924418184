import styled from 'styled-components';

export const StyledCustomBoolean = styled.div.attrs(() => ({
  className: 'custom-boolean'
}))`
  width: 100%;

  select {
    -webkit-appearance: menulist;
    min-height: 33px;
  }

  &.has-error {
    select {
      border-color: var(--failure-color);
    }
  }

  .error-message {
    color: var(--failure-color);
  }
`;
