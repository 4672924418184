import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import Card from '~/components/card';
import { Container, Row, Col } from '~/components/grid';
import {
  PublicWrapper,
  PublicHeader,
  PublicBanner,
  PublicFooter
} from '~/components/public';
import Badge from '~/components/badge';
import MessageBar from '~/components/message-bar';
import { Form, Input } from '~/components/form';
import Link from '~/components/link';
import Button from '~/components/button';
import FacebookAuth from '~/components/facebook-auth';
import Icon from '~/components/icon';
import Separator from '~/components/separator';
import StoreLinks from '~/components/store-links';
import { signUp, getInvitation, consumeInvitation } from '~/api/auth';
import { showLoading, closeLoading } from '~/store/ducks/loading';
import { registerAccount } from '~/store/ducks/account';
import { showMessage } from '~/store/ducks/messageBar';
import { getParam, getError, enableCompleteSignUp } from '~/utils';
import { login } from '~/services/auth';

class OnboardScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formDisabled: false,
      showInvitation: false,
      invitationExpire: null,
      name: '',
      email: '',
      email_invalid: false,
      password1: '',
      password1_invalid: false,
      password2: '',
      password2_invalid: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const uuid = getParam('uuid');
    const { showLoading, closeLoading, showMessage } = this.props;

    if (!uuid) {
      showMessage('UUID não encontrado', 'danger');
    } else {
      showLoading();

      getInvitation(uuid)
        .then(({ data }) => {
          const expireDate = moment(data.close_at);

          if (expireDate.isValid()) {
            const close = expireDate.fromNow(true);

            this.setState({
              showInvitation: true,
              invitationExpire: close.match(/(.+)\s(.+)/)
            });
          }
        })
        .catch(e => {
          getError(e, res => {
            showMessage(res.message, 'danger');
            res.fields.forEach(field => {
              this.setState({ [field]: true });
            });
          });
        })
        .finally(() => closeLoading());
    }
  }

  handleChange({ target }) {
    const { name, value } = target;
    this.setState({
      [name]: value,
      [`${name}_invalid`]: false
    });
  }

  handleSubmit() {
    const uuid = getParam('uuid');
    const { name, email, password1, password2 } = this.state;
    const { showLoading, closeLoading, showMessage } = this.props;

    if (password1 === password2) {
      showLoading();
      this.setState({ formDisabled: true });
      signUp(name, email, password1)
        .then(({ data }) => {
          login(data.token);

          consumeInvitation(uuid)
            .then(() => {
              this.props.registerAccount(data.account);
              enableCompleteSignUp();
              this.props.history.push('/complete-sign-up');
            })
            .catch(e => {
              getError(e, res => {
                showMessage(res.message, 'danger');
              });
            })
            .finally(() => {
              this.setState({ formDisabled: false });
              closeLoading();
            });
        })
        .catch(e => {
          getError(e, res => {
            closeLoading();
            showMessage(res.message, 'danger');
            const newState = { formDisabled: false };

            res.fields.forEach(field => {
              newState[field] = true;
            });

            this.setState(newState);
          });
        });
    } else {
      showMessage('As senhas precisam ser iguais!', 'danger');
      this.setState({
        password1_invalid: true,
        password2_invalid: true
      });
    }
  }

  render() {
    return (
      <PublicWrapper>
        <PublicHeader>
          <Card className="public-card">
            <FacebookAuth onSignIn={consumeInvitation}>
              Cadastrar com Facebook
            </FacebookAuth>
            <Separator>Ou use seu e-mail</Separator>
            <Form
              disabled={this.state.formDisabled}
              onSubmit={this.handleSubmit}
            >
              <Input
                name="name"
                type="text"
                placeholder="Digite seu nome"
                autoCorrect="off"
                autoFocus
                required
                value={this.state.name}
                onChange={this.handleChange}
              />
              <Input
                name="email"
                type="email"
                placeholder="Digite seu e-mail ou código"
                autoCorrect="off"
                required
                invalid={this.state.email_invalid}
                value={this.state.email}
                onChange={this.handleChange}
              />
              <Input
                name="password1"
                type="password"
                placeholder="Digite uma senha"
                autoComplete="off"
                required
                invalid={this.state.password1_invalid}
                value={this.state.password1}
                onChange={this.handleChange}
              />
              <Input
                name="password2"
                type="password"
                placeholder="Repita a senha"
                autoComplete="off"
                required
                invalid={this.state.password2_invalid}
                value={this.state.password2}
                onChange={this.handleChange}
              />
              <Button large fill color="primary">
                <Icon name="check" marginRight />
                <span>Cadastrar</span>
              </Button>
              <Separator />
              <div className="text-center">
                <span>Já é cadastrado? </span>
                <Link to="/sign-in">
                  <span>Faça login</span>
                  <Icon name="arrow-right" marginLeftSmall />
                </Link>
              </div>
            </Form>
          </Card>
        </PublicHeader>
        <div className="main">
          <PublicBanner>
            Você foi convidado a participar da mais inovadora ferramenta de
            mídia para micro-influenciadores nas redes sociais.
          </PublicBanner>
          {this.state.showInvitation && (
            <div style={{ marginBottom: -52 }}>
              <MessageBar color="secondary">
                <Row>
                  <Col md={5} style={{ textAlign: 'center' }}>
                    <span>Seu convite expira em </span>
                    <Badge>{this.state.invitationExpire[1]}</Badge>
                    <span> {this.state.invitationExpire[2]}.</span>
                  </Col>
                </Row>
              </MessageBar>
            </div>
          )}
          <div style={{ padding: '100px 0' }}>
            <Container>
              <Row>
                <Col md={5}>
                  <StoreLinks>
                    Baixe nosso app. Disponível para iOS e Android.
                  </StoreLinks>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <PublicFooter />
      </PublicWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showLoading,
      closeLoading,
      showMessage,
      registerAccount
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(OnboardScreen));
