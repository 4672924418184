import styled from 'styled-components';

export const StyledPDIMyTeam = styled.div.attrs(() => ({
  className: 'pdi-my-team-page'
}))`
  width: 100%;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    h1 {
      margin: 0px;
    }

    p {
      font-size: 14px;
    }

    .breadcrumb {
      padding-bottom: 0px;
    }
  }

  .filters {
    display: flex;
    margin-bottom: 32px;

    .search-input {
      width: 100%;
      flex: 1;
      margin-right: 16px;
      background: var(--white-color);
    }

    .select-multiple {
      max-width: 100%;
      flex: 1;
      min-height: 41px;
      margin: 10px 0px;

      &:nth-child(2) {
        margin-right: 16px;
      }
    }
  }

  .pdi-participant-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .custom-loading {
    margin: 32px 0px;
  }

  @media (max-width: 720px) {
    .page-header {
      align-items: flex-start;
    }

    .pdi-participant-list {
      margin-bottom: 56px;
    }

    .filters {
      flex-wrap: wrap;

      .search-input {
        flex: initial;
        margin-right: 0px;
        width: 100%;
      }

      .select-multiple:nth-child(2) {
        margin-right: 0px;
      }

      .select {
        width: 50%;
      }
    }
  }
`;
