export const Types = {
  OPEN: 'media-modal/OPEN',
  CLOSE: 'media-modal/CLOSE'
};

const defaultState = {
  open: false,
  content: '',
  onClose: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.OPEN:
      return {
        open: true,
        content: action.content,
        onClose: action.onClose
      };
    case Types.CLOSE:
      return defaultState;
    default:
      return state;
  }
}

export const openMediaModal = (content, onClose) => ({
  type: Types.OPEN,
  content,
  onClose
});

export const closeMediaModal = () => ({
  type: Types.CLOSE
});
