import styled from 'styled-components';

export const StyledInvitationList = styled.header.attrs(() => ({
  className: 'invitation-list'
}))`
  h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .invite-list-item {
    display: flex;
    align-items: center;
    gap: 14px;

    margin-top: 22px;
  }

  .invite-list-item .button {
    min-height: initial;

    padding: 0;

    font-size: 16px;
    text-decoration: none;

    white-space: nowrap;
  }

  .invite-list-item .button:hover,
  .invite-list-item .button:focus:hover {
    color: var(--brand-tertiary-color);
  }

  .invite-list-item > div {
    width: 100%;
    max-width: 32px;

    height: 32px;

    border-radius: 50px;
    background: radial-gradient(
        81.25% 81.25% at 87.5% 9.38%,
        #00bc9b 0%,
        #1696e1 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .invite-list-item > div img {
    height: 100%;
    border-radius: 50%;
  }

  .invite-list-item span {
    font-size: 18px;
    line-height: 110%;
    letter-spacing: 0.5px;
    color: var(--purple-color);
  }

  .modal-footer .button {
    height: 44px;

    font-size: 16px;
    padding: 10px 12px;
    border-radius: 50px;

    color: var(--white-color);
  }
`;
