import styled from 'styled-components';

export const StyledViewAllCoursesModal = styled.div.attrs(() => ({
  className: 'view-all-courses-modal'
}))`
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
  }

  .counter {
    font-size: 14px;
    color: var(--secondary-text-color);
    margin-bottom: 16px;

    span {
      color: var(--text-color);
    }
  }

  .search-input {
    margin-bottom: 16px;
  }

  .item-list-wrapper {
    min-height: 300px;
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 16px;

    .item-list {
    }
  }

  @media (max-width: 720px) {
    .modal-content {
      padding: 8px;
    }
  }
`;
