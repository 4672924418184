import styled from 'styled-components';

export const StyledItem = styled.button.attrs(() => ({
  type: 'button',
  className: 'item'
}))`
  width: 100%;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
  padding: 8px;

  &:hover {
    background: var(--background-placeholder-color);
  }

  &:last-child {
    border: none;
  }

  .info {
    display: flex;

    img {
      width: 80px;
      min-width: 80px;
      border-radius: 4px;
      margin-right: 16px;
    }

    .default-image-placeholder {
      width: 80px;
      min-width: 80px;
      max-height: 57px;
      border-radius: 4px;
      margin-right: 16px;
      background: var(--background-color);
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 24px;
        color: var(--secondary-text-color);
      }
    }

    .text-info {
      h3 {
        font-size: 16px;
        color: var(--text-color);
        font-weight: normal;
        max-width: 28ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .description {
        font-size: 16px;
        margin-top: 8px;
        font-weight: 400;
        color: var(--secondary-text-color);
        margin: 0;
        font-size: 14px;
      }

      .period-card {
        margin-top: 8px;
        width: max-content;
        padding: 2px 8px;
        border-radius: 4px;
        background: var(--background-color);
        font-size: 16px;

        display: flex;
        align-items: center;

        i {
          margin-right: 8px;
          color: var(--secondary-text-color);
          font-size: 14px;
        }

        p {
          color: var(--secondary-text-color);
          font-size: 14px;
        }
      }
    }
  }

  .actions {
    width: 50px;
    display: flex;
    justify-content: flex-end;

    i {
      color: var(--primary-color);
    }

    .button {
      border: none;
      width: 100%;
      padding: 0px;
    }

    .button:hover {
      color: var(--primary-color);
      background: none;
    }

    .button:disabled {
      color: var(--secondary-text-color);
    }
  }

  &:disabled {
    cursor: initial;

    h3 {
      color: var(--secondary-text-color);
    }

    i {
      color: var(--secondary-text-color);
    }
  }

  @media (max-width: 720px) {
    padding: 8px 0px;

    &:hover {
      background: none;
    }

    .info {
      img {
        margin-right: 8px;
      }

      .default-image-placeholder {
        margin-right: 8px;
      }

      .text-info {
        h3 {
          max-width: 18ch;
        }

        .period-card {
          font-size: 12px;

          i {
            margin-right: 4px;
            font-size: 10px;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }
`;
