import api from './api';

export const getInitialTrails = ({ search }) =>
  api.get('/accounts/me/trails/actives/', {
    params: {
      search
    }
  });

export const getNextItems = url => api.get(url);
