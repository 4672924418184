import React, { PureComponent } from 'react';
import './style.scss';
import { getInvitations } from '~/api/account';
import ConnectionsMenu from './connections-menu';
import ConnectionsHeader from './connections-header';
import Loading from '~/components/loading';

const ConnectionsScreen = ({ invitations, loading, error }) => (
  <>
    <ConnectionsHeader />

    <div className="account-screen mb20">
      <ConnectionsMenu />

      <Loading inline visible={loading} />

      {!loading && invitations.length === 0 ? (
        <p className="no-itens-message">Todos os convites aparecerão aqui.</p>
      ) : null}

      {invitations.map(invitation => (
        <div key={invitation.account.id} className="connection-item">
          <div>
            <img
              className="rounded mr10 img32"
              src={invitation.account.avatar_128x0}
              alt={invitation.account.name}
            />
            {invitation.account.name}
          </div>
        </div>
      ))}
    </div>
  </>
);

class ConnectionsScreenEnhanced extends PureComponent {
  state = {
    loading: false,
    error: null,
    invitations: []
  };

  componentDidMount() {
    this.load();
  }

  handleResult = ({ data }) => {
    this.setState({
      invitations: [...this.state.invitations, ...data],
      loading: false,
      error: null
    });
  };

  handleError = err => {
    this.setState({
      loading: false,
      error: err
    });
  };

  load() {
    this.setState({ loading: true }, () => {
      getInvitations()
        .then(this.handleResult)
        .finally(this.handleError);
    });
  }

  render() {
    const { invitations, loading, error } = this.state;

    return (
      <ConnectionsScreen
        invitations={invitations}
        loading={loading}
        error={error}
      />
    );
  }
}

export default ConnectionsScreenEnhanced;
