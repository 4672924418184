import styled from 'styled-components';

export const StyledLinkPreview = styled.div.attrs(() => ({
  className: 'link-preview'
}))`
  width: 100%;
  margin: 0px 16px 16px 0px;
  border: 1px solid var(--border-color);
  border-radius: 4px;

  display: flex;
  align-items: center;

  .info-side {
    width: 100%;
    display: flex;
    align-items: center;

    a {
      display: inline-block;
      max-width: 50ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .actions-side {
    min-width: max-content;

    button {
      margin-right: 12px;
    }
  }

  .preview {
    min-width: 72px;
    height: 72px;
    background-color: var(--border-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    i {
      font-size: 28px;
      color: var(--secondary-text-color);
    }
  }

  @media (max-width: 720px) {
    .info-side {
      a {
        max-width: 15ch;
      }
    }
  }
`;
