import React from 'react';
import { connect } from 'react-redux';

import { getConfig } from '~/utils';

import useSite from '~/hooks/use-site';

import StyledFooter from './styles';

function Footer({ account, organization }) {
  const { LogoImg } = useSite();

  const config = getConfig(
    account.data ? account.data.organization_is_active_set : [],
    organization.activeOrganizationId
  );

  return (
    <StyledFooter
      className={
        process.env.REACT_APP_CURRENT_ENVIRONMENT !== 'prod' ? 'highlight' : ''
      }
    >
      <div className="main-content">
        <p>{config.organizationName} usa a tecnologia </p>
        <img src={LogoImg} alt="" />
        <p>
          {' '}
          -{' '}
          {process.env.REACT_APP_VERSION
            ? ` v${process.env.REACT_APP_VERSION}`
            : ''}{' '}
          {process.env.REACT_APP_CURRENT_ENVIRONMENT !== 'prod'
            ? `(${process.env.REACT_APP_CURRENT_ENVIRONMENT})`
            : ''}{' '}
        </p>
      </div>
    </StyledFooter>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps)(Footer);
