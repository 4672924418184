import styled from 'styled-components';

export const StyledLimitedTextarea = styled.div.attrs(() => ({
  className: 'limited-textarea'
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  textarea {
    width: 100%;
    border-color: var(--border-color);
    padding: 12px;
    margin-bottom: 6px;

    &::placeholder {
      color: var(--secondary-text-color);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: var(--secondary-text-color);
    }

    &::-ms-input-placeholder {
      color: var(--secondary-text-color);
    }
  }

  .counter {
    color: var(--secondary-text-color);
  }
`;
