import React from 'react';
import { NavLink } from 'react-router-dom';

import { getConfig } from '~/utils';

import { connect } from 'react-redux';

const AccountMenu = ({ account, organization }) => {
  const config = getConfig(
    account.data ? account.data.organization_is_active_set : [],
    organization.activeOrganizationId
  );

  const itens = [
    {
      label: 'Meus Dados',
      url: '/account'
    },
    {
      label: 'Alterar Senha',
      url: '/account/change-password'
    }
    // {
    //   label: 'Minhas Redes',
    //   url: '/account/social-networks'
    // }
  ];

  if (config.hasCustom) {
    itens.push({
      label: 'Meu Perfil',
      url: '/account/my-profile'
    });
  }

  return (
    <div className="account-menu">
      {itens.map(item => (
        <NavLink key={item.url} to={item.url} exact activeClassName="active">
          {item.label}
        </NavLink>
      ))}
    </div>
  );
};

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps)(AccountMenu);
