import React from 'react';
import RewardBadge from '~/assets/images/Reward-Badge.png';
import './style.scss';

const WololoRewardLine = ({ wololo_reward, amount_wololo_reward }) => (
  <div className="reward-line">
    <img src={RewardBadge} alt="star" className="reward-icon" />
    <span>
      {wololo_reward}
      {amount_wololo_reward && ` (Até ${amount_wololo_reward} premiado(s))`}
    </span>
  </div>
);

export default WololoRewardLine;
