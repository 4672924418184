import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateProfile } from '~/api/account';
import { getAddressByCEP } from '~/api/utils';
import { showNewPoints } from '~/services/tasks';
import Button from '../../button';
import ExternalLink from '../../external-link';
import { Form, Input, Select } from '../../form';
import { Col, Row } from '../../grid';
import Icon from '../../icon';

const ZIPCODE_REGEX = /(\d{5})-?(\d{3})/;

export const states = [
  { uf: 'AC', name: 'Acre' },
  { uf: 'AL', name: 'Alagoas' },
  { uf: 'AP', name: 'Amapá' },
  { uf: 'AM', name: 'Amazonas' },
  { uf: 'BA', name: 'Bahia' },
  { uf: 'CE', name: 'Ceará' },
  { uf: 'DF', name: 'Distrito Federal' },
  { uf: 'ES', name: 'Espírito Santo' },
  { uf: 'GO', name: 'Goiás' },
  { uf: 'MA', name: 'Maranhão' },
  { uf: 'MT', name: 'Mato Grosso' },
  { uf: 'MS', name: 'Mato Grosso do Sul' },
  { uf: 'MG', name: 'Minas Gerais' },
  { uf: 'PA', name: 'Pará' },
  { uf: 'PB', name: 'Paraíba' },
  { uf: 'PR', name: 'Paraná' },
  { uf: 'PE', name: 'Pernambuco' },
  { uf: 'PI', name: 'Piauí' },
  { uf: 'RJ', name: 'Rio de Janeiro' },
  { uf: 'RN', name: 'Rio Grande do Norte' },
  { uf: 'RS', name: 'Rio Grande do Sul' },
  { uf: 'RO', name: 'Rondônia' },
  { uf: 'RR', name: 'Roraima' },
  { uf: 'SC', name: 'Santa Catarina' },
  { uf: 'SP', name: 'São Paulo' },
  { uf: 'SE', name: 'Sergipe' },
  { uf: 'TO', name: 'Tocantins' }
];

export const stateOptions = states.map(({ uf, name }) => [uf, name]);
export const stateUFOptions = states.map(({ uf }) => [uf, uf]);

class AddrCep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zipcode: '',
      zipcode_invalid: false,

      street: '',
      street_invalid: false,

      neighborhood: '',
      neighborhood_invalid: false,

      city: '',
      city_invalid: false,

      uf: '',
      uf_invalid: false,

      number: '',
      number_invalid: false,

      complement: '',

      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.getAddress = this.getAddress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [`${name}_invalid`]: false, [name]: value });
  }

  handleSubmit() {
    if (!this.state.loading) {
      const required = [
        'zipcode',
        'street',
        'neighborhood',
        'city',
        'uf',
        'number'
      ];

      const invalid = required.filter(name => !this.state[name]);

      if (!invalid.length) {
        const {
          zipcode,
          street,
          neighborhood,
          city,
          uf,
          number,
          complement
        } = this.state;
        const matches = zipcode.match(ZIPCODE_REGEX);

        if (matches) {
          this.setState({ loading: true });
          const { name, email } = this.props.account.data.profile;

          updateProfile({
            addr_cep: matches[1] + matches[2],
            addr_state: uf,
            addr_city: city,
            addr_street: street,
            addr_number: number,
            addr_district: neighborhood,
            addr_complement: complement,
            name,
            email
          })
            .then(() => {
              showNewPoints(this.props.pointsToShow);
              this.props.onFinish();
            })
            .catch(() => {
              this.setState({ loading: false });
              alert('Erro');
            });
        } else {
          this.setState({ zipcode_invalid: true });
        }
      } else {
        invalid.map(name => this.setState({ [`${name}_invalid`]: true }));
      }
    }
  }

  getAddress() {
    const { zipcode } = this.state;
    const matches = zipcode.match(ZIPCODE_REGEX);
    this.setState({ loading: true });

    if (matches) {
      getAddressByCEP(matches[1] + matches[2])
        .then(({ data }) => {
          this.setState({
            street: data.street || '',
            neighborhood: data.neighborhood || '',
            city: data.city,
            uf: data.state
          });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ zipcode_invalid: true });
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col>
            <Input
              name="zipcode"
              placeholder="CEP"
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              guide={false}
              onChange={this.handleChange}
              onBlur={this.getAddress}
              value={this.state.zipcode}
              invalid={this.state.zipcode_invalid}
            />
            <div className="text-right zipcode-link">
              <ExternalLink to="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCep.cfm">
                Não sei meu CEP
              </ExternalLink>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Select
              name="uf"
              onChange={this.handleChange}
              value={this.state.uf}
              invalid={this.state.uf_invalid}
              disabled
            >
              <option>UF</option>
              {states.map(state => (
                <option key={state.uf} value={state.uf}>
                  {state.name}
                </option>
              ))}
            </Select>
          </Col>
          <Col md={6}>
            <Input
              name="city"
              placeholder="Cidade"
              onChange={this.handleChange}
              value={this.state.city}
              invalid={this.state.city_invalid}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              name="street"
              placeholder="Endereço"
              onChange={this.handleChange}
              value={this.state.street}
              invalid={this.state.street_invalid}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Input
              name="number"
              placeholder="Número"
              onChange={this.handleChange}
              value={this.state.number}
              invalid={this.state.number_invalid}
            />
          </Col>
          <Col md={6}>
            <Input
              name="complement"
              placeholder="Complemento (opcional)"
              onChange={this.handleChange}
              value={this.state.complement}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              name="neighborhood"
              placeholder="Bairro"
              onChange={this.handleChange}
              value={this.state.neighborhood}
              invalid={this.state.neighborhood_invalid}
            />
          </Col>
        </Row>
        <div>
          <Button color="primary" disabled={this.state.loading}>
            <Icon name="arrow-right" marginRight />
            <span>Enviar</span>
          </Button>
        </div>
      </Form>
    );
  }
}

AddrCep.defaultProps = {
  onFinish: () => {}
};

AddrCep.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

export default connect(mapStateToProps)(AddrCep);
