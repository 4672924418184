import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import moment from 'moment';
import { connect } from 'react-redux';
import PostComment from '../post-comment';
import PostHeader from '../post-header';
import PostImage from '../post-image';
import { PostCommentBox } from '~/components/comments';
import Tags from '../tags';
import Separator from '../separator';
import Button from '../button';
import Icon from '../icon';
import SharePost from '../share-post';
import Poll from '../poll';
import { getComments, postComment, postPoll } from '~/api/post';
import LikeButtons from '~/components/like-buttons';
import ReactPlayer from 'react-player';
import useComments from '~/hooks/use-comments';
import './style.scss';
import { addWololo } from '~/services/tasks';
import { closePostModal } from '~/store/ducks/post-modal';
import store from '~/store';

const CommentCount = ({ type, id }) => {
  const { count } = useComments(type, id);

  return count;
};

class ViewPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      next: '',
      loaded: false,
      showShare: false,
      newComment: '',
      showComment: false,
      comments: [],
      poll_no_percent: null,
      poll_yes_percent: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showShare = this.showShare.bind(this);
    this.hideOptions = this.hideOptions.bind(this);
    this.changePoll = this.changePoll.bind(this);
  }

  componentDidMount() {
    if (this.props.data.can_comment) {
      getComments(this.props.data.id)
        .then(({ data }) => {
          const { next, results } = data;

          const parseDate = date => `${moment(date).fromNow(true)} atrás`;

          const parsedResult = results.map(comment => ({
            ...comment,
            created_at: parseDate(comment.created_at),
            accountcomment_set: comment.accountcomment_set.map(sub => ({
              ...sub,
              created_at: parseDate(sub.created_at)
            }))
          }));

          this.setState({
            loaded: true,
            comments: parsedResult,
            next
          });
        })
        .catch(e => {
          this.setState({ loaded: true });
          console.error(e);
        });
    }
  }

  handleChange({ target }) {
    this.setState({ newComment: target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    postComment(this.props.data.id, this.state.newComment)
      .then(({ data }) => {
        const { id } = this.props.data.account.data;
        const {
          name,
          avatar,
          avatar_128x0
        } = this.props.data.account.data.profile;

        data.created_at = `${moment(data.created_at).fromNow(true)} atrás`;

        data.owner = {
          id,
          name,
          avatar,
          avatar_128x0
        };

        this.setState(({ comments }) => ({
          comments: [data, ...comments]
        }));

        this.hideOptions();
      })
      .catch(e => {
        console.error(e);
        alert('Erro');
      });
  }

  showShare() {
    this.setState({
      showShare: true,
      showComment: false
    });
  }

  hideOptions() {
    this.setState({
      showShare: false,
      newComment: '',
      showComment: false
    });
  }

  changePoll(value) {
    postPoll(this.props.data.id, value)
      .then(({ data: { poll_yes_percent, poll_no_percent } }) => {
        this.setState({ poll_no_percent, poll_yes_percent });
      })
      .catch(e => {
        console.error(e);
        alert('Erro');
      });
  }

  setShowComments = () => {
    this.setState({ showComment: true });
  };

  build_url = embed_id => {
    return `https://cdnapisec.kaltura.com/p/2601552/sp/0/playManifest/entryId/${embed_id}/format/url/protocol/https/flavorParamId/0/video.mp4`;
  };

  render() {
    return (
      <div className="view-post">
        <PostHeader
          info={
            this.props.data.channel ? this.props.data.channel : 'Publicação'
          }
          points={this.props.data.wololo_points}
          organization={this.props.data.organization}
          meTrail={this.props.data.me_trail}
          expire={this.props.data.close_at}
          me_create_wololo={this.props.data.me_create_wololo}
          channel={
            this.props.data.channel ? this.props.data.channel : 'publishing'
          }
        />
        <div className="card-body">
          {this.props.data.image && <PostImage url={this.props.data.image} />}
          <div className="title-date-task">
            <h3>{this.props.data.title}</h3>
            <p>
              Postado em{' '}
              {moment(this.props.data.published_at).format('DD/MM/YYYY')}
            </p>
          </div>
          {this.props.data.description && (
            <p className="text-center">{this.props.data.description}</p>
          )}
          <Separator />
          {this.props.data.content && (
            <div className="post-html">
              {renderHTML(this.props.data.content)}
            </div>
          )}

          {this.props.data.wololo_target_embedded && (
            <div className="kaltura-movie">
              <ReactPlayer
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                      onContextMenu: e => e.preventDefault()
                    }
                  }
                }}
                controls
                playbackRate
                onContextMenu={e => e.preventDefault()}
                onEnded={() => {
                  store.dispatch(closePostModal());
                  addWololo(this.props.data.id);
                  this.props.data.onFinish();
                }}
                url={this.build_url(this.props.data.wololo_target_embedded)}
              />
            </div>
          )}

          {this.props.data.tags && <Tags>{this.props.data.tags}</Tags>}
        </div>
        {this.props.data.have_poll && (
          <Poll
            onChange={this.changePoll}
            pollState={{
              poll_yes_percent: this.state.poll_yes_percent,
              poll_no_percent: this.state.poll_no_percent
            }}
          >
            {this.props.data.poll}
          </Poll>
        )}

        <div>
          {this.props.data.can_comment && (
            // todo renderizar o contador do redux
            <PostComment
              link
              className="inline-block"
              onClick={this.setShowComments}
            >
              <CommentCount type="post" id={this.props.data.id} />
            </PostComment>
          )}

          {this.props.data.can_like && (
            <LikeButtons
              id={this.props.data.id}
              type="post"
              // allowLikeOnce
            />
          )}
        </div>

        {this.props.data.can_comment && this.state.showComment && (
          <PostCommentBox id={this.props.data.id} />
        )}

        <footer style={{ position: 'relative' }}>
          <div className="card-footer-options">
            {this.state.showShare && (
              <SharePost
                post={this.props.data.id}
                link={this.props.data.link}
              />
            )}
          </div>
          <div className="card-footer-buttons">
            {this.state.showShare && (
              <>
                <Button outline color="primary" onClick={this.hideOptions}>
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      lineHeight: 1,
                      marginRight: 7
                    }}
                  >
                    &times;
                  </span>
                  <span>Cancelar</span>
                </Button>
              </>
            )}
            {!this.state.showShare && this.props.data.can_share && (
              <Button color="primary" onClick={this.showShare}>
                <Icon name="share" marginRight />
                Compartilhar
              </Button>
            )}
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  account: store.account
});

export default connect(mapStateToProps)(ViewPost);
