import React, { useState, useEffect, useCallback, useRef } from 'react';
import SearchInput from '~/components/search-input';
import Button from '~/components/button';
import queryString from 'query-string';
import useDebounced from '~/hooks/use-debounce';
import { withRouter } from 'react-router-dom';

import './style.scss';

const SearchArea = ({ location, history }) => {
  const [term, setTerm] = useState(
    queryString.parse(location.search).termo || ''
  );

  const debouncedTerm = useDebounced(term, 1000);

  const firstRef = useRef(true);

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
      return;
    }
    if (debouncedTerm) {
      history.push(`/?termo=${debouncedTerm}`);
    } else {
      history.push('/');
    }
  }, [debouncedTerm]);

  const onClean = useCallback(() => {
    setTerm('');
  });

  return (
    <div className="search-area">
      <span>Busca</span>

      <SearchInput
        text="Digite sua busca"
        onChange={e => setTerm(e.target.value)}
        value={term}
      />

      {term && (
        <Button fill small color="primary" onClick={onClean}>
          Limpar
        </Button>
      )}
    </div>
  );
};

export default withRouter(SearchArea);
