import React, { useMemo } from 'react';
import SiteContext from './site-context';
import SiteFactory from './site-factory';
import { REACT_APP_SITE } from '~/settings';

const SiteProvider = ({ children }) => {
  const currentSite = REACT_APP_SITE;
  const site = useMemo(() => SiteFactory.get(currentSite), [currentSite]);

  return (
    <SiteContext.Provider value={site}>
      <div className={`site-${currentSite}`}>{children}</div>
    </SiteContext.Provider>
  );
};

export default SiteProvider;
