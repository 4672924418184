import React from 'react';

import { StyledMarkAsDoneButton } from './styles';

function MarkAsDoneButton({ isDone, canManuallyAnswer, onClick }) {
  return (
    <StyledMarkAsDoneButton
      className={isDone ? 'done' : !canManuallyAnswer ? 'disabled' : ''}
      onClick={onClick}
      disabled={isDone || !canManuallyAnswer}
    >
      {!isDone && <div className="open-check"></div>}
      <p>{isDone ? 'Feito' : 'Marcar como feito'}</p>
    </StyledMarkAsDoneButton>
  );
}

export default MarkAsDoneButton;
