import React from 'react';
import store from '~/store';
import ViewPost from '~/components/view-post';
import { openPostModal } from '~/store/ducks/post-modal';

const kaltura = {
  watch(props) {
    return new Promise(resolve => {
      store.dispatch(openPostModal(<ViewPost data={props} />));
      resolve();
    });
  }
};

export default kaltura;
