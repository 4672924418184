import styled from 'styled-components';

export const StyledPDIRegisterModal = styled.div`
  width: 100%;

  h3 {
    font-size: 21px;
    line-height: 1;
    margin-bottom: 32px;
  }

  .fields {
    margin-bottom: 48px;

    .form-group {
      margin-bottom: 32px;

      label {
        margin-bottom: 4px;
      }

      select,
      input,
      textarea {
        margin: 0px;
        border-radius: 4px;
        padding: 8px 12px;
        min-height: 39px;
      }

      select {
        -webkit-appearance: listbox;
      }
    }

    .form-row {
      display: flex;

      .form-group {
        width: 50%;
      }

      .form-group:first-child {
        padding-right: 16px;
      }
    }
  }

  .actions {
    margin-bottom: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      min-width: 96px;
      margin: 0px 4px;
    }
  }

  & > .custom-loading {
    margin-bottom: 48px;
  }
`;
