import api from './api';

export const preview = id => api.get(`/tasks/${id}/preview/`);

export const getInfo = id => api.get(`/tasks/${id}/`);

export const postWololo = (id, data = {}, config = {}) =>
  api.post(`/tasks/${id}/wololos/`, data, config);

export const getComments = id => api.get(`/tasks/${id}/comment`);

export const postComment = (id, comment, parent = null) =>
  api.post(`/tasks/${id}/comment`, {
    comment,
    parent
  });

export const editComment = (id, comment, parent = null) =>
  api.put(`/taskcomment/${id}`, {
    comment,
    parent
  });

export const destroyComment = id => api.delete(`/taskcomment/${id}`);
