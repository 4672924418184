import LogoImg from './geracao-s-assets/images/logo.png';
import LogoImg2x from './geracao-s-assets/images/logo@2x.png';
import LogoReverseImg from './geracao-s-assets/images/logo-reverse-bg.png';
import LogoReverseImg2x from './geracao-s-assets/images/logo-reverse-bg@2x.png';
import './main.scss';

const GeracaoSSite = {
  name: 'Geração SESI SENAI',
  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=br.com.cni.talkative&hl=pt_BR',
  appStoreUrl:
    'https://apps.apple.com/br/app/gera%C3%A7%C3%A3o-sesi-senai/id1437909024',
  showStoreLinks: true,
  LogoImg,
  LogoImg2x,
  LogoReverseImg,
  LogoReverseImg2x,
  completeSignUp: true,
  tutorialDescription:
    'Curta a postagem do SESI sobre Indicadores Industriais no Instagram',
  actionGenderPoints: 400,
  pushApplicationServerKey:
    'BMplX3pQp1CyS43RjWTg3imHX2DWbgCTYwBF5jkcOOYA9hcwVGXp5rCk_EBRV1-ugvQuLKDibRlFoDArWx5O4C4',
  loginMinutes: 60 * 6,
  primaryColor: '#6bb230'
};

export default GeracaoSSite;
