import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Close = ({ onClick }) => (
  <button className="close" onClick={onClick} title="Fechar">
    &times;
  </button>
);

Close.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default Close;
