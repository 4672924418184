import React from 'react';
import { connect } from 'react-redux';

import store from '~/store';

import { openNewDefaultModal } from '~/store/ducks/new-default-modal';

import { getConfig } from '~/utils';

import EmailMark from '~/assets/images/email_mark.svg';

import Logo from '~/components/logo';
import InvitationList from '../invitation-list';

import StyledCustomHeader from './styles';

function CustomHeader({
  expanded,
  account,
  organization,
  invitationIsPendingSet,
  children
}) {
  const config = getConfig(
    account.data.organization_is_active_set,
    organization.activeOrganizationId
  );

  const handleOpeninvitationModal = () => {
    store.dispatch(
      openNewDefaultModal(
        <InvitationList invitations={invitationIsPendingSet} />
      )
    );
  };

  return (
    <StyledCustomHeader className={`${config.appLogo ? 'has-app-logo' : ''}`}>
      <div className={`main-content ${expanded ? 'expanded' : ''}`}>
        <div className="logo-side">
          <Logo customLogo={config.appLogo} />
        </div>
        <div className="custom-side">{children}</div>
        {invitationIsPendingSet.length > 0 ? (
          <button
            className="notification-btn"
            onClick={handleOpeninvitationModal}
          >
            <img src={EmailMark} alt="Notification icon" />
          </button>
        ) : null}
      </div>
    </StyledCustomHeader>
  );
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps)(CustomHeader);
