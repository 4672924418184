import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../button';
import Icon from '../../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
import { BASE_ENDPOINT } from '~/settings';

class Linkedin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (!this.state.loading) {
      const { account } = this.props.account.data.profile;
      const baseUrl = window.location.origin;
      let callback = `${baseUrl}/wololo-success/${this.props.id}`;

      if (this.props.pointsToShow) {
        callback = `${baseUrl}/wololo-success/${this.props.id}?points=${
          this.props.pointsToShow
        }`;
      }

      this.setState({ loading: true });

      const url = `${BASE_ENDPOINT}/accounts/me/oauthlinkedin/?id=${account}&callback_url=${callback}`;
      window.location.href = url;
    }
  }

  render() {
    return (
      <Button
        color="linkedin"
        disabled={this.state.loading}
        onClick={this.handleClick}
      >
        <Icon name="linkedin" marginRight />
        <span>Conectar</span>
      </Button>
    );
  }
}

Linkedin.defaultProps = {
  onFinish: () => {}
};

Linkedin.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Linkedin);
