import React from 'react';

import ChoiceOption from '~/components/choice-option';
import { CheckList, Input } from '~/components/form';

import './styles.scss';

function CreatorsFormQuestion({
  question,
  answers,
  setAnswer,
  currentQuestionAnswer,
  isTask = false,
  iconName
}) {
  const handleText = value => {
    setAnswer(answers => {
      const newAnswers = [...answers];
      const questionIndex = answers.findIndex(
        answer => answer.question === question.pk
      );
      newAnswers[questionIndex].text = value;
      return newAnswers;
    });
  };

  const handleBoolean = event => {
    const { value } = event.target;

    setAnswer(answers => {
      const newAnswers = [...answers];
      const questionIndex = answers.findIndex(
        answer => answer.question === question.pk
      );
      newAnswers[questionIndex].boolean = parseInt(value);
      return newAnswers;
    });
  };

  const handleOption = value => {
    setAnswer(answers => {
      const newAnswers = [...answers];
      const questionIndex = answers.findIndex(
        answer => answer.question === question.pk
      );
      newAnswers[questionIndex].options = [value];
      return newAnswers;
    });
  };

  const handleMultiOption = value => {
    setAnswer(answers => {
      const newAnswers = [...answers];

      const questionIndex = answers.findIndex(
        answer => answer.question === question.pk
      );

      const optionIndex = answers[questionIndex].options.indexOf(value);
      if (optionIndex !== -1) {
        newAnswers[questionIndex].options.splice(optionIndex, 1);
      } else {
        newAnswers[questionIndex].options.push(value);
      }

      return newAnswers;
    });
  };

  const handleNumber = value => {
    setAnswer(answers => {
      const newAnswers = [...answers];
      const questionIndex = answers.findIndex(
        answer => answer.question === question.pk
      );
      newAnswers[questionIndex].number = value;
      return newAnswers;
    });
  };

  const handleOnlyNumbers = value => {
    const newValue = value.replace(/\D/g, '');

    setAnswer(answers => {
      const newAnswers = [...answers];
      const questionIndex = answers.findIndex(
        answer => answer.question === question.pk
      );
      newAnswers[questionIndex].number = newValue;
      return newAnswers;
    });
  };

  const handleDate = value => {
    setAnswer(answers => {
      const newAnswers = [...answers];
      const questionIndex = answers.findIndex(
        answer => answer.question === question.pk
      );
      newAnswers[questionIndex].date = value;
      return newAnswers;
    });
  };

  const getIntegerField = () => {
    if (isTask) {
      return (
        <Input
          type="text"
          value={
            answers.filter(answer => answer.question === question.pk)[0].number
          }
          onChange={event => handleOnlyNumbers(event.target.value)}
          placeholder="Digite o número"
          inputCustom={isTask}
          iconName={iconName}
        />
      );
    }

    return (
      <Input
        type="number"
        value={
          answers.filter(answer => answer.question === question.pk)[0].number
        }
        onChange={event => handleNumber(event.target.value)}
        placeholder="Digite o número"
      />
    );
  };

  const questionFieldFactory = () => {
    switch (question.type) {
      case 'short_text':
        return (
          <Input
            value={
              answers.filter(answer => answer.question === question.pk)[0].text
            }
            onChange={event => handleText(event.target.value)}
            placeholder="Digite sua resposta"
          />
        );
      case 'text':
        return (
          <Input
            textarea
            value={
              answers.filter(answer => answer.question === question.pk)[0].text
            }
            onChange={event => handleText(event.target.value)}
            placeholder="Digite sua resposta"
          />
        );
      case 'boolean':
        const options = [
          {
            name: question.false_label,
            value: 0
          },
          {
            name: question.true_label,
            value: 1
          }
        ];

        return (
          <CheckList
            options={options}
            value={
              answers.filter(answer => answer.question === question.pk)[0]
                .boolean
            }
            onChange={handleBoolean}
            inline
          />
        );
      case 'choice':
        return (
          <ChoiceOption
            options={question.options}
            onChange={handleOption}
            answers={
              answers.filter(answer => answer.question === question.pk)[0]
                .options
            }
            type="radio"
          />
        );
      case 'multiple_choice':
        return (
          <ChoiceOption
            options={question.options}
            onChange={handleMultiOption}
            answers={
              answers.filter(answer => answer.question === question.pk)[0]
                .options
            }
            type="checkbox"
          />
        );
      case 'integer':
        return getIntegerField();
      case 'float':
        return (
          <Input
            type="number"
            value={
              answers.filter(answer => answer.question === question.pk)[0]
                .number
            }
            onChange={event => handleNumber(event.target.value)}
            placeholder="Digite o número"
            step="0.1"
          />
        );
      case 'date':
        return (
          <Input
            type="date"
            value={
              answers.filter(answer => answer.question === question.pk)[0]
                .date || ''
            }
            onChange={event => handleDate(event.target.value)}
            placeholder="Escolha a Data"
          />
        );
      default:
        return <p>Campo indisponível</p>;
    }
  };

  return (
    <div
      className={`creators-form-question ${
        isTask ? 'creators-form-task-question' : ''
      }`}
    >
      <p className="title">{question.title}</p>
      {questionFieldFactory()}
      {currentQuestionAnswer.hasError && (
        <p className="error-message">{currentQuestionAnswer.errorMessage}</p>
      )}
    </div>
  );
}

export default CreatorsFormQuestion;
