import { useEffect } from 'react';
import useSite from '~/hooks/use-site';

export default () => {
  const { name } = useSite();

  useEffect(
    () => {
      let title;

      title = document.querySelector('head title');

      if (!title) {
        title = document.createElement('title');
        document.querySelector('head').appendChild(title);
      }

      title.innerHTML = name;
    },
    [name]
  );

  return null;
};
