import React from 'react';

import Button from '~/components/button';
import Icon from '~/components/icon';

import { StyledPaginationControl } from './styles';

function PaginationControl({ currentPage, pages = [], onPageChange }) {
  const handleNext = () => {
    onPageChange(currentPage + 1);
  };

  const handlePrevious = () => {
    onPageChange(currentPage - 1);
  };

  const handlePageClick = page => {
    if (page === currentPage) {
      return;
    }

    onPageChange(page);
  };

  return (
    <StyledPaginationControl>
      <Button
        color="white"
        className="previous"
        onClick={handlePrevious}
        disabled={pages.length === 0 || currentPage === pages[0].value}
      >
        <Icon name="previous" />
      </Button>
      <div className="pages">
        {pages.map(page => (
          <Button
            key={page.key}
            color="secondary"
            className={`page-link${
              page.value === currentPage ? ' active' : ''
            }`}
            onClick={() => handlePageClick(page.value)}
          >
            {page.value}
          </Button>
        ))}
      </div>
      <Button
        color="white"
        className="next"
        onClick={handleNext}
        disabled={
          pages.length === 0 || currentPage === pages[pages.length - 1].value
        }
      >
        <Icon name="next" />
      </Button>
    </StyledPaginationControl>
  );
}

export default PaginationControl;
