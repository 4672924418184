import { combineReducers } from 'redux';
import account from './account';
import comments from './comments';
import feed from './feed';
import genericModal from './generic-modal';
import invitation from './invitation';
import likes from './likes';
import links from './links';
import loading from './loading';
import mediaModal from './media-modal';
import messageBar from './messageBar';
import messageModal from './message-modal';
import postModal from './post-modal';
import tutorial from './tutorial';
import trails from './trails';
import forum from './forum';
import questionnaire from './questionnaire';
import survey from './survey';
import organization from './organization';
import defaultModal from './default-modal';
import newDefaultModal from './new-default-modal';

const reducers = combineReducers({
  account,
  comments,
  feed,
  genericModal,
  invitation,
  likes,
  links,
  loading,
  mediaModal,
  messageBar,
  messageModal,
  postModal,
  tutorial,
  trails,
  forum,
  questionnaire,
  survey,
  organization,
  defaultModal,
  newDefaultModal
});

export default reducers;
