import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from '~/components/button';
import Icon from '~/components/icon';

import { StyledCourseStep } from './styles';

function CourseStep({
  courseId,
  stepId,
  title,
  status,
  parent,
  disabled,
  history
}) {
  return (
    <StyledCourseStep>
      <div className="info-side">
        <div className={`step-status ${status} ${!parent ? 'no-parent' : ''}`}>
          <div className="status-content"></div>
        </div>

        <p className="step-title">{title}</p>
      </div>
      <div className="actions-side">
        <Button
          color="white"
          className="link"
          onClick={() =>
            history.push(`/training/course/${courseId}/step/${stepId}`)
          }
          disabled={disabled}
        >
          <Icon name="next" />
        </Button>
      </div>
    </StyledCourseStep>
  );
}

export default withRouter(CourseStep);
