import React from 'react';

import Button from '../button';
import Modal from '~/components/modal';
import Review from '~/assets/images/instagram-external/pending-review.png';
import Review2x from '~/assets/images/instagram-external/pending-review@2x.png';

import './style.scss';

function TaskInValidationModal({ onClose }) {
  return (
    <Modal onClose={onClose}>
      <div className="task-in-validation-content text-center">
        <div>
          <img src={Review} alt="Review" srcSet={`${Review2x} 2x`} />

          <div className="title-holder">
            <h3>Em verificação</h3>
            <p>
              Nossa equipe precisa verificar se esta ação foi realizada conforme
              as instruções para entregar sua pontuação.
            </p>
          </div>
        </div>
        <div className="task-in-validation-body">
          <p>Aguarde e acompanhe o status pelo seu histórico.</p>
        </div>
        <div>
          <Button
            className="message-modal-ok"
            onClick={onClose}
            color="primary"
            fill
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default TaskInValidationModal;
