import React from 'react';
import './style.scss';

const RumorInfo = props => (
  <span className={`rumor-info ${props.isTrue ? 'true' : 'false'}`}>
    {props.isTrue ? 'Verdadeiro' : 'Falso'}
  </span>
);

export default RumorInfo;
