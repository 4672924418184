import React from 'react';

import './style.scss';

const SurveyQuestionStatus = ({ current, total }) => {
  return (
    <div className="survey-question-status">
      <span>{current} </span>
      de {total}
    </div>
  );
};

export default SurveyQuestionStatus;
