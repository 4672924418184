export const Types = {
  RESET: 'survey/RESET',
  SET: 'survey/SET',
  SELECT: 'survey/SELECT',
  SET_CURRENT_QUESTION: 'survey/SET_CURRENT_QUESTION'
};

const defaultState = {
  surveys: [],
  selectedSurvey: null,
  currentQuestionIndex: 0,
  currentQuestion: null,
  questionsStatus: 1
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.RESET:
      return defaultState;

    case `${Types.SET}`: {
      return {
        ...state,
        surveys: action.surveys
      };
    }

    case `${Types.SELECT}`: {
      return {
        ...state,
        selectedSurvey: action.survey
      };
    }

    case `${Types.SET_CURRENT_QUESTION}`: {
      const currentQuestion =
        state.selectedSurvey.questions[action.currentQuestion];

      return {
        ...state,
        currentQuestion,
        questionsStatus: action.currentQuestion + 1
      };
    }

    default:
      return state;
  }
}

export const reset = () => ({
  type: Types.RESET
});

export const setSurveys = surveys => ({
  type: Types.SET,
  surveys
});

export const setSurvey = survey => ({
  type: Types.SELECT,
  survey
});

export const setCurrentQuestion = index => ({
  type: Types.SET_CURRENT_QUESTION,
  currentQuestion: index
});
