import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../button';
import Icon from '../../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
import { BASE_ENDPOINT } from '~/settings';

class Instagram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (!this.state.loading) {
      const { account } = this.props.account.data.profile;
      const baseUrl = window.location.origin;
      let callback;

      if (this.props.pointsToShow) {
        callback = `${baseUrl}/wololo-success/${this.props.id}?points=${
          this.props.pointsToShow
        }`;
      } else if (this.props.task) {
        window.localStorage.task = JSON.stringify(this.props.task);
        callback = `${baseUrl}/wololo-continue`;
      } else {
        callback = `${baseUrl}/wololo-success/${this.props.id}`;
      }

      this.setState({ loading: true });

      const url = `${BASE_ENDPOINT}/accounts/me/oauthinstagram/?id=${account}&callback_url=${callback}`;
      window.location.href = url;
    }
  }

  render() {
    return (
      <Button
        color="instagram"
        disabled={this.state.loading}
        onClick={this.handleClick}
      >
        <Icon name="instagram" marginRight />
        <span>Conectar</span>
      </Button>
    );
  }
}

Instagram.defaultProps = {
  onFinish: () => {}
};

Instagram.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Instagram);
