import React from 'react';

import Card from '~/components/card';

import { StyledPDIParticipantItem } from './styles';

function PDIParticipantItem({
  avatar,
  name,
  email,
  department,
  profile,
  onClick
}) {
  return (
    <StyledPDIParticipantItem onClick={onClick}>
      <Card className="pdi-item">
        <div className="avatar">
          <img src={avatar} alt="Avatar" />
        </div>
        <div className="participant-info">
          <h3 className="participant-name">{name}</h3>
          <p className="description email">{email}</p>
          <p className="description">
            {department} • {profile}
          </p>
        </div>
      </Card>
    </StyledPDIParticipantItem>
  );
}

export default PDIParticipantItem;
