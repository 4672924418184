export const storeSurvey = data => {
  localStorage.setItem(`survey${data.pk}`, JSON.stringify(data));
};

export const removeSurvey = key => {
  localStorage.removeItem(key);
};

export const getStoragedSurvey = key => {
  const survey = localStorage.getItem(key);

  return JSON.parse(survey);
};

export const answerFactory = survey => {
  const data = {};

  data.justification = survey.justification || null;

  const questions = survey.questions.map(question => ({
    question: question.pk,
    answer: question.answer || null,
    justification: question.justification || null,
    comment: question.comment || null
  }));

  data.answers = questions;

  return data;
};
