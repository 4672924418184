import React from 'react';
import Faq from 'react-faq-component';

import Card from '~/components/card';

import './style.scss';

const actions = {
  title: 'Ações',
  rows: [
    {
      title: 'Quais as ações possíveis com o YouTube?',
      content: 'A ação disponível para essas plataformas são de assistir.'
    },
    {
      title: 'Onde posso ver as ações que realizei?',
      content:
        'Todas as ações que você finaliza ficam disponíveis na aba “realizadas”, localizada na barra superior. Para vê-las no aplicativo, é só clicar em “histórico”.'
    },
    {
      title: 'Quero saber quando tiver uma nova ação disponível. Como faço?',
      content:
        'É fácil! Acesse a sua "conta", através da barra lateral esquerda, clique em "configurações" e ative as notificações.'
    },
    {
      title: 'Como sei que realizei uma ação com sucesso?',
      content:
        'Uma ação é realizada com sucesso quando você a executa, seja para curtir, compartilhar, assistir a um vídeo ou comentar em uma rede social. Para ver o histórico de suas ações, é só clicar em “realizadas".'
    }
  ]
};

const publications = {
  title: 'Publicações',
  rows: [
    {
      title:
        'Os comentários dos usuários nas publicações ficam públicos para todos os usuários?',
      content: 'Sim.'
    },
    {
      title:
        'Tenho um blog, é possível que as publicações vá automaticamente para ferramenta?',
      content: 'Não é possível.'
    },
    {
      title:
        'Quais as formas de notificar os usuários quando fizer uma publicação?',
      content: 'É possível notificar via push ou e-mail.'
    },
    {
      title:
        'O que é o “Link Externo” dentro de "Publicações" e para que serve?',
      content:
        'O link externo serve para o usuário compartilhar quando estiver lendo determinada publicação. Dessa forma, o que será compartilhado não é o conteúdo que está no Talkative, e sim o link que foi colocado.'
    },
    {
      title: 'O que são essas interações de publicações?',
      content:
        'Assim como os blogs e publicações em redes sociais, também é possível habilitar funções para os usuários interagirem com o conteúdo. Para isso, temos o “Like” e a opção de “Comentar”.'
    },
    {
      title: 'O que é a seção “Publicações”?',
      content:
        'A seção “Publicações” funciona como um blog. Neste lugar o administrador publica artigos para sua base ler.'
    },
    {
      title:
        'Esse curtir e comentar dentro das publicações interfere algo na relevância?',
      content:
        'Esses dados servem para os administradores avaliarem o conteúdo que está sendo publicado.'
    },
    {
      title: 'Na publicação, posso usar como capa um vídeo ou gif?',
      content: 'Ainda não, mas em breve será possível.'
    },
    {
      title:
        'É possível que os membros da minha rede também criem publicações?',
      content: 'Não.'
    },
    {
      title:
        'É possível subir vídeo nativamente para colocar no corpo do vídeo em "Publicações"?',
      content: 'Ainda não, mas em breve será possível.'
    },
    {
      title:
        'As publicações feitas dentro da plataforma são vistas em algum lugar externo?',
      content:
        'Não, os artigos publicados são exibidos exclusivamente dentro da plataforma.'
    },
    {
      title:
        'A notificação push de uma publicação pode ter o texto personalizado?',
      content: 'Ainda não, mas em breve será possível.'
    }
  ]
};

const accounts = {
  title: 'Contas',
  rows: [
    {
      title: 'Posso usar quais redes sociais no Talkative?',
      content: 'A ação disponível para essas plataformas são de assistir.'
    },
    {
      title:
        'Vocês terão acesso aos meus dados das redes sociais quando eu conectá-las ao meu perfil?',
      content:
        'Não se preocupe! Os únicos dados que a gente tem acesso são aqueles que estão em modo “público” no seu perfil do Facebook. Não temos permissão para ver nenhuma informação privada (mensagens, links salvos, cartões, entre outras informações), ok?'
    },
    {
      title: 'Esqueci a senha. Como faço pra recuperá-la?',
      content:
        'Clique em "Esqueci minha senha" abaixo do login e preencha seu email. Você receberá um link para cadastrar uma nova senha. Se você não tem mais acesso ao e-mail cadastrado, clique aqui e o nosso suporte vai te ajudar.'
    },
    {
      title: 'Como altero a minha senha?',
      content:
        'Pra mudar a sua senha, você só precisa acessar sua "conta" na barra lateral e alterar no campo “edição de senha”.'
    }
  ]
};

const config = {
  animate: true
};

function FAQ() {
  return (
    <div className="faq">
      <Card className="faq-block">
        <Faq data={actions} config={config} />
        <Faq data={publications} config={config} />
        <Faq data={accounts} config={config} />
      </Card>
    </div>
  );
}

export default FAQ;
