import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showMessage } from '~/store/ducks/messageBar';

import { getCustomFields, saveCustomFields } from '~/api/account';

import Loading from '~/components/loading';
import AccountMenu from '~/screens/account/account-menu';
import Icon from '~/components/icon';
import customFieldsFactory from '~/components/custom-fields/custom-fields-factory';

import StyledMyProfile from './styles';

function MyProfile({ showMessage, ...props }) {
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState([]);

  const handleSave = () => {
    setLoading(true);

    setCustomFields(lastCustomFields =>
      lastCustomFields.map(customField => {
        customField.error = null;
        return customField;
      })
    );

    const filteredCustomFields = customFields.filter(
      customField => !customField.read_only
    );
    const data = filteredCustomFields.map(customField => ({
      key: customField.pk,
      value: customField.answer
    }));

    saveCustomFields({
      account_custom_fields: data
    })
      .then(() => {
        showMessage('Perfil atualizado', 'primary', 3000);
      })
      .catch(error => {
        const { status, data } = error.response;

        if (status === 400) {
          setCustomFields(lastCustomFields => {
            const newCustomFields = lastCustomFields.map(customField => {
              if (
                data.errors.some(
                  dataError =>
                    parseInt(dataError.key) === parseInt(customField.pk)
                )
              ) {
                const [fieldError] = data.errors.filter(
                  error => parseInt(error.key) === parseInt(customField.pk)
                );

                customField.error = fieldError.error;
              }
              return customField;
            });
            return newCustomFields;
          });

          return;
        }

        showMessage('Ocorreu um erro ao salvar.', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadCustomFields = () => {
    setLoading(true);

    getCustomFields()
      .then(response => {
        setCustomFields(response.data);

        if (response.data.length === 0) {
          props.history.push('/account');
        }
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao carregar o perfil.', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancelChanges = () => {
    props.history.push('/');
  };

  useEffect(() => {
    loadCustomFields();
  }, []);

  return (
    <StyledMyProfile>
      <form onSubmit={() => {}}>
        <div className="account-screen">
          <AccountMenu />
          <div className="section-wrapper">
            <h2>Meu Perfil</h2>
            <p>Mantenha seus dados de perfil sempre atualizados.</p>
          </div>

          <div className="form-section section-wrapper">
            <div style={{ maxWidth: '500px', width: '100%', flex: 'initial' }}>
              {customFields.map(customField => (
                <div key={customField.pk} className="input-group">
                  {customFieldsFactory(
                    customField.pk,
                    customField.label,
                    customField.options,
                    customField.answer,
                    customField.type,
                    customField.read_only,
                    customFields,
                    setCustomFields,
                    customField.error
                  )}
                </div>
              ))}
            </div>
          </div>

          <Loading inline visible={loading} />

          <div className="text-center section-wrapper mobile-buttons-full actions">
            <button
              onClick={handleCancelChanges}
              type="button"
              className="outline button primary"
            >
              <Icon name="cancel-circle" /> Cancelar alterações
            </button>
            <button
              type="button"
              className="button primary ml10"
              onClick={handleSave}
            >
              <Icon name="floppy-disk" /> Salvar
            </button>
          </div>
        </div>
      </form>
    </StyledMyProfile>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(MyProfile);
