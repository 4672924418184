import LogoImg from './talkative-assets/images/logo-color.png';
import LogoImg2x from './talkative-assets/images/logo-color@2x.png';
import LogoReverseImg from './talkative-assets/images/logo-color.png';
import LogoReverseImg2x from './talkative-assets/images/logo-color@2x.png';
import './main.scss';

const TalkativeSite = {
  name: 'Talkative',
  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=media.talkative.mobify',
  appStoreUrl: 'https://itunes.apple.com/br/app/talkative-mobify/id1235312079',
  showStoreLinks: true,
  LogoImg,
  LogoImg2x,
  LogoReverseImg,
  LogoReverseImg2x,
  completeSignUp: false,
  tutorialDescription: 'Curta a postagem do Talkative sobre endomarketing',
  actionGenderPoints: 150,
  pushApplicationServerKey:
    'BMplX3pQp1CyS43RjWTg3imHX2DWbgCTYwBF5jkcOOYA9hcwVGXp5rCk_EBRV1-ugvQuLKDibRlFoDArWx5O4C4',
  loginMinutes: null,
  primaryColor: '#04B89E'
};

export default TalkativeSite;
