import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '~/components/button';
import Icon from '~/components/icon';
import './style.scss';

const NavButton = ({ children, className, onClick }) => {
  return (
    <Button color="white" className={className} onClick={onClick}>
      {children}
    </Button>
  );
};

const NavOption = props => {
  const exact = props.exact;

  const content = (
    <div id={props.submenuId ? props.submenuId : ''} className="content-item">
      {!props.image && <Icon name={props.icon} />}
      {props.image && (
        <img className="nav-img" src={props.image} alt="Avatar" />
      )}
      <span>{props.name}</span>
      {props.suboptions && (
        <div className="submenu">
          {props.suboptions.map(suboption => (
            <div key={suboption.name}>
              {suboption.url ? (
                <NavLink
                  to={suboption.url}
                  className={classes}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon name="link" />
                  <span>{suboption.name}</span>
                </NavLink>
              ) : (
                <>
                  {suboption.page && suboption.page.content ? (
                    <NavLink
                      to={`/page/${suboption.page.id}`}
                      className={classes}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon name="link" />
                      <span>{suboption.name}</span>
                    </NavLink>
                  ) : (
                    <NavButton className={classes} onClick={suboption.onClick}>
                      <Icon name="link" />
                      <span>{suboption.name}</span>
                    </NavButton>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  let classes = ['nav-option'];

  if (props.className) {
    classes.push(props.className);
  }

  classes = classes.join(' ');

  return (
    <li key={props.url}>
      {props.url ? (
        <NavLink
          to={props.url}
          className={classes}
          activeClassName="active"
          isActive={(match, location) => {
            if (location.pathname === props.url) {
              return true;
            }

            if (
              props.url === '/forms' &&
              (location.pathname.indexOf('/performances') !== -1 ||
                location.pathname.indexOf('/survey') !== -1)
            ) {
              return true;
            }

            if (
              props.url === '/training' &&
              location.pathname.indexOf('/training') !== -1
            ) {
              return true;
            }

            if (
              props.url === '/metlife' &&
              location.pathname.indexOf('/metlife') !== -1
            ) {
              return true;
            }

            return false;
          }}
        >
          {content}
        </NavLink>
      ) : props.suboptions ? (
        <div className="nav-option">{content}</div>
      ) : (
        <NavButton className={classes} onClick={props.onClick}>
          {content}
        </NavButton>
      )}
    </li>
  );
};

export default NavOption;
