import React from 'react';
import { withRouter } from 'react-router-dom';

import Icon from '~/components/icon';

import { StyledDeparmentCard } from './styles';

function DepartmentCard({
  title,
  subtitle,
  description,
  color,
  url,
  readyOnly,
  history
}) {
  const handleClick = () => {
    if (readyOnly) {
      return;
    }
    history.push(url);
  };

  return (
    <StyledDeparmentCard onClick={handleClick} readyOnly={readyOnly}>
      <div className="text-content">
        <div className="status">
          {color && <span style={{ backgroundColor: color }}></span>}
          {description && <p>{description}</p>}
        </div>
        <h3>{title}</h3>
        <span className="departments">{subtitle}</span>
      </div>
      <div className="actions">{!readyOnly && <Icon name="next" />}</div>
    </StyledDeparmentCard>
  );
}

export default withRouter(DepartmentCard);
