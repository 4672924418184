import { useState, useEffect } from 'react';

import { getDownloads } from '~/api/organizations';

const useDownloads = organizationId => {
  const [downloads, setDownloads] = useState(null);

  useEffect(
    () => {
      getDownloads(organizationId)
        .then(response => {
          setDownloads(response.data);
        })
        .catch(() => {
          setDownloads([]);
        });
    },
    [organizationId]
  );

  return downloads;
};

export default useDownloads;
