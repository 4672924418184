import {
  skipTutorial as skipTutorialStorage,
  skip as setSkipTutorialStorage,
  clearSkip as clearSkipStorage
} from '~/services/tutorial';

import { Types as AccountTypes } from './account';

export const Types = {
  SKIP: 'account/SKIP'
};

const defaultState = {
  skip: skipTutorialStorage()
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Types.SKIP: {
      setSkipTutorialStorage();

      return {
        skip: true
      };
    }

    case AccountTypes.UNREGISTER: {
      clearSkipStorage();
      return defaultState;
    }

    default:
      return state;
  }
}

export const skipTutorial = () => ({
  type: Types.SKIP
});
