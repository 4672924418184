import _ from 'lodash';
import { useMemo } from 'react';
import useAccount from '~/hooks/use-account';

export default socialNetwork => {
  const account = useAccount();

  return useMemo(
    () => {
      return _.get(account, 'data.externalaccount_set', []).filter(
        externalAccount => externalAccount.type === socialNetwork
      );
    },
    [account]
  );
};
