import axios from 'axios';
import { BASE_ENDPOINT, TOKEN_KEY } from '../settings';

const api = axios.create({
  baseURL: BASE_ENDPOINT
});

api.interceptors.request.use(async config => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

export default api;
