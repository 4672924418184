import { useEffect } from 'react';
import _ from 'lodash';
import { getAllPages } from '~/api/misc';
import useAccount from '~/hooks/use-account';
import { useDispatch } from 'redux-react-hook';
import { setLinks } from '~/store/ducks/links';

export default () => {
  const account = useAccount();
  const id = _.get(account, 'data.id');
  const dispatch = useDispatch();

  useEffect(
    () => {
      (async function() {
        if (id) {
          getAllPages(account.data.organization_is_active_set, 0, []).then(
            data => {
              dispatch(setLinks(data));
            }
          );
        }
      })();
    },
    [id]
  );

  return null;
};
