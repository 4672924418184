import React, { Component } from 'react';
import ForumPost from '../forum-post';
import Card from '~/components/card';

import './style.scss';

class ForumFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  onChange = (currentResult, newObject) => {
    const newResults = [...this.props.results];

    const newResult = {
      ...currentResult,
      object: newObject
    };

    const index = this.props.results.indexOf(currentResult);

    newResults.splice(index, 1, newResult);

    this.props.onChange(newResults);
  };

  onScroll(e) {
    const { offsetHeight, scrollHeight } = document.body;
    const scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (offsetHeight + offsetHeight * 0.5 + scrollTop >= scrollHeight) {
      this.props.onScrollFinish();
    }
  }

  render() {
    return (
      <div className="forum-feed">
        {this.props.search && this.props.total !== null && (
          <Card>
            <b>{this.props.total}</b>{' '}
            {this.props.total === 1 ? 'resultado' : 'resultados'} para a busca{' '}
            <b>{this.props.search}</b>
          </Card>
        )}
        {this.props.results.map(res => (
          <ForumPost
            key={res.id}
            onChange={newObject => this.onChange(res, newObject)}
            forumPost={res}
            owner={res.owner}
            onFinishItem={() => this.props.onFinishItem(res)}
          />
        ))}
      </div>
    );
  }
}

export default ForumFeed;
