import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input } from '../../form';
import { Row, Col } from '../../grid';
import Button from '../../button';
import Icon from '../../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
import { updateProfile } from '~/api/account';
import { showMessage } from '~/store/ducks/messageBar';
import { withRouter } from 'react-router-dom';
import store from '~/store';
import {
  openGenericModal,
  closeGenericModal
} from '~/store/ducks/generic-modal';
import { ExternalActionExplanation } from '~/components/external-action-explanation';
import AlertModal from '~/components/alert-modal';

class Email extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      email_invalid: false,
      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [`${name}_invalid`]: false, [name]: value });
  }

  handleSubmit() {
    const { showMessage, history } = this.props;

    if (!this.state.loading) {
      const required = ['email'];
      const invalid = required.filter(name => !this.state[name]);

      if (!invalid.length) {
        this.setState({ loading: true });
        const { email } = this.state;
        const { name } = this.props.account.data.profile;
        //       const { name, email } = this.props.account.data.profile;

        const onContinue = () => {
          store.dispatch(closeGenericModal());
          updateProfile({
            email: `${email}`,
            name
          })
            .then(() => {
              store.dispatch(
                openMessageModal(
                  <AlertModal
                    title="Obrigado."
                    content="Você receberá um e-mail após análise."
                  />
                )
              );
              this.props.onFinish();
            })
            .catch(error => {
              this.setState({ loading: false });
              if (
                error.response.status === 400 ||
                error.response.status === '400'
              ) {
                let msg = error.response.data.errors[0];
                if (typeof msg === 'string') {
                  showMessage(msg, 'danger');
                  return;
                }
              }

              history.push('/unexpected-error');
            });
        };

        const onCancel = () => {
          store.dispatch(closeGenericModal());
          this.props.onFinish();
        };

        store.dispatch(
          openGenericModal(
            <ExternalActionExplanation
              onContinue={onContinue}
              onCancel={onCancel}
              message="Para esta ação, enviaremos um e-mail de confirmação para o endereço informado"
            />
          )
        );
      } else {
        invalid.map(name => this.setState({ [`${name}_invalid`]: true }));
      }
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col md={12}>
            <Input
              name="email"
              type="email"
              placeholder="Digite seu e-mail"
              autoCorrect="off"
              required
              invalid={this.state.email_invalid}
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <div>
          <Button color="primary" disabled={this.state.loading}>
            <Icon name="arrow-right" marginRight />
            <span>Enviar</span>
          </Button>
        </div>
      </Form>
    );
  }
}

Email.defaultProps = {
  onFinish: () => {}
};

Email.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal, showMessage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Email));
