import api from './api';

export const getDownloads = id => api.get(`/organizations/${id}/downloads/`);

export const getPages = id => api.get(`/organizations/${id}/pages/`);

// export const signUp = (name, username, password) =>
//   api.post('/signup/email-and-password', {
//     device: 'web',
//     name,
//     username,
//     password
//   });
