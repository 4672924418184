import styled from 'styled-components';

export const StyledPDIDetailsModal = styled.div.attrs(() => ({
  className: 'pdi-details-modal'
}))`
  width: 100%;
  margin-bottom: 24px;

  h3 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .details-table {
    width: 100%;
    min-height: 600px;
    border: 1px solid var(--border-color);
    border-radius: 4px;

    .details-header {
      border-bottom: 1px solid var(--border-color);
      padding: 16px;

      display: flex;
      align-items: center;

      & > * {
        margin-right: 12px;
      }

      .is-late-message {
        color: #f05050;
      }
    }

    .details-content {
      width: 100%;
      display: flex;

      .pdi-details-side {
        flex: 1;
        height: 538px;
        border-right: 1px solid var(--border-color);
        padding: 16px;
        overflow-y: scroll;

        .detail-info-line {
          width: 100%;
          display: flex;
        }

        .detail-info {
          width: 100%;
          margin-bottom: 24px;

          h3 {
            font-size: 16px;
            margin: 0px;
            font-weight: normal;
          }

          p {
            color: var(--secondary-text-color);
          }
        }
      }

      .timeline-side {
        flex: 1;
        min-height: 538px;
        background-color: var(--background-color);
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .timeline-list-wrapper {
          min-height: 538px;
          max-height: 538px;
          overflow-y: scroll;
        }

        .timeline {
          /* min-height: 538px; */
          /* overflow-y: scroll; */

          .empty-list {
            margin-top: 8px;
            p {
              font-size: 14px;
            }
          }

          .custom-loading {
            margin: 8px 0px;
          }
        }

        .comment-field {
          width: 100%;
          background: var(--white-color);
          box-shadow: 1px 1px 16px var(--background-placeholder-color);
          padding: 8px;
          border-left: 1px solid var(--border-color);
        }
      }
    }
  }

  @media (max-width: 720px) {
    .details-table {
      .details-content {
        flex-direction: column;

        .pdi-details-side {
          border-right: none;
        }

        .timeline-side {
          min-height: 340px;

          .timeline-list-wrapper {
            min-height: 340px;
            max-height: 340px;
          }
        }
      }
    }
  }
`;
