import styled from 'styled-components';

const StyledPendingModificationModal = styled.div`
  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .content-wrapper {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;

    .button {
      margin-left: 8px;
    }
  }

  @media (max-width: 720px) {
    .modal-content {
      padding: 8px;
    }
  }
`;

export default StyledPendingModificationModal;
