import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { closeDefaultModal } from '~/store/ducks/default-modal';

import Button from '~/components/button';

import StyledPendingModificationModal from './styles';

function PendingModificationModal({ onAction, closeDefaultModal }) {
  const handleClick = () => {
    closeDefaultModal();
    onAction();
  };

  return (
    <StyledPendingModificationModal>
      <h3 className="title">As modificações não foram salvas</h3>
      <p className="subtitle">Deseja continuar sem salvar as alterações?</p>

      <div className="content-wrapper">
        <Button color="primary" className="outline" onClick={handleClick}>
          Continuar sem salvar
        </Button>
        <Button color="primary" onClick={() => closeDefaultModal()}>
          Cancelar
        </Button>
      </div>
    </StyledPendingModificationModal>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(PendingModificationModal));
