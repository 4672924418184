import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import './styles.scss';

const BreadcrumbLinksDropdown = ({ links, onClickCallback, history }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const goToDestination = destinationRoute => {
    if (onClickCallback) {
      onClickCallback(destinationRoute);
    }
    history.push(destinationRoute);
  };

  const handleBlur = e => {
    const currentTarget = e.currentTarget;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setDropdownOpen(false);
      }
    }, 0);
  };

  return (
    <>
      <span className="font-book">/</span>
      <div className="links-dropdown" onBlur={handleBlur}>
        <button
          type="button"
          className="toggle-dropdown"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          ...
        </button>
        <div className={`dropdown-content ${dropdownOpen ? 'open' : ''}`}>
          {links.map(link => (
            <button
              type="button"
              key={link.id}
              onClick={() => goToDestination(link.route)}
              className="no-button"
            >
              {link.title}
            </button>
          ))}
        </div>
      </div>
      <span className="font-book">/</span>
    </>
  );
};

export default withRouter(BreadcrumbLinksDropdown);
