import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import useSite from '~/hooks/use-site';

import { limitFileName } from '~/utils';

import Icon from '~/components/icon';
import Button from '~/components/button';
import ProgressBar from '~/components/progress-bar';

import { StyledDropzone } from './styles';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  marginTop: '10px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#EDF1F2',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  color: '#bbbec4',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const acceptStyle = {
  borderColor: '#8f9194'
};

const rejectStyle = {
  borderColor: '#f05050'
};

function Dropzone({
  placeholder,
  accept = '*',
  onDrop,
  disabled,
  sendedFileName,
  onRemove,
  progress,
  hasPreview,
  factoryPreview
}) {
  const siteSettings = useSite();

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept,
    onDrop,
    disabled,
    noDragEventsBubbling: true
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <StyledDropzone>
      {hasPreview ? (
        factoryPreview()
      ) : (
        <div className={`container ${disabled ? 'disabled' : ''}`}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Icon name="sendfile" />
            <p>
              {placeholder ||
                'Arraste e solte seus arquivos aqui, ou clique para selecionar'}
            </p>
            {sendedFileName && (
              <div className="sended-file">
                <p className="sended-file-name">
                  {limitFileName(sendedFileName)}
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="footer">
        {progress && !progress.completed && progress.percentage > 0 && (
          <ProgressBar
            completed={progress.percentage}
            isLabelVisible={false}
            bgColor={siteSettings.primaryColor}
            height="8px"
          />
        )}
        <div className="actions">
          {progress &&
            progress.onCancel &&
            !progress.completed &&
            progress.percentage > 0 && (
              <Button color="link" onClick={progress.onCancel}>
                <Icon name="cancel-circle" />
                Cancelar
              </Button>
            )}
          {onRemove && sendedFileName && !disabled && (
            <Button color="link" onClick={onRemove}>
              <Icon name="cancel-circle" />
              Remover
            </Button>
          )}
        </div>
      </div>
    </StyledDropzone>
  );
}

export default Dropzone;
