import _ from 'lodash';
import React, { PureComponent, useRef, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import useVersion from '~/hooks/use-version';
import './style.scss';
import ConnectionsMenu from './connections-menu';
import ConnectionsHeader from './connections-header';
import NetworkIllustration from '~/assets/images/network-illustration.svg';
import useSite from '~/hooks/use-site';
import Button from '~/components/button';

// example
// https://talkative.media/onboard?uuid=12312312-1e4c-46c7-acde-1c965b89bbf2 =>
// http://current-size:port/onboard?uuid=12312312-1e4c-46c7-acde-1c965b89bbf2 =>

const getSameSiteInvitationUrl = url => {
  const splited = url.split('onboard?');
  if (splited.length === 2) {
    return `${window.location.origin}/onboard?${splited[1]}`;
  } else {
    return url;
  }
};

const ConnectionsInviteScreen = ({ url }) => {
  const version = useVersion();
  const { name } = useSite();

  const [copied, setCopied] = useState(false);

  const inputRef = useRef();

  const onCopy = useCallback(() => {
    inputRef.current.select();
    document.execCommand('copy');
    setCopied(true);
  }, []);

  return (
    <>
      <ConnectionsHeader />

      <div className="account-screen">
        <ConnectionsMenu />

        <div className="invite-connections gray-bg ">
          <div className="text-center invite-connections-center">
            <img src={NetworkIllustration} alt="Network Illustration" />

            <h2>Aumente sua rede</h2>

            <p>
              Convide parentes e amigos para o {name} <br /> e ajude a aumentar
              nosso alcance.
            </p>

            <div className="box-form">
              <legend>Compartilhe o link abaixo para convidar pessoas</legend>

              <div>
                {version === 'desktop' ? (
                  <input
                    className="input"
                    type="text"
                    onChange={() => {}}
                    ref={inputRef}
                    value={url}
                  />
                ) : (
                  <textarea
                    rows={3}
                    className="input"
                    type="text"
                    ref={inputRef}
                    onChange={() => {}}
                    value={url}
                  />
                )}
              </div>

              <Button type="button" color="primary" onClick={onCopy}>
                {copied ? 'Copiado com sucesso!' : 'Copiar'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

class ConnectionsInviteScreenEnhanced extends PureComponent {
  render() {
    const activeOrganizationIndex = this.props.account.data.organization_is_active_set.findIndex(
      org => {
        return org.id === this.props.organization.activeOrganizationId;
      }
    );

    const url = _.get(
      this.props,
      `account.data.organization_is_active_set[${
        activeOrganizationIndex !== -1 ? activeOrganizationIndex : 0
      }].invitation_url`
    );

    return <ConnectionsInviteScreen url={getSameSiteInvitationUrl(url)} />;
  }
}

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(mapStateToProps)(ConnectionsInviteScreenEnhanced);
