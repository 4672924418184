import React from 'react';
import YouTube from 'react-youtube';

import MarkAsDoneButton from '~/components/mark-as-done-button';

import { StyledReadVideo } from './styles';

function ReadVideo({
  contentType,
  title,
  videoUrl,
  isDone,
  canManuallyAnswer,
  markAsDone
}) {
  const getYoutubeVideoId = url => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  };

  return (
    <StyledReadVideo>
      <div className="step-section">
        <div className="title">
          <h3>{title}</h3>
          <MarkAsDoneButton
            isDone={isDone}
            canManuallyAnswer={canManuallyAnswer}
            onClick={() =>
              markAsDone({
                ignoreAction: true
              })
            }
          />
        </div>
      </div>
      <div className="step-section">
        <YouTube
          videoId={getYoutubeVideoId(videoUrl)}
          containerClassName="youtube-video-player"
          onEnd={markAsDone}
        />
      </div>
    </StyledReadVideo>
  );
}

export default ReadVideo;
