import React from 'react';
import notFoundSvg from '~/assets/images/not-found.svg';
import './style.scss';

const ContentCardError = () => {
  return (
    <div className="card-text-center">
      <img src={notFoundSvg} alt="Content not found" width="200px" />
      <h3>Ops! Tem algo errado aí...</h3>
      <div>
        <p>
          Parece que você está tentando acessar um conteúdo que não existe em
          sua comunidade.
        </p>
        <p>
          Verifique se o link está correto ou entre em contato com o suporte.
        </p>
      </div>
    </div>
  );
};

export default ContentCardError;
