import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getStep, registerReply, registerReplyDone } from '~/api/training';

import { showMessage } from '~/store/ducks/messageBar';

import downloadFile from '~/services/download';

import { copyToClipboard } from '~/utils';

import CustomLoading from '~/components/custom-loading';
import PageHeader from '~/components/page-header';
import CustomHeader from '~/components/custom-header';
import Button from '~/components/button';
import Icon from '~/components/icon';
import Breadcrumb from '~/components/breadcrumb';
import trailContentFactory from '~/components/trail-contents/trail-content-factory';
import EmptyList from '~/components/empty-list';

import { StyledCourseContent } from './styles';

function CourseContent({ organization, history, location, showMessage }) {
  const { activeOrganizationId } = organization;
  const courseId = location.pathname
    .split('/step/')[0]
    .replace('/training/course/', '');
  const stepId = location.pathname.split('/step/')[1];

  // const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);

  const [totalSteps, setTotalSteps] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [stepLabel, setStepLabel] = useState('');

  const [previousStep, setPreviousStep] = useState(null);
  const [nextStep, setNextStep] = useState(null);
  const [nextRequiredStep, setNextRequiredStep] = useState(null);

  const [stepTitle, setStepTitle] = useState('');
  const [contentType, setContentType] = useState('');
  const [contentTypeLabel, setContentTypeLabel] = useState('');
  const [canAnswer, setCanAnswer] = useState(false);
  const [hasAnswer, setHasAnswer] = useState(false);
  const [stepData, setStepData] = useState({});
  const [stepLoading, setStepLoading] = useState(false);
  const [stepIsDone, setStepIsDone] = useState(false);
  const [canManuallyAnswer, setCanManuallyAnswer] = useState(false);
  const [stepsNeedValidation, setStepsNeedValidation] = useState(false);

  const handleBack = () => {
    history.push(`/training/course/${courseId}`);
  };

  const changeStep = step => {
    history.push(`/training/course/${courseId}/step/${step}`);
  };

  const handleDownload = fileData => {
    downloadFile(fileData.url)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileData.name}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(e => {
        showMessage('Ocorreu um erro ao fazer o download', 'danger', 3000);
      })
      .finally(() => {
        setStepLoading(false);
        if (stepsNeedValidation) {
          setCanManuallyAnswer(true);
        } else {
          setStepIsDone(true);
        }
      });
  };

  const handleLinkClick = data => {
    if (data.copy) {
      copyToClipboard(data.url);
      showMessage('Link copiado para a área de transferência', 'success', 2000);
    } else {
      window.open(data.url, '_blank').focus();
    }

    setStepLoading(false);
    if (stepsNeedValidation) {
      setCanManuallyAnswer(true);
    } else {
      setStepIsDone(true);
    }
  };

  const handleAction = (data, response = null) => {
    switch (contentType) {
      case 'download_file':
        if (data.ignoreDownload) {
          setStepLoading(false);
          if (stepsNeedValidation) {
            setCanManuallyAnswer(true);
          } else {
            setStepIsDone(true);
          }
        } else {
          handleDownload(data);
        }
        break;
      case 'read_link':
        handleLinkClick(data);
        break;
      case 'read_video':
        setStepLoading(false);
        if (stepsNeedValidation) {
          setCanManuallyAnswer(true);
        } else {
          setStepIsDone(true);
        }
        break;
      case 'kaltura_video':
        setStepLoading(false);
        if (stepsNeedValidation) {
          setCanManuallyAnswer(true);
        } else {
          setStepIsDone(true);
        }
        break;
      case 'single_choice':
        loadContent(stepId);
        break;
      case 'multi_choice':
        loadContent(stepId);
        break;
      case 'write_text':
        loadContent(stepId);
        break;
      default:
        showMessage('Ocorreu um erro inesperado', 'danger', 3000);
        setStepLoading(false);
    }
  };

  const markAsDone = data => {
    setStepLoading(true);

    // Impede que a ação de clique no botão "Feito" seja executada
    if (stepIsDone && data.ignoreAction) {
      return;
    }

    if (stepIsDone) {
      handleAction(data);
      return;
    }

    var replyFunc = registerReply;
    if (data.ignoreAction && stepsNeedValidation) {
      replyFunc = registerReplyDone;
    }

    replyFunc(activeOrganizationId, courseId, stepId, data)
      .then(response => {
        if (data.ignoreAction) {
          setStepLoading(false);
          setStepIsDone(true);
        } else {
          handleAction(data, response);
        }
      })
      .catch(error => {
        var messageErro = '';
        error.response.data.errors.forEach(error => {
          messageErro += error;
        });
        showMessage(messageErro, 'danger', 3000);
        setStepLoading(false);
      });
  };

  const loadContent = step => {
    setLoading(true);

    getStep(activeOrganizationId, courseId, step)
      .then(response => {
        const {
          trail_name_plural,
          breadcrumb,
          step_count,
          step_idx,
          step_name,
          step_name_plural,
          prev_step_pk,
          next_step_pk,
          next_required_step_pk,
          title,
          type,
          type_name,
          can_answer,
          has_answer,
          can_manually_answer,
          steps_need_validation,
          object
        } = response.data;

        const bread = breadcrumb.map(link => {
          link.id = link.pk;
          link.route = `/training/category/${link.pk}`;
          delete link.pk;
          return link;
        });

        bread[0].id = `${Date.now()}1`;
        bread[0].route = '/training';

        bread[1].id = `${Date.now()}2`;
        bread[1].route = '/training/my-courses';

        bread[bread.length - 1].route = `/training/course/${courseId}`;
        bread[bread.length - 1].id = Date.now();

        setPageTitle(trail_name_plural);
        setBreadcrumbLinks(bread);
        setTotalSteps(step_count);
        setCurrentStep(step_idx);
        setStepLabel(step_count === 1 ? step_name : step_name_plural);
        setPreviousStep(prev_step_pk);
        setNextStep(next_step_pk);
        setNextRequiredStep(next_required_step_pk);
        setStepTitle(title);
        setContentType(type);
        setContentTypeLabel(type_name);
        setCanAnswer(can_answer);
        setHasAnswer(has_answer);
        setStepIsDone(has_answer);
        setCanManuallyAnswer(can_manually_answer);
        setStepsNeedValidation(steps_need_validation);
        setStepData(object);
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao carregar o conteúdo', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
        setStepLoading(false);
      });
  };

  useEffect(() => {
    loadContent(stepId);
  }, [stepId]);

  return (
    <StyledCourseContent>
      <CustomHeader expanded>
        <div className="header-actions">
          <div className="left-side">
            <Button color="primary" className="link" onClick={handleBack}>
              <Icon name="back" />
              Voltar
            </Button>
          </div>
          <div className="right-side"></div>
        </div>
      </CustomHeader>

      {!loading && (
        <div className="page-content">
          <PageHeader>
            <div className="text-info">
              <h1>{pageTitle}</h1>
            </div>
          </PageHeader>

          <Breadcrumb
            links={breadcrumbLinks}
            compressionStart={10}
            onClickCallback={() => {}}
          />

          <div className="step-content-card">
            <div className="step-header">
              <div className="pagination-control">
                <Button
                  color="primary"
                  className="link"
                  onClick={() => changeStep(previousStep)}
                  disabled={!previousStep}
                >
                  <Icon name="previous" />
                </Button>

                <p>
                  {currentStep}/{totalSteps} {stepLabel}
                </p>

                <Button
                  color="primary"
                  className="link"
                  onClick={() => changeStep(nextStep)}
                  disabled={!nextStep}
                >
                  <Icon name="next" />
                </Button>
              </div>
            </div>
            <div className="step-content">
              {(canAnswer || hasAnswer) &&
                trailContentFactory(
                  stepTitle,
                  contentType,
                  contentTypeLabel,
                  stepData,
                  setStepData,
                  stepIsDone,
                  canManuallyAnswer,
                  markAsDone
                )}

              {!canAnswer && !hasAnswer && (
                <>
                  <EmptyList
                    message="Este conteúdo não pode ser exibido no momento pois possui dependências"
                    className="empty-list"
                  />
                  {nextRequiredStep && (
                    <Button
                      color="primary"
                      className="outline next-required-step"
                      onClick={() => changeStep(nextRequiredStep)}
                    >
                      Próxima atividade
                    </Button>
                  )}
                </>
              )}
            </div>

            {stepLoading && (
              <div className="loading-wrapper">
                <CustomLoading type="spin" height={64} width={64} fluid />
              </div>
            )}
          </div>
        </div>
      )}

      {loading && (
        <CustomLoading
          type="spin"
          height={56}
          width={56}
          fluid
          className="screen-loading"
        />
      )}
    </StyledCourseContent>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseContent));
