import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getAccount } from '~/api/account';
import { getSurvey, sendForm } from '~/api/creators';
import { getSurveyCustom, sendFormCustom } from '~/api/forms';

import store from '~/store';
import { showMessage } from '~/store/ducks/messageBar';
import { registerAccount } from '~/store/ducks/account';
import { closeDefaultModal } from '~/store/ducks/default-modal';
import { openMessageModal } from '~/store/ducks/message-modal';
import { getTaskInformation, showFullPoints } from '~/services/tasks';

import Button from '../button';
import CustomLoading from '../custom-loading';
import CreatorsFormTask from './creators-form-task';
import CreatorsFormQuestion from './creators-form-question';

import './styles.scss';

function CreatorsForm({
  actionId,
  onFinish,
  showMessage,
  closeDefaultModal,
  openMessageModal,
  organization: { activeOrganizationId },
  ...props
}) {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sendLoading, setSendLoading] = useState(false);
  const [haveAnswer, setHaveAnswer] = useState(false);

  const createAnswerStructure = question => {
    const answer = {
      question: question.pk,
      hasError: false,
      errorMessage: ''
    };

    switch (question.type) {
      case 'short_text':
        answer.text = null;
        break;
      case 'text':
        answer.text = null;
        break;
      case 'boolean':
        answer.boolean = null;
        break;
      case 'choice':
        answer.options = [];
        break;
      case 'multiple_choice':
        answer.options = [];
        break;
      case 'integer':
        answer.number = null;
        break;
      case 'float':
        answer.number = null;
        break;
      case 'date':
        answer.date = null;
        break;
      default:
        break;
    }

    return answer;
  };

  const checkAnswers = () => {
    return answers.some(
      answer => answer.number !== null && answer.number !== ''
    );
  };

  const updateErrorState = error => {
    setAnswers(answersErrorList => {
      error.response.data.errors.forEach((error, index) => {
        answersErrorList[index].hasError = error.field != null;
        answersErrorList[index].errorMessage = error.error ? error.error : '';
      });
      return answersErrorList;
    });
  };

  const handleSendFormTask = () => {
    const checker = checkAnswers();
    if (!checker) {
      showMessage('É necessário preencher ao menos um campo', 'danger', 3000);

      return;
    }

    setSendLoading(true);

    const data = {
      answers
    };

    // Obtém as informações da task
    // Depois envia a resposta da ação e abre o modal de realização
    getTaskInformation(props.id)
      .then(res => {
        sendFormCustom(activeOrganizationId, actionId, data)
          .then(response => {
            let taskData = res;
            taskData.points = response.data.points;
            taskData.bonus = response.data.bonus;
            taskData.wololo_task_reward = response.data.wololo_task_reward;

            onFinish();
            closeDefaultModal();
            showFullPoints(taskData);

            getAccount()
              .then(({ data }) => {
                store.dispatch(registerAccount(data));
              })
              .catch(() => {
                showMessage(
                  'Ocorreu um erro ao atualizar as informações do usuário',
                  'danger',
                  3000
                );
              });
          })
          .catch(error => {
            if (error.response.status === 400) {
              updateErrorState(error);
              return;
            }

            showMessage(
              'Ocorreu um erro ao enviar o formulário',
              'danger',
              3000
            );
          })
          .finally(() => {
            setSendLoading(false);
          });
      })
      .catch(() => {
        setSendLoading(false);
        showMessage(
          'Ocorreu um erro ao obter as informações da ação',
          'danger',
          3000
        );
      });
  };

  const handleSend = () => {
    setSendLoading(true);

    const data = {
      answers
    };

    sendForm(activeOrganizationId, actionId, data)
      .then(() => {
        onFinish();
        closeDefaultModal();
        openMessageModal(
          <div className="action-finish-alert">
            <h3>Parabéns, você realizou esta ação.</h3>
          </div>
        );
      })
      .catch(error => {
        if (error.response.status === 400) {
          updateErrorState(error);
          return;
        }
        showMessage('Ocorreu um erro ao enviar o formulário', 'danger', 3000);
      })
      .finally(() => {
        setSendLoading(false);
      });
  };

  const getMethodForm = () => {
    if (props.isTask) {
      return getSurveyCustom;
    }

    return getSurvey;
  };

  useEffect(() => {
    const methodForm = getMethodForm();

    methodForm(activeOrganizationId, actionId)
      .then(response => {
        const { questions } = response.data;
        setQuestions(questions);
        setAnswers(questions.map(question => createAnswerStructure(question)));
      })
      .catch(error => {
        showMessage('Ocorreu um erro ao carregar o formulário', 'danger', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const checker = checkAnswers();
      setHaveAnswer(checker);
    }, 1000);
    return () => clearTimeout(timer);
  }, [answers]);

  return (
    <>
      {!props.isTask ? (
        <div className="creators-form">
          <h3 className="title">Formulário para criadores</h3>

          <div className="question-list">
            {!loading &&
              questions.map(question => (
                <CreatorsFormQuestion
                  key={question.pk}
                  question={question}
                  answers={answers}
                  setAnswer={setAnswers}
                  currentQuestionAnswer={
                    answers.filter(answer => answer.question === question.pk)[0]
                  }
                />
              ))}

            {loading && (
              <CustomLoading type="spin" height={36} width={36} fluid />
            )}
          </div>

          {!loading && questions.length > 0 && (
            <div className="actions">
              <Button
                color="primary"
                onClick={handleSend}
                disabled={sendLoading}
              >
                {sendLoading ? 'Enviando...' : 'Enviar'}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <CreatorsFormTask
          loading={loading}
          questions={questions}
          answers={answers}
          setAnswers={setAnswers}
          handleSend={handleSendFormTask}
          sendLoading={sendLoading}
          haveAnswer={haveAnswer}
          {...props}
        />
      )}
    </>
  );
}

const mapStateToProps = store => ({
  organization: store.organization
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage,
      closeDefaultModal,
      openMessageModal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreatorsForm));
