import React, { useState } from 'react';
import CommentButtons from './comment_buttons';

const CommentBoxAndButtons = ({
  CommentBoxComponent,
  initialOpened = false,
  ...props
}) => {
  const [opened, setOpened] = useState(initialOpened);

  return (
    <>
      <CommentButtons
        {...props}
        opened={opened}
        onComment={() => setOpened(true)}
        onCloseComment={() => setOpened(false)}
      />

      {opened ? <CommentBoxComponent {...props} /> : null}
    </>
  );
};

export default CommentBoxAndButtons;
