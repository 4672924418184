import styled from 'styled-components';

export const StyledStatusSelect = styled.div.attrs(() => ({
  className: 'status-select'
}))`
  width: max-content;

  .custom-select {
    & > button {
      width: 150px;
      border: none;

      &[disabled] {
        opacity: 1;
      }

      & > div {
        width: 100%;
        height: 34px;
        border-radius: 4px;
        padding: 0px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--white-color);

        i {
          margin-left: 8px;
          font-size: 12px;
        }

        svg:not([fill]) {
          fill: var(--white-color);
        }

        &.not_started {
          background: #4a4a4a;
        }

        &.in_progress {
          background: #f4b21b;
        }

        &.finished {
          background: #77a1ff;
        }

        &.canceled {
          background: #f6f6fb;
          color: var(--secondary-text-color);

          svg:not([fill]) {
            fill: var(--text-color);
          }
        }

        &.is_late {
          background: #f05050;
        }

        &.in-load {
          opacity: 0.4;
        }
      }
    }

    .suspended-content {
      min-width: 150px;

      button {
        background: var(--white-color);
        display: flex;
        align-items: center;

        span {
          display: block;
          width: 16px;
          height: 16px;
          margin-right: 8px;
          border-radius: 4px;

          &.not_started {
            background: #4a4a4a;
          }

          &.in_progress {
            background: #f4b21b;
          }

          &.finished {
            background: #77a1ff;
          }

          &.canceled {
            background: #f6f6fb;
          }

          &.is_late {
            background: #f05050;
          }
        }

        &:hover {
          background: var(--border-color);
        }
      }
    }
  }
`;
