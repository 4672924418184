import React, { useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { withRouter } from 'react-router-dom';

import { answerSurvey } from '~/api/survey';

import { setSurvey, setCurrentQuestion } from '~/store/ducks/survey';
import { openPostModal, closePostModal } from '~/store/ducks/post-modal';

import { storeSurvey, answerFactory, removeSurvey } from '~/services/survey';

import Button from '~/components/button';
import Icon from '~/components/icon';
import SkipQuestionModal from '~/components/survey/skip-question-modal';
import CompletedSurveyModal from '~/components/survey/completed-survey-modal';

import './style.scss';

function SurveyQuestion({ selectedSurvey, currentQuestionIndex, history }) {
  const dispatch = useDispatch();

  const [showSkipModal, setShowSkipModal] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendAnswer = survey => {
    const answerData = answerFactory(survey);

    setLoading(true);

    answerSurvey(survey.pk, answerData)
      .then(() => {
        removeSurvey(`survey${survey.pk}`);

        dispatch(
          openPostModal(
            <CompletedSurveyModal onClose={() => dispatch(closePostModal())} />
          )
        );

        history.push('/survey');
      })
      .catch(() => {
        alert('Ocorreu um erro inesperado!');
        history.push('/survey');
      });
  };

  const storeAnswer = survey => {
    storeSurvey(survey);

    dispatch(setSurvey(survey));
    dispatch(setCurrentQuestion(currentQuestionIndex + 1));
    setAnswer(null);
  };

  const handleAnswer = () => {
    selectedSurvey.questions[currentQuestionIndex].answer = answer;

    if (selectedSurvey.questions.length === currentQuestionIndex + 1) {
      sendAnswer(selectedSurvey);
    } else {
      storeAnswer(selectedSurvey);
    }
  };

  const skipQuestion = () => {
    setShowSkipModal(true);
    setAnswer(null);
  };

  return (
    <>
      <div className="survey-question">
        <div className="options">
          <button
            type="button"
            onClick={() => setAnswer(0)}
            className={answer === 0 ? 'active' : ''}
          >
            0
          </button>
          <button
            type="button"
            onClick={() => setAnswer(1)}
            className={answer === 1 ? 'active' : ''}
          >
            1
          </button>
          <button
            type="button"
            onClick={() => setAnswer(2)}
            className={answer === 2 ? 'active' : ''}
          >
            2
          </button>
          <button
            type="button"
            onClick={() => setAnswer(3)}
            className={answer === 3 ? 'active' : ''}
          >
            3
          </button>
          <button
            type="button"
            onClick={() => setAnswer(4)}
            className={answer === 4 ? 'active' : ''}
          >
            4
          </button>
          <button
            type="button"
            onClick={() => setAnswer(5)}
            className={answer === 5 ? 'active' : ''}
          >
            5
          </button>
        </div>
        <div className="actions">
          <Button
            color="primary"
            className="outline button secondary"
            onClick={() => skipQuestion()}
          >
            <Icon name="cancel-circle" color="primary" />
            Pular questão
          </Button>
          <Button color="primary" onClick={handleAnswer}>
            <Icon name="arrow-right" color="white" />
            {!loading ? 'Responder' : 'Concluindo...'}
          </Button>
        </div>
      </div>

      {showSkipModal && (
        <SkipQuestionModal
          onClose={() => setShowSkipModal(false)}
          survey={selectedSurvey}
          currentQuestionIndex={currentQuestionIndex}
        />
      )}
    </>
  );
}

export default withRouter(SurveyQuestion);
