import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useDispatch } from 'redux-react-hook';
import _ from 'lodash';

import { getAccount } from '~/api/account';
import { tokenRefresh } from '~/api/auth';

import { logoff, getToken, isAuth } from '~/services/auth';

import { registerAccount, unregisterAccount } from '~/store/ducks/account';
import { setOrganization } from '~/store/ducks/organization';
import { showMessage } from '~/store/ducks/messageBar';

import useSite from '~/hooks/use-site';

import { findActiveOrganization } from '~/utils';

import DefaultModal from '~/components/default-modal';
import NewDefaultModal from '~/components/new-default-modal';
import Notifications from '~/components/profile-task/tasks/notifications';
import Footer from '~/components/footer';

import './style.scss';

const ExpandedLayout = ({
  account,
  organization: { activeOrganizationId },
  registerAccount,
  unregisterAccount,
  defaultModal,
  newDefaultModal,
  history,
  children
}) => {
  const dispatch = useDispatch();
  const { loginMinutes } = useSite();

  if (account.data && account.data.is_reseted_password) {
    history.push('/account/change-password');
  }

  useEffect(() => {
    if (isAuth(loginMinutes) && !account.loaded) {
      getAccount()
        .then(({ data }) => {
          registerAccount(data);

          const newActiveOrganization = findActiveOrganization(
            data.organization_is_active_set,
            activeOrganizationId
          );

          dispatch(setOrganization(newActiveOrganization.id));

          const token = getToken();
          tokenRefresh({
            organization: newActiveOrganization.id,
            token: token
          }).catch(() => {
            history.push('/unexpected-error');
          });
        })
        .catch(e => {
          if (
            ['authentication_failed', 'not_authenticated'].includes(
              _.get(e, 'response.data.code')
            )
          ) {
            unregisterAccount();
            logoff();
            history.push('/sign-in');
          }
        });
    }
  }, []);

  return (
    <div className="expanded-wrapper">
      {account.loaded && <div className="expanded-main">{children}</div>}

      <Footer />

      <DefaultModal isOpen={defaultModal.open} onClose={defaultModal.onClose}>
        {defaultModal.content}
      </DefaultModal>

      <NewDefaultModal
        isOpen={newDefaultModal.open}
        onClose={newDefaultModal.onClose}
      >
        {newDefaultModal.content}
      </NewDefaultModal>

      <div id="modal-root" />

      <Notifications />
    </div>
  );
};

const mapStateToProps = ({
  account,
  organization,
  defaultModal,
  newDefaultModal,
  messageModal,
  mediaModal,
  genericModal
}) => ({
  account,
  organization,
  defaultModal,
  newDefaultModal,
  messageModal,
  mediaModal,
  genericModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerAccount,
      unregisterAccount,
      showMessage
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExpandedLayout)
);
