import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createPDI, showPDI, updatePDI } from '~/api/performance';

import { closeDefaultModal } from '~/store/ducks/default-modal';
import { showMessage } from '~/store/ducks/messageBar';

import Select from '~/components/form/select';
import Input from '~/components/form/input';
import Button from '~/components/button';
import CustomLoading from '~/components/custom-loading';

import { StyledPDIRegisterModal } from './styles';

function PDIRegisterModal({
  pdiId,
  feedback,
  targetId,
  feedbackId,
  getPDIList,
  currentPage,
  organization,
  closeDefaultModal,
  message
}) {
  const { activeOrganizationId } = organization;

  const [formState, setFormState] = useState({
    competence: feedback.competences[0].pk,
    gap: '',
    action: '',
    learning_model: feedback.learning_model[0]
      ? feedback.learning_model[0].pk
      : null,
    deadline: ''
  });
  const [fieldErrors, setFieldErrors] = useState({
    deadline: {
      hasError: false,
      errorMessage: ''
    }
  });
  const [loading, setLoading] = useState(pdiId ? true : false);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleCompetence = ({ target }) => {
    setFormState(lastFormState => ({
      ...lastFormState,
      competence: target.value
    }));
  };

  const handleGap = ({ target }) => {
    setFormState(lastFormState => ({
      ...lastFormState,
      gap: target.value
    }));
  };

  const handleAction = ({ target }) => {
    setFormState(lastFormState => ({
      ...lastFormState,
      action: target.value
    }));
  };

  const handleLearningModel = ({ target }) => {
    setFormState(lastFormState => ({
      ...lastFormState,
      learning_model: target.value
    }));
  };

  const handleDeadline = ({ target }) => {
    setFormState(lastFormState => ({
      ...lastFormState,
      deadline: target.value
    }));
  };

  const handleSave = () => {
    setSaveLoading(true);

    createPDI(activeOrganizationId, targetId, feedbackId, formState)
      .then(() => {
        getPDIList(1, feedback);
        closeDefaultModal();
      })
      .catch(error => {
        if (error.response.status === 400) {
          setFieldErrors(lastFieldErrors => {
            error.response.data.errors.forEach(error => {
              lastFieldErrors[error.field].errorMessage = error.error;
              lastFieldErrors[error.field].hasError = true;
            });
            return lastFieldErrors;
          });

          return;
        }

        message.showMessage('Ocorreu um erro ao salvar o PDI.', 'danger', 3000);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const handleUpdate = () => {
    setSaveLoading(true);

    updatePDI(activeOrganizationId, targetId, feedbackId, pdiId, formState)
      .then(() => {
        getPDIList(currentPage, feedback);
        closeDefaultModal();
      })
      .catch(error => {
        if (error.response.status === 400) {
          message.showMessage(error.response.data.errors[0].error, 'danger');
          return;
        }

        message.showMessage(
          'Ocorreu um erro ao atualizar o PDI.',
          'danger',
          3000
        );
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const handleSaveAction = () => {
    if (pdiId) {
      handleUpdate();
    } else {
      handleSave();
    }
  };

  useEffect(() => {
    if (pdiId) {
      showPDI(activeOrganizationId, targetId, feedbackId, pdiId)
        .then(response => {
          const {
            action,
            competence,
            gap,
            learning_model,
            deadline
          } = response.data;

          setFormState({
            competence: competence.id,
            gap,
            action,
            learning_model: learning_model.pk,
            deadline
          });
        })
        .catch(error => {
          closeDefaultModal();
          message.showMessage(
            'Ocorreu um erro ao carregar o PDI.',
            'danger',
            3000
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <StyledPDIRegisterModal>
      <h3>PDI</h3>

      {!loading && (
        <div className="fields">
          <div className="form-group">
            <label htmlFor="competence">Competência</label>
            <Select
              name="competence"
              id="competence"
              onChange={handleCompetence}
              value={formState.competence}
              disabled={saveLoading}
            >
              {feedback.competences &&
                feedback.competences.map(competence => (
                  <option key={competence.pk} value={competence.pk}>
                    {competence.title}
                  </option>
                ))}
            </Select>
          </div>

          <div className="form-group">
            <label htmlFor="gap">Lacuna</label>
            <Input
              type="text"
              id="gap"
              value={formState.gap}
              onChange={handleGap}
              disabled={saveLoading}
            />
          </div>

          <div className="form-group">
            <label htmlFor="action">Ação</label>
            <Input
              type="text"
              textarea
              id="action"
              value={formState.action}
              onChange={handleAction}
              disabled={saveLoading}
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="learningModels">Tipo de experiência</label>
              <Select
                name="learningModels"
                id="learningModels"
                onChange={handleLearningModel}
                value={formState.learning_model}
                disabled={saveLoading}
              >
                {feedback.learning_model &&
                  feedback.learning_model.map(learningModel => (
                    <option key={learningModel.pk} value={learningModel.pk}>
                      {learningModel.title}
                    </option>
                  ))}
              </Select>
            </div>

            <div className="form-group">
              <label htmlFor="deadline">Prazo em semanas</label>
              <Input
                type="number"
                id="deadline"
                value={formState.deadline}
                onChange={handleDeadline}
                min="1"
                disabled={saveLoading}
              />
              {fieldErrors.deadline.hasError && (
                <p className="error-message">
                  {fieldErrors.deadline.errorMessage}
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      {!loading && (
        <div className="actions">
          <Button
            color="primary"
            className="outline"
            onClick={() => closeDefaultModal()}
            disabled={saveLoading}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={handleSaveAction}
            disabled={saveLoading}
          >
            {!saveLoading && 'Salvar'}
            {saveLoading && (
              <CustomLoading
                type="spin"
                height={16}
                width={16}
                fluid
                color="#FFFFFF"
              />
            )}
          </Button>
        </div>
      )}

      {loading && <CustomLoading type="spin" height={56} width={56} fluid />}
    </StyledPDIRegisterModal>
  );
}

const mapDispatchToProps = dispatch => ({
  closeDefaultModal: () => {
    dispatch(closeDefaultModal());
  },
  message: bindActionCreators(
    {
      showMessage
    },
    dispatch
  )
});

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PDIRegisterModal));
