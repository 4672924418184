import api from './api';

export const signIn = (username, password) =>
  api.post('/login/email-and-password/', {
    device: 'web',
    username: username,
    email: username,
    password
  });

export const signUp = (name, email, password) =>
  api.post('/signup/email-and-password', {
    device: 'web',
    name,
    email,
    password
  });

export const recovery = email =>
  api.post('/login/password-reset-key-request', {
    email
  });

export const passwordReset = (id, key, new_password) =>
  api.post('/login/password-reset-request/', {
    device: 'web',
    id,
    key,
    new_password
  });

export const loginFacebook = token =>
  api.post('/login/facebook', {
    device: 'web',
    token
  });

export const getInvitation = uuid => api.get(`/invitations/${uuid}`);
export const consumeInvitation = uuid =>
  api.post(`/invitations/${uuid}/consume-request/`);

export const tokenRefresh = ({ organization, token }) =>
  api.post(`/login/token-refresh/`, {
    organization,
    token
  });
