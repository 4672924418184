import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import Icon from '../icon';
import { copyToClipboard } from '~/utils';
import { postShare } from '~/api/post';
import './style.scss';

const SharePost = ({ post, link }) => {
  const shareUrl = encodeURI(link);

  const share = url => {
    window.open(url);
    postShare(post);
  };

  const copyLink = () => {
    copyToClipboard(link);
  };

  return (
    <div className="text-center">
      <h2>Compartilhar</h2>
      <div className="social-share">
        <div>
          <Button
            onClick={() =>
              share(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`)
            }
            color="facebook"
            circle
          >
            <Icon name="facebook" />
          </Button>
          <h4>Facebook</h4>
        </div>
        <div>
          <Button
            onClick={() => share(`https://twitter.com/home?status=${shareUrl}`)}
            color="twitter"
            circle
          >
            <Icon name="twitter" />
          </Button>
          <h4>Twitter</h4>
        </div>
        <div>
          <Button
            onClick={() => share(`https://wa.me/?text=${shareUrl}`)}
            color="whatsapp"
            circle
          >
            <Icon name="whatsapp" />
          </Button>
          <h4>Whatsapp</h4>
        </div>
        <div>
          <Button color="primary" onClick={copyLink} circle>
            <Icon name="attachment" />
          </Button>
          <h4>Copiar URL</h4>
        </div>
        <div>
          <Button
            onClick={() => share(`mailto:?&body=${shareUrl}`)}
            color="primary"
            circle
          >
            <Icon name="envelop" />
          </Button>
          <h4>E-mail</h4>
        </div>
      </div>
    </div>
  );
};

SharePost.propTypes = {
  link: PropTypes.string.isRequired
};

export default SharePost;
