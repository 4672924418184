import React from 'react';

import { REACT_APP_SITE } from '~/settings';

import Card from '~/components/card';
import Icon from '~/components/icon';
// import Link from '~/components/link';
import StoreLinks from '~/components/store-links';
import {
  PublicBanner,
  PublicFooter,
  PublicHeader,
  PublicWrapper
} from '~/components/public';

import { StyledPasswordResetSuccess } from './styles';

function PasswordResetSuccessScreen() {
  return (
    <StyledPasswordResetSuccess>
      <PublicWrapper>
        <PublicHeader>
          <Card className="public-card">
            <div className="content-icon-success">
              <Icon name="check" />
            </div>

            <div className="title-subtitle">
              <strong>Senha alterada com sucesso!</strong>
              <p>
                Acesse o aplicativo Talkative no seu telefone para continuar.
              </p>
              {/* <Link to="/sign-in">
                <span>Continuar no navegador</span>
                <Icon name="arrow-right" marginLeftSmall />
              </Link> */}
            </div>
          </Card>
        </PublicHeader>
        <div className="main">
          <PublicBanner>
            {REACT_APP_SITE !== 'flow' && REACT_APP_SITE !== 'elle' ? (
              <StoreLinks>
                <span>Usuário, baixe nosso app para</span>
                <br />
                <span>interagir com suas comunidades.</span>
                <br />
                <span>Disponível para iOS e Android.</span>
              </StoreLinks>
            ) : (
              <span>Seja bem-vindo!</span>
            )}
          </PublicBanner>
          <div className="hide-on-mobile" style={{ marginBottom: 200 }} />
        </div>
        <PublicFooter />
      </PublicWrapper>
    </StyledPasswordResetSuccess>
  );
}

export default PasswordResetSuccessScreen;
