import styled from 'styled-components';

export const StyledMetlifeExtracts = styled.div.attrs(() => ({
  className: 'metlife-extracts'
}))`
  .header-actions {
    min-height: 34px;
  }

  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;

    h1 {
      margin: 0px;
    }

    .search-input {
      max-height: 42px;
      width: 40%;
      margin-left: 16px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .tag-filter {
    margin-bottom: 16px;
  }

  .departments {
    margin-bottom: 16px;
  }

  @media (max-width: 720px) {
    .actions {
      flex-direction: column;

      .breadcrumb,
      .search-input {
        width: 100%;
      }

      .search-input {
        margin-left: 0px;
      }
    }
  }
`;
