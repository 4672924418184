import styled from 'styled-components';

export const StyledCourseReactionSurveyQuestion = styled.div`
  width: 100%;
  background: var(--white-color);
  /* padding: 32px 42px; */
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 32px;

  .question-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;
    padding: 32px 42px 0px 42px;

    .info {
      width: 100%;
      display: flex;
      align-items: flex-start;

      .question-number {
        width: 28px;
        min-width: 28px;
        height: 28px;
        border: 1px solid var(--border-color);
        border-radius: 50%;
        text-align: center;
        line-height: 28px;
        color: var(--text-color);
        margin-right: 12px;
      }

      .statement {
        margin-top: 5px;
        color: var(--text-color);
      }
    }

    .actions {
      padding-left: 8px;
      display: flex;
      justify-content: center;

      i.correct {
        font-size: 20px;
        color: var(--success-color);
      }

      i.incorrect {
        font-size: 18px;
        color: var(--failure-color);
      }
    }
  }

  .single-choice-options {
    width: 100%;
    padding: 0px 42px 8px 42px;
    border-bottom: 1px solid var(--border-color);

    .button {
      width: 100%;
      border: 1px solid var(--border-color);
      color: var(--secondary-text-color);
      text-align: start;
      display: flex;
      align-items: center;
      padding: 8px 18px;
      margin-bottom: 24px;
      line-height: 18px;

      :hover {
        background: var(--background-color);
        color: var(--primary-color);
      }

      &.selected {
        border-color: var(--primary-color);
        color: var(--primary-color);
      }
    }
  }

  .text-field {
    width: 100%;
    padding: 0px 42px 8px 42px;
    border-bottom: 1px solid var(--border-color);
  }

  .nps-options {
    width: 100%;
    padding: 0px 42px 8px 42px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 32px;
    padding-top: 32px;
    border-top: 1px solid var(--border-color);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .option-wrapper {
      position: relative;
      width: max-content;
      margin: 16px;
      height: 34px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .button {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        padding: 0px;
        border: 1px solid var(--border-color);
        color: var(--secondary-text-color);
        text-align: center;
        line-height: 32px;

        display: flex;
        justify-content: center;
        align-items: center;

        :hover {
          background: var(--background-color);
          color: var(--primary-color);
        }

        &.selected {
          border-color: var(--primary-color);
          color: var(--primary-color);
        }
      }

      .description {
        position: absolute;
        width: 64px;
        max-width: 64px;
        line-height: 1;
        text-align: start;
        color: var(--secondary-text-color);
        font-size: 12px;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .option-wrapper:first-child {
      .description {
        left: -72px;
        text-align: end;
      }
    }

    .option-wrapper:last-child {
      .description {
        right: -72px;
      }
    }
  }

  .ReactCollapse--collapse {
    transition: height 400ms;
  }

  @media (max-width: 720px) {
    .question-header {
      flex-direction: column-reverse;
      padding: 16px 0px 0px;

      & > div {
        width: 100%;
        padding: 16px;
      }

      .actions {
        justify-content: flex-end;
        padding: 0px 16px 16px 16px;
        border-bottom: 1px solid var(--border-color);
      }
    }

    .single-choice-options {
      padding: 0px 16px 8px 16px;
    }
  }
`;

export default StyledCourseReactionSurveyQuestion;
