import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import store from '~/store';

import { openMessageModal } from '~/store/ducks/message-modal';
import { openDefaultModal } from '~/store/ducks/default-modal';

import CreatorsForm from '~/components/creators-form';
import Button from '../../button';
import Icon from '../../icon';

class Creators extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.openForm = this.openForm.bind(this);
  }

  openForm() {
    store.dispatch(
      openDefaultModal(
        <CreatorsForm
          actionId={this.props.wololoTargetId}
          onFinish={this.props.onFinish}
          {...this.props}
        />
      )
    );
  }

  render() {
    return (
      <div>
        <Button
          color="primary"
          disabled={this.state.loading}
          onClick={this.openForm}
        >
          <Icon name="arrow-right" marginRight />
          <span>{this.props.isTask ? 'Realizar' : 'Responder'}</span>
        </Button>
      </div>
    );
  }
}

Creators.defaultProps = {
  onFinish: () => {}
};

Creators.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Creators);
