import React from 'react';
import Modal from '~/components/modal';
import Button from '../button';
import Review from '~/assets/images/instagram-external/pending-review.png';
import Review2x from '~/assets/images/instagram-external/pending-review@2x.png';
import './styles.scss';

const ExternalActionConfirm = ({ onConfirm, onCancel }) => (
  <Modal onClose={onCancel}>
    <div className="external-action-confirm text-center">
      <img src={Review} alt="Review" srcSet={`${Review2x} 2x`} />

      <div className="title-holder">
        <h3>Enviar para verificação</h3>

        <p>
          Nosso sistema precisa verificar se esta ação foi realizada para
          entregar sua pontuação
        </p>
      </div>

      <p className="confirm-question">Confirmar realização?</p>
      <div>
        <Button color="primary" fill onClick={onConfirm}>
          Sim, realizei
        </Button>
      </div>
      <div>
        <Button color="black" fill onClick={onCancel}>
          Não, deixarei para depois
        </Button>
      </div>
    </div>
  </Modal>
);

export default ExternalActionConfirm;
