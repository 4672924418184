import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuth } from '~/services/auth';

import useSite from '~/hooks/use-site';

import ExpandedLayout from '~/layouts/expanded-layout';

const Expanded = ({ component: Component, noSidebar, ...rest }) => {
  const { loginMinutes } = useSite();
  const { redirectTo } = rest;

  if (redirectTo) {
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Redirect
            to={{ pathname: redirectTo, state: { from: matchProps.location } }}
          />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={matchProps =>
        isAuth(loginMinutes) ? (
          <ExpandedLayout>
            <Component {...matchProps} />
          </ExpandedLayout>
        ) : (
          <Redirect
            to={{ pathname: '/sign-in', state: { from: matchProps.location } }}
          />
        )
      }
    />
  );
};

export default Expanded;
