import React, { useState } from 'react';
import { Collapse } from 'react-collapse';

import Button from '~/components/button';
import Icon from '~/components/icon';
import CourseStep from '~/components/course-step';

import { StyledCourseTrail } from './styles';

function CourseTrail({ title, steps, parent, courseId }) {
  const [isOpened, setIsOpened] = useState(false);

  const getStatus = (hasAnswer, canAnswer) => {
    if (hasAnswer) {
      return 'done';
    }

    if (!hasAnswer && canAnswer) {
      return 'available';
    }

    return '';
  };

  const convertToStepList = steps => {
    const stepList = [];

    steps.forEach(step => {
      let currentStep = { ...step };
      let currentChild = step.child ? { ...step.child } : null;
      delete currentStep.child;
      stepList.push(currentStep);

      while (currentChild) {
        currentStep = { ...currentChild };
        currentChild = currentStep.child ? { ...currentStep.child } : null;
        delete currentStep.child;

        stepList.push(currentStep);
      }
    });

    return stepList;
  };

  return (
    <StyledCourseTrail
      isOpened={isOpened}
      className={parent ? 'has-parent' : ''}
    >
      {parent && <Icon name="arrow-up" />}
      <Button
        color="white"
        className="trail-header link"
        onClick={() => setIsOpened(!isOpened)}
      >
        <h3>{title}</h3>
        {isOpened ? <Icon name="up-arrow" /> : <Icon name="down-arrow" />}
      </Button>
      <div className="trail-content">
        <Collapse
          isOpened={isOpened}
          initialStyle={{ height: 0, overflow: 'hidden' }}
        >
          {convertToStepList(steps).map(step => (
            <CourseStep
              key={step.pk}
              courseId={courseId}
              stepId={step.pk}
              title={step.title}
              contentType={step.type}
              status={getStatus(step.has_answer, step.can_answer)}
              parent={step.parent}
              disabled={!step.can_answer && !step.has_answer}
            />
          ))}
        </Collapse>
      </div>
    </StyledCourseTrail>
  );
}

export default CourseTrail;
