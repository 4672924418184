import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../button';
import Icon from '../../icon';
import { openMessageModal } from '~/store/ducks/message-modal';
import { updateProfile } from '~/api/account';
import { showNewPoints } from '~/services/tasks';
import { Form, Field } from 'react-final-form';
import { InputFinalForm } from '~/components/form';
import {
  requiredValueValidator,
  composeValidators,
  cpfValidator as cpfFormValidator
} from '~/components/form/validators';

const cpfValidator = composeValidators(
  requiredValueValidator,
  cpfFormValidator
);

class Cpf extends Component {
  state = {
    loading: false
  };

  handleSubmit = async ({ cpf }) => {
    if (!this.state.loading) {
      const { name, email } = this.props.account.data.profile;

      this.setState({ loading: true });

      try {
        await updateProfile({
          cpf,
          name,
          email
        });

        showNewPoints(this.props.pointsToShow);
        this.props.onFinish();
      } catch (e) {
        this.setState({ loading: false });
        const errors = _.get(e, 'response.data.errors');

        if (errors) {
          return errors.reduce((memo, { error, field }) => {
            memo[field] = error;
            return memo;
          }, {});
        } else {
          alert('Houve um erro desconhecido. Tente mais tarde');
        }
      }
    }
  };

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="cpf"
              component={InputFinalForm}
              validate={cpfValidator}
              mask={[
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/
              ]}
            />

            <Button color="primary" type="submit" disabled={this.state.loading}>
              <Icon name="arrow-right" marginRight />
              <span>Enviar</span>
            </Button>
          </form>
        )}
      />
    );
  }
}

Cpf.defaultProps = {
  onFinish: () => {}
};

Cpf.propTypes = {
  onFinish: PropTypes.func
};

const mapStateToProps = store => ({
  account: store.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openMessageModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cpf);
