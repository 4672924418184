import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { showMessage } from '~/store/ducks/messageBar';

import { copyToClipboard } from '~/utils';

import Button from '../button';
import Icon from '../icon';

import { StyledLinkPreview } from './styles';

function LinkPreview({ url, showMessage, onClick }) {
  const copyLink = () => {
    if (onClick) {
      onClick({ copy: true, url });
    } else {
      copyToClipboard(url);
      showMessage('Link copiado para a área de transferência', 'success', 2000);
    }
  };

  const handleClick = event => {
    if (onClick) {
      event.preventDefault();
      onClick({ url });
    }
  };

  return (
    <StyledLinkPreview>
      <div className="info-side">
        <div className="preview">
          <Icon name="globe" />
        </div>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="library-file"
          onClick={handleClick}
        >
          Acessar conteúdo
        </a>
      </div>
      <div className="actions-side">
        <Button color="primary" className="link" onClick={copyLink}>
          Copiar
        </Button>
      </div>
    </StyledLinkPreview>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(LinkPreview);
