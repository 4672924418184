import { useCallback } from 'react';
import { useMappedState } from 'redux-react-hook';

import { getKey } from '~/store/ducks/comments';

export default (type, id) => {
  const mapStateToProps = useCallback(({ comments }) => ({ comments }), []);
  const { comments } = useMappedState(mapStateToProps);

  return comments[getKey(type, id)];
};
