import React from 'react';

import Button from '~/components/button';

import { StyledSingleChoiceQuestion } from './styles';

function SingleChoiceQuestion({
  options,
  answer,
  setAnswer,
  correctAnswers,
  disabled,
  isDone
}) {
  const handleSelect = answer => {
    setAnswer(answer);
  };

  return (
    <StyledSingleChoiceQuestion>
      <div className="question-options">
        {options.map(option => (
          <Button
            key={option.pk}
            color="primary"
            className={`
              outline
              ${answer === option.pk ? ' selected' : ''}
              ${
                correctAnswers.some(answer => answer === option.pk) && isDone
                  ? ' is-correct'
                  : ''
              }
            `}
            onClick={() => handleSelect(option.pk)}
            disabled={disabled}
          >
            <p>{option.text}</p>
          </Button>
        ))}
      </div>
    </StyledSingleChoiceQuestion>
  );
}

export default SingleChoiceQuestion;
