import { useEffect } from 'react';
import useVersion from '~/hooks/use-version';

export default () => {
  const version = useVersion();

  useEffect(
    () => {
      const body = document.querySelector('body');

      body.classList.remove(`desktop-version`);
      body.classList.remove(`mobile-version`);
      body.classList.add(`${version}-version`);
    },
    [version]
  );

  return null;
};
