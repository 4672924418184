import styled from 'styled-components';

export const StyledInputCustom = styled.div.attrs(() => ({
  className: 'input-custom'
}))`
  .input-custom-container {
    max-width: 309px;
    height: 48px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px 14px;
    border: 1px solid #dde0e6;
    border-radius: 8px;

    div {
      width: 40px;
      height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon {
      font-size: 24px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input {
      border: none;
    }
  }
`;
